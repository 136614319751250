import { IVisibleMessage } from '../../../../types';
import {
    ADD_MESSAGE,
    MessageActionTypes,
    REMOVE_MESSAGE,
} from '../../types/messageActionTypes';

const DEFAULT_STATE: IVisibleMessage = {
    message: { text: '', bgColor: '', visible: false },
};

const messageReducer = (state = DEFAULT_STATE, action: MessageActionTypes) => {
    switch (action.type) {
        case ADD_MESSAGE:
            return {
                ...state,
                message: action.message,
            };
        case REMOVE_MESSAGE:
            return {
                ...state,
                message: action.message,
            };
        default:
            return state;
    }
};

export default messageReducer;
