import React, {
    FC,
    useState,
    useCallback,
    useEffect,
    useRef,
    FormEvent,
} from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import { Grid, Input, Button } from '@material-ui/core';
import { connect } from 'react-redux';

// or scss:
import './Cropper.scss';
import { IProduct, IProductForm } from '../../../../types';
import { AppState } from '../../store/store';
import { mapDispatchToProps } from '../../types/dispatchActionTypes';

interface IOwnProps {
    product: IProduct;
}

interface ICropperStateProps {
    productForm: IProductForm;
}

type CropperProps = ICropperStateProps & IDispatchProps & IOwnProps;

const Cropper: FC<CropperProps> = (props) => {
    const [src, setSrc] = useState<string | ArrayBuffer | null | undefined>('');
    const [crop, setCrop] = useState<Crop>({
        unit: '%',
        width: 40,
        aspect: 1 / 1,
    });
    const imgRef = useRef<any>(null);
    const previewCanvasRef = useRef<any>(null);
    const [completedCrop, setCompletedCrop] = useState<any>(null);

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image: any = imgRef.current;
        const canvas: any = previewCanvasRef.current;
        const crop: any = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height,
        );
    }, [completedCrop]);

    const handleChange = (event: any) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = function (event: ProgressEvent<FileReader>) {
            // The file's text will be printed here
            setSrc(event?.target?.result);
        };
        reader.readAsDataURL(file);
    };

    const handleSubmit = async (event: FormEvent) => {
        const { product, editProductsImageAction } = props;
        event.preventDefault();
        const canvas: any = previewCanvasRef.current;
        canvas.toBlob((blob: Blob) => {
            const formData = new FormData();
            formData.append('image', blob, `product_cover.${Date.now()}.png`);
            console.log(formData);
            editProductsImageAction(formData, product._id);
        });
    };

    return (
        <section>
            <form onSubmit={handleSubmit}>
                <Grid container={true} justify="center">
                    <Grid item={true} xs={12} md={8}>
                        <Input
                            type="file"
                            name="image"
                            onChange={handleChange}
                        />
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                        >
                            Send
                        </Button>
                    </Grid>
                    <Grid item={true} xs={12} md={8}>
                        <ReactCrop
                            src={src as string}
                            onImageLoaded={onLoad}
                            crop={crop}
                            onChange={(newCrop: Crop) => setCrop(newCrop)}
                            onComplete={(crop: Crop) => setCompletedCrop(crop)}
                        />
                    </Grid>
                    <Grid item={true} xs={12} md={8}>
                        <canvas
                            ref={previewCanvasRef}
                            // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                            style={{
                                width: Math.round(completedCrop?.width ?? 0),
                                height: Math.round(completedCrop?.height ?? 0),
                            }}
                        />
                    </Grid>
                </Grid>
            </form>
        </section>
    );
};

function mapStateToProps(state: AppState): ICropperStateProps {
    return {
        productForm: state.productFormState.productForm,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Cropper);
