import { ILocalitiesByRegion } from '../../../../types';
import {
    LocalitiesByRegionActionTypes,
    SET_LOCALITIES_BY_REGION,
} from '../../types/localitiesByRegionAcionTypes';

const DEFAULT_STATE: ILocalitiesByRegion[] = [];

const localitiesByRegionReducer = (
    state = DEFAULT_STATE,
    action: LocalitiesByRegionActionTypes,
) => {
    switch (action.type) {
        case SET_LOCALITIES_BY_REGION:
            return action.localitiesByRegion;
        default:
            return state;
    }
};

export default localitiesByRegionReducer;
