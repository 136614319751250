import {
    UPDATE_CHECKOUT_FORM,
    RESET_CHECKOUT_FORM,
} from '../../types/checkoutFormActionTypes';
import { AppActions } from '../../types/actions';
import { ICheckoutForm, ICheckoutFormState } from '../../../../types';

export function updateCheckoutForm(checkoutForm: ICheckoutForm): AppActions {
    return {
        type: UPDATE_CHECKOUT_FORM,
        checkoutFormState: { checkoutForm },
    };
}

export function resetCheckoutForm(
    checkoutFormState: ICheckoutFormState,
): AppActions {
    return {
        type: RESET_CHECKOUT_FORM,
        checkoutFormState,
    };
}
