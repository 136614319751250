import { ISignUpFormState } from '../../../types';

export const UPDATE_SIGNUP_FORM = 'UPDATE_SIGNUP_FORM';
export const RESET_SIGNUP_FORM_STATE = 'RESET_SIGNUP_FORM_STATE';

export interface UpdateSignUpForm {
    type: typeof UPDATE_SIGNUP_FORM;
    signUpFormState: ISignUpFormState;
}

export interface ResetSignUpFormState {
    type: typeof RESET_SIGNUP_FORM_STATE;
    signUpFormState: ISignUpFormState;
}

export type SignUpFormActionTypes = UpdateSignUpForm | ResetSignUpFormState;
