import { IContact } from '../../../types';

export const SET_CONTACTS = 'SET_CONTACTS';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const SET_CONTACT = 'SET_CONTACT';

export interface SetContacts {
    type: typeof SET_CONTACTS;
    contacts: IContact[];
}

export interface UpdateContact {
    type: typeof UPDATE_CONTACT;
    contact: IContact;
}

export interface SetContact {
    type: typeof SET_CONTACT;
    contact: IContact;
}

export type ContactsActionTypes = SetContacts | UpdateContact | SetContact;
