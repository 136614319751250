import { IAccount } from '../../../types';

export const SET_ACCOUNTS = 'SET_ACCOUNTS';
export const ADD_ACCOUNT = 'ADD_ACCOUNT';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const REMOVE_ACCOUNT = 'REMOVE_ACCOUNT';

export interface SetAccounts {
    type: typeof SET_ACCOUNTS;
    accounts: IAccount[];
}

export interface AddAccount {
    type: typeof ADD_ACCOUNT;
    account: IAccount;
}

export interface UpdateAccount {
    type: typeof UPDATE_ACCOUNT;
    account: IAccount;
}

export interface RemoveAccount {
    type: typeof REMOVE_ACCOUNT;
    id: string;
}

export type AccountActionTypes =
    | SetAccounts
    | AddAccount
    | UpdateAccount
    | RemoveAccount;
