import { IOikoPolkuLink } from '../../types';

export const OikopolutLinks: IOikoPolkuLink[] = [
    {
        href: '/polttopuuinfo',
        innerHTML: 'Polttopuuinfo',
        id: 1,
    },
    {
        href: '/polttopuuinfo/termit-ja-mittayksikot',
        innerHTML: 'Termit ja mittayksiköt',
        id: 2,
    },
    {
        href: '/polttopuuinfo/polttopuun-ominaisuudet',
        innerHTML: 'Polttopuun ominaisuudet',
        id: 3,
    },
    {
        href: '/polttopuuinfo/polttopuun-kuivaus-ja-varastointi',
        innerHTML: 'Polttopuun kuivaus ja varastointi',
        id: 4,
    },
    {
        href: '/polttopuuinfo/tulisijan-lammitys',
        innerHTML: 'Tulisijan lämmitys',
        id: 5,
    },
    {
        href: '/polttopuuinfo/energiavertailu',
        innerHTML: 'Energiavertailu',
        id: 6,
    },
    {
        href: '/polttopuuinfo/energialaskuri',
        innerHTML: 'Energialaskuri',
        id: 7,
    },
];
