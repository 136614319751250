import { IDeliveryCost, IDeliveryCostForm } from '../../../../types';
import { apiCall, setHeader } from '../../apiRequests/apiCall';
import { AppActions } from '../../types/actions';
import {
    ADD_DELIVERYCOST,
    REMOVE_DELIVERYCOST,
    SET_DELIVERYCOSTS,
    UPDATE_DELIVERYCOST,
} from '../../types/deliveryCostActionTypes';
import { addMessage } from './messageActions';
import { Dispatch } from 'redux';
import { resetDeliveryCostForm } from './deliveryCostFormActions';
import { resetDeliveryCostFormFunction } from '../../utils/resetUtils';

/** Public Method */
export function setDeliveryCosts(deliveryCosts: IDeliveryCost[]): AppActions {
    return {
        type: SET_DELIVERYCOSTS,
        deliveryCosts,
    };
}
/** Restricted access to product owners only, for Users */
export function addDeliveryCost(deliveryCost: IDeliveryCost): AppActions {
    return {
        type: ADD_DELIVERYCOST,
        deliveryCost,
    };
}

export function updateDeliveryCost(deliveryCost: IDeliveryCost): AppActions {
    return {
        type: UPDATE_DELIVERYCOST,
        deliveryCost,
    };
}

export function removeDeliveryCost(deliveryCostId: string): AppActions {
    return {
        type: REMOVE_DELIVERYCOST,
        id: deliveryCostId,
    };
}

/** Public method */
export const fetchDeliveryCosts = (query: string): ThunkResult<void> => {
    setHeader('get', '');
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('get', '/api/deliverycosts' + query, null)
                .then((res: any) => {
                    dispatch(setDeliveryCosts(res.deliveryCosts));
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

/** Restricted access to admins only */
/** create Region */
export const createDeliveryCost = (
    regionForm: IDeliveryCostForm,
): ThunkResult<void> => {
    setHeader('post', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('post', '/api/deliverycosts', regionForm)
                .then((res: any) => {
                    const { deliveryCost, message } = res;
                    dispatch(addDeliveryCost(deliveryCost));
                    dispatch(
                        addMessage({
                            text: message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

/** edit Region */
export const editDeliveryCost = (
    regionForm: IDeliveryCostForm,
    regionId: string,
): ThunkResult<void> => {
    setHeader('put', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('put', '/api/deliverycosts/' + regionId, regionForm)
                .then((res: any) => {
                    const { deliveryCost, message } = res;
                    dispatch(updateDeliveryCost(deliveryCost));
                    dispatch(
                        addMessage({
                            text: message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    dispatch(
                        resetDeliveryCostForm(resetDeliveryCostFormFunction()),
                    );
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

/** Delete Region */
export const deleteDeliveryCost = (regionId: string): ThunkResult<void> => {
    setHeader('delete', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('delete', '/api/deliverycosts/' + regionId, null)
                .then((res: any) => {
                    const { message } = res;
                    dispatch(removeDeliveryCost(regionId));
                    dispatch(
                        addMessage({
                            text: message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};
