import React, { FC } from 'react';
import { connect } from 'react-redux';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    makeStyles,
    Theme,
} from '@material-ui/core';
import './ModalAdmin.scss';
import { AddCircle, Edit } from '@material-ui/icons';
import { mapDispatchToProps } from '../../../types/dispatchActionTypes';
import { AppState } from '../../../store/store';
import { setAdminPanelUiStateValues } from '../../../utils/utils';
import EditUserForm from '../Users/EditUserForm';
import { IAdminPanelUiProps, IUser } from '../../../../../types';

interface IModalStateProps {
    users: IUser[];
    adminPanelUiProps: IAdminPanelUiProps;
}

interface IOwnProps {
    userId?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    dialog: {
        padding: '1.5rem',
    },
    iconText: {
        display: 'flex',
        paddingTop: '.8rem',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg': {
            marginRight: '.6rem',
        },
    },
}));

type ModalProps = IModalStateProps & IOwnProps & IDispatchProps;

const Modal: FC<ModalProps> = (props) => {
    const { users, adminPanelUiProps, userId } = props;
    const { modalOpen } = adminPanelUiProps;
    const modalOpenHandler = () => {
        const { updateAdminPanelUiStateAction } = props;
        updateAdminPanelUiStateAction(
            setAdminPanelUiStateValues('modalOpen', !modalOpen),
        );
    };

    const user = users.find((userInFind: IUser) => userInFind._id === userId);
    const classes = useStyles();
    return (
        <Dialog
            open={modalOpen}
            className={classes.dialog}
            onClose={modalOpenHandler}
            maxWidth="lg"
            fullWidth={true}
        >
            <DialogTitle>
                {userId && (
                    <div className={classes.iconText}>
                        <Edit />
                        <span>{'Muokkaa ' + user?.username}</span>
                    </div>
                )}
                {!userId && (
                    <div className={classes.iconText}>
                        <AddCircle />
                        <span>{`Lisää kategoria`}</span>
                    </div>
                )}
            </DialogTitle>
            <DialogContent>
                {userId && user && <EditUserForm userId={user._id} />}
            </DialogContent>
        </Dialog>
    );
};
function mapStateToProps(state: AppState): IModalStateProps {
    return {
        users: state.users,
        adminPanelUiProps: state.adminPanelUiState.adminPanelUiProps,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Modal);
