import React from 'react';
import jwtDecode from 'jwt-decode';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import './App.scss';
import { isSessionValid, clearSession } from '../../session';
import { AppState } from '../../store/store';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FrontPage from '../FrontPage/FrontPage';
import AppFooter from '../../components/AppFooter/AppFooter';
import Loading from '../../components/Loading/Loading';
import Message from '../../components/Message/Message';
import AppNavbar from '../AppNavbar/AppNavbar';
import { resetCurrentUser, resetMessage } from '../../utils/resetUtils';
import Profile from '../Profile/Profile';
import { mapDispatchToProps } from '../../types/dispatchActionTypes';
import ServiceTerms from '../../components/ServiceTerms/ServiceTerms';
import FirewoodInfo from '../../components/FirewoodInfo/FirewoodInfo';
import FirewoodInfoUnitsOfMeasurement from '../../components/FirewoodInfo/UnitsOfMeasurement';
import FirewoodInfoDryingAndStorage from '../../components/FirewoodInfo/DryingAndStorage';
import FirewoodInfoProperties from '../../components/FirewoodInfo/Properties';
import FirewoodInfoHeating from '../../components/FirewoodInfo/Heating';
import FirewoodInfoComparison from '../../components/FirewoodInfo/Comparison';
import FirewoodInfoCounter from '../../components/FirewoodInfo/Counter';
import SalesTerms from '../../components/SalesTerms/SalesTerms';
import QualityTerms from '../../components/QualityTerms/QualityTerms';
import Cart from '../Cart/Cart';
import { validateUserRole } from '../../utils/utils';
import AdminPanel from '../admin/AdminPanel/AdminPanel';
import SignIn from '../SignIn/SignIn';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import LoggedInRoute from './LoggedInRoute';
import LoggedOutRoute from './LoggedOutRoute';
import NotFound404 from './NotFound404';
import AdminRoute from './AdminRoute';
import Contact from '../Contact/Contact';
import OpenTicket from '../Contact/OpenTicket';
import ProductsGrid from '../Products/ProductsGrid';
import {
    AdminRole,
    ICart,
    ICurrentUser,
    ILoading,
    IFlashMessage,
    IUiProps,
    IUser,
    IProduct,
} from '../../../../types';
// import ProductsWitMap from '../Products/ProductsWitMap';
import SellersPage from '../Products/SellersPage';

// Timer is used to remove message after set timeout and to cleanTimeout.
let timer: NodeJS.Timer;
let reVerifyTokenTimer: NodeJS.Timer;

interface AppStateProps {
    cart: ICart;
    currentUser: ICurrentUser;
    isLoading: ILoading;
    message: IFlashMessage;
    isAuthenticated: boolean;
    uiProps: IUiProps;
    products: IProduct[];
    favorites: IProduct[];
}

type AppProps = AppStateProps & IDispatchProps & RouteComponentProps;

class App extends React.Component<AppProps, AppState> {
    public componentDidMount() {
        const {
            fetchRegionsAction,
            fetchOrdersAction,
            fetchFavoritesAction,
            fetchLocalitiesByRegionAction,
            uiProps,
            logoutAction,
            isAuthenticated,
            verifyTokenAction,
        } = this.props;
        fetchRegionsAction('');
        fetchFavoritesAction();
        fetchLocalitiesByRegionAction();
        if (
            uiProps.responseStatus === 0 &&
            isAuthenticated &&
            !reVerifyTokenTimer
        ) {
            reVerifyTokenTimer = setTimeout(verifyTokenAction, 60000);
        }
        if (uiProps.responseStatus === 401 && isAuthenticated) {
            logoutAction();
            this.props.history.push('/kirjaudu');
        }
        const {
            setCurrentUserAction,
            removeCurrentUserAction,
            addMessageAction,
        } = this.props;
        if (localStorage.token) {
            // prevent someone from manually tampering with the key of jwtToken in localStorage
            try {
                const sessionValid = isSessionValid();
                if (!!sessionValid) {
                    const token = localStorage.getItem('token');
                    if (token) {
                        const payload: IUser = jwtDecode(token);
                        fetchOrdersAction('?userid=' + payload._id);
                        setCurrentUserAction(
                            payload,
                            true,
                            validateUserRole(payload.role as AdminRole),
                        );
                    }
                }
            } catch (e) {
                clearSession();
                removeCurrentUserAction(resetCurrentUser(), false, false);
                addMessageAction({
                    text: 'Tietojasi on muutettu, tästä johtuen sinut on välittömästi kirjattu ulos.',
                    bgColor: 'warning',
                    visible: true,
                });
            }
        }
    }
    public onClickHandler = () => {
        const { removeMessageAction } = this.props;
        removeMessageAction({ text: '', bgColor: '', visible: false });
    };
    public componentDidUpdate() {
        const {
            removeMessageAction,
            message,
            uiProps,
            logoutAction,
            isAuthenticated,
            verifyTokenAction,
        } = this.props;
        if (message.visible) {
            timer = setTimeout(
                () => removeMessageAction(resetMessage()),
                10000,
            );
        }
        if (
            uiProps.responseStatus === 0 &&
            isAuthenticated &&
            !reVerifyTokenTimer
        ) {
            clearTimeout(reVerifyTokenTimer);
            reVerifyTokenTimer = setTimeout(verifyTokenAction, 60000);
        }
        if (uiProps.responseStatus === 401 && isAuthenticated) {
            logoutAction();
            this.props.history.push('/kirjaudu');
        }
    }
    public UNSAFE_componentWillUpdate() {
        clearTimeout(timer);
        clearTimeout(reVerifyTokenTimer);
    }
    public render() {
        const { isLoading } = this.props;
        const { message } = this.props;
        const { text, bgColor } = this.props.message;

        return (
            <ThemeProvider theme={theme}>
                <div className="App">
                    <AppNavbar />
                    {!!message.visible && (
                        <Message
                            text={text}
                            bgColor={bgColor}
                            onClose={this.onClickHandler}
                        />
                    )}
                    <main>
                        <Switch>
                            <LoggedOutRoute
                                path="/"
                                exact={true}
                                Component={FrontPage}
                            />
                            <Route path="/haku" component={SellersPage} />
                            <Route
                                path="/ilmoitukset"
                                component={ProductsGrid}
                            />
                            <Route
                                path="/asiakaspalvelu/:id"
                                component={OpenTicket}
                            />
                            <Route path="/asiakaspalvelu" component={Contact} />
                            <LoggedOutRoute
                                path="/kirjaudu"
                                Component={SignIn}
                            />
                            <LoggedOutRoute
                                path="/rekisteroidy"
                                Component={SignIn}
                            />
                            <Route
                                path="/kayttoehdot"
                                component={ServiceTerms}
                            />
                            {/* Firewood-Info Pages */}
                            <Route
                                path="/polttopuuinfo/termit-ja-mittayksikot"
                                component={FirewoodInfoUnitsOfMeasurement}
                            />
                            <Route
                                path="/polttopuuinfo/polttopuun-ominaisuudet"
                                component={FirewoodInfoProperties}
                            />
                            <Route
                                path="/polttopuuinfo/polttopuun-kuivaus-ja-varastointi"
                                component={FirewoodInfoDryingAndStorage}
                            />
                            <Route
                                path="/polttopuuinfo/tulisijan-lammitys"
                                component={FirewoodInfoHeating}
                            />
                            <Route
                                path="/polttopuuinfo/energiavertailu"
                                component={FirewoodInfoComparison}
                            />
                            <Route
                                path="/polttopuuinfo/energialaskuri"
                                component={FirewoodInfoCounter}
                            />
                            <Route
                                path="/polttopuuinfo"
                                component={FirewoodInfo}
                            />

                            <Route path="/myyntiehdot" component={SalesTerms} />
                            <Route
                                path="/laatuvaatimukset"
                                component={QualityTerms}
                            />
                            <Route path="/ostoskori" component={Cart} />
                            <LoggedInRoute
                                exact={true}
                                path="/profiili"
                                Component={Profile}
                            />
                            <AdminRoute
                                exact={true}
                                path="/admin/"
                                Component={AdminPanel}
                            />
                            <Route path="*" component={NotFound404} />
                        </Switch>
                    </main>
                    <footer>
                        {!!isLoading.isLoading && (
                            <Loading text="Tietoja ladataan..." />
                        )}
                        <AppFooter stories={[]} />
                    </footer>
                </div>
            </ThemeProvider>
        );
    }
}
function mapStateToProps(state: AppState): AppStateProps {
    return {
        currentUser: state.currentUser,
        cart: state.cartState,
        isAuthenticated: state.currentUser.isAuthenticated,
        isLoading: state.isLoading,
        message: state.message.message,
        products: state.products,
        favorites: state.favorites,
        uiProps: state.uiState.uiProps,
    };
}

export default withRouter(
    connect<AppStateProps, IDispatchProps, {}, AppState>(
        mapStateToProps,
        mapDispatchToProps,
    )(App),
);
