import {
    AppBar,
    Box,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Tab,
    Tabs,
    Typography,
} from '@material-ui/core';
import { Edit, Search } from '@material-ui/icons';
import React, { ChangeEvent, FC, useState } from 'react';
import { connect } from 'react-redux';
import { ILocation, IRegion } from '../../../../../../types';
import { AppState } from '../../../../store/store';
import { mapDispatchToProps } from '../../../../types/dispatchActionTypes';
import LocalityForm from './Localities/LocalityForm';

interface ILocationManagementStateProps {
    localities: ILocation[];
    regions: IRegion[];
}

type LocationManagementProps = ILocationManagementStateProps & IDispatchProps;

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    tabId: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, tabId, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={tabId !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {tabId === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const LocationManagement: FC<LocationManagementProps> = (props) => {
    const { regions, localities, fetchLocalitiesAction } = props;
    /** show forms states */
    const [showLocalityForm, updateShowLocalityForm] = useState<boolean>(false);
    const [tabId, updateTabId] = useState<number>(0);
    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    const handleChange = (event: ChangeEvent<{}>, id: number): void => {
        event.preventDefault();
        return updateTabId(id);
    };
    return (
        <Grid container={true} justify="center">
            <Grid item={true} xs={12} md={12}>
                <AppBar color="default" position="static">
                    <Tabs
                        value={tabId}
                        onChange={handleChange}
                        aria-label="simple tabs example"
                    >
                        <Tab label="Paikkakunnat" {...a11yProps(0)} />
                        <Tab label="Maakunnat" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <Box p={2}>
                    <Grid container={true} justify="space-between">
                        <Grid item={true} md={10}>
                            <TabPanel tabId={tabId} index={0}>
                                <Box p={1}>
                                    <List>
                                        {regions &&
                                            regions.map((region: IRegion) => {
                                                return (
                                                    <ListItem key={region._id}>
                                                        <ListItemText>
                                                            {region.name}
                                                        </ListItemText>
                                                        <ListItemSecondaryAction>
                                                            <IconButton
                                                                onClick={() =>
                                                                    fetchLocalitiesAction(
                                                                        region._id,
                                                                    )
                                                                }
                                                            >
                                                                <Search />
                                                            </IconButton>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                );
                                            })}
                                    </List>
                                </Box>
                            </TabPanel>
                            <TabPanel tabId={tabId} index={1}>
                                <Box p={1}>
                                    <List>
                                        {localities &&
                                            localities.map(
                                                (locality: ILocation) => {
                                                    return (
                                                        <ListItem
                                                            key={locality._id}
                                                        >
                                                            <ListItemText>
                                                                {
                                                                    locality.location
                                                                }
                                                            </ListItemText>
                                                            <ListItemSecondaryAction>
                                                                <IconButton
                                                                    onClick={() =>
                                                                        updateShowLocalityForm(
                                                                            !showLocalityForm,
                                                                        )
                                                                    }
                                                                >
                                                                    <Edit />
                                                                </IconButton>
                                                            </ListItemSecondaryAction>
                                                            {showLocalityForm && (
                                                                <LocalityForm />
                                                            )}
                                                        </ListItem>
                                                    );
                                                },
                                            )}
                                    </List>
                                </Box>
                            </TabPanel>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
};

function mapStateToProps(state: AppState): ILocationManagementStateProps {
    return {
        localities: state.localities,
        regions: state.regions,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationManagement);
