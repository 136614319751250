import { ILocalityForm } from '../../../../types';
import { AppActions } from '../../types/actions';
import {
    RESET_LOCALITY_FORM,
    UPDATE_LOCALITY_FORM,
} from '../../types/localityFormActionTypes';

export function updateLocalityForm(localityForm: ILocalityForm): AppActions {
    return {
        type: UPDATE_LOCALITY_FORM,
        localityFormState: { localityForm },
    };
}

export function resetLocalityForm(localityForm: ILocalityForm): AppActions {
    return {
        type: RESET_LOCALITY_FORM,
        localityFormState: { localityForm },
    };
}
