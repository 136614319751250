import React, { FunctionComponent } from 'react';
import { Typography, Grid, Paper, Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import './QualityTerms.scss';

interface QualityTermsProps {
    agreed: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    box: {
        padding: '3rem',
        [theme.breakpoints.down('sm')]: {
            padding: '3rem 1.6rem',
        },
    },
}));

const QualityTerms: FunctionComponent<QualityTermsProps> = () => {
    const classes = useStyles();

    return (
        <section>
            <Box py={8} px={1}>
                <Grid container={true} justify="center">
                    <Grid item={true} xs={12} md={8}>
                        <Paper>
                            <div className={classes.box}>
                                <Grid container={true} justify="center">
                                    <Grid item={true}>
                                        <Typography variant="h4">
                                            Halkoliiteri.com -verkkopalvelun
                                            polttopuun mitta- ja
                                            laatuvaatimukset
                                        </Typography>
                                        <br />
                                    </Grid>
                                    <Grid item={true}>
                                        <Typography
                                            className="colorBlack"
                                            variant="body1"
                                        >
                                            Halkoliiterissä myytävän polttopuun
                                            mitta- ja laatuvaatimusten osalta
                                            noudatetaan pääsääntöisesti EN
                                            14961-5 pilkestandardin laatuluokan
                                            A1 mukaisia vaatimuksia. EN
                                            standardit:{' '}
                                            <a
                                                href="https://www.sfs.fi/"
                                                target="blank"
                                            >
                                                www.sfs.fi
                                            </a>
                                            .
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            <strong>
                                                Halkoliiterissä noudatettavat
                                                polttopuun mitta- ja
                                                laatuvaatimukset
                                            </strong>
                                        </Typography>
                                        <br />
                                        <Typography variant="h5">
                                            Alkuperä
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            Polttopuu on tehty runkopuusta tai
                                            kemiallisesti käsittelemättömästä
                                            puutähteestä.
                                        </Typography>
                                        <br />
                                        <Typography variant="h5">
                                            Pilkelaji
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            Pilke 25 cm (L25) +/- 2 cm
                                        </Typography>
                                        <Typography variant="body1">
                                            Pilke 33 cm (L33) +/- 3 cm
                                        </Typography>
                                        <Typography variant="body1">
                                            Pilke 40 cm (L40) +/- 4 cm
                                        </Typography>
                                        <Typography variant="body1">
                                            Pilke 50 cm (L50) +/- 5 cm
                                        </Typography>
                                        <Typography variant="body1">
                                            Halko 100 cm (L100) +/- 5 cm
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            Muu pituus: ilmoitetaan
                                            lisätiedoissa mikäli tarjolla on
                                            edellisistä poikkeavia pituuksia
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            Mahdollisista poikkeamista näistä
                                            ohjepituuksista on ilmoitettava
                                            asiakkaalle riittävän selvästi.
                                            Muuntokertoimet löytyvät
                                            myyntiehdoista.
                                        </Typography>
                                        <br />
                                        <Typography variant="h5">
                                            Kosteus, % märkäpainosta (veden
                                            osuus veden ja kuiva-aineen
                                            painosta)
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            Kuiva: kosteus alle 25 %, heti
                                            käyttöön otettavissa
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            Tuore: kosteus yli 25 %, vaatii
                                            varastointiaikaa ennen käyttöönottoa
                                        </Typography>
                                        <br />
                                        <Typography variant="h5">
                                            Tilavuus, kiinto-, pino- tai irto-m3
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            Ilmoitettava mitä yksikköä
                                            käytetään.
                                        </Typography>
                                        <br />
                                        <Typography variant="h5">
                                            Halkaistujen osuus
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            Halkaistujen pilkkeiden osuuden
                                            tulee olla vähintään 90 %. Pilkkeet
                                            voivat myös olla aisattuja. Siitä on
                                            kuitenkin mainittava lisätiedoissa.
                                        </Typography>
                                        <br />
                                        <Typography variant="h5">
                                            Katkaisupinta
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            Katkaisupinnan on oltava tasainen.
                                            Jos polttopuu on katkaistu tai
                                            halkaistu ns. giljotiinimenetelmällä
                                            on siitä mainittava lisätiedoissa.
                                        </Typography>
                                        <br />
                                        <Typography variant="h5">
                                            Laho
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            Kova laho ja värivika sallitaan,
                                            mutta niistä on mainittava
                                            lisätiedoissa.
                                        </Typography>
                                        <br />
                                        <Typography variant="h5">
                                            Puhtaus
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            Polttopuun joukossa ei sallita
                                            muovia, nokea, hiiltä, metalleja,
                                            kemikaaleja tai muita haitallisia
                                            vieraita aineita tai esineitä.
                                            Myöskään lunta tai jäätä ei sallita.
                                        </Typography>
                                        <br />
                                        <Typography variant="h5">
                                            Järeys
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            Polttopuu tehdään pääsääntöisesti
                                            kuitupuun mitat täyttävästä
                                            puutavarasta. Yrittäjä voi antaa
                                            lisätiedoissa tai tuoteselosteessa
                                            yksityiskohtaisempia tietoja
                                            pilkkeen järeydestä.
                                        </Typography>
                                        <br />
                                        <Typography variant="h5">
                                            Hinnoittelu
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            Pilke hinnoitellaan euroa /
                                            irtokuutiometri ja halko, sahapinta
                                            (pitkänä myytävä) ja polttoranka
                                            euroa / pinokuutiometri. Hinnat
                                            sisältävät kulloinkin voimassa
                                            olevan arvonlisäveron.
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            <strong>
                                                Mitta- ja laatuvaatimukset
                                                päivitetty 21.3.2011
                                            </strong>
                                        </Typography>
                                        <br />
                                        <Typography
                                            variant="body1"
                                            noWrap={true}
                                        >
                                            Lataa{' '}
                                            <a
                                                className="dark"
                                                href="/downloads/Polttopuu_malli_tuoteseloste_EN_14961_5.pdf"
                                                download={true}
                                            >
                                                Polttopuu_malli_tuoteseloste_EN_14961_5.pdf
                                            </a>{' '}
                                            (pdf)
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            Lue lisää{' '}
                                            <a
                                                className="dark"
                                                href="/downloads/laadun_hallinta.pdf"
                                                download={true}
                                            >
                                                laadun_hallinta.pdf
                                            </a>{' '}
                                            (pdf)
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </section>
    );
};

export default QualityTerms;
