import React, { Component } from 'react';
import { ICurrentUser, IDeliveryCost } from '../../../../types';
import { AppState } from '../../store/store';
import DeliveringWoodImg from '../../assets/images/delivering-wood.jpg';
import './DeliveryCosts.scss';
import {
    Grid,
    Card,
    CardHeader,
    CardMedia,
    CardContent,
    List,
    Box,
    Fade,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    IconButton,
} from '@material-ui/core';
import { DeleteForever, Edit, CheckCircle } from '@material-ui/icons';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../types/dispatchActionTypes';
import DeliveryCostForm from './DeliveryCostForm';

interface IDeliveryCostsStateProps {
    deliveryCosts: IDeliveryCost[];
    currentUser: ICurrentUser;
}
type DeliveryCostsListProps = IDeliveryCostsStateProps & IDispatchProps;

class DeliveryCostsList extends Component<DeliveryCostsListProps, AppState> {
    public componentDidMount() {
        const { fetchDeliveryCostsAction, currentUser } = this.props;
        fetchDeliveryCostsAction('?owner=' + currentUser.user._id);
    }
    public deleteHandler = (id: string) => {
        const { deleteDeliveryCostsAction } = this.props;
        deleteDeliveryCostsAction(id);
    };
    public editHandler = (id: string) => {
        const { deliveryCosts, resetDeliveryCostFormAction } = this.props;
        const foundDeliveryCost = deliveryCosts.find(
            (deliveryCost2: IDeliveryCost) => deliveryCost2._id === id,
        );
        resetDeliveryCostFormAction({
            edit: true,
            unit: foundDeliveryCost?.unit,
            name: foundDeliveryCost?.name,
            price: foundDeliveryCost?.price,
            available: foundDeliveryCost?.available,
            multipliedByQuantity: foundDeliveryCost?.multipliedByQuantity,
            deliveryCostId: foundDeliveryCost?._id,
        });
    };
    public render() {
        const { deliveryCosts } = this.props;
        return (
            <Grid container={true}>
                <Grid item={true} xs={12} md={6}>
                    <Typography variant="h5">
                        Luo tai muokkaa toimituskulu(a)
                    </Typography>
                    <Box m={2} />
                    <DeliveryCostForm />
                </Grid>
                <Grid item={true} xs={12} md={6}>
                    <Typography variant="h5">Toimituskulut</Typography>
                    <Box m={2} />
                    <List>
                        {deliveryCosts.map((deliveryCost: IDeliveryCost) => {
                            return (
                                <Fade key={deliveryCost._id} in={true}>
                                    <ListItem>
                                        <ListItemText
                                            primary={`Nimi: ${deliveryCost.name}, hinta: ${deliveryCost.price}€/${deliveryCost.unit}`}
                                        />
                                        <ListItemIcon>
                                            {deliveryCost.multipliedByQuantity && (
                                                <IconButton disabled={true}>
                                                    <CheckCircle color="primary" />{' '}
                                                    Kerrotaan määrällä
                                                </IconButton>
                                            )}
                                            {deliveryCost.available && (
                                                <IconButton disabled={true}>
                                                    <CheckCircle color="primary" />{' '}
                                                    Aktiivinen
                                                </IconButton>
                                            )}
                                            <IconButton
                                                value={deliveryCost._id}
                                                color="primary"
                                                onClick={() =>
                                                    this.editHandler(
                                                        deliveryCost._id,
                                                    )
                                                }
                                            >
                                                <Edit />
                                            </IconButton>
                                            <IconButton
                                                id={deliveryCost._id}
                                                onClick={() =>
                                                    this.deleteHandler(
                                                        deliveryCost._id,
                                                    )
                                                }
                                                color="secondary"
                                            >
                                                <DeleteForever />
                                            </IconButton>
                                        </ListItemIcon>
                                    </ListItem>
                                </Fade>
                            );
                        })}
                    </List>
                </Grid>
                <Grid item={true} xs={12}>
                    <Box mt={3}>
                        <Card raised={true}>
                            <Grid container={true} direction="row">
                                <Grid item={true} md={4}>
                                    <CardMedia
                                        className="image"
                                        image={DeliveringWoodImg}
                                        title="Polttopuun kuljetus"
                                    />
                                </Grid>
                                <Grid item={true} md={8}>
                                    <Box p={2}>
                                        <CardHeader title="Toimituskulut" />
                                        <CardContent className="content">
                                            <Grid
                                                container={true}
                                                direction="column"
                                                justify="space-around"
                                                alignItems="center"
                                            >
                                                <Typography variant="body1">
                                                    Tomituskulut ovat olennainen
                                                    osa toimintaasi, kun
                                                    valitset kuljetuksen tai
                                                    noutopisteen ja kujetuksen
                                                    päätoimitustvaksesi.
                                                </Typography>
                                                <Typography variant="body1">
                                                    Kaikki tomituskulut joiden
                                                    tila on{' '}
                                                    <strong>aktiivinen</strong>
                                                    näkyvät ostajille
                                                    ostoskorissa.
                                                </Typography>
                                            </Grid>
                                        </CardContent>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Card>
                    </Box>
                </Grid>
            </Grid>
        );
    }
}

function mapStateToProps(state: AppState): IDeliveryCostsStateProps {
    return {
        deliveryCosts: state.deliveryCosts,
        currentUser: state.currentUser,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryCostsList);
