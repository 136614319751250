import { apiCall, setHeader } from '../../../apiRequests/apiCall';
import { AppActions } from '../../../types/actions';
import { Dispatch } from 'redux';
import { addMessage } from '../messageActions';
import {
    ADD_USER,
    REMOVE_USER,
    REMOVE_USERS,
    SET_USERS,
    UPDATE_USER,
} from '../../../types/admin/usersActionTypes';
import {
    ADD_NEW_USER,
    REMOVE_NEW_USER,
    SET_NEW_USERS,
    UPDATE_NEW_USER,
} from '../../../types/admin/newUsersActionTypes';
import { emptySignUpForm } from '../../../utils/resetUtils';
import { resetUserForm } from './userFormActions';
import { AdminRole, IUserForm, IUser } from '../../../../../types';

/** Restricted access, Admins only */
export function setUsers(users: IUser[]): AppActions {
    return {
        type: SET_USERS,
        users,
    };
}

export function addUser(user: IUser): AppActions {
    return {
        type: ADD_USER,
        user,
    };
}

export function updateUser(user: IUser): AppActions {
    return {
        type: UPDATE_USER,
        user,
    };
}

export function removeUser(id: string): AppActions {
    return {
        type: REMOVE_USER,
        id,
    };
}

export function removeUsers(ids: string[]): AppActions {
    return {
        type: REMOVE_USERS,
        ids,
    };
}

/** NewUsers Actions */
export function setNewUsers(newUsers: IUser[]): AppActions {
    return {
        type: SET_NEW_USERS,
        newUsers,
    };
}

export function addNewUser(newUser: IUser): AppActions {
    return {
        type: ADD_NEW_USER,
        newUser,
    };
}

export function updateNewUser(newUser: IUser): AppActions {
    return {
        type: UPDATE_NEW_USER,
        newUser,
    };
}

export function removeNewUser(id: string): AppActions {
    return {
        type: REMOVE_NEW_USER,
        id,
    };
}

/** Fetch all users */
export const fetchUsers = (query: string): ThunkResult<void> => {
    setHeader('get', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('get', '/api/admin/users' + query, null)
                .then((res: any) => {
                    const { sellers } = res;
                    dispatch(setUsers(sellers));
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error.message,
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

/** Create user action */
export const createUser = (userForm: IUserForm): ThunkResult<void> => {
    setHeader('post', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('post', '/api/admin/users', userForm)
                .then((res: any) => {
                    const { seller, adminMessage } = res;
                    dispatch(addUser(seller));
                    dispatch(
                        addMessage({
                            text: adminMessage,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    dispatch(resetUserForm({ userForm: emptySignUpForm() }));
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error.message,
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

/** Edit user, to admin only */
export const editUser = (
    userForm: IUserForm,
    userId: string,
): ThunkResult<void> => {
    setHeader('put', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('put', `/api/admin/users/${userId}`, userForm)
                .then((response: any) => {
                    const { seller, message } = response;
                    dispatch(updateUser(seller));
                    dispatch(resetUserForm({ userForm: emptySignUpForm() }));
                    dispatch(
                        addMessage({
                            text: message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error) => {
                    dispatch(
                        addMessage({
                            text: error.message,
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

/** Restricted method, to admins only */
export const editUserAccountStatus = (
    id: string,
    active: boolean,
    newUser?: boolean,
): ThunkResult<void> => {
    setHeader('patch', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('patch', `/api/admin/users/${id}/activation`, {
                active,
                paid: active === true ? true : false,
            })
                .then((res: any) => {
                    const { user, message } = res;
                    if (newUser) {
                        dispatch(updateNewUser(user));
                    } else {
                        dispatch(updateUser(user));
                    }
                    dispatch(
                        addMessage({
                            text: message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error: any) => {
                    dispatch(
                        addMessage({
                            text: error.message,
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

/** Public method */
export const fetchNewUsers = (query: string): ThunkResult<void> => {
    setHeader('get', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('get', '/api/admin/users/un-active', null)
                .then((res: any) => {
                    const { newUsers } = res;
                    dispatch(setNewUsers(newUsers));
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error.message,
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

/** update user role */
export const updateUsersRole = (
    role: AdminRole,
    id: string,
): ThunkResult<void> => {
    setHeader('patch', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('patch', '/api/admin/users/' + id + '/update-role', {
                role,
            })
                .then((res: any) => {
                    const { message, user } = res;
                    dispatch(updateUser(user));
                    dispatch(
                        addMessage({
                            text: message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error: any) => {
                    dispatch(
                        addMessage({
                            text: error.message,
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

/** Delete User */
export const deleteUser = (id: string): ThunkResult<void> => {
    setHeader('delete', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('delete', '/api/admin/users/' + id, null)
                .then((res: any) => {
                    const { message } = res;
                    dispatch(removeUser(id));
                    dispatch(
                        addMessage({
                            text: message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error: any) => {
                    dispatch(
                        addMessage({
                            text: error.message,
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

export const deleteUsers = (ids: string[]): ThunkResult<void> => {
    setHeader('delete', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('delete', '/api/admin/users/', { ids })
                .then((res: any) => {
                    const { message } = res;
                    dispatch(removeUsers(ids));
                    dispatch(
                        addMessage({
                            text: message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error: any) => {
                    dispatch(
                        addMessage({
                            text: error.message,
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};
