import React, { Component } from 'react';
import './Order.scss';
import {
    Box,
    Grid,
    List,
    ListItem,
    ListItemText,
    Paper,
    Typography,
} from '@material-ui/core';
import {
    resolveStatus,
    setAdminPanelUiStateValues,
    setPriceTag,
    setSideBarValues,
} from '../../utils/utils';
import { ListAlt } from '@material-ui/icons';
import { AppState } from '../../store/store';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../types/dispatchActionTypes';
import {
    resetAdminPanelUiProps,
    resetSideBarState,
} from '../../utils/resetUtils';
import { IOrderItem, IUser, ISidebarProps, IOrder } from '../../../../types';

interface IOrderListStateProps {
    orders: IOrder[];
    user: IUser;
    sideBarProps: ISidebarProps;
}
type OrderListProps = IOrderListStateProps & IDispatchProps;

class OrderList extends Component<OrderListProps, AppState> {
    public render() {
        const { orders, fetchOrderAction, sideBarProps } = this.props;
        const { showOrder } = sideBarProps;
        const setOrder = (id: string) => {
            const {
                updateSideBarAction,
                resetSideBarAction,
                resetAdminPanelUiStateAction,
                updateAdminPanelUiStateAction,
            } = this.props;
            fetchOrderAction(id);
            resetSideBarAction(resetSideBarState());
            resetAdminPanelUiStateAction(resetAdminPanelUiProps());
            updateSideBarAction(
                setSideBarValues('showOrder', showOrder ? false : true),
            );
            updateAdminPanelUiStateAction(
                setAdminPanelUiStateValues('showOrder', true),
            );
        };
        return (
            <Box my={4}>
                <Grid container={true} justify="center">
                    {!!orders.length &&
                        orders.map((order: IOrder) => {
                            return (
                                <Grid
                                    className="clickableItem"
                                    tabIndex={0}
                                    onClick={() => setOrder(order._id)}
                                    onKeyDown={() => setOrder(order._id)}
                                    key={order._id}
                                    item={true}
                                    xs={12}
                                    md={10}
                                >
                                    <Box p={2}>
                                        <Paper>
                                            <Grid
                                                className={`header-box ${
                                                    order.status === 'recieved'
                                                        ? 'royalBlue'
                                                        : order.status ===
                                                          'in progress'
                                                        ? 'sandyBrown'
                                                        : order.status ===
                                                          'in delivery'
                                                        ? 'coral'
                                                        : 'forestGreen'
                                                }`}
                                                container={true}
                                            >
                                                <Grid
                                                    className="text-left"
                                                    item={true}
                                                    xs={12}
                                                    md={8}
                                                >
                                                    <Box className="flex">
                                                        <ListAlt />
                                                        <Typography variant="h6">
                                                            {order.orderNumber}
                                                        </Typography>
                                                    </Box>
                                                    {order.createdAt && (
                                                        <Typography variant="body2">
                                                            Tilaus loutu:{' '}
                                                            {new Date(
                                                                order.createdAt,
                                                            ).toLocaleDateString(
                                                                'fi',
                                                            )}
                                                        </Typography>
                                                    )}
                                                </Grid>
                                                <Grid
                                                    className="text-left"
                                                    item={true}
                                                    xs={12}
                                                    md={4}
                                                >
                                                    <Typography variant="body1">
                                                        Tila:{' '}
                                                        {resolveStatus(
                                                            order.status,
                                                        )}
                                                    </Typography>
                                                    <Typography variant="body1">
                                                        Toimitustapa:{' '}
                                                        {order.delivery
                                                            ? 'Toimitus'
                                                            : 'Nouto'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Box p={2}>
                                                <Grid container={true}>
                                                    <Grid
                                                        className="text-left"
                                                        item={true}
                                                        md={8}
                                                    >
                                                        <Typography variant="body1">
                                                            Tuotteet ja
                                                            palvelut:
                                                        </Typography>
                                                        <List>
                                                            {order.items.map(
                                                                (
                                                                    item: IOrderItem,
                                                                ) => {
                                                                    return (
                                                                        <ListItem
                                                                            key={
                                                                                item._id
                                                                            }
                                                                        >
                                                                            <ListItemText
                                                                                primary={`${
                                                                                    item.name
                                                                                } ${
                                                                                    item.material
                                                                                } ${
                                                                                    item.plength
                                                                                } ${setPriceTag(
                                                                                    item.unitPrice *
                                                                                        item.quantity,
                                                                                )}`}
                                                                            />
                                                                        </ListItem>
                                                                    );
                                                                },
                                                            )}
                                                        </List>
                                                    </Grid>
                                                    <Grid
                                                        className="text-left"
                                                        item={true}
                                                        md={4}
                                                    >
                                                        <Typography variant="body1">
                                                            Tilaaja:
                                                        </Typography>
                                                        <Typography variant="body1">
                                                            {`
                                                                ${order.buyer.firstname} ${order.buyer.lastname} 
                                                            `}
                                                        </Typography>
                                                        <Typography variant="body1">
                                                            {`
                                                                ${order.buyer.address.street}, ${order.buyer.address.zip} ${order.buyer.address.locality.location}
                                                            `}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Paper>
                                    </Box>
                                </Grid>
                            );
                        })}
                </Grid>
            </Box>
        );
    }
}

function mapStateToProps(state: AppState): IOrderListStateProps {
    return {
        orders: state.orders,
        user: state.currentUser.user,
        sideBarProps: state.sidebarState.sidebarProps,
    };
}

export default connect<IOrderListStateProps, IDispatchProps, {}, AppState>(
    mapStateToProps,
    mapDispatchToProps,
)(OrderList);
