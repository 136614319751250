import { IAdvertisement } from '../../../types';

export const SET_ADVERTISEMENTS = 'SET_ADVERTISEMENTS';
export const ADD_ADVERTISEMENT = 'ADD_ADVERTISEMENT';
export const UPDATE_ADVERTISEMENT = 'UPDATE_ADVERTISEMENT';
export const REMOVE_ADVERTISEMENT = 'REMOVE_ADVERTISEMENT';

export interface SetAdvertisements {
    type: typeof SET_ADVERTISEMENTS;
    advertisements: IAdvertisement[];
}

export interface AddAdvertisement {
    type: typeof ADD_ADVERTISEMENT;
    advertisement: IAdvertisement;
}

export interface UpdateAdvertisement {
    type: typeof UPDATE_ADVERTISEMENT;
    advertisement: IAdvertisement;
}

export interface RemoveAdvertisement {
    type: typeof REMOVE_ADVERTISEMENT;
    id: string;
}

export type AdvertisementsActionTypes =
    | SetAdvertisements
    | AddAdvertisement
    | UpdateAdvertisement
    | RemoveAdvertisement;
