import React, { Component, Fragment } from 'react';
import './Cart.scss';
import { AppState } from '../../store/store';
import {
    Grid,
    TextField,
    InputBaseComponentProps,
    FormControl,
    InputLabel,
    Select,
    FormHelperText,
    MenuItem,
    Typography,
    Theme,
} from '@material-ui/core';
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles';
import { mapDispatchToProps } from '../../types/dispatchActionTypes';
import { withRouter, Link, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { setCheckoutFormInputValues } from '../../utils/utils';
import { ICheckoutForm, IRegion, ILocation, IUser } from '../../../../types';

interface IContactInformationFormStateProps {
    currentUser: IUser;
    checkoutForm: ICheckoutForm;
    regions: IRegion[];
    localities: ILocation[];
}

type ContactInformationFormProps = IContactInformationFormStateProps &
    IDispatchProps &
    RouteComponentProps &
    WithStyles<typeof styles>;

const styles = (theme: Theme) =>
    createStyles({
        textField: {
            marginTop: '2.5rem',
        },
        grid: {
            padding: '0 0.5rem',
        },
        info: {
            padding: '0 0.5rem',
            marginTop: '1rem',
        },
        infoLink: {
            color: '#333',
        },
        formTitle: {
            marginTop: '0.8rem',
            display: 'inline-block',
            marginBottom: '-0.5rem',
            backgroundColor: '#eee',
            padding: '1rem 1.2rem',
            borderRadius: '5px',
        },
        formTitleLastItem: {
            [theme.breakpoints.down('xs')]: {
                marginTop: '3rem',
            },
        },
    });

class ContactInformationForm extends Component<
    ContactInformationFormProps,
    AppState
> {
    public handleChange = (event: InputBaseComponentProps) => {
        const { updateCheckoutFormAction } = this.props;
        updateCheckoutFormAction(setCheckoutFormInputValues(event));
        if (event.target.name === 'region') {
            this.getLocalities(event.target.value);
        }
        return event.value;
    };
    public getLocalities(region: string) {
        const { fetchLocalitiesAction } = this.props;
        return fetchLocalitiesAction(region);
    }

    public render() {
        const { checkoutForm, regions, localities, classes } = this.props;
        const {
            firstname,
            lastname,
            email,
            phonenumber,
            street,
            zip,
            locality,
            region,
        } = checkoutForm;
        return (
            <Fragment>
                <Grid container={true} justify="center">
                    <Grid item={true} xs={12}>
                        <form>
                            <Grid container={true}>
                                <Grid
                                    item={true}
                                    xs={12}
                                    sm={6}
                                    className={classes.grid}
                                >
                                    <Grid container={true}>
                                        <Grid
                                            item={true}
                                            className={classes.grid}
                                        >
                                            <Typography
                                                variant="h6"
                                                className={classes.formTitle}
                                            >
                                                Henkilötiedot:
                                            </Typography>
                                        </Grid>
                                        <Grid item={true} xs={12}>
                                            <TextField
                                                variant="outlined"
                                                className={classes.textField}
                                                required={true}
                                                fullWidth={true}
                                                type="text"
                                                id="firstname-required"
                                                name="firstname"
                                                label="Etunimi"
                                                value={firstname}
                                                onChange={this.handleChange}
                                            />
                                        </Grid>
                                        <Grid item={true} xs={12}>
                                            <TextField
                                                variant="outlined"
                                                className={classes.textField}
                                                required={true}
                                                fullWidth={true}
                                                type="text"
                                                id="lastname-required"
                                                name="lastname"
                                                label="Sukunimi"
                                                value={lastname}
                                                onChange={this.handleChange}
                                            />
                                        </Grid>
                                        <Grid item={true} xs={12}>
                                            <TextField
                                                variant="outlined"
                                                className={classes.textField}
                                                required={true}
                                                fullWidth={true}
                                                type="email"
                                                id="email-required"
                                                name="email"
                                                label="Email"
                                                value={email}
                                                onChange={this.handleChange}
                                            />
                                        </Grid>
                                        <Grid item={true} xs={12}>
                                            <TextField
                                                variant="outlined"
                                                className={classes.textField}
                                                required={true}
                                                fullWidth={true}
                                                type="tel"
                                                id="phone-required"
                                                name="phonenumber"
                                                label="Puhelinnumero"
                                                value={phonenumber}
                                                onChange={this.handleChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item={true}
                                    xs={12}
                                    sm={6}
                                    className={classes.grid}
                                >
                                    <Grid container={true}>
                                        <Grid
                                            item={true}
                                            className={classes.grid}
                                        >
                                            <Typography
                                                variant="h6"
                                                className={`${classes.formTitle} ${classes.formTitleLastItem}`}
                                            >
                                                Osoitetiedot:
                                            </Typography>
                                        </Grid>
                                        <Grid item={true} xs={12}>
                                            <TextField
                                                variant="outlined"
                                                className={classes.textField}
                                                required={true}
                                                fullWidth={true}
                                                type="text"
                                                name="street"
                                                id="street-required"
                                                label="Katuosoite"
                                                value={street}
                                                onChange={this.handleChange}
                                            />
                                        </Grid>
                                        <Grid item={true} xs={12}>
                                            <TextField
                                                variant="outlined"
                                                className={classes.textField}
                                                required={true}
                                                fullWidth={true}
                                                type="text"
                                                name="zip"
                                                id="zip-required"
                                                label="Postinumero"
                                                value={zip}
                                                onChange={this.handleChange}
                                            />
                                        </Grid>
                                        <Grid item={true} xs={12}>
                                            <FormControl
                                                variant="outlined"
                                                className={classes.textField}
                                                fullWidth={true}
                                                required={true}
                                            >
                                                <InputLabel id="demo-simple-select-required-label">
                                                    Maakunta
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-required-label"
                                                    id="demo-simple-select-required"
                                                    name="region"
                                                    value={region}
                                                    onChange={this.handleChange.bind(
                                                        this,
                                                    )}
                                                >
                                                    <MenuItem value={region}>
                                                        <em>Maakunta *</em>
                                                    </MenuItem>
                                                    {!!regions.length &&
                                                        regions.map(
                                                            (
                                                                region2: IRegion,
                                                            ) => {
                                                                return (
                                                                    <MenuItem
                                                                        key={
                                                                            region2._id
                                                                        }
                                                                        value={
                                                                            region2._id
                                                                        }
                                                                    >
                                                                        {
                                                                            region2.name
                                                                        }
                                                                    </MenuItem>
                                                                );
                                                            },
                                                        )}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item={true} xs={12}>
                                            <FormControl
                                                variant="outlined"
                                                className={classes.textField}
                                                fullWidth={true}
                                                required={true}
                                            >
                                                <InputLabel id="demo-simple-select-required-label">
                                                    Kunta
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-required-label"
                                                    id="demo-simple-select-required"
                                                    name="locality"
                                                    value={locality}
                                                    onChange={this.handleChange.bind(
                                                        this,
                                                    )}
                                                >
                                                    <MenuItem value={locality}>
                                                        <em>Kunta *</em>
                                                    </MenuItem>
                                                    {!!localities.length &&
                                                        localities.map(
                                                            (
                                                                locality2: ILocation,
                                                            ) => {
                                                                return (
                                                                    <MenuItem
                                                                        key={
                                                                            locality2._id
                                                                        }
                                                                        value={
                                                                            locality2._id
                                                                        }
                                                                    >
                                                                        {
                                                                            locality2.location
                                                                        }
                                                                    </MenuItem>
                                                                );
                                                            },
                                                        )}
                                                </Select>
                                                <FormHelperText>
                                                    Jos haluat muuttaa kuntaa
                                                    muualle Suomeen, muuta ensin
                                                    maakuntaa
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item={true} xs={12}>
                                    <Typography
                                        variant="body1"
                                        className={classes.info}
                                    >
                                        Tilaus on sitova, lisätietoa
                                        Halkoliiteri.com{' '}
                                        <Link
                                            to="/myyntiehdot"
                                            className={classes.infoLink}
                                        >
                                            myyntiehdoista.
                                        </Link>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
}

function mapStateToProps(state: AppState): IContactInformationFormStateProps {
    return {
        currentUser: state.currentUser.user,
        checkoutForm: state.checkoutFormState.checkoutForm,
        regions: state.regions,
        localities: state.localities,
    };
}

export default withRouter(
    connect<IContactInformationFormStateProps, IDispatchProps, {}, AppState>(
        mapStateToProps,
        mapDispatchToProps,
    )(withStyles(styles)(ContactInformationForm)),
);
