import React, { FC } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
    Box,
    Grid,
    Paper,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';
import CongratsIcon from './CongratsIcon';
import { AppState } from '../../store/store';
import { IUser } from '../../../../types';
import { mapDispatchToProps } from '../../types/dispatchActionTypes';

interface IActivateAccountInfoStateProps {
    user: IUser;
}

type ActivateAccountInfoProps = IActivateAccountInfoStateProps &
    IDispatchProps &
    RouteComponentProps;
const ActivateAccountInfo: FC<ActivateAccountInfoProps> = (props) => {
    const logoutHandler = () => {
        const { logoutAction } = props;
        logoutAction();
        props.history.push('/');
    };
    return (
        <>
            <Box mt={5} />
            <Grid
                container={true}
                direction="column"
                justify="center"
                alignItems="center"
            >
                <Grid item={true} xs={12} md={6}>
                    <Paper>
                        <Box p={3}>
                            <Grid
                                container={true}
                                justify="center"
                                alignItems="center"
                            >
                                <Grid item={true} xs={8}>
                                    <span>
                                        <CongratsIcon />
                                    </span>
                                </Grid>
                            </Grid>
                            <Typography variant="h4">
                                Tervetuloa halkoliiteri.com palveluun
                            </Typography>
                            <Typography variant="body1">
                                Voitte käyttää tiliänne, kun olette maksaneet
                                laskun ja halkoliiteri.com admin on aktivoinut
                                tilinne.
                            </Typography>
                            <Typography variant="body1">
                                Ongelmatilanteissa ja mikäli teillä on
                                kysymyksiä, voitte kysy lisätietoja osoitteesta{' '}
                                <a
                                    className="black"
                                    href="mailto: asiakaspalvelu@halkoliiteri.com"
                                >
                                    asiakaspalvelu@halkoliiteri.com
                                </a>
                                .
                            </Typography>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item={true} xs={6}>
                    <List>
                        <ListItem button={true} onClick={logoutHandler}>
                            <ListItemIcon>
                                <ExitToApp />
                            </ListItemIcon>
                            <ListItemText primary="Kirjaudu ulos" />
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </>
    );
};

function mapStateToProps(state: AppState): IActivateAccountInfoStateProps {
    return {
        user: state.currentUser.user,
    };
}

export default withRouter(
    connect<IActivateAccountInfoStateProps, IDispatchProps, {}, AppState>(
        mapStateToProps,
        mapDispatchToProps,
    )(ActivateAccountInfo),
);
