import React, { Component, FormEvent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { AppState } from '../../../store/store';
import {
    Grid,
    Box,
    Paper,
    Typography,
    TextField,
    Button,
    InputBaseComponentProps,
    Theme,
} from '@material-ui/core';
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles';
import { mapDispatchToProps } from '../../../types/dispatchActionTypes';
import {
    setResetPasswordInputValues,
    setUiPropsValues,
} from '../../../utils/utils';
import {
    validatePassword,
    validateConfPassword,
    validateEmail,
} from '../../../utils/formInputValidationUtil';
import MainStepper from '../../../components/Stepper/MainStepper';
import { IResetPasswordForm, IStep, IUiProps } from '../../../../../types';

interface IResetPasswordFormStateProps {
    uiProps: IUiProps;
    resetPasswordForm: IResetPasswordForm;
}

type ResetPasswordFormProps = IResetPasswordFormStateProps &
    IDispatchProps &
    RouteComponentProps &
    WithStyles<typeof styles>;

const steps: IStep[] = [
    { label: 'Sähköposti', _id: 0 },
    { label: 'Pinkoodi', _id: 1 },
    { label: 'Salasanan vaihto', _id: 2 },
];

const styles = (theme: Theme) =>
    createStyles({
        mainContainer: {
            padding: '0 2rem',
        },
        stepsContainer: {
            marginTop: '5rem',
        },
        paper: {
            marginTop: '3rem',
            paddingTop: '2rem',
        },
    });

class ResetPasswordForm extends Component<ResetPasswordFormProps, AppState> {
    public componentDidMount() {
        const { updateUiStateAction, resetPasswordForm, uiProps } = this.props;
        if (resetPasswordForm.email.length === 0 && uiProps.activeStep !== 0) {
            updateUiStateAction(setUiPropsValues('activeStep', 0));
        }
    }

    public componentDidUpdate() {
        const { resetPasswordForm, uiProps } = this.props;
        if (resetPasswordForm.email.length === 0 && uiProps.activeStep === 2) {
            this.props.history.push('/kirjaudu');
        }
    }

    public componentWillUnmount() {
        const { resetPasswordForm, uiProps, updateUiStateAction } = this.props;
        if (resetPasswordForm.email.length === 0 && uiProps.activeStep === 2) {
            updateUiStateAction(setUiPropsValues('activeStep', 0));
        }
    }

    public handleChange = (event: InputBaseComponentProps): string => {
        const {
            updateResetPasswordFormAction,
            // requestPasswordResetAction,
        } = this.props;
        updateResetPasswordFormAction(setResetPasswordInputValues(event));
        return event.value;
    };

    public resetPasswordRecoveryForm = () => {
        const { updateUiStateAction } = this.props;
        updateUiStateAction(setUiPropsValues('activeStep', 0));
    };

    public submitHandler = (event: FormEvent, value: string) => {
        event.preventDefault();
        const {
            requestResetPasswordPincodeAction,
            requestPasswordResetAction,
            requestPincodeValidationAction,
            resetPasswordForm,
        } = this.props;
        if (value === 'email') {
            requestResetPasswordPincodeAction(resetPasswordForm);
        } else if (value === 'password') {
            requestPasswordResetAction(resetPasswordForm);
        } else if (value === 'pincode') {
            requestPincodeValidationAction(resetPasswordForm);
        }
    };

    public render() {
        const { activeStep } = this.props.uiProps;
        const { email, password, confPassword, pincode } =
            this.props.resetPasswordForm;
        const { classes } = this.props;

        return (
            <Grid
                container={true}
                justify="center"
                className={classes.mainContainer}
            >
                <Grid
                    md={11}
                    sm={12}
                    item={true}
                    className={classes.stepsContainer}
                >
                    <MainStepper steps={steps} activeStep={activeStep} />
                </Grid>
                <Grid item={true} sm={8} md={6} xs={12}>
                    <Paper className={classes.paper}>
                        <Grid container={true} justify="center">
                            <Grid item={true}>
                                <Typography variant="h4">
                                    Salasanan palautus
                                </Typography>
                            </Grid>
                            <Grid item={true} sm={8} xs={10}>
                                <Box my={3}>
                                    {activeStep === 0 && (
                                        <form
                                            onSubmit={(event: FormEvent) =>
                                                this.submitHandler(
                                                    event,
                                                    'email',
                                                )
                                            }
                                        >
                                            <Grid
                                                container={true}
                                                justify="center"
                                            >
                                                <Grid item={true}>
                                                    <TextField
                                                        type="email"
                                                        error={validateEmail(
                                                            email,
                                                        )}
                                                        helperText={
                                                            validateEmail(email)
                                                                ? 'Sähköposti on väärää formaattia.'
                                                                : ''
                                                        }
                                                        autoFocus={true}
                                                        required={true}
                                                        id="email-required"
                                                        label="Sähköposti"
                                                        name="email"
                                                        fullWidth={true}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        value={email}
                                                    />
                                                    <Typography variant="body1">
                                                        Syötä kenttään
                                                        sähköposti joka on
                                                        rekisteröity
                                                        käyttäjätilillenne.
                                                        Mikäli ette muista
                                                        sähköposti osoitetta,
                                                        voitte ottaa yhteyttä{' '}
                                                        <Link
                                                            to="/asiakaspalvelu"
                                                            style={{
                                                                color: 'forestGreen',
                                                            }}
                                                        >
                                                            asiakaspalveluumme
                                                        </Link>
                                                        .
                                                    </Typography>
                                                </Grid>
                                                <Grid item={true}>
                                                    <Box my={3}>
                                                        <Button
                                                            type="submit"
                                                            variant="outlined"
                                                            color="primary"
                                                        >
                                                            Palauta salasana
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    )}
                                    {activeStep === 1 && (
                                        <form
                                            onSubmit={(event: FormEvent) =>
                                                this.submitHandler(
                                                    event,
                                                    'pincode',
                                                )
                                            }
                                        >
                                            <Grid
                                                container={true}
                                                justify="center"
                                            >
                                                <Grid item={true} xs={8}>
                                                    <Box my={2}>
                                                        <TextField
                                                            name="pincode"
                                                            id="pincode-required"
                                                            value={pincode}
                                                            fullWidth={true}
                                                            required={true}
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid
                                                    item={true}
                                                    xs={10}
                                                    md={6}
                                                >
                                                    <Box py={2}>
                                                        <Button
                                                            type="button"
                                                            variant="outlined"
                                                            color="secondary"
                                                            onClick={
                                                                this
                                                                    .resetPasswordRecoveryForm
                                                            }
                                                        >
                                                            Alkuun
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                                <Grid item={true} xs={6}>
                                                    <Box my={2}>
                                                        <Button
                                                            type="submit"
                                                            variant="outlined"
                                                            color="primary"
                                                        >
                                                            Varmenna
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    )}
                                    {activeStep === 2 && (
                                        <form
                                            onSubmit={(event: FormEvent) =>
                                                this.submitHandler(
                                                    event,
                                                    'password',
                                                )
                                            }
                                        >
                                            <Grid
                                                container={true}
                                                justify="center"
                                            >
                                                <Grid item={true} xs={12}>
                                                    <Box my={2}>
                                                        <TextField
                                                            error={validatePassword(
                                                                password,
                                                            )}
                                                            helperText={
                                                                validatePassword(
                                                                    password,
                                                                )
                                                                    ? 'Heikko salasana'
                                                                    : ''
                                                            }
                                                            autoFocus={true}
                                                            required={true}
                                                            id="password-required"
                                                            label="Salasana"
                                                            name="password"
                                                            fullWidth={true}
                                                            type="password"
                                                            color="primary"
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item={true} xs={12}>
                                                    <Box my={2}>
                                                        <TextField
                                                            error={validateConfPassword(
                                                                confPassword,
                                                                password,
                                                            )}
                                                            helperText={
                                                                validateConfPassword(
                                                                    confPassword,
                                                                    password,
                                                                )
                                                                    ? 'Salasanat eivät täsmää!'
                                                                    : ''
                                                            }
                                                            required={true}
                                                            name="confPassword"
                                                            id="confpassword-required"
                                                            label="Vahvista salasana"
                                                            fullWidth={true}
                                                            type="password"
                                                            color="primary"
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item={true} xs={12}>
                                                    <Box mt={2}>
                                                        <Grid
                                                            container={true}
                                                            justify="space-between"
                                                        >
                                                            <Grid
                                                                item={true}
                                                                xs={6}
                                                            >
                                                                <Link to="/kirjaudu">
                                                                    <Button
                                                                        type="button"
                                                                        variant="outlined"
                                                                        color="secondary"
                                                                    >
                                                                        Takaisin
                                                                    </Button>
                                                                </Link>
                                                            </Grid>
                                                            <Grid
                                                                item={true}
                                                                xs={6}
                                                            >
                                                                <Button
                                                                    type="submit"
                                                                    variant="outlined"
                                                                    color="primary"
                                                                >
                                                                    Tallenna
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

function mapStateToProps(state: AppState): IResetPasswordFormStateProps {
    return {
        uiProps: state.uiState.uiProps,
        resetPasswordForm: state.resetPasswordFormState.resetPasswordForm,
    };
}

export default withRouter(
    connect<IResetPasswordFormStateProps, IDispatchProps, {}, AppState>(
        mapStateToProps,
        mapDispatchToProps,
    )(withStyles(styles)(ResetPasswordForm)),
);
