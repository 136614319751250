import { apiCall, setHeader } from '../../apiRequests/apiCall';
import { AppActions } from '../../types/actions';
import { Dispatch } from 'redux';
import { addMessage } from './messageActions';
import {
    ADD_FAVORITE,
    REMOVE_FAVORITE,
    SET_FAVORITES,
} from '../../types/favoritesActionTypes';
import { IProduct } from '../../../../types';

/** Public Method */
export function setFavorites(favorites: IProduct[]): AppActions {
    return {
        type: SET_FAVORITES,
        favorites,
    };
}

/** Restricted access to favorite owners only */
export function addFavorite(favorite: IProduct): AppActions {
    return {
        type: ADD_FAVORITE,
        favorite,
    };
}

export function removeFavorite(id: string): AppActions {
    return {
        type: REMOVE_FAVORITE,
        id,
    };
}

/** Public method */
export const fetchFavorites = (): ThunkResult<void> => {
    setHeader('get', '');
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('get', '/api/favorites', null)
                .then((res: any) => {
                    const { favorites } = res;
                    dispatch(setFavorites(favorites));
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

/** Restricted access to service owners only, for Users */
export const addFavoriteCall = (id: string): ThunkResult<void> => {
    setHeader('post', '');
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('post', '/api/favorites', { id })
                .then((res: any) => {
                    const { favorites } = res;
                    dispatch(setFavorites(favorites));
                    dispatch(
                        addMessage({
                            text: res.message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

export const removeFavoriteCall = (id: string): ThunkResult<void> => {
    setHeader('patch', '');
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('patch', '/api/favorites/', { id })
                .then((res: any) => {
                    const { message, favorites } = res;
                    dispatch(setFavorites(favorites));
                    dispatch(
                        addMessage({
                            text: message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};
