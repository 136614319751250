import { IRegionFormState } from '../../../../types';
import {
    RegionFormActionTypes,
    RESET_REGION_FORM,
    UPDATE_REGION_FORM,
} from '../../types/regionFormActionTypes';
import { resetRegionFormFunction } from '../../utils/resetUtils';

const DEFAULT_STATE: IRegionFormState = {
    regionForm: resetRegionFormFunction(),
};

const regionFormReducer = (
    state = DEFAULT_STATE,
    action: RegionFormActionTypes,
) => {
    switch (action.type) {
        case UPDATE_REGION_FORM:
            return Object.assign({}, state, {
                regionForm: {
                    ...state.regionForm,
                    [Object.keys(action.regionFormState.regionForm)[0]]:
                        Object.values(action.regionFormState.regionForm)[0],
                },
            });
        case RESET_REGION_FORM:
            return {
                ...state,
                regionForm: action.regionFormState.regionForm,
            };
        default:
            return state;
    }
};

export default regionFormReducer;
