import React, { FC, Fragment } from 'react';
import './Modal.scss';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ProductForm from '../ManageProducts/ProductForm';
import ServiceForm from '../ManageProducts/ServiceForm';
import { getProductName, setUiPropsValues } from '../../utils/utils';
import { Edit, AddCircle } from '@material-ui/icons';
import { AppState } from '../../store/store';
import { mapDispatchToProps } from '../../types/dispatchActionTypes';
import { connect } from 'react-redux';
import SellerProfile from './SellerProfile';
import {
    IProduct,
    IUiProps,
    IUser,
    ProductCategories,
} from '../../../../types';
import {
    resetProductFormFunction,
    resetServiceFormFunction,
} from '../../utils/resetUtils';
import Cropper from '../Cropper/Cropper';

interface IModalStateProps {
    userProducts: IProduct[];
    otherUserProducts: IProduct[];
    userServices: IProduct[];
    sellers: IUser[];
    uiProps: IUiProps;
}

const useStyles = makeStyles((theme: Theme) => ({
    iconText: {
        display: 'flex',
        paddingTop: '.8rem',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg': {
            marginRight: '.6rem',
        },
    },
}));

interface OwnProps {
    modalOpen: boolean;
    editProduct: boolean;
    showProductForm?: boolean;
    showServiceForm?: boolean;
    showImageUpload?: boolean;
    selectedCategory: ProductCategories;
}

type ModalProps = IModalStateProps & OwnProps & IDispatchProps;

const Modal: FC<ModalProps> = (props) => {
    const {
        showProductForm,
        showServiceForm,
        showImageUpload,
        editProduct,
        selectedCategory,
        sellers,
        uiProps,
        userProducts,
        otherUserProducts,
        userServices,
    } = props;
    const { sellerId, modalOpen } = uiProps;
    const classes = useStyles();

    const showModalHandler = (showModal: boolean) => {
        const {
            updateUiStateAction,
            resetServiceFormAction,
            resetProductFormAction,
        } = props;
        updateUiStateAction(setUiPropsValues('modalOpen', showModal));
        updateUiStateAction(setUiPropsValues('showServiceForm', false));
        updateUiStateAction(setUiPropsValues('showProductForm', false));
        updateUiStateAction(setUiPropsValues('showImageUploadForm', false));
        updateUiStateAction(setUiPropsValues('showEditProductForm', false));
        updateUiStateAction(setUiPropsValues('showEditServiceForm', false));
        updateUiStateAction(setUiPropsValues('itemId', ''));
        resetProductFormAction(resetProductFormFunction());
        resetServiceFormAction(resetServiceFormFunction());
    };
    const seller = sellers.find(
        (sellerInFind: IUser) => sellerInFind._id === sellerId,
    );
    const itemToEdit = userProducts
        .concat(userServices)
        .concat(otherUserProducts)
        .find(
            (productInFind: IProduct) => productInFind._id === uiProps.itemId,
        );
    return (
        <Dialog
            open={modalOpen as boolean}
            onClose={() => showModalHandler(!modalOpen)}
            fullWidth={sellerId ? true : false}
            maxWidth="xl"
        >
            {!showServiceForm && !showProductForm && !showImageUpload ? (
                <SellerProfile
                    seller={seller as IUser}
                    showModalHandler={showModalHandler}
                    showModal={modalOpen as boolean}
                />
            ) : (
                <Fragment>
                    <DialogTitle id="form-dialog-title" className="center">
                        {itemToEdit && (
                            <div className={classes.iconText}>
                                <Edit />
                                <span>
                                    {'Muokkaa ' + showProductForm
                                        ? 'Tuotetta '
                                        : 'Palvelua ' +
                                          getProductName(itemToEdit)}
                                </span>
                            </div>
                        )}
                        {!itemToEdit && (
                            <div className={classes.iconText}>
                                <AddCircle />
                                <span>{`Lisää ${
                                    showProductForm ? 'tuote' : 'palvelu'
                                }`}</span>
                            </div>
                        )}
                    </DialogTitle>
                    <DialogContent>
                        {showProductForm ? (
                            <ProductForm
                                selectedCategory={selectedCategory}
                                buttonText={editProduct ? 'Tallenna' : 'Luo'}
                            />
                        ) : showServiceForm ? (
                            <ServiceForm
                                buttonText={editProduct ? 'Tallenna' : 'Luo'}
                                selectedCategory={selectedCategory}
                            />
                        ) : showImageUpload ? (
                            <Cropper product={itemToEdit as IProduct} />
                        ) : (
                            <p>Nothing selected</p>
                        )}
                    </DialogContent>
                </Fragment>
            )}
        </Dialog>
    );
};

function mapStateToProps(state: AppState): IModalStateProps {
    return {
        userProducts: state.userProducts,
        otherUserProducts: state.otherUserProducts,
        userServices: state.userServices,
        sellers: state.sellers,
        uiProps: state.uiState.uiProps,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
