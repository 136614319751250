import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import './AppFooter.scss';
import { Typography, Grid, List, ListItem, Box } from '@material-ui/core';
import { OikopolutLinks } from '../../oikopolutLinks';
import { IOikoPolkuLink } from '../../../../types';

interface FooterProps {
    stories: [];
}

const AppFooter: FunctionComponent<FooterProps> = (props) => {
    return (
        <Box py={2}>
            <Grid container={true} justify="space-evenly">
                <Grid item={true} xs={11} sm={6} md={4}>
                    <List>
                        <ListItem>
                            <Typography variant="h6">
                                Halkoliiteri.com
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                Y-tunnus: 2470070-6
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <a
                                    href="https://www.finder.fi/Kuljetusliike/ATMD+Oy/Klaukkala/yhteystiedot/2676939?ref=redirect"
                                    target="__blank"
                                >
                                    ATMD Oy
                                </a>
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="h6">Asiakaspalvelu</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <Link to="/asiakaspalvelu">Ota yhteyttä</Link>
                            </Typography>
                        </ListItem>
                    </List>
                </Grid>
                <Grid item={true} xs={11} sm={6} md={4}>
                    <List>
                        {OikopolutLinks.map((oikopolutLink: IOikoPolkuLink) => {
                            return (
                                <Typography
                                    key={oikopolutLink.id}
                                    variant="body1"
                                >
                                    <Link to={oikopolutLink.href}>
                                        {oikopolutLink.innerHTML}
                                    </Link>
                                </Typography>
                            );
                        })}
                    </List>
                </Grid>
                <Grid item={true} xs={11} sm={6} md={4}>
                    <Typography variant="body1">
                        Halkoliiteri.com palvelun{' '}
                        <Link to="/kayttoehdot">Käyttöehdot</Link>
                    </Typography>
                    <Typography variant="body1">
                        Halkoliiteri.com palvelun{' '}
                        <Link to="/myyntiehdot">Myyntiehdot</Link>
                    </Typography>
                    <Typography variant="body1">
                        Halkoliiteri.com palvelun{' '}
                        <Link to="/laatuvaatimukset">
                            Mitta- ja laatuvaatimukset
                        </Link>
                    </Typography>
                </Grid>
                <Grid item={true} xs={12}>
                    <Typography variant="body1">
                        Designed and developed by{' '}
                        <a href="https://www.devdesign.fi" target="__blank">
                            www.devdesign.fi
                        </a>{' '}
                        2021
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default AppFooter;
