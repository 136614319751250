import React, { FC } from 'react';
import './AdminPanel.scss';

type colors = 'warning' | 'success' | 'danger' | 'info' | 'purple' | 'rose';

interface CardIconProps {
    color: colors;
}

const CardIcon: FC<CardIconProps> = (props) => {
    const { children, color } = props;
    return <div className={'card_header__icon ' + color}>{children}</div>;
};

export default CardIcon;
