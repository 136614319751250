import React, { FC, FormEvent, useState } from 'react';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    IconButton,
    InputBaseComponentProps,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    TextField,
    Typography,
} from '@material-ui/core';
import {
    Close,
    ContactMail,
    FiberManualRecord,
    Forum,
} from '@material-ui/icons';
import { connect } from 'react-redux';
import { AppState } from '../../../store/store';
import { mapDispatchToProps } from '../../../types/dispatchActionTypes';
import CardHeader from './CardHeader';
import { IMessage, IContact } from '../../../../../types';

interface IContactsCardStateProps {
    contacts: IContact[];
    contact: IContact;
}

type ContactsCardProps = IContactsCardStateProps & IDispatchProps;

const ContactsCard: FC<ContactsCardProps> = (props) => {
    const [showContact, updateShowContact] = useState<boolean>(false);
    const [messageValue, updateMessageValue] = useState<string>('');
    const { contacts, contact, editContactAction } = props;
    const showContactHandler = (show: boolean, id?: string) => {
        updateShowContact(show);
        if (id) {
            const { fetchContactAction } = props;
            fetchContactAction(id);
        }
    };
    const handleChange = (event: InputBaseComponentProps) => {
        updateMessageValue(event.target.value as string);
    };
    const handleSubmit = (event: FormEvent, id: string) => {
        event.preventDefault();
        const { respondToContactFromAdminAction } = props;
        respondToContactFromAdminAction(id, messageValue);
        updateMessageValue('');
    };
    return (
        <Card className="card">
            <CardHeader
                className="card_fullWidthHeader"
                color="purple"
                plain={true}
                stats={true}
                icon={true}
            >
                <Typography
                    className="card_fullWidthHeader__heading"
                    variant="h6"
                >
                    Yhteydenotot
                </Typography>
                <Typography
                    className="card_fullWidthHeader__paragraph"
                    variant="body1"
                >
                    Yhteydenottojen hallinta
                </Typography>
            </CardHeader>
            <CardContent>
                {!showContact ? (
                    <List dense={true}>
                        <ListItem>
                            <Typography variant="h6">Yhteydenotot</Typography>
                        </ListItem>
                        {contacts &&
                            contacts.map((contact2: IContact) => {
                                return (
                                    <ListItem key={contact2._id}>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <ContactMail />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                'Luonut: ' +
                                                contact2.name +
                                                ', Aihe: ' +
                                                contact2.subject
                                            }
                                            secondary={
                                                'Updated At: ' +
                                                new Date(
                                                    contact2.createdAt,
                                                ).toLocaleDateString('fi')
                                            }
                                        />
                                        <ListItemSecondaryAction>
                                            <IconButton
                                                edge="end"
                                                aria-label="update status"
                                                onClick={() => {
                                                    editContactAction(
                                                        contact2._id,
                                                        contact2.status ===
                                                            'recieved'
                                                            ? 'in progress'
                                                            : 'resolved',
                                                    );
                                                }}
                                            >
                                                <FiberManualRecord
                                                    color={
                                                        contact2.status ===
                                                        'recieved'
                                                            ? 'action'
                                                            : contact2.status ===
                                                              'in progress'
                                                            ? 'primary'
                                                            : 'secondary'
                                                    }
                                                />
                                            </IconButton>
                                            <IconButton
                                                edge="end"
                                                aria-label="open messages"
                                                onClick={() =>
                                                    showContactHandler(
                                                        !showContact,
                                                        contact2._id,
                                                    )
                                                }
                                            >
                                                <Forum />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                );
                            })}
                    </List>
                ) : (
                    <Grid container={true} justify="center" alignItems="center">
                        <Grid item={true} xs={9}>
                            {contact && (
                                <Box p={3}>
                                    <Typography align="left" variant="h6">
                                        {'Aihe: ' + contact.subject}
                                    </Typography>
                                    <Typography align="left" variant="body1">
                                        {'Luonut: ' + contact.name}
                                    </Typography>
                                    <Typography align="left" variant="body1">
                                        {'email: ' + contact.email}
                                    </Typography>
                                    <Typography align="left" variant="body1">
                                        {'id: ' + contact._id}
                                    </Typography>
                                </Box>
                            )}
                        </Grid>
                        <Grid item={true} xs={1}>
                            <IconButton
                                edge="end"
                                aria-label="open messages"
                                onClick={() => showContactHandler(!showContact)}
                            >
                                <Close />
                            </IconButton>
                        </Grid>
                        <Grid item={true} xs={12}>
                            <Box p={1}>
                                <List dense={true}>
                                    {contact.messages.map(
                                        (message: IMessage) => {
                                            return (
                                                <ListItem key={message.author}>
                                                    <ListItemText
                                                        primary={message.text}
                                                        secondary={
                                                            '- ' +
                                                            message.author +
                                                            ', ' +
                                                            new Date(
                                                                message.createdAt,
                                                            ).toLocaleDateString(
                                                                'fi',
                                                            ) +
                                                            ' klo: ' +
                                                            new Date(
                                                                message.createdAt,
                                                            ).toLocaleTimeString(
                                                                'fi',
                                                            )
                                                        }
                                                    />
                                                </ListItem>
                                            );
                                        },
                                    )}
                                </List>
                                <form>
                                    <Grid container={true} justify="center">
                                        <Grid item={true} xs={12}>
                                            <Box p={1}>
                                                <TextField
                                                    variant="outlined"
                                                    name="message"
                                                    value={messageValue}
                                                    onChange={handleChange}
                                                    fullWidth={true}
                                                    required={true}
                                                    multiline={true}
                                                    rows={2}
                                                    label="Viesti"
                                                    type="text"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item={true} xs={12}>
                                            <Box p={1}>
                                                <Button
                                                    onClick={(
                                                        event: FormEvent,
                                                    ) =>
                                                        handleSubmit(
                                                            event,
                                                            contact._id,
                                                        )
                                                    }
                                                    type="button"
                                                    variant="outlined"
                                                    color="primary"
                                                    size="medium"
                                                >
                                                    Lähetä
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </CardContent>
        </Card>
    );
};

function mapStateToProps(state: AppState): IContactsCardStateProps {
    return {
        contact: state.contact,
        contacts: state.contacts,
    };
}

export default connect<IContactsCardStateProps, IDispatchProps, {}, AppState>(
    mapStateToProps,
    mapDispatchToProps,
)(ContactsCard);
