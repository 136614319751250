import React, { FunctionComponent } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
    Typography,
    Grid,
    Paper,
    Box,
    TableHead,
    TableRow,
    TableContainer,
    Table,
    TableCell,
    TableBody,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    box: {
        padding: '3rem 4rem',
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            padding: '3rem 1.6rem',
        },
    },
    image: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        fontSize: '1.4rem',
        '& img': {
            maxWidth: '100%',
        },
        '& span': {
            marginTop: '0.8rem',
            fontWeight: 'bold',
        },
    },
    list: {
        marginTop: '0.2rem',
        '& li': {
            marginBottom: '0.3rem',
        },
    },
    link: {
        color: '#333',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    unitSub: {
        fontSize: '90%',
        position: 'absolute',
        paddingLeft: '3px',
    },
}));

const tableInfo = [
    {
        name: 'Kuiva koivupilke',
        value: '1,0 irto-m3',
    },
    {
        name: 'Kuiva koivupilke',
        value: '0,6 pino-m3',
    },
    {
        name: 'Hake',
        value: '1,3 irto-m3',
    },
    {
        name: 'Pelletti',
        value: '0,3 irto-m3',
    },
    {
        name: 'Kevyt polttoöljy',
        value: '0,1 m3(= 100 litraa)',
    },
];

const secondTableInfo = [
    {
        name: 'Kaukolämpö',
        unit: '6,32 c/kWh',
        sub: '1)',
    },
    {
        name: 'Pelletti',
        unit: '6,58 c/kWh',
        sub: '2)',
    },
    {
        name: 'Pilke (koivu)',
        unit: '6,21 c/kWh',
        sub: '3)',
    },
    {
        name: 'Sähkö 1 (jatkuvatoiminen lämmitys)',
        unit: '11,25 c/kWh',
        sub: '4)',
    },
    {
        name: 'Sähkö 2 (osittain varaava lämmitys)',
        unit: '10,48 c/kWh',
        sub: '4)',
    },
    {
        name: 'Sähkö 3 (muu kuin lämmityssähkö)',
        unit: '13,79 c/kWh',
        sub: '4)',
    },
    {
        name: 'Öljy',
        unit: '10,93 c/kWh',
        sub: '5)',
    },
];

const UnitsOfMeasurement: FunctionComponent<{}> = () => {
    const classes = useStyles();

    return (
        <section>
            <Box py={8} px={1}>
                <Grid container={true} justify="center">
                    <Grid item={true} xs={12} md={8}>
                        <Paper>
                            <div className={classes.box}>
                                <Grid container={true}>
                                    <Grid item={true}>
                                        <Typography variant="h4">
                                            Energiavertailu
                                        </Typography>
                                        <br />
                                    </Grid>
                                    <Grid item={true}>
                                        <br />
                                        <Typography variant="body1">
                                            Yksi irtokuutiometri (i-m3) kuivaa
                                            koivupilkettä sisältää energiaa 1010
                                            kWh.
                                            <br /> Alla olevat polttoainemäärät
                                            ovat energiasisällöltään yhtä
                                            suuret.
                                        </Typography>

                                        <Grid item={true} xs={12} md={6}>
                                            <br />
                                            <br />
                                            <TableContainer component={Paper}>
                                                <Table aria-label="customized table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>
                                                                Polttoaine
                                                            </TableCell>
                                                            <TableCell>
                                                                Määrä
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {tableInfo.map(
                                                            (item, index) => {
                                                                return (
                                                                    <TableRow
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <TableCell>
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {
                                                                                item.value
                                                                            }
                                                                        </TableCell>
                                                                    </TableRow>
                                                                );
                                                            },
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                        <br />
                                        <br />
                                        <Typography>
                                            Kun koivupilkkeen määrä jaetaan
                                            kymmenellä, saadaan sillä
                                            korvattavissa oleva öljymäärä.
                                            <br />
                                            (Laskelmassa on käytetty
                                            bruttoenergiasisältöä. Eri
                                            polttimissa ja tulisijoissa on
                                            erilaiset hyötysuhteet, mistä
                                            johtuen todellinen korvaavuus voi
                                            vaihdella.)
                                        </Typography>
                                        <br />
                                        <br />
                                        <Typography>
                                            Lataa tästä: <br />
                                            <a
                                                className={classes.link}
                                                target="_blank"
                                                href="/downloads/energiavertailu.pdf"
                                            >
                                                Energisasisältöjen vertailu
                                            </a>{' '}
                                            (pdf)
                                        </Typography>
                                        <br />
                                        <Grid item={true} xs={12}>
                                            <Typography variant="subtitle2">
                                                Taulukko. Pientalojen eri
                                                lämmitysjärjestelmien
                                                lämmönhintoja
                                            </Typography>
                                            <br />
                                            <TableContainer component={Paper}>
                                                <Table aria-label="customized table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>
                                                                Lämmönlähde
                                                            </TableCell>
                                                            <TableCell>
                                                                Hinta (sis. alv)
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {secondTableInfo.map(
                                                            (item, index) => {
                                                                return (
                                                                    <TableRow
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <TableCell>
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {
                                                                                item.unit
                                                                            }
                                                                            <span
                                                                                className={
                                                                                    classes.unitSub
                                                                                }
                                                                            >
                                                                                {
                                                                                    item.sub
                                                                                }
                                                                            </span>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                );
                                                            },
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                        <br />
                                        <br />
                                        <Typography>
                                            Polttoaineiden hintatiedot:
                                            <ol className={classes.list}>
                                                <li>
                                                    Energiateollisuus:
                                                    Kaukolämmön hintatilasto
                                                    1.7.2010
                                                </li>
                                                <li>
                                                    Tilastokeskus: Puupelletin
                                                    kuluttajahinta 11/2010 5,26
                                                    c/kWh . Lämmön hinta
                                                    laskettu 80% hyötysuhteella.
                                                </li>
                                                <li>
                                                    Halkoliiteri.com: koivu,
                                                    kuiva, 33 cm, keskihinta
                                                    4,97 c/kWh ilman
                                                    kuljetuskustannusta. Lämmön
                                                    hinta laskettu 80%
                                                    hyötysuhteella.
                                                </li>
                                                <li>
                                                    Energiamarkkinavirasto:
                                                    Sähkön hintatilasto
                                                    1.12.2010
                                                </li>
                                                <li>
                                                    Öljy- ja kaasualan
                                                    keskusliitto: Öljytuotteiden
                                                    kuluttajahintaseuranta
                                                    15.12.2010 9,29 c/kWh.
                                                    Lämmön hinta laskettu 85%
                                                    hyötysuhteella.
                                                </li>
                                            </ol>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </section>
    );
};

export default UnitsOfMeasurement;
