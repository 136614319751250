import { IRegion } from '../../../types';

export const SET_REGIONS = 'SET_REGIONS';
export const ADD_REGION = 'ADD_REGION';
export const UPDATE_REGION = 'UPDATE_REGION';
export const REMOVE_REGION = 'REMOVE_REGION';

export interface SetRegions {
    type: typeof SET_REGIONS;
    regions: IRegion[];
}

export interface AddRegion {
    type: typeof ADD_REGION;
    region: IRegion;
}

export interface UpdateRegion {
    type: typeof UPDATE_REGION;
    region: IRegion;
}

export interface RemoveRegion {
    type: typeof REMOVE_REGION;
    id: string;
}

export type RegionActionTypes =
    | SetRegions
    | AddRegion
    | UpdateRegion
    | RemoveRegion;
