/** Form Input Validation Util */
/** This util is used to validate form inputs, with reusable validation functions */

/** Validate password */
export function validatePassword(password: string): boolean {
    return (
        (password.length > 0 && /(.)\1{2,}/.test(password)) ||
        !/(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(
            password,
        )
    );
}

export function validateConfPassword(
    confPassword: string,
    password: string,
): boolean {
    return (
        password.length > 0 &&
        confPassword.length > 0 &&
        confPassword !== password
    );
}

/** Validate email input */
export function validateEmail(email: string): boolean {
    return (
        email.length > 0 &&
        !/^[a-zA-Z0-9_\-.]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$/.test(email)
    );
}

/** Phonenumber Validation */
export function validatePhoneNumber(phoneNumber: string): boolean {
    return !/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(
        phoneNumber,
    );
}

export function validateCartItemsQuantity(value: number, max: number): number {
    if (value > max) {
        return max;
    } else if (value < 1) {
        return 1;
    } else {
        return value;
    }
}
