import { IOrderTotals } from '../../../types';

export const SET_ORDER_TOTALS = 'SET_ORDER_TOTALS';
export const RESET_ORDER_TOTALS = 'RESET_ORDER_TOTALS';

export interface SetOrderTotals {
    type: typeof SET_ORDER_TOTALS;
    orderTotals: IOrderTotals;
}

export interface ResetOrderTotals {
    type: typeof RESET_ORDER_TOTALS;
    orderTotals: IOrderTotals;
}
export type OrderTotalsActionTypes = SetOrderTotals | ResetOrderTotals;
