import { apiCall, setHeader } from '../../apiRequests/apiCall';
import {
    SET_USER_SERVICES,
    ADD_SERVICE,
    UPDATE_SERVICE,
    REMOVE_SERVICE,
} from '../../types/userServicesActionTypes';
import { AppActions } from '../../types/actions';
import { Dispatch } from 'redux';
import { addMessage } from './messageActions';
import { IServiceForm, IProduct } from '../../../../types';

/** Public Method */
export function setUserServices(userServices: IProduct[]): AppActions {
    return {
        type: SET_USER_SERVICES,
        userServices,
    };
}

/** Restricted access to service owners only, for Users */
export function addService(service: IProduct): AppActions {
    return {
        type: ADD_SERVICE,
        service,
    };
}

export function updateService(service: IProduct): AppActions {
    return {
        type: UPDATE_SERVICE,
        service,
    };
}

export function removeService(id: string): AppActions {
    return {
        type: REMOVE_SERVICE,
        id,
    };
}

/** Restricted access to service owners only, for Users */
export const createService = (serviceForm: IServiceForm): ThunkResult<void> => {
    setHeader('post', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('post', '/api/products', serviceForm)
                .then((res: any) => {
                    const { product } = res;
                    dispatch(addService(product));
                    dispatch(
                        addMessage({
                            text: res.message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

export const editService = (
    serviceForm: IServiceForm,
    serviceId: string,
): ThunkResult<void> => {
    setHeader('put', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('put', '/api/products/' + serviceId, serviceForm)
                .then((res: any) => {
                    const { product, message } = res;
                    dispatch(updateService(product));
                    dispatch(
                        addMessage({
                            text: message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

/** Restricted access to service owners only, for Users */
export const deleteService = (id: string): ThunkResult<void> => {
    setHeader('delete', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('delete', '/api/products/' + id, {})
                .then((res: any) => {
                    const { message } = res;
                    dispatch(removeService(id));
                    dispatch(
                        addMessage({
                            text: message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};
