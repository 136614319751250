import { apiCall, setHeader } from '../../apiRequests/apiCall';
import {
    UPDATE_LOCALITY,
    REMOVE_LOCALITY,
    SET_LOCALITIES,
    ADD_LOCALITY,
} from '../../types/localitiesActionTypes';
import { AppActions } from '../../types/actions';
import { Dispatch } from 'redux';
import { addMessage } from './messageActions';
import { ILocalityForm, ILocation } from '../../../../types';

/** Public Method */
export function setLocalities(localities: ILocation[]): AppActions {
    return {
        type: SET_LOCALITIES,
        localities,
    };
}
/** Restricted access to admins only */
export function addLocality(locality: ILocation): AppActions {
    return {
        type: ADD_LOCALITY,
        locality,
    };
}

export function updateLocality(locality: ILocation): AppActions {
    return {
        type: UPDATE_LOCALITY,
        locality,
    };
}

export function removeLocality(id: string): AppActions {
    return {
        type: REMOVE_LOCALITY,
        id,
    };
}
/** Public method */
export const fetchLocalities = (query: string): ThunkResult<void> => {
    setHeader('get', '');
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('get', '/api/locations?region=' + query, null)
                .then((res: any) => {
                    const { localities } = res;
                    dispatch(setLocalities(localities));
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

/** Restricted access to admins only */
/** Create Locality */
export const createLocality = (
    localityForm: ILocalityForm,
): ThunkResult<void> => {
    setHeader('post', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('post', '/api/locations', localityForm)
                .then((res: any) => {
                    const { message, locality } = res;
                    dispatch(addLocality(locality));
                    dispatch(
                        addMessage({
                            text: message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

/** Edit Locality */
export const editLocality = (
    localityForm: ILocalityForm,
    localityId: string,
): ThunkResult<void> => {
    setHeader('put', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('put', '/api/locations/' + localityId, localityForm)
                .then((res: any) => {
                    const { message, locality } = res;
                    dispatch(updateLocality(locality));
                    dispatch(
                        addMessage({
                            text: message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

/** Delete Locality */
export const deleteLocality = (localityId: string): ThunkResult<void> => {
    setHeader('delete', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('delete', '/api/locations/' + localityId, null)
                .then((res: any) => {
                    const { message } = res;
                    dispatch(
                        addMessage({
                            text: message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    dispatch(removeLocality(localityId));
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};
