import React, { FC } from 'react';
import './ShowProfile.scss';
import {
    Card,
    CardHeader,
    Avatar,
    CardContent,
    Typography,
    Paper,
} from '@material-ui/core';
import { PersonPin } from '@material-ui/icons';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { IUser } from '../../../../types';

interface IUserCardStateProps {
    user: IUser;
}

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        paddingTop: '2rem',
    },
}));

const UserCard: FC<IUserCardStateProps> = ({ user }) => {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <Paper className="circle mt-minus3">
                <CardHeader
                    avatar={
                        <Avatar aria-label="recipe" className="circle">
                            <PersonPin />
                        </Avatar>
                    }
                    className="circle center"
                />
            </Paper>
            <CardContent>
                <Typography variant="h6">Nimi</Typography>
                {user.business ? (
                    <Typography color="textSecondary" gutterBottom={true}>
                        {user.companyName}
                    </Typography>
                ) : (
                    <Typography color="textSecondary" gutterBottom={true}>
                        {user.contactInformation.firstname +
                            ' ' +
                            user.contactInformation.lastname}
                    </Typography>
                )}
                <Typography variant="h6">Osoite</Typography>
                <Typography color="textSecondary" gutterBottom={true}>
                    {user.address.street +
                        ', ' +
                        user.address.zip +
                        ' ' +
                        user.address.locality.location}
                </Typography>
                <Typography variant="h6">Yhteystiedot</Typography>
                <Typography color="textSecondary" gutterBottom={true}>
                    email: {user.email}
                </Typography>
                <Typography color="textSecondary" gutterBottom={true}>
                    puhelin: {user.contactInformation.phonenumbers[0]}
                </Typography>
                <Typography variant="body1" gutterBottom={true}>
                    Tili luotu:{' '}
                    {new Date(user.createdAt).toLocaleDateString('fi')}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default UserCard;
