import { IProduct } from '../../../../types';
import {
    SET_USER_PRODUCTS,
    ADD_PRODUCT,
    UPDATE_PRODUCT,
    REMOVE_PRODUCT,
    UserProductActionTypes,
} from '../../types/userProductsActionTypes';

const DEFAULT_STATE: IProduct[] = [];

const userProductReducer = (
    state = DEFAULT_STATE,
    action: UserProductActionTypes,
) => {
    switch (action.type) {
        case SET_USER_PRODUCTS:
            return action.userProducts;
        case ADD_PRODUCT:
            return [...state, action.product];
        case UPDATE_PRODUCT:
            return state.map((product: IProduct) => {
                if (product._id === action.product._id) {
                    return {
                        ...product,
                        ...action.product,
                    };
                } else {
                    return product;
                }
            });
        case REMOVE_PRODUCT:
            return state.filter(
                (product: IProduct) => product._id !== action.id,
            );
        default:
            return state;
    }
};

export default userProductReducer;
