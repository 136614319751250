import { IRegionFormState } from '../../../types';

export const UPDATE_REGION_FORM = 'UPDATE_REGION_FORM';
export const RESET_REGION_FORM = 'RESET_REGION_FORM';

export interface UpdateRegionForm {
    type: typeof UPDATE_REGION_FORM;
    regionFormState: IRegionFormState;
}

export interface ResetRegionForm {
    type: typeof RESET_REGION_FORM;
    regionFormState: IRegionFormState;
}

export type RegionFormActionTypes = UpdateRegionForm | ResetRegionForm;
