import React, { ComponentType, FC } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { AppState } from '../../store/store';

interface IAdminRouteProps {
    exact?: boolean;
    isAuthenticated: boolean | null;
    isAdmin: boolean | null;
    path: string;
    Component: ComponentType<any>;
}

const AdminRoute: FC<IAdminRouteProps> = ({
    isAuthenticated,
    isAdmin,
    Component,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                if (isAuthenticated && isAdmin) {
                    return <Component {...props} />;
                } else {
                    return (
                        <Redirect
                            to={{
                                pathname: '/',
                                state: {
                                    from: props.location,
                                },
                            }}
                        />
                    );
                }
            }}
        />
    );
};

const mapStateToProps = (state: AppState) => ({
    isAuthenticated: state.currentUser.isAuthenticated,
    isAdmin: state.currentUser.isAdmin,
});

export default connect(mapStateToProps)(AdminRoute);
