import React, { Component, FormEvent } from 'react';
import { mapDispatchToProps } from '../../types/dispatchActionTypes';
import { AppState } from '../../store/store';
import {
    InputBaseComponentProps,
    Grid,
    FormControl,
    InputLabel,
    Select,
    Button,
    MenuItem,
    Box,
    TextField,
} from '@material-ui/core';
import {
    setPriceTag,
    setServiceCreationFormInputValues,
} from '../../utils/utils';
import { ProductFormOptionLists, SetQueryKey } from '../../formOptionList';
import { resetServiceFormFunction } from '../../utils/resetUtils';
import { connect } from 'react-redux';
import {
    ProductNames,
    IServiceForm,
    IUser,
    IUiProps,
    ProductCategories,
    IProduct,
} from '../../../../types';

interface IServiceFormStateProps {
    currentUser: IUser;
    serviceForm: IServiceForm;
    userServices: IProduct[];
    uiProps: IUiProps;
}

interface OwnProps {
    buttonText: string;
    selectedCategory: ProductCategories;
}

type ServiceFormProps = IServiceFormStateProps & OwnProps & IDispatchProps;

class ServiceForm extends Component<ServiceFormProps, AppState> {
    public componentDidMount() {
        const { updateServiceFormAction, currentUser } = this.props;
        updateServiceFormAction({ user_id: currentUser._id });
    }
    public handleChange = (event: InputBaseComponentProps) => {
        const { updateServiceFormAction } = this.props;
        updateServiceFormAction(setServiceCreationFormInputValues(event));
    };
    public resetForm = () => {
        const { resetServiceFormAction, updateUiStateAction } = this.props;
        resetServiceFormAction(resetServiceFormFunction());
        updateUiStateAction({ showEditServiceForm: false });
        updateUiStateAction({ serviceId: '' });
    };
    public handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        const { createServiceAction, editServiceAction, serviceForm, uiProps } =
            this.props;
        const { showEditServiceForm, itemId } = uiProps;

        if (showEditServiceForm && itemId) {
            editServiceAction(serviceForm, itemId);
        } else {
            createServiceAction(serviceForm);
        }
        this.resetForm();
    };
    public render() {
        const {
            userServices,
            serviceForm,
            buttonText,
            uiProps,
            selectedCategory,
        } = this.props;
        const { itemId } = uiProps;
        const service = userServices.find(
            (serviceInFind) => serviceInFind._id === itemId,
        );
        const { units, vatValues, names } = ProductFormOptionLists;
        const { name, price, unit, description, vat, category, quantity } =
            serviceForm;
        let key = SetQueryKey(selectedCategory as ProductCategories);
        return (
            <Box>
                <form onSubmit={this.handleSubmit}>
                    <Grid container={true} justify="space-evenly">
                        <Grid item={true} xs={12} md={6}>
                            <Box p={1}>
                                <FormControl
                                    variant="outlined"
                                    fullWidth={true}
                                    required={true}
                                >
                                    <InputLabel id="demo-simple-select-required-label">
                                        Kategoria
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-required-label"
                                        id="demo-simple-select-required"
                                        name="category"
                                        value={
                                            category
                                                ? category
                                                : service
                                                ? service.category
                                                : category
                                        }
                                        onChange={this.handleChange}
                                        label="Kategoria"
                                    >
                                        <MenuItem
                                            value={
                                                selectedCategory
                                                    ? selectedCategory
                                                    : service
                                                    ? service.category
                                                    : selectedCategory
                                            }
                                        >
                                            <em>Kategoria</em>
                                        </MenuItem>

                                        <MenuItem
                                            key={selectedCategory}
                                            value={selectedCategory}
                                        >
                                            {selectedCategory}
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <Box p={1}>
                                <FormControl
                                    variant="outlined"
                                    fullWidth={true}
                                    required={true}
                                >
                                    <InputLabel id="demo-simple-select-required-label">
                                        Tuotetyyppi
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-required-label"
                                        id="demo-simple-select-required"
                                        name="name"
                                        value={
                                            name
                                                ? name
                                                : service
                                                ? service.name
                                                : name
                                        }
                                        onChange={this.handleChange}
                                        label="Tuotetyyppi"
                                    >
                                        <MenuItem
                                            value={
                                                name
                                                    ? name
                                                    : service
                                                    ? service.name
                                                    : name
                                            }
                                        >
                                            <em>Tuotetyyppi</em>
                                        </MenuItem>
                                        {names[key].map(
                                            (name2: ProductNames | string) => {
                                                return (
                                                    <MenuItem
                                                        key={name2}
                                                        value={name2}
                                                    >
                                                        {name2}
                                                    </MenuItem>
                                                );
                                            },
                                        )}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <Box p={1}>
                                <FormControl
                                    variant="outlined"
                                    fullWidth={true}
                                    required={true}
                                >
                                    <InputLabel id="demo-simple-select-required-label">
                                        Valitse yksikkö
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-required-label"
                                        id="demo-simple-select-required"
                                        name="unit"
                                        value={
                                            unit
                                                ? unit
                                                : service
                                                ? service.unit
                                                : unit
                                        }
                                        onChange={this.handleChange}
                                        label="Valitse yksikkö"
                                    >
                                        <MenuItem
                                            value={
                                                unit
                                                    ? unit
                                                    : service
                                                    ? service.unit
                                                    : unit
                                            }
                                        >
                                            <em>Valitse Yksikkö</em>
                                        </MenuItem>
                                        {!!units.length &&
                                            units.map((unit2: string) => {
                                                return (
                                                    <MenuItem
                                                        key={unit2}
                                                        value={unit2}
                                                    >
                                                        {unit2}
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <Box p={1}>
                                <TextField
                                    variant="outlined"
                                    name="price"
                                    type="number"
                                    id="price-required"
                                    value={
                                        price
                                            ? price
                                            : service
                                            ? setPriceTag(service.price)
                                            : price
                                    }
                                    onChange={this.handleChange}
                                    fullWidth={true}
                                    required={true}
                                    label="Hinta"
                                />
                            </Box>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <Box p={1}>
                                <TextField
                                    name="quantity"
                                    type="number"
                                    id="quantity-required"
                                    value={
                                        quantity
                                            ? quantity
                                            : service
                                            ? service.quantity
                                            : quantity
                                    }
                                    onChange={this.handleChange}
                                    fullWidth={true}
                                    required={true}
                                    label="Määrä"
                                    variant="outlined"
                                />
                            </Box>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <Box p={1}>
                                <FormControl
                                    variant="outlined"
                                    fullWidth={true}
                                    required={true}
                                >
                                    <InputLabel id="demo-simple-select-required-label">
                                        Valitse Alv kanta
                                    </InputLabel>
                                    <Select
                                        labelId="vat-selection-menu"
                                        id="vat-selection-menu"
                                        name="vat"
                                        value={
                                            vat
                                                ? vat
                                                : service
                                                ? service.vat
                                                : vat
                                        }
                                        onChange={this.handleChange}
                                        label="Valitse Alk kanta"
                                    >
                                        <MenuItem
                                            value={
                                                vat
                                                    ? vat
                                                    : service
                                                    ? service.vat
                                                    : vat
                                            }
                                        >
                                            <em>Valitse Alv kanta</em>
                                        </MenuItem>
                                        {!!vatValues.length &&
                                            vatValues.map((vat2: number) => {
                                                return (
                                                    <MenuItem
                                                        key={vat2}
                                                        value={vat2}
                                                    >
                                                        {vat2}
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item={true} xs={12}>
                            <Box p={1}>
                                <TextField
                                    variant="outlined"
                                    name="description"
                                    multiline={true}
                                    rows={4}
                                    value={
                                        description
                                            ? description
                                            : service
                                            ? service.description
                                            : description
                                    }
                                    onChange={this.handleChange}
                                    fullWidth={true}
                                    required={false}
                                    label="Kuvaus"
                                    type="text"
                                />
                            </Box>
                        </Grid>
                        <Grid
                            container={true}
                            alignItems="flex-start"
                            justify="flex-end"
                            direction="row"
                        >
                            <Box p={1}>
                                {service && (
                                    <Button
                                        type="reset"
                                        variant="outlined"
                                        color="secondary"
                                        size="large"
                                        onClick={this.resetForm}
                                    >
                                        Tyhjennä
                                    </Button>
                                )}
                                <Button
                                    type="submit"
                                    size="large"
                                    variant="outlined"
                                    color="primary"
                                >
                                    {buttonText}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        );
    }
}
function mapStateToProps(state: AppState): IServiceFormStateProps {
    return {
        userServices: state.userServices,
        serviceForm: state.serviceFormState.serviceForm,
        currentUser: state.currentUser.user,
        uiProps: state.uiState.uiProps,
    };
}
export default connect<
    IServiceFormStateProps,
    IDispatchProps,
    OwnProps,
    AppState
>(
    mapStateToProps,
    mapDispatchToProps,
)(ServiceForm);
