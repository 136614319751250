import React, { FC, ChangeEvent, MouseEvent, useState } from 'react';
import clsx from 'clsx';
import {
    createStyles,
    lighten,
    makeStyles,
    Theme,
} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import UserRow from './UserRow';
import { AppState } from '../../../store/store';
import { mapDispatchToProps } from '../../../types/dispatchActionTypes';
import { connect } from 'react-redux';
import { Close } from '@material-ui/icons';
import Modal from '../Modal/Modal';
import { IAdminPanelUiProps, IUser } from '../../../../../types';

interface IUsersTableStateProps {
    users: IUser[];
    ids: (string | string[])[];
    adminPanelUiProps: IAdminPanelUiProps;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    _id: keyof IUser;
    label: string;
    numeric: boolean;
}

const headCells: any = [
    { _id: 'companyName', numeric: false, disablePadding: true, label: 'Nimi' },
    {
        _id: 'address',
        numeric: false,
        disablePadding: false,
        label: 'Paikkakunta',
    },
    {
        _id: 'business',
        numeric: false,
        disablePadding: false,
        label: 'Käyttäjätyyppi',
    },
    {
        _id: 'username',
        numeric: false,
        disablePadding: false,
        label: 'Username',
    },
    {
        _id: 'userAccount',
        numeric: false,
        disablePadding: false,
        label: 'Tilityyppi',
    },
    {
        _id: 'paid',
        numeric: false,
        disablePadding: false,
        label: 'Maksettu',
    },
    {
        _id: 'activated',
        numeric: false,
        disablePadding: false,
        label: 'Aktiivinen',
    },
];

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    numSelected: number;
    onRequestSort: (
        event: MouseEvent<unknown>,
        companyName: keyof IUser,
    ) => void;
    onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const {
        classes,
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props;
    // tslint:disable-next-line: variable-name
    const createSortHandler =
        (_id: keyof IUser) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, _id);
        };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={
                            numSelected > 0 && numSelected < rowCount
                        }
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'Valitse kaikki käyttäjät',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell: HeadCell) => (
                    <TableCell
                        key={headCell._id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell._id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell._id}
                            direction={orderBy === headCell._id ? order : 'asc'}
                            onClick={createSortHandler(headCell._id)}
                        >
                            {headCell.label}
                            {orderBy === headCell._id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc'
                                        ? 'Suodata laskevasti'
                                        : 'Suodata nousevasti'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
        },
        highlight:
            theme.palette.type === 'light'
                ? {
                      color: theme.palette.secondary.main,
                      backgroundColor: lighten(
                          theme.palette.secondary.light,
                          0.85,
                      ),
                  }
                : {
                      color: theme.palette.text.primary,
                      backgroundColor: theme.palette.secondary.dark,
                  },
        title: {
            flex: '1 1 100%',
        },
    }),
);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 750,
        },
        th: {
            maxWidth: '100%',
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
    }),
);

type UsersTableProps = IUsersTableStateProps & IDispatchProps;

const UsersTable: FC<UsersTableProps> = (props) => {
    const classes = useStyles();
    const toolbarClasses = useToolbarStyles();
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof IUser>('companyName');
    const [page, setPage] = useState<number>(0);
    const [dense, setDense] = useState<boolean>(true);
    const [rowsPerPage, setRowsPerPage] = useState<number>(15);

    const { users, ids, updateIdsAction, resetIdsAction, adminPanelUiProps } =
        props;
    const { userId, modalOpen } = adminPanelUiProps;

    const handleRequestSort = (
        event: MouseEvent<unknown>,
        companyName: keyof IUser,
    ) => {
        const isAsc = orderBy === companyName && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(companyName);
    };

    const handleSelectAllClick = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        if (event.target.checked) {
            const newSelecteds: string[] = users.map((user: IUser) => user._id);
            updateIdsAction(newSelecteds);
            return;
        }
        resetIdsAction([]);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDense(event.target.checked);
    };

    const deleteHandler = () => {
        const { deleteUserAction, deleteUsersAction } = props;
        if (ids.length === 1) {
            deleteUserAction(ids[0] as string);
        } else {
            deleteUsersAction(ids as string[]);
        }
    };

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, users.length - page * rowsPerPage);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Toolbar
                    className={
                        'deleteHandle ' +
                        clsx(toolbarClasses.root, {
                            [toolbarClasses.highlight]: ids.length > 0,
                        })
                    }
                >
                    <FormControlLabel
                        control={
                            <Switch
                                checked={dense}
                                onChange={handleChangeDense}
                            />
                        }
                        label={dense ? 'Suurempi välitys' : 'Pienempi välitys'}
                    />
                    {ids.length > 0 ? (
                        <Typography
                            className={toolbarClasses.title}
                            color="inherit"
                            variant="subtitle1"
                            component="div"
                        >
                            {ids.length} Valittu
                        </Typography>
                    ) : (
                        <Typography
                            className={toolbarClasses.title}
                            variant="h6"
                            id="tableTitle"
                            component="div"
                        >
                            Käyttäjät
                        </Typography>
                    )}
                    {ids.length > 0 ? (
                        <React.Fragment>
                            <Tooltip title="Poista">
                                <IconButton
                                    onClick={deleteHandler}
                                    aria-label="delete"
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Poista">
                                <IconButton
                                    onClick={() => resetIdsAction([])}
                                    aria-label="delete"
                                >
                                    <Close />
                                </IconButton>
                            </Tooltip>
                        </React.Fragment>
                    ) : (
                        <Tooltip title="Listan suodatus">
                            <IconButton
                                aria-label="filter list"
                                onClick={() =>
                                    stableSort(
                                        users,
                                        getComparator('desc', 'username'),
                                    )
                                }
                            >
                                <FilterListIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </Toolbar>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={ids.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={users.length}
                        />
                        <TableBody>
                            {stableSort(
                                users as any,
                                getComparator(order, orderBy),
                            )
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage,
                                )
                                .map((user: any, index) => {
                                    return (
                                        <UserRow
                                            key={user._id}
                                            user={user}
                                            index={index}
                                        />
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    className="pagination"
                    rowsPerPageOptions={[15, 25, 50]}
                    component="div"
                    count={users.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
            {modalOpen && <Modal userId={userId} />}
        </div>
    );
};

function mapStateToProps(state: AppState): IUsersTableStateProps {
    return {
        users: state.users,
        ids: state.ids,
        adminPanelUiProps: state.adminPanelUiState.adminPanelUiProps,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable);
