import React, { Component } from 'react';
import './SignIn.scss';
import { mapDispatchToProps } from '../../types/dispatchActionTypes';
import { connect } from 'react-redux';
import { AppState } from '../../store/store';
import {
    Grid,
    Box,
    Typography,
    InputBaseComponentProps,
    Theme,
} from '@material-ui/core';
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles';
import AuthForm from './AuthForm/AuthForm';
import MainStepper from '../../components/Stepper/MainStepper';
import SubscriptionCards from '../../components/SubscriptionCards/SubscriptionCards';
import PinCodeForm from './AuthForm/PinCodeForm';
import { setUiPropsValues, setInputValues } from '../../utils/utils';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
    ICurrentUser,
    IStep,
    IUiProps,
    IAdvertisement,
} from '../../../../types';

interface ISignInFlowStateProps {
    advertisements: IAdvertisement[];
    uiProps: IUiProps;
    loggedInUser: ICurrentUser;
}

type SignInFlowProps = ISignInFlowStateProps &
    IDispatchProps &
    RouteComponentProps &
    WithStyles<typeof styles>;

const steps: IStep[] = [
    { label: 'Tili', _id: 0 },
    { label: 'Tiedot', _id: 1 },
    { label: 'Vahvistus', _id: 2 },
];

const styles = (theme: Theme) => createStyles({});

class SignInFlow extends Component<SignInFlowProps, AppState> {
    public handleChange = (event: InputBaseComponentProps): string => {
        const { updateSignUpFormAction } = this.props;
        updateSignUpFormAction(setInputValues(event));
        if (event.target.name === 'region') {
            this.getLocalities(event.target.value);
        }
        return event.value;
    };
    public getLocalities(region: string) {
        const { fetchLocalitiesAction } = this.props;
        return fetchLocalitiesAction(region);
    }
    public componentDidMount() {
        const { advertisements, fetchAdvertisementsAction } = this.props;
        if (advertisements.length === 0) {
            fetchAdvertisementsAction('');
        }
    }
    public componentDidUpdate() {
        const { isAuthenticated } = this.props.loggedInUser;
        // tslint:disable-next-line
        !!isAuthenticated && this.props.history.push('/profiili');
    }
    public showRegisterForm = (activeStep: number | undefined) => {
        const { updateUiStateAction } = this.props;
        updateUiStateAction(setUiPropsValues('activeStep', activeStep));
    };
    public render() {
        const { advertisements } = this.props;
        const { activeStep } = this.props.uiProps;

        return (
            <Box my={4}>
                <Grid container={true} justifyContent="center">
                    <Grid item={true} xs={12}>
                        <Box py={3}>
                            <Grid
                                container={true}
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid item={true} xs={12}>
                                    <Typography variant="h4">
                                        Luo tili
                                    </Typography>
                                    <MainStepper
                                        steps={steps}
                                        activeStep={activeStep}
                                    />
                                </Grid>
                                {activeStep === 0 && (
                                    <Grid item={true} xs={12} md={9}>
                                        <SubscriptionCards
                                            advertisements={advertisements}
                                            handleChange={this.handleChange}
                                            changeActiveStep={
                                                this.showRegisterForm
                                            }
                                        />
                                    </Grid>
                                )}
                                {activeStep === 1 && (
                                    <Grid item={true} xs={12}>
                                        <AuthForm login={false} />
                                    </Grid>
                                )}
                                {activeStep === 2 && (
                                    <Grid item={true} xs={6}>
                                        <PinCodeForm />
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        );
    }
}

function mapStateToProps(state: AppState): ISignInFlowStateProps {
    return {
        advertisements: state.advertisements,
        loggedInUser: state.currentUser,
        uiProps: state.uiState.uiProps,
    };
}

export default withRouter(
    connect<ISignInFlowStateProps, IDispatchProps, {}, AppState>(
        mapStateToProps,
        mapDispatchToProps,
    )(withStyles(styles)(SignInFlow)),
);
