import { ISearchFormState } from '../../../types';

export const UPDATE_SEARCH_FORM = 'UPDATE_SEARCH_FORM';
export const RESET_SEARCH_FORM = 'RESET_SEARCH_FORM';

export interface UpdateSearchForm {
    type: typeof UPDATE_SEARCH_FORM;
    searchFormState: ISearchFormState;
}

export interface ResetSearchForm {
    type: typeof RESET_SEARCH_FORM;
    searchFormState: ISearchFormState;
}

export type SearchFormActionTypes = UpdateSearchForm | ResetSearchForm;
