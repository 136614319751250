import React, { FC } from 'react';

const CongratsIcon: FC = () => {
    return (
        <svg
            width="100px"
            height="100px"
            viewBox="0 0 100 100"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>Assets/Illustration/Illustration_4</title>
            <desc>Created with Sketch.</desc>
            <g
                id="Assets/Illustration/Illustration_4"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
            >
                <g>
                    <path
                        d="M50,0 C77.596,0 100,22.404 100,50 C100,77.59575 77.596,100 50,100 C22.40425,100 0,77.59575 0,50 C0,22.404 22.40425,0 50,0 Z"
                        id="Background"
                        fill="#F4F6F9"
                    ></path>
                    <g
                        id="Illustration"
                        transform="translate(22.187547, 17.000000)"
                        stroke="#4E6576"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                    >
                        <path
                            d="M5.4391025,60.81305 C4.2220025,61.3229 2.8174525,61.04655 1.8842525,60.11355 C0.951102501,59.1806 0.674452501,57.7761 1.1841025,56.5589 C7.4474525,41.59965 21.1279025,8.926 21.1279025,8.926 L24.1291025,9.862 L54.5184525,40.25175 C54.5184525,40.25175 20.7017525,54.4189 5.4391025,60.81305 Z"
                            id="Fill-3"
                            fill="#FFFFFF"
                        ></path>
                        <path
                            d="M13.7655525,57.31425 C13.7655525,57.31425 7.3565025,59.98285 2.7905025,61.88405 C2.0381025,62.1973 1.1710525,62.02665 0.593502501,61.4516 C0.0159525014,60.8765 -0.158447499,60.0102 0.151602501,59.25645 C2.6890525,53.0878 6.9389025,42.7561 6.9389025,42.7561"
                            id="Fill-6"
                            fill="#FFFFFF"
                        ></path>
                        <polygon
                            id="Fill-8"
                            fill="#D8D8D8"
                            points="53.4214525 -2.13162821e-14 50.8124525 2.6095 53.4214525 5.2185 56.0309525 2.6095"
                        ></polygon>
                        <path
                            d="M22.0354025,7.4405 C25.3566025,4.119 35.3729525,8.7425 44.3889525,17.7585 C53.4049525,26.775 58.0284525,36.79105 54.7074525,40.11225 C51.3859525,43.4334 41.3699525,38.8099 32.3539525,29.794 C23.3378025,20.778 18.7142525,10.7615 22.0354025,7.4405 Z"
                            id="Fill-12"
                            fill="#D8D8D8"
                        ></path>
                        <path
                            d="M21.0053025,9.737 C21.1212025,8.7965 21.4584025,8.0175 22.0354025,7.4405 C25.3566025,4.119 35.3729525,8.7425 44.3889525,17.7585 C53.4049525,26.775 58.0284525,36.79105 54.7074525,40.11225 C54.0614525,40.7583 53.1619525,41.10375 52.0659525,41.1746 C51.7644525,40.97945 51.5889525,40.85905 51.5884525,40.84285 C51.3619525,36.2122 47.0484525,28.79 40.2029525,21.9445 C33.4189525,15.1605 26.0683525,10.8635 21.4299025,10.5665 C21.4024025,10.5645 21.2457525,10.253 21.0053025,9.737 Z"
                            id="Fill-14"
                            fill="#FFFFFF"
                        ></path>
                        <polygon
                            id="Fill-16"
                            fill="#98A8B5"
                            points="55.4219525 13 52.8124525 15.609 55.4219525 18.2185 58.0309525 15.609"
                        ></polygon>
                        <path
                            d="M45.0379525,23.5665 C45.1069525,23.3605 45.2999525,23.222 45.5174525,23.222 C45.7344525,23.222 45.9274525,23.3605 45.9964525,23.5665 L46.5479525,25.2055 C46.5529525,25.2205 46.5624525,25.2335 46.5754525,25.243 C46.5879525,25.252 46.6034525,25.257 46.6194525,25.2575 L48.3484525,25.2755 C48.5654525,25.2775 48.7569525,25.418 48.8244525,25.6245 C48.8914525,25.8315 48.8189525,26.0575 48.6449525,26.187 L47.2564525,27.218 C47.2434525,27.2275 47.2344525,27.2405 47.2294525,27.2555 C47.2244525,27.2705 47.2244525,27.287 47.2289525,27.302 L47.7464525,28.952 C47.8114525,29.159 47.7369525,29.385 47.5609525,29.5125 C47.3854525,29.64 47.1479525,29.6415 46.9709525,29.5155 L45.5614525,28.5135 C45.5484525,28.5045 45.5329525,28.4995 45.5174525,28.4995 C45.5014525,28.4995 45.4859525,28.5045 45.4729525,28.5135 L44.0634525,29.5155 C43.8864525,29.6415 43.6489525,29.64 43.4734525,29.5125 C43.2974525,29.385 43.2229525,29.159 43.2879525,28.952 L43.8054525,27.302 C43.8099525,27.287 43.8099525,27.2705 43.8049525,27.2555 C43.7999525,27.2405 43.7909525,27.2275 43.7779525,27.218 L42.3894525,26.187 C42.2154525,26.0575 42.1429525,25.8315 42.2099525,25.6245 C42.2774525,25.418 42.4689525,25.2775 42.6859525,25.2755 L44.4149525,25.2575 C44.4309525,25.257 44.4464525,25.252 44.4589525,25.243 C44.4719525,25.2335 44.4814525,25.2205 44.4864525,25.2055 L45.0379525,23.5665 Z"
                            id="Fill-18"
                            fill="#FFFFFF"
                        ></path>
                        <path
                            d="M30.6651408,3.3445 C30.7341408,3.1385 30.9271408,3 31.1446408,3 C31.3616408,3 31.5546408,3.1385 31.6236408,3.3445 L32.1751408,4.9835 C32.1801408,4.9985 32.1896408,5.0115 32.2026408,5.021 C32.2156408,5.03 32.2306408,5.035 32.2466408,5.0355 L33.9756408,5.0535 C34.1931408,5.0555 34.3846408,5.196 34.4516408,5.4025 C34.5186408,5.6095 34.4461408,5.8355 34.2721408,5.965 L32.8836408,6.996 C32.8711408,7.0055 32.8616408,7.0185 32.8566408,7.0335 C32.8516408,7.0485 32.8516408,7.065 32.8561408,7.08 L33.3736408,8.73 C33.4386408,8.937 33.3641408,9.163 33.1881408,9.2905 C33.0126408,9.418 32.7751408,9.4195 32.5981408,9.2935 L31.1886408,8.2915 C31.1756408,8.2825 31.1601408,8.2775 31.1446408,8.2775 C31.1286408,8.2775 31.1131408,8.2825 31.1001408,8.2915 L29.6906408,9.2935 C29.5136408,9.4195 29.2761408,9.418 29.1006408,9.2905 C28.9246408,9.163 28.8501408,8.937 28.9151408,8.73 L29.4326408,7.08 C29.4371408,7.065 29.4371408,7.0485 29.4321408,7.0335 C29.4276408,7.0185 29.4181408,7.0055 29.4051408,6.996 L28.0166408,5.965 C27.8426408,5.8355 27.7701408,5.6095 27.8371408,5.4025 C27.9046408,5.196 28.0961408,5.0555 28.3131408,5.0535 L30.0421408,5.0355 C30.0581408,5.035 30.0736408,5.03 30.0861408,5.021 C30.0991408,5.0115 30.1086408,4.9985 30.1136408,4.9835 L30.6651408,3.3445 Z"
                            id="Fill-20"
                            fill="#98A8B5"
                        ></path>
                        <path
                            d="M33.1269525,20.2955 C33.1269525,20.2955 45.2919525,13.724 41.4394525,7.472 C39.3814525,4.131 36.9154525,8.247 39.3074525,10.205 C41.6989525,12.163 47.1384525,10.0175 47.8669525,7.055"
                            id="Stroke-22"
                        ></path>
                        <path
                            d="M4.7173525,48.10325 C4.7173525,48.10325 5.2178025,54.68725 13.8937025,57.2823 L19.2055025,54.99155 C19.2055025,54.99155 7.3536525,51.87315 7.5088025,41.5869 L4.7173525,48.10325 Z"
                            id="Fill-24"
                            fill="#98A8B5"
                        ></path>
                        <path
                            d="M11.3991525,32.1575 C11.3991525,32.1575 13.8805025,45.9842 29.4579525,50.64365 L36.8369525,47.5867 C36.8369525,47.5867 14.7404025,41.83015 15.0190025,23.361 L11.3991525,32.1575 Z"
                            id="Fill-26"
                            fill="#98A8B5"
                        ></path>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default CongratsIcon;
