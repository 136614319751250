import React, { FC, useState } from 'react';
import './GoogleMaps.scss';
import {
    Map,
    GoogleApiWrapper,
    GoogleAPI,
    Marker,
    InfoWindow,
} from 'google-maps-react';
import PublicProfile from '../PublicProfile/PublicProfile';
import { resetCurrentUser } from '../../utils/resetUtils';
import { AppState } from '../../store/store';
import { mapDispatchToProps } from '../../types/dispatchActionTypes';
import { connect } from 'react-redux';
import { IUser } from '../../../../types';
import {
    ICurrentUser,
    IGoogleMapsState,
    IGoogleMapsProps,
} from '../../../../types';

interface IGoogleMapStateProps {
    sellers: IUser[];
    currentUser: ICurrentUser;
}

interface OwnProps {
    google: GoogleAPI;
    googleMapsState: IGoogleMapsState;
}

type GoogleMapProps = IGoogleMapStateProps & OwnProps & IDispatchProps;

const GoogleMap: FC<GoogleMapProps> = (props) => {
    const [activeMarker, setActiveMarker] = useState<
        google.maps.Marker | undefined
    >(undefined);
    const [selectedSeller, setSelectedSeller] = useState<IUser>(
        resetCurrentUser(),
    );
    const [infoWindowVisible, setInfoWindowVisible] = useState(false);
    const { sellers, googleMapsState } = props;
    const { lat, lng, zoom } = googleMapsState.googleMapsProps;
    const onMarkerClick = (_: any, marker: any) => {
        setActiveMarker(marker);
        setSelectedSeller(marker.seller);
        setInfoWindowVisible(true);
    };

    const displayMarkers = (mapStateProps: IGoogleMapsProps) => {
        return sellers.map((seller: IUser) => {
            return (
                <Marker
                    seller={seller}
                    key={seller._id}
                    position={{
                        lat: seller.address.coords.lat,
                        lng: seller.address.coords.lng,
                    }}
                    mapCenter={{
                        lat: mapStateProps.lat,
                        lng: mapStateProps.lng,
                    }}
                    onMouseover={onMarkerClick}
                    onClick={onMarkerClick}
                />
            );
        });
    };
    return (
        <Map
            style={{
                top: 0,
                left: 0,
                display: 'block',
                width: '100%',
                height: '100%',
                position: 'absolute',
            }}
            google={props.google}
            zoom={zoom}
            center={{ lat, lng }}
            centerAroundCurrentLocation={false}
            initialCenter={{
                lat,
                lng,
            }}
        >
            {displayMarkers(googleMapsState.googleMapsProps)}
            <InfoWindow
                google={props.google}
                marker={activeMarker}
                visible={infoWindowVisible}
            >
                <PublicProfile currentUser={selectedSeller} />
            </InfoWindow>
        </Map>
    );
};

const GoogleMapsWrapper = GoogleApiWrapper({
    apiKey: 'AIzaSyCLMsySVoKHZqa61TWdskhiu1On5ysoAeA',
    language: 'fin',
})(GoogleMap);

function mapStateToProps(state: AppState): IGoogleMapStateProps {
    return {
        currentUser: state.currentUser,
        sellers: state.sellers,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(GoogleMapsWrapper);
