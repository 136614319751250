import { Grid, Typography } from '@material-ui/core';
import React, { FC } from 'react';

const NotFound404: FC = () => {
    return (
        <Grid container={true} justify="center" alignItems="center">
            <Grid item={true} xs={12} md={12}>
                <Typography variant="h2">404 Not found!</Typography>
                <Typography variant="h4">
                    Valitettavasti hakemaanne sivua ei löytynyt!
                </Typography>
            </Grid>
        </Grid>
    );
};

export default NotFound404;
