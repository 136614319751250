import { ILocation } from '../../../types';

export const SET_LOCALITIES = 'SET_LOCALITIES';
export const ADD_LOCALITY = 'ADD_LOCALITY';
export const UPDATE_LOCALITY = 'UPDATE_LOCALITY';
export const REMOVE_LOCALITY = 'REMOVE_LOCALITY';

export interface SetLocalities {
    type: typeof SET_LOCALITIES;
    localities: ILocation[];
}

export interface AddLocality {
    type: typeof ADD_LOCALITY;
    locality: ILocation;
}

export interface UpdateLocality {
    type: typeof UPDATE_LOCALITY;
    locality: ILocation;
}

export interface RemoveLocality {
    type: typeof REMOVE_LOCALITY;
    id: string;
}

export type LocalitiesActionTypes =
    | SetLocalities
    | AddLocality
    | UpdateLocality
    | RemoveLocality;
