import { apiCall, setHeader } from '../../apiRequests/apiCall';
import { SET_LOCALITIES_BY_REGION } from '../../types/localitiesByRegionAcionTypes';
import { AppActions } from '../../types/actions';
import { Dispatch } from 'redux';
import { addMessage } from './messageActions';
import { ILocalitiesByRegion } from '../../../../types';

export function setLocalitiesByRegion(
    localitiesByRegion: ILocalitiesByRegion[],
): AppActions {
    return {
        type: SET_LOCALITIES_BY_REGION,
        localitiesByRegion,
    };
}

/** Public method */
export const fetchLocalitiesByRegion = (): ThunkResult<void> => {
    setHeader('get', '');
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('get', '/api/locations/localitieslist', null)
                .then((res: any) => {
                    dispatch(setLocalitiesByRegion(res.localitiesByRegion));
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};
