import { IContact } from '../../../../types';
import {
    ContactsActionTypes,
    SET_CONTACT,
} from '../../types/contactsActionTypes';
import { resetContact } from '../../utils/resetUtils';

const DEFAULT_STATE: IContact = resetContact();

const contactReducer = (state = DEFAULT_STATE, action: ContactsActionTypes) => {
    switch (action.type) {
        case SET_CONTACT:
            return action.contact;
        default:
            return state;
    }
};

export default contactReducer;
