import { IUiState } from '../../../types';

export const UPDATE_UISTATE = 'UPDATE_UISTATE';

export interface UpdateUiState {
    type: typeof UPDATE_UISTATE;
    uiState: IUiState;
}

export type UiActionTypes = UpdateUiState;
