import React, { Component, FormEvent } from 'react';
import { mapDispatchToProps } from '../../types/dispatchActionTypes';
import { AppState } from '../../store/store';
import {
    InputBaseComponentProps,
    Grid,
    FormControl,
    InputLabel,
    Select,
    Button,
    MenuItem,
    TextField,
    Box,
    FormLabel,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import {
    setPriceTag,
    setProductCreationFormInputValues,
} from '../../utils/utils';
import { ProductFormOptionLists, SetQueryKey } from '../../formOptionList';
import { resetProductFormFunction } from '../../utils/resetUtils';
import { connect } from 'react-redux';
import {
    ProductNames,
    IUiProps,
    IUser,
    IProductForm,
    ProductCategories,
    IProduct,
} from '../../../../types';

interface IProductFormStateProps {
    currentUser: IUser;
    productForm: IProductForm;
    userProducts: IProduct[];
    otherUserProducts: IProduct[];
    uiProps: IUiProps;
}

interface OwnProps {
    buttonText: string;
    selectedCategory: ProductCategories;
}

type ProductFormProps = IProductFormStateProps & OwnProps & IDispatchProps;

class ProductForm extends Component<ProductFormProps, AppState> {
    public componentDidMount() {
        const { updateProductFormAction, currentUser, selectedCategory } =
            this.props;
        updateProductFormAction({
            user_id: currentUser._id,
            category: selectedCategory,
        });
    }
    public handleChange = (event: InputBaseComponentProps) => {
        const { updateProductFormAction } = this.props;
        updateProductFormAction(setProductCreationFormInputValues(event));
    };
    public resetForm = () => {
        const { resetProductFormAction, updateUiStateAction } = this.props;
        resetProductFormAction(resetProductFormFunction());
        updateUiStateAction({ showEditProductForm: false });
        updateUiStateAction({ productId: '' });
    };
    public handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        const { createProductAction, editProductAction, productForm, uiProps } =
            this.props;
        const { showEditProductForm, itemId } = uiProps;

        if (showEditProductForm && itemId) {
            editProductAction(productForm, itemId);
        } else {
            createProductAction(productForm);
        }
        this.resetForm();
    };
    public render() {
        const {
            userProducts,
            otherUserProducts,
            productForm,
            buttonText,
            uiProps,
            currentUser,
            selectedCategory,
        } = this.props;
        const { itemId } = uiProps;
        const product = otherUserProducts
            .concat(userProducts)
            .find((productInFind: IProduct) => productInFind._id === itemId);
        const {
            plengths,
            qualities,
            deliveryTypes,
            materials,
            units,
            names,
            vatValues,
        } = ProductFormOptionLists;
        const {
            name,
            plength,
            deliveryType,
            price,
            quality,
            material,
            unit,
            category,
            description,
            discount,
            quantity,
            vat,
        } = productForm;
        let key = SetQueryKey(selectedCategory as ProductCategories);
        return (
            <Box>
                <form onSubmit={this.handleSubmit}>
                    <Grid container={true} justify="space-evenly">
                        {selectedCategory === 'Polttopuut' && (
                            <>
                                <Grid item={true} xs={12} md={6}>
                                    <Box p={1}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth={true}
                                            required={true}
                                        >
                                            <InputLabel id="demo-simple-select-required-label">
                                                Kategoria
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-required-label"
                                                id="demo-simple-select-required"
                                                name="category"
                                                value={
                                                    category
                                                        ? category
                                                        : product
                                                        ? product.category
                                                        : category
                                                }
                                                onChange={this.handleChange}
                                                label="Kategoria"
                                            >
                                                <MenuItem
                                                    value={
                                                        selectedCategory
                                                            ? selectedCategory
                                                            : product
                                                            ? product.category
                                                            : selectedCategory
                                                    }
                                                >
                                                    <em>Kategoria</em>
                                                </MenuItem>
                                                <MenuItem
                                                    key={selectedCategory}
                                                    value={selectedCategory}
                                                >
                                                    {selectedCategory}
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item={true} xs={12} md={6}>
                                    <Box p={1}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth={true}
                                            required={true}
                                        >
                                            <InputLabel id="demo-simple-select-required-label">
                                                Tuotetyyppi
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-required-label"
                                                id="demo-simple-select-required"
                                                name="name"
                                                value={
                                                    name
                                                        ? name
                                                        : product
                                                        ? product.name
                                                        : name
                                                }
                                                onChange={this.handleChange}
                                                label="Tuotetyyppi"
                                            >
                                                <MenuItem
                                                    value={
                                                        name
                                                            ? name
                                                            : product
                                                            ? product.name
                                                            : name
                                                    }
                                                >
                                                    <em>Tuotetyyppi</em>
                                                </MenuItem>
                                                {names[key].map(
                                                    (name2: string) => {
                                                        return (
                                                            <MenuItem
                                                                key={name2}
                                                                value={name2}
                                                            >
                                                                {name2}
                                                            </MenuItem>
                                                        );
                                                    },
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item={true} xs={12} md={4}>
                                    <Box p={1}>
                                        <TextField
                                            name="price"
                                            type="number"
                                            id="price-required"
                                            value={
                                                price
                                                    ? price
                                                    : product
                                                    ? setPriceTag(product.price)
                                                    : price
                                            }
                                            onChange={this.handleChange}
                                            fullWidth={true}
                                            required={true}
                                            label="Hinta"
                                            variant="outlined"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item={true} xs={12} md={4}>
                                    <Box p={1}>
                                        <TextField
                                            name="quantity"
                                            type="number"
                                            id="quantity-required"
                                            value={
                                                quantity
                                                    ? quantity
                                                    : product
                                                    ? product.quantity
                                                    : quantity
                                            }
                                            onChange={this.handleChange}
                                            fullWidth={true}
                                            required={true}
                                            label="Määrä"
                                            variant="outlined"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item={true} xs={12} md={4}>
                                    <Box p={1}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth={true}
                                            required={true}
                                        >
                                            <InputLabel id="demo-simple-select-required-label">
                                                Valitse Alv kanta
                                            </InputLabel>
                                            <Select
                                                labelId="vat-selection-menu"
                                                id="vat-selection-menu"
                                                name="vat"
                                                value={
                                                    vat
                                                        ? vat
                                                        : product
                                                        ? product.vat
                                                        : vat
                                                }
                                                onChange={this.handleChange}
                                                label="Valitse Alk kanta"
                                            >
                                                <MenuItem
                                                    value={
                                                        vat
                                                            ? vat
                                                            : product
                                                            ? product.vat
                                                            : vat
                                                    }
                                                >
                                                    <em>Valitse Alv kanta</em>
                                                </MenuItem>
                                                {!!vatValues.length &&
                                                    vatValues.map(
                                                        (vat2: number) => {
                                                            return (
                                                                <MenuItem
                                                                    key={vat2}
                                                                    value={vat2}
                                                                >
                                                                    {vat2}
                                                                </MenuItem>
                                                            );
                                                        },
                                                    )}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item={true} xs={12} md={6}>
                                    <Box p={1}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth={true}
                                            required={true}
                                        >
                                            <InputLabel id="demo-simple-select-required-label">
                                                Valitse laatu
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-required-label"
                                                id="demo-simple-select-required"
                                                name="quality"
                                                value={
                                                    quality
                                                        ? quality
                                                        : product
                                                        ? product.quality
                                                        : quality
                                                }
                                                onChange={this.handleChange}
                                                label="Valitse laatu"
                                            >
                                                <MenuItem
                                                    value={
                                                        quality
                                                            ? quality
                                                            : product
                                                            ? product.quality
                                                            : quality
                                                    }
                                                >
                                                    <em>Valitse laatu</em>
                                                </MenuItem>
                                                {!!qualities.length &&
                                                    qualities.map(
                                                        (quality2: string) => {
                                                            return (
                                                                <MenuItem
                                                                    key={
                                                                        quality2
                                                                    }
                                                                    value={
                                                                        quality2
                                                                    }
                                                                >
                                                                    {quality2}
                                                                </MenuItem>
                                                            );
                                                        },
                                                    )}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item={true} xs={12} md={6}>
                                    <Box p={1}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth={true}
                                            required={true}
                                        >
                                            <InputLabel id="demo-simple-select-required-label">
                                                Valitse Materiaali
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-required-label"
                                                id="demo-simple-select-required"
                                                name="material"
                                                value={
                                                    material
                                                        ? material
                                                        : product
                                                        ? product.material
                                                        : material
                                                }
                                                onChange={this.handleChange}
                                                label="Valitse Materiaali"
                                            >
                                                <MenuItem
                                                    value={
                                                        material
                                                            ? material
                                                            : product
                                                            ? product.material
                                                            : material
                                                    }
                                                >
                                                    <em>Valitse Kategoria</em>
                                                </MenuItem>
                                                {!!materials.length &&
                                                    materials.map(
                                                        (material2: string) => {
                                                            return (
                                                                <MenuItem
                                                                    key={
                                                                        material2
                                                                    }
                                                                    value={
                                                                        material2
                                                                    }
                                                                >
                                                                    {material2}
                                                                </MenuItem>
                                                            );
                                                        },
                                                    )}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item={true} xs={12} md={4}>
                                    <Box p={1}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth={true}
                                            required={true}
                                        >
                                            <InputLabel id="demo-simple-select-required-label">
                                                Valitse toimitusmuoto
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-required-label"
                                                id="demo-simple-select-required"
                                                name="deliveryType"
                                                value={
                                                    deliveryType
                                                        ? deliveryType
                                                        : product
                                                        ? product.deliveryType
                                                        : deliveryType
                                                }
                                                onChange={this.handleChange}
                                                label="Valitse toimitusmuoto"
                                            >
                                                <MenuItem
                                                    value={
                                                        deliveryType
                                                            ? deliveryType
                                                            : product
                                                            ? product.deliveryType
                                                            : deliveryType
                                                    }
                                                >
                                                    <em>
                                                        Valitse Toimitusmuoto
                                                    </em>
                                                </MenuItem>
                                                {!!deliveryTypes.length &&
                                                    deliveryTypes.map(
                                                        (
                                                            deliveryType2: string,
                                                        ) => {
                                                            return (
                                                                <MenuItem
                                                                    key={
                                                                        deliveryType2
                                                                    }
                                                                    value={
                                                                        deliveryType2
                                                                    }
                                                                >
                                                                    {
                                                                        deliveryType2
                                                                    }
                                                                </MenuItem>
                                                            );
                                                        },
                                                    )}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item={true} xs={12} md={4}>
                                    <Box p={1}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth={true}
                                            required={true}
                                        >
                                            <InputLabel id="demo-simple-select-required-label">
                                                Valitse pituus
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-required-label"
                                                id="demo-simple-select-required"
                                                name="plength"
                                                value={
                                                    plength
                                                        ? plength
                                                        : product
                                                        ? product.plength
                                                        : plength
                                                }
                                                onChange={this.handleChange}
                                                label="Valitse pituus"
                                            >
                                                <MenuItem
                                                    value={
                                                        plength
                                                            ? plength
                                                            : product
                                                            ? product.plength
                                                            : plength
                                                    }
                                                >
                                                    <em>Valitse pituus</em>
                                                </MenuItem>
                                                {!!plengths.length &&
                                                    plengths.map(
                                                        (plength2: string) => {
                                                            return (
                                                                <MenuItem
                                                                    key={
                                                                        plength2
                                                                    }
                                                                    value={
                                                                        plength2
                                                                    }
                                                                >
                                                                    {plength2}
                                                                </MenuItem>
                                                            );
                                                        },
                                                    )}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item={true} xs={12} md={4}>
                                    <Box p={1}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth={true}
                                            required={true}
                                        >
                                            <InputLabel id="demo-simple-select-required-label">
                                                Valitse yksikkö
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-required-label"
                                                id="demo-simple-select-required"
                                                name="unit"
                                                value={
                                                    unit
                                                        ? unit
                                                        : product
                                                        ? product.unit
                                                        : unit
                                                }
                                                onChange={this.handleChange}
                                                label="Valitse yksikkö"
                                            >
                                                <MenuItem
                                                    value={
                                                        unit
                                                            ? unit
                                                            : product
                                                            ? product.unit
                                                            : unit
                                                    }
                                                >
                                                    <em>Valitse yksikkö</em>
                                                </MenuItem>
                                                {!!units.length &&
                                                    units.map(
                                                        (unit2: string) => {
                                                            return (
                                                                <MenuItem
                                                                    key={unit2}
                                                                    value={
                                                                        unit2
                                                                    }
                                                                >
                                                                    {unit2}
                                                                </MenuItem>
                                                            );
                                                        },
                                                    )}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item={true} xs={12}>
                                    <Box p={1}>
                                        <TextField
                                            variant="outlined"
                                            name="description"
                                            multiline={true}
                                            rows={4}
                                            value={
                                                description
                                                    ? description
                                                    : product
                                                    ? product.description
                                                    : description
                                            }
                                            onChange={this.handleChange}
                                            fullWidth={true}
                                            required={false}
                                            label="Kuvaus"
                                            type="text"
                                        />
                                    </Box>
                                </Grid>
                            </>
                        )}
                        {selectedCategory === 'Muut_tuotteet' && (
                            <>
                                <Grid item={true} xs={12} md={6}>
                                    <Box p={1}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth={true}
                                            required={true}
                                        >
                                            <InputLabel id="demo-simple-select-required-label">
                                                Kategoria
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-required-label"
                                                id="demo-simple-select-required"
                                                name="category"
                                                value={
                                                    category
                                                        ? category
                                                        : product
                                                        ? product.category
                                                        : category
                                                }
                                                onChange={this.handleChange}
                                                label="Kategoria"
                                            >
                                                <MenuItem
                                                    value={
                                                        selectedCategory
                                                            ? selectedCategory
                                                            : product
                                                            ? product.category
                                                            : selectedCategory
                                                    }
                                                >
                                                    <em>Kategoria</em>
                                                </MenuItem>
                                                <MenuItem
                                                    key={selectedCategory}
                                                    value={selectedCategory}
                                                >
                                                    {selectedCategory}
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item={true} xs={12} md={6}>
                                    <Box p={1}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth={true}
                                            required={true}
                                        >
                                            <InputLabel id="demo-simple-select-required-label">
                                                Tuotetyyppi
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-required-label"
                                                id="demo-simple-select-required"
                                                name="name"
                                                value={
                                                    name
                                                        ? name
                                                        : product
                                                        ? product.name
                                                        : name
                                                }
                                                onChange={this.handleChange}
                                                label="Tuotetyyppi"
                                            >
                                                <MenuItem
                                                    value={
                                                        name
                                                            ? name
                                                            : product
                                                            ? product.name
                                                            : name
                                                    }
                                                >
                                                    <em>Tuotetyyppi</em>
                                                </MenuItem>
                                                {names[key].map(
                                                    (
                                                        name2:
                                                            | string
                                                            | ProductNames,
                                                    ) => {
                                                        return (
                                                            <MenuItem
                                                                key={name2}
                                                                value={name2}
                                                            >
                                                                {name2}
                                                            </MenuItem>
                                                        );
                                                    },
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item={true} xs={12} md={6}>
                                    <Box p={1}>
                                        <TextField
                                            variant="outlined"
                                            name="price"
                                            type="number"
                                            id="price-required"
                                            value={
                                                price
                                                    ? price
                                                    : product
                                                    ? product.price
                                                    : price
                                            }
                                            onChange={this.handleChange}
                                            fullWidth={true}
                                            required={true}
                                            label="Hinta"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item={true} xs={12} md={6}>
                                    <Box p={1}>
                                        <TextField
                                            name="quantity"
                                            type="number"
                                            id="quantity-required"
                                            value={
                                                quantity
                                                    ? quantity
                                                    : product
                                                    ? product.quantity
                                                    : quantity
                                            }
                                            onChange={this.handleChange}
                                            fullWidth={true}
                                            required={true}
                                            label="Määrä"
                                            variant="outlined"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item={true} xs={12} md={6}>
                                    <Box p={1}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth={true}
                                            required={true}
                                        >
                                            <InputLabel id="demo-simple-select-required-label">
                                                Valitse Alv kanta
                                            </InputLabel>
                                            <Select
                                                labelId="vat-selection-menu"
                                                id="vat-selection-menu"
                                                name="vat"
                                                value={
                                                    vat
                                                        ? vat
                                                        : product
                                                        ? product.vat
                                                        : vat
                                                }
                                                onChange={this.handleChange}
                                                label="Valitse Alv kanta"
                                            >
                                                <MenuItem
                                                    value={
                                                        vat
                                                            ? vat
                                                            : product
                                                            ? product.vat
                                                            : vat
                                                    }
                                                >
                                                    <em>Valitse Alv kanta</em>
                                                </MenuItem>
                                                {!!vatValues.length &&
                                                    vatValues.map(
                                                        (vat2: number) => {
                                                            return (
                                                                <MenuItem
                                                                    key={vat2}
                                                                    value={vat2}
                                                                >
                                                                    {vat2}
                                                                </MenuItem>
                                                            );
                                                        },
                                                    )}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item={true} xs={12} md={6}>
                                    <Box p={1}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth={true}
                                            required={true}
                                        >
                                            <InputLabel id="demo-simple-select-required-label">
                                                Valitse Yksikkö
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-required-label"
                                                id="demo-simple-select-required"
                                                name="unit"
                                                value={
                                                    unit
                                                        ? unit
                                                        : product
                                                        ? product.unit
                                                        : unit
                                                }
                                                onChange={this.handleChange}
                                                label="Valitse Yksikkö"
                                            >
                                                <MenuItem
                                                    value={
                                                        unit
                                                            ? unit
                                                            : product
                                                            ? product.unit
                                                            : unit
                                                    }
                                                >
                                                    <em>Valitse yksikkö</em>
                                                </MenuItem>
                                                {!!units.length &&
                                                    units.map(
                                                        (unit2: string) => {
                                                            return (
                                                                <MenuItem
                                                                    key={unit2}
                                                                    value={
                                                                        unit2
                                                                    }
                                                                >
                                                                    {unit2}
                                                                </MenuItem>
                                                            );
                                                        },
                                                    )}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item={true} xs={12}>
                                    <Box p={1}>
                                        <TextField
                                            variant="outlined"
                                            name="description"
                                            multiline={true}
                                            rows={4}
                                            value={
                                                description
                                                    ? description
                                                    : product
                                                    ? product.description
                                                    : description
                                            }
                                            onChange={this.handleChange}
                                            fullWidth={true}
                                            required={false}
                                            label="Kuvaus"
                                            type="text"
                                        />
                                    </Box>
                                </Grid>
                            </>
                        )}

                        {currentUser.userAccount.account.accountPlan ===
                            'premium' &&
                            currentUser.userAccount.paid && (
                                <Grid item={true} xs={12} md={12}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">
                                            Premium tilin Tarjouserät
                                        </FormLabel>
                                        <FormGroup
                                            aria-label="position"
                                            row={true}
                                        >
                                            <FormControlLabel
                                                value="discount"
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        name="discount"
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        checked={
                                                            discount
                                                                ? true
                                                                : false
                                                        }
                                                        value={
                                                            discount
                                                                ? 'true'
                                                                : 'false'
                                                        }
                                                    />
                                                }
                                                label="Luo tarjouserä"
                                                labelPlacement="end"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                            )}
                        <Grid
                            container={true}
                            alignItems="flex-start"
                            justify="flex-end"
                            direction="row"
                        >
                            <Box p={1}>
                                {product && (
                                    <Button
                                        type="reset"
                                        variant="outlined"
                                        color="secondary"
                                        size="large"
                                        onClick={this.resetForm}
                                    >
                                        Tyhjennä
                                    </Button>
                                )}
                                <Button
                                    type="submit"
                                    variant="outlined"
                                    color="primary"
                                    size="large"
                                >
                                    {buttonText}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        );
    }
}
function mapStateToProps(state: AppState): IProductFormStateProps {
    return {
        userProducts: state.userProducts,
        otherUserProducts: state.otherUserProducts,
        productForm: state.productFormState.productForm,
        currentUser: state.currentUser.user,
        uiProps: state.uiState.uiProps,
    };
}
export default connect<
    IProductFormStateProps,
    IDispatchProps,
    OwnProps,
    AppState
>(
    mapStateToProps,
    mapDispatchToProps,
)(ProductForm);
