import { Box, Grid } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import SmallCard from './SmallCard';
import './AdminPanel.scss';
import { connect } from 'react-redux';
import { AppState } from '../../../store/store';
import { mapDispatchToProps } from '../../../types/dispatchActionTypes';
import NewUsersTabsCard from './NewUsersTabsCard';
import ContactsCard from './ContactsCard';
import {
    IAdminPanelPage,
    IAdminPanelUiProps,
    IContact,
} from '../../../../../types';

interface IAdminPanelPageStateProps {
    adminPanelPage: IAdminPanelPage;
    adminPanelUiProps: IAdminPanelUiProps;
    contacts: IContact[];
}

type AdminPanelPageProps = IAdminPanelPageStateProps & IDispatchProps;

const AdminPanelPage: FC<AdminPanelPageProps> = (props) => {
    const { adminPanelPage } = props;
    const [fetchData, setFetchData] = useState<boolean>(true);
    useEffect(() => {
        const {
            fetchAdminPanelPageDataAction,
            fetchNewUsersAction,
            fetchContactsAction,
        } = props;
        if (fetchData) {
            fetchAdminPanelPageDataAction();
            fetchNewUsersAction();
            fetchContactsAction('');
            setFetchData(false);
        }
    }, [fetchData, setFetchData, props]);
    const { ordersCount, usersCount, newUsersCount, contactsCount } =
        adminPanelPage;
    return (
        <Grid container={true}>
            <Grid item={true} xs={6} md={3}>
                <Box px={2} py={2} mt={5}>
                    <SmallCard
                        header="Tilaukset"
                        color="warning"
                        message={'Tilauksia: ' + ordersCount}
                        icon="Assignment"
                    />
                </Box>
            </Grid>
            <Grid item={true} xs={6} md={3}>
                <Box px={2} py={2} mt={5}>
                    <SmallCard
                        header="Myyjät"
                        color="rose"
                        message={'Myyjiä: ' + usersCount}
                        icon="People"
                    />
                </Box>
            </Grid>
            <Grid item={true} xs={6} md={3}>
                <Box px={2} py={2} mt={5}>
                    <SmallCard
                        header="Uudet Tilit"
                        color="success"
                        message={'Uusia myyjiä: ' + newUsersCount}
                        icon="AccountBox"
                    />
                </Box>
            </Grid>
            <Grid item={true} xs={6} md={3}>
                <Box px={2} py={2} mt={5}>
                    <SmallCard
                        header="Yhteydenotot"
                        color="info"
                        message={'Yhteydenottoja: ' + contactsCount}
                        icon="Contacts"
                    />
                </Box>
            </Grid>
            <Grid item={true} xs={12} sm={12} md={6}>
                <Box mt={5} px={2} py={2}>
                    <NewUsersTabsCard />
                </Box>
            </Grid>
            <Grid item={true} xs={12} sm={12} md={6}>
                <Box mt={5} px={2} py={2}>
                    <ContactsCard />
                </Box>
            </Grid>
        </Grid>
    );
};

function mapStateToProps(state: AppState): IAdminPanelPageStateProps {
    return {
        adminPanelPage: state.adminPanelPageState.adminPanelPage,
        adminPanelUiProps: state.adminPanelUiState.adminPanelUiProps,
        contacts: state.contacts,
    };
}

export default connect<IAdminPanelPageStateProps, IDispatchProps, {}, AppState>(
    mapStateToProps,
    mapDispatchToProps,
)(AdminPanelPage);
