import { IOrderTotals } from '../../../../types';
import {
    OrderTotalsActionTypes,
    RESET_ORDER_TOTALS,
    SET_ORDER_TOTALS,
} from '../../types/orderTotalsActionTypes';
import { resettedOrderTotals } from '../../utils/resetUtils';

const DEFAULT_STATE: IOrderTotals = resettedOrderTotals();

const orderTotalsReducer = (
    state = DEFAULT_STATE,
    action: OrderTotalsActionTypes,
) => {
    switch (action.type) {
        case SET_ORDER_TOTALS:
            return action.orderTotals;
        case RESET_ORDER_TOTALS:
            return action.orderTotals;
        default:
            return state;
    }
};

export default orderTotalsReducer;
