import React, { FormEvent, Component } from 'react';
import {
    Grid,
    Typography,
    ListItem,
    ListItemIcon,
    Checkbox,
    ListItemText,
    TextField,
    FormControl,
    InputLabel,
    MenuItem,
    FormHelperText,
    Select,
    FormControlLabel,
    Paper,
    InputBaseComponentProps,
    List,
    Button,
    Box,
    Theme,
    Radio,
    FormLabel,
    RadioGroup,
} from '@material-ui/core';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { PersonAdd } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import {
    getAccountsPrice,
    setAccountOfferText,
    setInputValues,
    setUserFormInputValues,
} from '../../../utils/utils';
import {
    validateConfPassword,
    validatePassword,
    validateEmail,
} from '../../../utils/formInputValidationUtil';
import { AppState } from '../../../store/store';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../../types/dispatchActionTypes';
import {
    ILocation,
    IRegion,
    ISignUpForm,
    IUserForm,
    IAccount,
} from '../../../../../types';

interface ISignUpFormStateProps {
    userForm: IUserForm;
    signUpForm: ISignUpForm;
    accounts: any;
    localities: any;
    regions: any;
}

interface IOwnProps {
    createUser?: boolean;
}
type SignUpFormProps = ISignUpFormStateProps &
    IOwnProps &
    IDispatchProps &
    WithStyles<typeof styles>;

const styles = (theme: Theme) =>
    createStyles({
        mainGrid: {
            padding: '0 2rem',
        },
        paper: {
            color: theme.colors.beige,
            padding: '1.5rem 0',
        },
        sectionTitle: {
            margin: '4.5rem 0 0rem 0',
        },
        btn: {
            margin: '1rem 0 4rem 0',
        },
        termsGrid: {
            marginTop: '2rem',
        },
    });

class SignUpForm extends Component<SignUpFormProps, AppState> {
    public getLocalities(region: string) {
        const { fetchLocalitiesAction } = this.props;
        return fetchLocalitiesAction(region);
    }
    public handleChange = (event: InputBaseComponentProps): string => {
        const { updateSignUpFormAction, updateUserFormAction, createUser } =
            this.props;
        if (createUser) {
            updateUserFormAction(setUserFormInputValues(event));
        } else {
            updateSignUpFormAction(setInputValues(event));
        }
        if (event.target.name === 'region') {
            this.getLocalities(event.target.value);
        }
        return event.value;
    };
    public handleSignUp = (event: FormEvent) => {
        event.preventDefault();
        const {
            signUpUserAction,
            signUpForm,
            createUserAction,
            userForm,
            createUser,
        } = this.props;
        if (createUser) {
            createUserAction(userForm);
        } else {
            signUpUserAction(signUpForm);
        }
    };
    public render() {
        const {
            signUpForm,
            userForm,
            createUser,
            classes,
            accounts,
            regions,
            localities,
        } = this.props;
        const {
            business,
            password,
            confPassword,
            firstname,
            lastname,
            email,
            contactPerson,
            contactByPhone,
            street,
            socialSecurityNumber,
            ytunnus,
            zip,
            username,
            locality,
            region,
            phonenumber,
            terms,
            accountPlan,
            companyName,
            formValid,
            website,
        } = createUser ? userForm : signUpForm;
        return (
            <Grid
                item={true}
                xs={12}
                md={7}
                sm={10}
                className={classes.mainGrid}
            >
                <Paper className={classes.paper}>
                    <Box p={2}>
                        <form onSubmit={this.handleSignUp}>
                            <Grid item={true} xs={12} className="mb-2">
                                <Typography variant="h4">
                                    <PersonAdd />{' '}
                                    {createUser
                                        ? 'Luo käyttäjä'
                                        : 'Rekisteröidy'}
                                </Typography>
                            </Grid>
                            <Grid
                                container={true}
                                justify="space-evenly"
                                alignContent="center"
                                className="form"
                            >
                                <Grid
                                    item={true}
                                    xs={6}
                                    className="form_inputContainer"
                                >
                                    <Box py={3}>
                                        <Typography variant="h6">
                                            Tilityyppi:
                                        </Typography>
                                        <List>
                                            {accounts.map(
                                                (account: IAccount) => {
                                                    return (
                                                        <ListItem
                                                            key={account._id}
                                                            role={undefined}
                                                            dense={true}
                                                        >
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    edge="start"
                                                                    checked={
                                                                        account.accountPlan ===
                                                                        accountPlan
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    tabIndex={
                                                                        -1
                                                                    }
                                                                    disableRipple={
                                                                        true
                                                                    }
                                                                    name="accountPlan"
                                                                    value={
                                                                        account.accountPlan
                                                                    }
                                                                    onClick={
                                                                        this
                                                                            .handleChange
                                                                    }
                                                                    inputProps={{
                                                                        'aria-labelledby':
                                                                            account._id,
                                                                    }}
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                id={account._id}
                                                                primary={
                                                                    account.accountPlan ===
                                                                    accountPlan
                                                                        ? 'Valittu: ' +
                                                                          account.accountPlan +
                                                                          ', ' +
                                                                          getAccountsPrice(
                                                                              account,
                                                                          ) +
                                                                          ',00 €/vuosi '
                                                                        : account.accountPlan +
                                                                          ', ' +
                                                                          getAccountsPrice(
                                                                              account,
                                                                          ) +
                                                                          ',00 €/vuosi '
                                                                }
                                                                secondary={setAccountOfferText(
                                                                    account,
                                                                )}
                                                            />
                                                        </ListItem>
                                                    );
                                                },
                                            )}
                                        </List>
                                    </Box>
                                </Grid>
                                <Grid
                                    item={true}
                                    xs={6}
                                    className="form_inputContainer"
                                >
                                    <Box py={3}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">
                                                Käyttäjätyyppi:
                                            </FormLabel>
                                            <RadioGroup
                                                aria-label="business"
                                                name="business"
                                                value={
                                                    business ? 'true' : 'false'
                                                }
                                                onChange={this.handleChange}
                                            >
                                                <FormControlLabel
                                                    value="true"
                                                    control={<Radio />}
                                                    label="Yritys"
                                                />
                                                <FormControlLabel
                                                    value="false"
                                                    control={<Radio />}
                                                    label="Yksityishenkilö"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid
                                    item={true}
                                    xs={12}
                                    className="form_inputContainer"
                                >
                                    <Box py={3}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">
                                                Yhteydenotto tapa:
                                            </FormLabel>
                                            <RadioGroup
                                                aria-label="contactByPhone"
                                                name="contactByPhone"
                                                value={
                                                    contactByPhone
                                                        ? 'true'
                                                        : 'false'
                                                }
                                                onChange={this.handleChange}
                                            >
                                                <FormControlLabel
                                                    value="false"
                                                    control={<Radio />}
                                                    label="Sähköposti"
                                                />
                                                <FormControlLabel
                                                    value="true"
                                                    control={<Radio />}
                                                    label="SMS"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid
                                    item={true}
                                    xs={12}
                                    className="form_inputContainer"
                                >
                                    <Typography variant="h6">
                                        {business
                                            ? 'Yritystiedot:'
                                            : 'Henkilötiedot:'}
                                    </Typography>
                                </Grid>
                                {business ? (
                                    <>
                                        <Grid
                                            item={true}
                                            xs={12}
                                            sm={6}
                                            className="form_inputContainer"
                                        >
                                            <TextField
                                                name="companyName"
                                                label="Yrityksen nimi"
                                                type="text"
                                                id="companyName"
                                                fullWidth={true}
                                                value={companyName}
                                                required={true}
                                                onChange={this.handleChange}
                                            />
                                        </Grid>
                                        <Grid
                                            item={true}
                                            xs={12}
                                            sm={6}
                                            className="form_inputContainer"
                                        >
                                            <TextField
                                                name="ytunnus"
                                                label="Y-Tunnus"
                                                type="text"
                                                id="ytunnus-required"
                                                required={true}
                                                fullWidth={true}
                                                value={ytunnus}
                                                onChange={this.handleChange}
                                            />
                                        </Grid>
                                        <Grid
                                            item={true}
                                            xs={12}
                                            sm={6}
                                            className="form_inputContainer"
                                        >
                                            <TextField
                                                name="website"
                                                label="Kotisivun domain osoite"
                                                type="text"
                                                id="website"
                                                fullWidth={true}
                                                placeholder="www.jokudomain.fi"
                                                value={website}
                                                onChange={this.handleChange}
                                            />
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <Grid
                                            item={true}
                                            xs={12}
                                            sm={6}
                                            className="form_inputContainer"
                                        >
                                            <TextField
                                                name="firstname"
                                                label="Etunimi"
                                                type="text"
                                                id="firstname-required"
                                                required={true}
                                                fullWidth={true}
                                                value={firstname}
                                                onChange={this.handleChange}
                                            />
                                        </Grid>
                                        <Grid
                                            item={true}
                                            xs={12}
                                            sm={6}
                                            className="form_inputContainer"
                                        >
                                            <TextField
                                                name="lastname"
                                                label="Sukunimi"
                                                type="text"
                                                id="lastname-required"
                                                required={true}
                                                fullWidth={true}
                                                value={lastname}
                                                onChange={this.handleChange}
                                            />
                                        </Grid>
                                        <Grid
                                            item={true}
                                            xs={12}
                                            sm={6}
                                            className="form_inputContainer"
                                        >
                                            <TextField
                                                name="socialSecurityNumber"
                                                label="Henkilötunnus"
                                                type="text"
                                                id="socialSecurityNumber-required"
                                                required={true}
                                                fullWidth={true}
                                                value={socialSecurityNumber}
                                                onChange={this.handleChange}
                                            />
                                        </Grid>
                                    </>
                                )}
                                <Grid
                                    item={true}
                                    xs={12}
                                    className={classes.sectionTitle}
                                >
                                    <Typography variant="h6">
                                        Yhteystiedot:
                                    </Typography>
                                </Grid>
                                <Grid
                                    item={true}
                                    xs={12}
                                    sm={6}
                                    className="form_inputContainer"
                                >
                                    <TextField
                                        name="street"
                                        label="Katuosoite"
                                        type="text"
                                        id="street-required"
                                        required={true}
                                        fullWidth={true}
                                        value={street}
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid
                                    item={true}
                                    xs={12}
                                    sm={6}
                                    className="form_inputContainer"
                                >
                                    <TextField
                                        name="zip"
                                        label="Postinumero"
                                        type="text"
                                        id="zip-required"
                                        required={true}
                                        fullWidth={true}
                                        value={zip}
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid
                                    item={true}
                                    xs={12}
                                    sm={6}
                                    className="form_inputContainer"
                                >
                                    <FormControl
                                        fullWidth={true}
                                        required={true}
                                    >
                                        <InputLabel id="demo-simple-select-required-label">
                                            Maakunta
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-required-label"
                                            id="demo-simple-select-required"
                                            name="region"
                                            value={region}
                                            onChange={this.handleChange}
                                        >
                                            <MenuItem value={region}>
                                                <em>Maakunta</em>
                                            </MenuItem>
                                            {!!regions.length &&
                                                regions.map(
                                                    (region2: IRegion) => {
                                                        return (
                                                            <MenuItem
                                                                key={
                                                                    region2._id
                                                                }
                                                                value={
                                                                    region2._id
                                                                }
                                                            >
                                                                {region2.name}
                                                            </MenuItem>
                                                        );
                                                    },
                                                )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid
                                    item={true}
                                    xs={12}
                                    sm={6}
                                    className="form_inputContainer"
                                >
                                    <FormControl
                                        fullWidth={true}
                                        required={true}
                                    >
                                        <InputLabel id="demo-simple-select-required-label">
                                            Kunta
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-required-label"
                                            id="demo-simple-select-required"
                                            name="locality"
                                            value={locality}
                                            onChange={this.handleChange}
                                        >
                                            <MenuItem value={locality}>
                                                <em>Kunta *</em>
                                            </MenuItem>
                                            {!!localities.length &&
                                                localities.map(
                                                    (locality2: ILocation) => {
                                                        return (
                                                            <MenuItem
                                                                key={
                                                                    locality2._id
                                                                }
                                                                value={
                                                                    locality2._id
                                                                }
                                                            >
                                                                {
                                                                    locality2.location
                                                                }
                                                            </MenuItem>
                                                        );
                                                    },
                                                )}
                                        </Select>
                                        <FormHelperText>
                                            Jos haluat muuttaa kuntaa muualle
                                            Suomeen, muuta ensin maakuntaa
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid
                                    item={true}
                                    xs={12}
                                    sm={6}
                                    className="form_inputContainer"
                                >
                                    <TextField
                                        name="contactPerson"
                                        label="Yhteyshenkilö"
                                        id="demo-simple-select-required"
                                        value={contactPerson}
                                        onChange={this.handleChange}
                                        required={true}
                                        fullWidth={true}
                                    />
                                </Grid>
                                <Grid
                                    item={true}
                                    xs={12}
                                    sm={6}
                                    className="form_inputContainer"
                                >
                                    <TextField
                                        name="phonenumber"
                                        label="Puhelinnumero"
                                        type="tel"
                                        id="phonenumber-required"
                                        required={true}
                                        fullWidth={true}
                                        value={phonenumber}
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid
                                    item={true}
                                    xs={12}
                                    className={classes.sectionTitle}
                                >
                                    <Typography variant="h6">
                                        Käyttäjätilin tiedot:
                                    </Typography>
                                </Grid>
                                <Grid
                                    item={true}
                                    xs={12}
                                    sm={6}
                                    className="form_inputContainer"
                                >
                                    <TextField
                                        error={
                                            validateEmail(email ? email : '')
                                                ? true
                                                : false
                                        }
                                        helperText={
                                            validateEmail(email ? email : '')
                                                ? 'Sähköposti formaatti ei täsmää!'
                                                : ''
                                        }
                                        name="email"
                                        label="Email"
                                        type="email"
                                        id="email-required"
                                        required={contactByPhone ? false : true}
                                        fullWidth={true}
                                        value={email}
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid
                                    item={true}
                                    xs={12}
                                    sm={6}
                                    className="form_inputContainer"
                                >
                                    <TextField
                                        name="username"
                                        label="Käyttäjätunnus"
                                        type="text"
                                        id="username-required"
                                        required={true}
                                        fullWidth={true}
                                        value={username}
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid
                                    item={true}
                                    xs={12}
                                    sm={6}
                                    className="form_inputContainer"
                                >
                                    <TextField
                                        error={
                                            validatePassword(
                                                password ? password : '',
                                            )
                                                ? true
                                                : false
                                        }
                                        helperText={
                                            validatePassword(
                                                password ? password : '',
                                            )
                                                ? 'Heikko salasana'
                                                : 'Salasanan on oltava vähintään 8 merkkiä pitkä, ja sen tulee sisältää vähintään yksi iso kirjain ja numero sekä pieniä kirjaimia.'
                                        }
                                        name="password"
                                        label="Salasana"
                                        type="password"
                                        id="password-required"
                                        required={true}
                                        fullWidth={true}
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid
                                    item={true}
                                    xs={12}
                                    sm={6}
                                    className="form_inputContainer"
                                >
                                    <TextField
                                        error={
                                            validateConfPassword(
                                                confPassword
                                                    ? confPassword
                                                    : '',
                                                password ? password : '',
                                            )
                                                ? true
                                                : false
                                        }
                                        helperText={
                                            validateConfPassword(
                                                confPassword
                                                    ? confPassword
                                                    : '',
                                                password ? password : '',
                                            )
                                                ? 'Salasanat eivät täsmää!'
                                                : ''
                                        }
                                        name="confPassword"
                                        label="Vahvista salasana"
                                        type="password"
                                        id="confPassword-required"
                                        required={true}
                                        fullWidth={true}
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid
                                    item={true}
                                    xs={12}
                                    className={classes.termsGrid}
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={terms}
                                                name="terms"
                                                id="terms"
                                                onChange={this.handleChange}
                                                value={terms}
                                            />
                                        }
                                        label={
                                            <label htmlFor="terms">
                                                Hyväksyn{' '}
                                                <Link
                                                    className="dark"
                                                    to="/kayttoehdot"
                                                >
                                                    käyttöehdot
                                                </Link>
                                            </label>
                                        }
                                    />
                                </Grid>
                                <Grid item={true} xs={12}>
                                    <Button
                                        disabled={formValid ? true : false}
                                        startIcon={<PersonAdd />}
                                        variant="outlined"
                                        type="submit"
                                        color="primary"
                                        className={classes.btn}
                                    >
                                        {createUser
                                            ? ' Luo käyttäjä'
                                            : ' Rekisteröidy'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                        {!createUser && (
                            <Grid
                                container={true}
                                justify="center"
                                alignContent="center"
                            >
                                <Grid item={true} xs={12}>
                                    <Typography variant="body1">
                                        Oletko jo Halkoliiterin myyjä? Kirjaudu{' '}
                                        <Link className="dark" to="/kirjaudu">
                                            tästä
                                        </Link>
                                        .
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                    </Box>
                </Paper>
            </Grid>
        );
    }
}

function mapStateTopProps(state: AppState): ISignUpFormStateProps {
    return {
        userForm: state.userFormState.userForm,
        signUpForm: state.signUpFormState.formData,
        accounts: state.accounts,
        localities: state.localities,
        regions: state.regions,
    };
}

export default connect<
    ISignUpFormStateProps,
    IDispatchProps,
    IOwnProps,
    AppState
>(
    mapStateTopProps,
    mapDispatchToProps,
)(withStyles(styles)(SignUpForm));
