import React, { FunctionComponent } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, Grid, Paper, Box } from '@material-ui/core';
import KuivausJaVarastointiKuva from '../../assets/images/firewood-storage.jpg';

const useStyles = makeStyles((theme: Theme) => ({
    box: {
        padding: '3rem 4rem',
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            padding: '3rem 1.6rem',
        },
    },
    image: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        fontSize: '1.4rem',
        '& img': {
            maxWidth: '100%',
        },
        '& span': {
            marginTop: '0.8rem',
            fontWeight: 'bold',
        },
    },
    link: {
        color: '#333',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

const FirewoodInfo: FunctionComponent<{}> = () => {
    const classes = useStyles();

    return (
        <section>
            <Box py={8} px={1}>
                <Grid container={true} justify="center">
                    <Grid item={true} xs={12} md={8}>
                        <Paper>
                            <div className={classes.box}>
                                <Grid container={true} justify="center">
                                    <Grid item={true}>
                                        <Typography variant="h4">
                                            Polttopuun kuivaus ja varastointi
                                        </Typography>
                                        <br />
                                    </Grid>
                                    <Grid item={true}>
                                        <br />
                                        <Typography variant="body1">
                                            Kaatotuoreen puun kosteuspitoisuus
                                            on noin 50 %. Puun kuivumisen
                                            kannalta otollisinta aikaa on kevät
                                            ja alkukesä. Varhain keväällä
                                            hakatut pilkkeet kuivuvat muutamassa
                                            kuukaudessa. Myöhemmin hakatut
                                            tarvitsevat pidemmän kuivumisajan.
                                            Varastointi talven yli liiterissä ei
                                            enää oleellisesti vähennä puun
                                            kosteutta. Puun kuivuminen on
                                            nopeinta, kun ilman suhteellinen
                                            kosteus on alhainen, lämpötila
                                            korkea ja tuulet voimakkaita.
                                            Kuivuessaan pilkepinot kutistuvat
                                            6-7 prosenttia.
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            Polttopuun kuivumiseen vaikuttavat
                                            puulaji, paksuus, valmistustapa,
                                            ilman lämpötila ja suhteellinen
                                            kosteus sekä tuulen nopeus.
                                            Polttopuun varastopaikan valinnassa
                                            kannattaa olla tarkkana.
                                            Varastopaikaksi kannattaa valita
                                            tuulinen ja aurinkoinen paikka.
                                            Polttopuut kuivuvat parhaiten, kun
                                            ilma kulkee pinon läpi. Pinojen alle
                                            tulee laittaa kunnolliset aluspuut,
                                            joiden alla ilma pääsee liikkumaan.
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            Polttopuut tulisi halkaista
                                            kuivumisen nopeuttamiseksi.
                                            Pienemmillä puilla riittää kuoren
                                            rikkominen. Etenkin halkaisematon
                                            lehtipuu kuivuu erittäin huonosti ja
                                            lahoaa herkästi.
                                        </Typography>
                                        <Typography>
                                            Puut olisi hyvä siirtää liiteriin
                                            ennen syyssateita. Jos pinot
                                            jätetään ulos talveksi, ne pitää
                                            peittää. Tällöin on huolehdittava
                                            kosteuden haihtumisesta. Puupinon ja
                                            sitä peittävän muovin tms. väliin on
                                            jätettävä rako, jotta ilma kiertää.
                                        </Typography>
                                        <br />
                                        <br />
                                        <Typography>
                                            Lue lisää: <br />
                                            <a
                                                className={classes.link}
                                                target="_blank"
                                                href="/downloads/klapia_liiteriin_opas.pdf"
                                            >
                                                Klapia liiteriin – kuivan
                                                polttopuun varastointiohje
                                            </a>{' '}
                                            (pdf)
                                        </Typography>
                                        <br />
                                        <br />
                                        <div className={classes.image}>
                                            <img
                                                src={KuivausJaVarastointiKuva}
                                                alt="halkoliiteri.com kuivaus ja varastointi"
                                            />
                                            <span>
                                                Polttopuuvarasto. Kuva: Jorma
                                                Anttoora.
                                            </span>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </section>
    );
};

export default FirewoodInfo;
