import React, { FC } from 'react';
import './AccountSettings.scss';
import { Grid, Box } from '@material-ui/core';
import UserAccountInfoCard from '../ShowProfile/UserAccountInfoCard';
import { IUser } from '../../../../types';

interface IAccountSettingsStateProps {
    user: IUser;
}

const AccountSettings: FC<IAccountSettingsStateProps> = ({ user }) => {
    return (
        <Grid container={true} justify="center" className="profile">
            <Grid item={true} md={10} xs={12}>
                <Box>
                    <UserAccountInfoCard
                        user={user}
                        showAccountSettings={true}
                    />
                </Box>
            </Grid>
        </Grid>
    );
};

export default AccountSettings;
