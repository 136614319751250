import { ISidebarState } from '../../../types';

export const UPDATE_SIDEBAR = 'UPDATE_SIDEBAR';
export const RESET_SIDEBAR = 'RESET_SIDEBAR';

export interface UpdateSideBar {
    type: typeof UPDATE_SIDEBAR;
    sidebarState: ISidebarState;
}

export interface ResetSideBar {
    type: typeof RESET_SIDEBAR;
    sidebarState: ISidebarState;
}

export type SideBarActionTypes = UpdateSideBar | ResetSideBar;
