import { IProduct } from '../../../types';

export const SET_USER_SERVICES = 'SET_USER_SERVICES';
export const ADD_SERVICE = 'ADD_SERVICE';
export const UPDATE_SERVICE = 'UPDATE_SERVICE';
export const REMOVE_SERVICE = 'REMOVE_SERVICE';

export interface SetUserServices {
    type: typeof SET_USER_SERVICES;
    userServices: IProduct[];
}

export interface AddService {
    type: typeof ADD_SERVICE;
    service: IProduct;
}

export interface UpdateService {
    type: typeof UPDATE_SERVICE;
    service: IProduct;
}

export interface RemoveService {
    type: typeof REMOVE_SERVICE;
    id: string;
}

export type UserServiceActionTypes =
    | SetUserServices
    | AddService
    | UpdateService
    | RemoveService;
