import React, { FC, useEffect, useState } from 'react';
import { Grid, Box } from '@material-ui/core';
import { mapDispatchToProps } from '../../../types/dispatchActionTypes';
import { connect } from 'react-redux';
import { AppState } from '../../../store/store';
import OrderList from '../../../components/Order/OrderList';
import { IOrder } from '../../../../../types';

interface IAdminOrdersStateProps {
    orders: IOrder[];
}

type AdminOrdersProps = IAdminOrdersStateProps & IDispatchProps;

const Orders: FC<AdminOrdersProps> = (props) => {
    const { orders, fetchOrdersAction } = props;
    const [fetchOrders, updateFetchOrders] = useState<boolean>(true);
    useEffect(() => {
        if (fetchOrders) {
            fetchOrdersAction('');
            updateFetchOrders(false);
        }
    }, [fetchOrdersAction, fetchOrders, updateFetchOrders]);
    return (
        <Grid container={true}>
            <Grid item={true} xs={10} md={12}>
                <Box py={4}>{orders.length && <OrderList />}</Box>
            </Grid>
        </Grid>
    );
};

function mapStateToProps(state: AppState): IAdminOrdersStateProps {
    return {
        orders: state.orders,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
