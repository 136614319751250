import { IAccountSettingsFormState } from '../../../../types';
import {
    UPDATE_ACCOUNT_SETTINGS_FORM,
    RESET_ACCOUNT_SETTINGS_FORM,
    AccountSettingsFormActionTypes,
} from '../../types/accountSettingsFormTypes';
import { resetAccountSettingsForm } from '../../utils/resetUtils';

const DEFAULT_STATE: IAccountSettingsFormState = {
    accountSettingsForm: resetAccountSettingsForm(),
};

const accountSettingsFormReducer = (
    state = DEFAULT_STATE,
    action: AccountSettingsFormActionTypes,
) => {
    switch (action.type) {
        case UPDATE_ACCOUNT_SETTINGS_FORM:
            return Object.assign({}, state, {
                accountSettingsForm: {
                    ...state.accountSettingsForm,
                    [Object.keys(
                        action.accountSettingsFormState.accountSettingsForm,
                    )[0]]: Object.values(
                        action.accountSettingsFormState.accountSettingsForm,
                    )[0],
                },
            });
        case RESET_ACCOUNT_SETTINGS_FORM:
            return {
                ...state,
                accountSettingsFormState:
                    action.accountSettingsFormState.accountSettingsForm,
            };
        default:
            return state;
    }
};

export default accountSettingsFormReducer;
