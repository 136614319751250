import { emptySignUpForm } from '../../utils/resetUtils';
import {
    SignUpFormActionTypes,
    UPDATE_SIGNUP_FORM,
    RESET_SIGNUP_FORM_STATE,
} from '../../types/signUpFormStateActionTypes';
import { ISignUpFormState } from '../../../../types';

const DEFAULT_STATE: ISignUpFormState = {
    formData: emptySignUpForm(),
};

const signUpFormReducer = (
    state = DEFAULT_STATE,
    action: SignUpFormActionTypes,
) => {
    switch (action.type) {
        case UPDATE_SIGNUP_FORM:
            return Object.assign({}, state, {
                formData: {
                    ...state.formData,
                    [Object.keys(action.signUpFormState.formData)[0]]:
                        Object.values(action.signUpFormState.formData)[0],
                },
            });
        case RESET_SIGNUP_FORM_STATE:
            return {
                ...state,
                formData: action.signUpFormState.formData,
            };
        default:
            return state;
    }
};

export default signUpFormReducer;
