import {
    UPDATE_SERVICE_FORM,
    RESET_SERVICE_FORM,
} from '../../types/serviceFormActionTypes';
import { AppActions } from '../../types/actions';
import { IServiceForm } from '../../../../types';

export function updateServiceForm(serviceForm: IServiceForm): AppActions {
    return {
        type: UPDATE_SERVICE_FORM,
        serviceFormState: { serviceForm },
    };
}

export function resetServiceForm(serviceForm: IServiceForm): AppActions {
    return {
        type: RESET_SERVICE_FORM,
        serviceFormState: { serviceForm },
    };
}
