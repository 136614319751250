import React, { FC, useEffect, useState } from 'react';
import {
    InputBaseComponentProps,
    Box,
    Grid,
    Typography,
    TextField,
    Button,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppState } from '../../store/store';
import { mapDispatchToProps } from '../../types/dispatchActionTypes';
import { IFlashMessage, IMessage, IContact } from '../../../../types';

interface IOpenTicketStateProps {
    contact: IContact;
    message: IFlashMessage;
}

interface MatchParams {
    id: string;
    t: string;
}

interface IOpenTicketProps extends RouteComponentProps<MatchParams> {
    contact: IContact;
    message: IFlashMessage;
}

type OpenTicketProps = IOpenTicketProps & IDispatchProps & RouteComponentProps;

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        borderRadius: '5px',
        border: '1px solid #DCDCDC',
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)',
        padding: '1.5rem 2rem',
        background: '#f7f7f7',
    },
    topGrid: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '1rem',
        marginBottom: '1.2rem',
        borderBottom: '1px solid #DFDFDF',
        '& h6': {
            marginLeft: '1rem',
        },
    },
    grid: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '1rem',
    },
    authorMsg: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        background: '#333',
        color: '#fff',
        width: '100%',
        borderRadius: '5px',
        padding: '1rem 2rem',
        marginBottom: '2rem',
        '& h6, & p': {
            color: '#fff',
        },
    },
    adminMsg: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        background: '#1E650E',
        color: '#fff',
        width: '100%',
        borderRadius: '5px',
        padding: '1rem 2rem',
        marginBottom: '2rem',
        '& h6, & p': {
            color: '#fff',
        },
    },
    white: {
        color: '#fff !important',
        display: 'flex',
        alignItems: 'center',
        '& em': {
            fontSize: '70%',
            marginLeft: '0.5rem',
        },
    },
}));

const OpenTicket: FC<OpenTicketProps> = (props) => {
    const classes = useStyles();
    const [hasSent, setHasSent] = useState(false);
    const [replyMsg, setReplyMsg] = useState('');
    const contactId = props.match.params.id;

    const getParameterByName = (name: string) => {
        const url: string = window.location.href;
        name = name.replace(/[[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        const results = regex.exec(url);

        if (!results || !results[2]) {
            return '';
        }
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    };

    useEffect(() => {
        if (!hasSent) {
            const { fetchTicketAction } = props;
            fetchTicketAction(contactId, getParameterByName('t'));

            setHasSent(true);
        }
    }, [hasSent, props, contactId]);

    const formatDate = (date: Date) => {
        const dateStr =
            ('00' + date.getDate()).slice(-2) +
            '-' +
            ('00' + (date.getMonth() + 1)).slice(-2) +
            '-' +
            date.getFullYear() +
            ' ' +
            ('00' + date.getHours()).slice(-2) +
            ':' +
            ('00' + date.getMinutes()).slice(-2);

        return dateStr;
    };

    const handleReplyInputChange = (e: InputBaseComponentProps) => {
        setReplyMsg(e.target.value);
    };

    const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const { respondToContactAction } = props;
        respondToContactAction(
            contactId,
            replyMsg,
            props.contact.name,
            getParameterByName('t'),
        );
        setReplyMsg('');
    };

    const messages = props.contact.messages.map((msg: IMessage) => {
        const className =
            props.contact.name === msg.author
                ? classes.authorMsg
                : classes.adminMsg;
        return (
            <div className={className} key={msg._id}>
                <Typography variant="h6" className={classes.white}>
                    {msg.author ? msg.author : 'Admin'}
                    <em>{formatDate(new Date(msg?.createdAt))}</em>
                </Typography>

                <br />
                <Typography variant="body1">{msg?.text}</Typography>
            </div>
        );
    });

    if (
        props.message.text ===
        'Vslitettavasti, linkkinne on virheellinen tai vanhentunut.'
    ) {
        props.history.push('/asiakaspalvelu');
    }

    return (
        <section>
            <Grid container={true}>
                <Grid item={true}>
                    <Box mt={5} p={3}>
                        <div className={classes.paper}>
                            <Grid container={true}>
                                <Grid item={true} className={classes.topGrid}>
                                    <Typography variant="h5">
                                        Keskustelu:
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        {contactId}
                                    </Typography>
                                </Grid>

                                <Grid item={true} className={classes.grid}>
                                    <Typography variant="body1">
                                        <strong>Keskustelun on luonut:</strong>{' '}
                                        {props.contact.name} (
                                        {props.contact.email})
                                    </Typography>
                                </Grid>

                                <Grid item={true} className={classes.grid}>
                                    <Typography variant="body1">
                                        <strong>Luotu:</strong>{' '}
                                        {formatDate(
                                            new Date(props.contact.createdAt),
                                        )}
                                    </Typography>
                                </Grid>

                                <Grid item={true} className={classes.grid}>
                                    <Typography variant="body1">
                                        <strong>Aihe: </strong>
                                        {props.contact.subject}
                                    </Typography>
                                </Grid>

                                <Grid item={true} className={classes.grid}>
                                    <Typography variant="body1">
                                        <strong>Tila: </strong>
                                        {props.contact.status ===
                                            'resolved' && (
                                            <span>Ratkaistu</span>
                                        )}
                                        {props.contact.status ===
                                            'in progress' && (
                                            <span>Käsittelyssä</span>
                                        )}
                                        {props.contact.status ===
                                            'recieved' && (
                                            <span>Vastaanotettu</span>
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container={true}>{messages}</Grid>
                            {props.contact.status !== 'resolved' && (
                                <form onSubmit={handleFormSubmit}>
                                    <Grid
                                        container={true}
                                        direction="column"
                                        justify="space-evenly"
                                        alignItems="flex-end"
                                    >
                                        <Grid item={true}>
                                            <TextField
                                                variant="outlined"
                                                name="message"
                                                fullWidth={true}
                                                value={replyMsg}
                                                onChange={
                                                    handleReplyInputChange
                                                }
                                                multiline={true}
                                                rows={3}
                                                label="Viesti"
                                                type="text"
                                            />
                                        </Grid>
                                        <br />

                                        <Button
                                            type="submit"
                                            variant="outlined"
                                            color="primary"
                                            size="large"
                                        >
                                            Vastaa
                                        </Button>
                                    </Grid>
                                </form>
                            )}
                        </div>
                    </Box>
                </Grid>
            </Grid>
        </section>
    );
};

function mapStateToProps(state: AppState): IOpenTicketStateProps {
    return {
        contact: state.contact,
        message: state.message.message,
    };
}

export default connect<IOpenTicketStateProps, IDispatchProps, {}, AppState>(
    mapStateToProps,
    mapDispatchToProps,
)(OpenTicket);
