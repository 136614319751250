import React, { Component } from 'react';
import './SignIn.scss';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { mapDispatchToProps } from '../../types/dispatchActionTypes';
import { connect } from 'react-redux';
import { AppState } from '../../store/store';
import AuthForm from './AuthForm/AuthForm';
import SignInFlow from './SignInFlow';
import ResetPasswordForm from './AuthForm/ResetPasswordForm';
import { IUiProps } from '../../../../types';

interface ISignInStateProps {
    uiProps: IUiProps;
}

type SignInProps = ISignInStateProps & IDispatchProps & RouteComponentProps;

class SignIn extends Component<SignInProps, AppState> {
    public componentDidMount() {
        const { updateUiStateAction } = this.props;
        if (window.location.pathname === '/rekisteroidy') {
            updateUiStateAction({ showLoginForm: false });
        }
    }

    public render() {
        return (
            <React.Fragment>
                {window.location.pathname === '/kirjaudu' && (
                    <AuthForm login={true} />
                )}
                {window.location.pathname === '/kirjaudu/salasananpalautus' && (
                    <ResetPasswordForm />
                )}
                {window.location.pathname === '/rekisteroidy' && <SignInFlow />}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: AppState): ISignInStateProps {
    return {
        uiProps: state.uiState.uiProps,
    };
}

export default withRouter(
    connect<ISignInStateProps, IDispatchProps, {}, AppState>(
        mapStateToProps,
        mapDispatchToProps,
    )(SignIn),
);
