import { IUiState } from '../../../../types';
import { UPDATE_UISTATE, UiActionTypes } from '../../types/uiActionTypes';

const DEFAULT_STATE: IUiState = {
    uiProps: {
        modalOpen: false,
        showFavoriteList: false,
        showEditProductForm: false,
        showEditServiceForm: false,
        showImageUploadForm: false,
        navbarExpanded: false,
        cardMenuOpen: false,
        showLoginForm: true,
        activeStep: 0,
        responseStatus: 0,
        category: '',
        region: '',
        locality: '',
        sellerId: '',
        itemId: '',
    },
};

const uiReducer = (state = DEFAULT_STATE, action: UiActionTypes) => {
    switch (action.type) {
        case UPDATE_UISTATE:
            return Object.assign({}, state, {
                uiProps: {
                    ...state.uiProps,
                    [Object.keys(action.uiState.uiProps)[0]]: Object.values(
                        action.uiState.uiProps,
                    )[0],
                },
            });
        default:
            return state;
    }
};

export default uiReducer;
