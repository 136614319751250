import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../store/store';
import { mapDispatchToProps } from '../../../types/dispatchActionTypes';
import AdminSideBar from '../SideBar/SideBar';
import Orders from '../Orders/Orders';
import Users from '../Users/Users';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import SortMenuBar from '../../../components/ManageProducts/SortMenuBar';
import AdminPanelPage from './AdminPanelPage';
import Order from '../../../components/Order/Order';
import { resetAdminPanelUiProps } from '../../../utils/resetUtils';
import { setAdminPanelUiStateValues } from '../../../utils/utils';
import LocationMagement from './LocationManagement/LocationMagement';
import CreateUser from '../Users/CreateUser';
import {
    IAdminPanelUiProps,
    ICurrentUser,
    IOrder,
    IOrderTotals,
    IUiProps,
    OrderStatus,
    IUser,
} from '../../../../../types';

interface IAdminPanelStateProps {
    currentUser: ICurrentUser;
    orders: IOrder[];
    order: IOrder;
    orderTotals: IOrderTotals;
    adminPanelUiProps: IAdminPanelUiProps;
    sellers: IUser[];
    uiProps: IUiProps;
}

type AdminPanelProps = IAdminPanelStateProps &
    IDispatchProps &
    RouteComponentProps;

class AdminPanel extends Component<AdminPanelProps, AppState> {
    public componentDidMount() {
        const { fetchUsersAction, sellers } = this.props;
        if (sellers.length === 0) {
            fetchUsersAction('');
        }
    }
    public returnOrders = () => {
        const {
            adminPanelUiProps,
            updateAdminPanelUiStateAction,
            resetAdminPanelUiStateAction,
        } = this.props;
        const { showOrders } = adminPanelUiProps;
        resetAdminPanelUiStateAction(resetAdminPanelUiProps());
        updateAdminPanelUiStateAction(
            setAdminPanelUiStateValues('showOrders', showOrders ? false : true),
        );
    };
    public updateOrderStatus = (id: string, status: OrderStatus) => {
        const { updateOrderAction } = this.props;
        updateOrderAction(id, status);
    };
    public componentDidUpdate() {
        const { uiProps, logoutAction } = this.props;
        if (uiProps.responseStatus === 401) {
            logoutAction();
            this.props.history.push('/kirjaudu');
        }
    }
    public render() {
        const {
            showOrders,
            showUsers,
            createUser,
            showOrder,
            showAdminPanel,
            showRegionsAndLocalities,
        } = this.props.adminPanelUiProps;
        const { order, orderTotals } = this.props;
        return (
            <section className="productContainer">
                <div className="sidebarContainer">
                    <AdminSideBar />
                </div>
                <div className="products-wrapper">
                    <SortMenuBar
                        title={`Hallintapaneeli /
                                    ${
                                        showUsers
                                            ? ' Käyttäjähallinta'
                                            : showOrders
                                            ? 'Tilaushallinta'
                                            : createUser
                                            ? 'Luo käyttäjä'
                                            : showRegionsAndLocalities
                                            ? 'Maakunta- ja paikkakuntahallinta'
                                            : 'Etusivu'
                                    }`}
                    />
                    {showAdminPanel && <AdminPanelPage />}
                    {showUsers && <Users />}
                    {createUser && <CreateUser />}
                    {showOrders && <Orders />}
                    {showOrder && (
                        <Order
                            order={order}
                            orderTotals={orderTotals}
                            returnOrders={this.returnOrders}
                            updateOrderStatus={this.updateOrderStatus}
                        />
                    )}
                    {showRegionsAndLocalities && <LocationMagement />}
                </div>
            </section>
        );
    }
}

function mapStateToProps(state: AppState): IAdminPanelStateProps {
    return {
        currentUser: state.currentUser,
        orders: state.orders,
        order: state.order,
        adminPanelUiProps: state.adminPanelUiState.adminPanelUiProps,
        sellers: state.sellers,
        uiProps: state.uiState.uiProps,
        orderTotals: state.orderTotals,
    };
}

export default withRouter(
    connect<IAdminPanelStateProps, IDispatchProps, {}, AppState>(
        mapStateToProps,
        mapDispatchToProps,
    )(AdminPanel),
);
