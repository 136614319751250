import React, { FunctionComponent } from 'react';
import {
    Typography,
    Grid,
    Paper,
    TableHead,
    TableRow,
    TableContainer,
    Table,
    TableCell,
    TableBody,
    Box,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import './SalesTerms.scss';
import { Link } from 'react-router-dom';

interface SalesTermsProps {
    agreed: boolean;
}

const pilkkeet25Ja50 = [
    {
        units1: 1,
        units2: 0.62,
        units3: 0.4,
    },
    {
        units1: 1.63,
        units2: 1,
        units3: 0.65,
    },
    {
        units1: 2.5,
        units2: 1.54,
        units3: 1,
    },
];

const plikkeetYli60JaHalko = [
    {
        units1: 1,
        units2: 0.65,
        units3: 0.4,
    },
    {
        units1: 1.55,
        units2: 1,
        units3: 0.62,
    },
    {
        units1: 2.5,
        units2: 1.61,
        units3: 1,
    },
];

const useStyles = makeStyles((theme: Theme) => ({
    box: {
        padding: '3rem',
        [theme.breakpoints.down('sm')]: {
            padding: '3rem 1.6rem',
        },
    },
}));

const SalesTerms: FunctionComponent<SalesTermsProps> = () => {
    const classes = useStyles();

    return (
        <section>
            <Box py={8} px={1}>
                <Grid container={true} justify="center">
                    <Grid item={true} xs={12} md={8}>
                        <Paper>
                            <div className={classes.box}>
                                <Grid container={true} justify="center">
                                    <Grid item={true}>
                                        <Typography variant="h4">
                                            Halkoliiteri.com -verkkopalvelun
                                            yleiset myynti- ja toimitusehdot
                                        </Typography>
                                    </Grid>
                                    <Grid item={true}>
                                        <br />
                                        <br />
                                        <Typography
                                            className="colorBlack"
                                            variant="h5"
                                        >
                                            1. HINNOITTELU
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            Tuotteiden ja palvelujen hintana
                                            käytetään kunkin käyttäjän
                                            myynti-ilmoituksessa merkitsemää
                                            arvonlisäverollista hintaa
                                            (yksikkönä pilkkeissä €/irto-m³,
                                            haloissa €/pino-m³, sahapinnoissa ja
                                            polttorangoissa €/pino-m3), ellei
                                            osapuolten välisessä
                                            myyntineuvottelussa sovita toisin.
                                        </Typography>
                                        <Typography variant="body1">
                                            Kuljetus ja mahdolliset muut
                                            palvelut ja tuotteet voidaan
                                            hinnoitella erikseen. Hinta on
                                            voimassa kaupoille, jotka on tehty
                                            kyseisen päivän aikana. Puun myyjä
                                            pidättää oikeuden mahdollisiin
                                            hintamuutoksiin.
                                        </Typography>
                                        <br />
                                        <br />
                                        <Typography
                                            className="colorBlack"
                                            variant="h5"
                                        >
                                            2. PUUERÄN LASKUTUS
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            Puuerän laskutus tapahtuu suoraan
                                            puun ostajan ja myyjän välillä.
                                            Maksutapoina ovat käteismaksu tai
                                            laskutus, joista kaupan osapuolet
                                            sopivat aina keskenään.
                                        </Typography>
                                        <br />
                                        <br />
                                        <Typography
                                            className="colorBlack"
                                            variant="h5"
                                        >
                                            3. PUUERÄN LAATUVAATIMUKSET JA
                                            TOIMITUS
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            Myyjä vastaa siitä, että hänen
                                            ilmoittamansa puuerä täyttää
                                            Halkoliiteri.com -verkkopalvelun
                                            polttopuun{' '}
                                            <Link to="/laatuvaatimukset">
                                                mitta- ja laatuvaatimukset
                                            </Link>
                                            . Kotiinkuljetus ja toimitusajat
                                            sekä mahdolliset muut kauppaan
                                            liittyvät palvelut sovitaan aina
                                            kaupan osapuolten kesken erikseen.
                                        </Typography>
                                        <br />
                                        <br />
                                        <Typography
                                            className="colorBlack"
                                            variant="h5"
                                        >
                                            4. MITTAYKSIKÖIDEN MUUNTOKERTOIMET
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            Halkoliiterissä käytetään seuraavia
                                            muuntokertoimia:
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            HUOM! Muuntokertoimet ovat
                                            keskimääräisiä lukuja ja niissä on
                                            käytännön toiminnassa noin 10%
                                            vaihteluväli. Myynti- ja
                                            toimitusehdot päivitetty 21.3.2011
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            Pilkkeet pituus 25 - 50 cm
                                        </Typography>
                                        <br />
                                    </Grid>
                                    <Grid item={true} xs={10}>
                                        <TableContainer component={Paper}>
                                            <Table aria-label="customized table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            Pilkkeen pituudet
                                                        </TableCell>
                                                        <TableCell>
                                                            Irto-m³
                                                        </TableCell>
                                                        <TableCell>
                                                            Pino-m³
                                                        </TableCell>
                                                        <TableCell>
                                                            {' '}
                                                            Kiinto-m³
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {pilkkeet25Ja50.map(
                                                        (pilke, index) => {
                                                            return (
                                                                <TableRow
                                                                    key={index}
                                                                >
                                                                    <TableCell>
                                                                        Pilkkeet
                                                                        25cm -
                                                                        50cm
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {
                                                                            pilke.units1
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {
                                                                            pilke.units2
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {
                                                                            pilke.units3
                                                                        }
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        },
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <br />
                                        <Typography variant="body1">
                                            Pilkkeet yli 60 cm ja halko 1 m
                                        </Typography>
                                        <br />
                                        <TableContainer component={Paper}>
                                            <Table aria-label="customized table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            Pilkkeen ja halon
                                                            pituudet
                                                        </TableCell>
                                                        <TableCell>
                                                            Irto-m³
                                                        </TableCell>
                                                        <TableCell>
                                                            Pino-m³
                                                        </TableCell>
                                                        <TableCell>
                                                            {' '}
                                                            Kiinto-m³
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {plikkeetYli60JaHalko.map(
                                                        (pilke, index) => {
                                                            return (
                                                                <TableRow
                                                                    key={index}
                                                                >
                                                                    <TableCell>
                                                                        60cm
                                                                        pilke ja
                                                                        halko 1m
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {
                                                                            pilke.units1
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {
                                                                            pilke.units2
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {
                                                                            pilke.units3
                                                                        }
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        },
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </section>
    );
};

export default SalesTerms;
