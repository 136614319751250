import React, { FC } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ContactForm from '../../components/ContactForm/ContactForm';

const Contact: FC<RouteComponentProps> = (props) => {
    return (
        <section>
            <Grid container={true} justify="center" alignItems="center">
                <Grid item={true} md={10}>
                    <Box mt={5} p={2}>
                        <Grid
                            container={true}
                            justify="center"
                            alignItems="center"
                        >
                            <Grid item={true} xs={12} md={4}>
                                <Typography variant="h4">
                                    Ota yhteyttä
                                </Typography>
                                <Box my={3} />
                                <Typography variant="body1">
                                    Pyrimme jatkuvasti parantamaan palveluamme,
                                    siksi meille onkin ensiarvoisen tärkeää
                                    kuulla juuri teidän mielipiteenne
                                    halkoliiteri.com palvelusta ja sen
                                    käyttömukavuudesta sekä siitä miten näette
                                    halkoliiteri.com palvelun toimivan paremmin
                                    teidän tarpeisiin nähden.
                                </Typography>
                                <Box my={2} />
                                <Typography variant="body1">
                                    Jos teillä on kysyttävää tai haluatte antaa
                                    palutetta, voitte ottaa meihin yhteyttä
                                    täyttämällä yhteydenottolomakkeen.
                                </Typography>
                            </Grid>
                            <Grid item={true} xs={12} md={8}>
                                <Box mt={5} px={2}>
                                    <ContactForm />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </section>
    );
};

export default withRouter(Contact);
