import {
    Badge,
    Box,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    AppBar,
    Tabs,
    Tab,
} from '@material-ui/core';
import {
    Business,
    Close,
    LocationOn,
    Mail,
    Face,
    Person,
    Phone,
    Map,
} from '@material-ui/icons';
import './Modal.scss';
import React, { FC, Fragment, useState } from 'react';
import ProductCard from '../ProductCard/ProductCard';
import TabPanel from './TabPanel';
import { ILocation, IProduct, IUser } from '../../../../types';

interface ISellerProfileStateProps {
    seller: IUser;
    showModal: boolean;
    showModalHandler: (showModal: boolean) => void;
}

const SellerProfile: FC<ISellerProfileStateProps> = (props) => {
    const { seller, showModal, showModalHandler } = props;
    const [value, setValue] = useState<number>(0);
    const handleChange = (event: any, newValue: number) => {
        setValue(newValue);
    };
    const a11yProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };
    return (
        <Fragment>
            <DialogTitle id="form-dialog-title" className="dialogTitleSeller">
                <Grid
                    container={true}
                    className="dialogTitleSeller_container"
                    justify="center"
                    alignItems="center"
                >
                    <Grid
                        item={true}
                        xs={11}
                        className={
                            seller.business
                                ? 'dialogTitleSeller_container__item sellerModalBg sellerModalBg_purpleColor'
                                : 'dialogTitleSeller_container__item sellerModalBg sellerModalBg_orange'
                        }
                    >
                        <Grid
                            className="dialogTitleSeller_container__item___box"
                            container={true}
                            direction="row"
                            justify="space-around"
                            alignItems="center"
                        >
                            <Typography
                                className="dialogTitleSeller_container__item___box____text"
                                variant="h5"
                            >
                                {seller.business ? (
                                    <Badge badgeContent="Yritys" color="error">
                                        <Business fontSize="large" />
                                    </Badge>
                                ) : (
                                    <Face fontSize="large" />
                                )}
                                {seller.business ? (
                                    <> {seller.companyName} </>
                                ) : (
                                    <>
                                        {seller.fullName ||
                                            seller.contactPerson}
                                    </>
                                )}
                            </Typography>
                            <Typography
                                variant="h5"
                                className="dialogTitleSeller_container__item___box____text"
                            >
                                {seller.deliveryMethod}
                            </Typography>
                            <Typography
                                variant="h5"
                                className="dialogTitleSeller_container__item___box____text"
                            >
                                {seller.address.region.name +
                                    ', ' +
                                    seller.address.locality.location}
                                {seller.deliveryMethod
                                    ? ' | ' + seller.deliveryMethod
                                    : ''}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item={true} xs={1} className="closeModalContainer">
                        <Grid
                            className={
                                seller.business
                                    ? 'closeModalContainer_box closeModalContainer_boxPurpleColor'
                                    : 'closeModalContainer_box closeModalContainer_boxOrange'
                            }
                            container={true}
                            justify="center"
                            alignItems="center"
                        >
                            <IconButton
                                color="secondary"
                                onClick={() => showModalHandler(!showModal)}
                            >
                                <Close fontSize="large" color="secondary" />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent className="sellerModalContainer">
                <Grid container={true}>
                    <Grid
                        item={true}
                        md={3}
                        className="sellerModalContainer_infoContainer"
                    >
                        <Grid
                            container={true}
                            justify="center"
                            alignItems="center"
                        >
                            <div
                                className={
                                    seller.business
                                        ? 'sellerModalContainer_infoContainer__sellerInfoHeading sellerModalContainer_infoContainer__purpleColor'
                                        : 'sellerModalContainer_infoContainer__sellerInfoHeading sellerModalContainer_infoContainer__orange'
                                }
                            >
                                <Typography variant="h6">
                                    Yhteystiedot
                                </Typography>
                            </div>
                            <List
                                className="sellerInfoList"
                                component="nav"
                                aria-label="main mailbox phone address location"
                            >
                                <ListItem className="sellerInfoList_item">
                                    <ListItemIcon>
                                        <Person />
                                    </ListItemIcon>
                                    <ListItemText>
                                        {seller.contactPerson}
                                    </ListItemText>
                                </ListItem>
                                <ListItem className="sellerInfoList_item">
                                    <ListItemIcon>
                                        <Phone />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <a
                                            className="link"
                                            href={
                                                'tel:' +
                                                seller.contactInformation
                                                    .phonenumbers[0]
                                            }
                                        >
                                            {
                                                seller.contactInformation
                                                    .phonenumbers[0]
                                            }
                                        </a>
                                    </ListItemText>
                                </ListItem>
                                {!seller.billingByPhone && (
                                    <ListItem className="sellerInfoList_item">
                                        <ListItemIcon>
                                            <Mail />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <a
                                                className="link"
                                                href={'mailto:' + seller.email}
                                            >
                                                {seller.email}
                                            </a>
                                        </ListItemText>
                                    </ListItem>
                                )}
                                <ListItem className="sellerInfoList_item">
                                    <ListItemIcon>
                                        <LocationOn />
                                    </ListItemIcon>
                                    <ListItemText>
                                        {seller.address.street +
                                            ' ' +
                                            seller.address.zip}
                                        <br />
                                        {seller.address.locality.location +
                                            ' ' +
                                            seller.address.region.name}
                                    </ListItemText>
                                </ListItem>
                                {seller.website && (
                                    <ListItem>
                                        <ListItemText
                                            primary={
                                                <a
                                                    href={`${seller.website}`}
                                                    target="__blank"
                                                >
                                                    {seller.website}
                                                </a>
                                            }
                                        />
                                    </ListItem>
                                )}
                            </List>
                        </Grid>
                    </Grid>
                    <Grid item={true} md={9}>
                        <Grid container={true}>
                            <Grid item={true} xs={12}>
                                <AppBar
                                    position="static"
                                    className={
                                        seller.business
                                            ? 'sellerModalContainer_infoContainer__sellerInfoHeading sellerModalContainer_infoContainer__purpleColor'
                                            : 'sellerModalContainer_infoContainer__sellerInfoHeading sellerModalContainer_infoContainer__orange'
                                    }
                                >
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="simple tabs example"
                                    >
                                        <Tab
                                            label="Tuotteet ja Palvelut"
                                            {...a11yProps(0)}
                                        />
                                        <Tab label="Tiedot" {...a11yProps(1)} />
                                        <Tab
                                            label="Toimituspaikkakunnat"
                                            {...a11yProps(2)}
                                        />
                                    </Tabs>
                                </AppBar>
                                <TabPanel value={value} index={0}>
                                    <Grid container={true}>
                                        {seller.products.map(
                                            (product: IProduct) => {
                                                return (
                                                    <Grid
                                                        key={product._id}
                                                        item={true}
                                                        xs={12}
                                                        md={4}
                                                    >
                                                        <Box p={2}>
                                                            <ProductCard
                                                                product={
                                                                    product
                                                                }
                                                            />
                                                        </Box>
                                                    </Grid>
                                                );
                                            },
                                        )}
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <Typography variant="body1">
                                        {seller.accountDescription}
                                    </Typography>
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                    <Grid container={true}>
                                        <Grid item={true} xs={12}>
                                            <Typography variant="h5">
                                                <Map />
                                                Toimituspaikkakunnat
                                            </Typography>
                                        </Grid>
                                        {seller.deliveryLocations.map(
                                            (deliveryLocation: ILocation) => {
                                                return (
                                                    <Grid
                                                        key={
                                                            deliveryLocation._id
                                                        }
                                                        item={true}
                                                        xs={3}
                                                    >
                                                        <Typography variant="body1">
                                                            {
                                                                deliveryLocation.location
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                );
                                            },
                                        )}
                                    </Grid>
                                </TabPanel>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Fragment>
    );
};

export default SellerProfile;
