import {
    UPDATE_SEARCH_FORM,
    RESET_SEARCH_FORM,
} from '../../types/searchFormActionTypes';
import { AppActions } from '../../types/actions';
import { ISearchForm, ISearchFormState } from '../../../../types';

export function updateSearchForm(searchForm: ISearchForm): AppActions {
    return {
        type: UPDATE_SEARCH_FORM,
        searchFormState: { searchForm },
    };
}

export function resetSearchForm(searchFormState: ISearchFormState): AppActions {
    return {
        type: RESET_SEARCH_FORM,
        searchFormState,
    };
}
