import { apiCall, setHeader } from '../../apiRequests/apiCall';
import {
    UPDATE_REGION,
    SET_REGIONS,
    ADD_REGION,
    REMOVE_REGION,
} from '../../types/regionActionTypes';
import { AppActions } from '../../types/actions';
import { Dispatch } from 'redux';
import { addMessage } from './messageActions';
import { IRegionForm, IRegion } from '../../../../types';

/** Public Method */
export function setRegions(regions: IRegion[]): AppActions {
    return {
        type: SET_REGIONS,
        regions,
    };
}
/** Restricted access to product owners only, for Users */
export function addRegion(region: IRegion): AppActions {
    return {
        type: ADD_REGION,
        region,
    };
}

export function updateRegion(region: IRegion): AppActions {
    return {
        type: UPDATE_REGION,
        region,
    };
}

export function removeRegion(regionId: string): AppActions {
    return {
        type: REMOVE_REGION,
        id: regionId,
    };
}

/** Public method */
export const fetchRegions = (query: string): ThunkResult<void> => {
    setHeader('get', '');
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('get', '/api/regions' + query, null)
                .then((res: any) => {
                    dispatch(setRegions(res.regions));
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

/** Restricted access to admins only */
/** create Region */
export const createRegion = (regionForm: IRegionForm): ThunkResult<void> => {
    setHeader('post', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('post', '/api/regions', regionForm)
                .then((res: any) => {
                    const { region, message } = res;
                    dispatch(addRegion(region));
                    dispatch(
                        addMessage({
                            text: message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

/** edit Region */
export const editRegion = (
    regionForm: IRegionForm,
    regionId: string,
): ThunkResult<void> => {
    setHeader('put', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('put', '/api/regions/' + regionId, regionForm)
                .then((res: any) => {
                    const { region, message } = res;
                    dispatch(updateRegion(region));
                    dispatch(
                        addMessage({
                            text: message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

/** Delete Region */
export const deleteRegion = (regionId: string): ThunkResult<void> => {
    setHeader('delete', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('delete', '/api/regions/' + regionId, null)
                .then((res: any) => {
                    const { message } = res;
                    dispatch(removeRegion(regionId));
                    dispatch(
                        addMessage({
                            text: message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};
