import { IUser } from '../../../../types';
import { SET_SELLERS, SellerActionTypes } from '../../types/sellersActionTypes';

const DEFAULT_STATE: IUser[] = [];

const sellerReducer = (state = DEFAULT_STATE, action: SellerActionTypes) => {
    switch (action.type) {
        case SET_SELLERS:
            return action.sellers;
        default:
            return state;
    }
};

export default sellerReducer;
