import { emptySignUpForm } from '../../../utils/resetUtils';
import {
    UserFormActionTypes,
    UPDATE_USER_FORM,
    RESET_USER_FORM,
} from '../../../types/admin/userFormActionTypes';
import { IUserFormState } from '../../../../../types';

const DEFAULT_STATE: IUserFormState = {
    userForm: emptySignUpForm(),
};

const userFormReducer = (
    state = DEFAULT_STATE,
    action: UserFormActionTypes,
) => {
    switch (action.type) {
        case UPDATE_USER_FORM:
            return Object.assign({}, state, {
                userForm: {
                    ...state.userForm,
                    [Object.keys(action.userFormState.userForm)[0]]:
                        Object.values(action.userFormState.userForm)[0],
                },
            });
        case RESET_USER_FORM:
            return {
                ...state,
                userForm: action.userFormState.userForm,
            };
        default:
            return state;
    }
};

export default userFormReducer;
