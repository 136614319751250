import React, { ComponentType, FC } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { IUser } from '../../../../types';
import { AppState } from '../../store/store';
import ActivateAccountInfo from './ActivateAccountInfo';

interface ILoggedInRouteProps {
    exact?: boolean;
    user?: IUser;
    isAuthenticated: boolean | null;
    path: string;
    Component: ComponentType<any>;
}

const LoggedInRoute: FC<ILoggedInRouteProps> = ({
    isAuthenticated,
    Component,
    user,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                if (isAuthenticated && !user?.userAccount.isActive) {
                    return <ActivateAccountInfo />;
                } else if (isAuthenticated) {
                    return <Component {...props} />;
                } else {
                    return (
                        <Redirect
                            to={{
                                pathname: '/',
                                state: {
                                    from: props.location,
                                },
                            }}
                        />
                    );
                }
            }}
        />
    );
};

const mapStateToProps = (state: AppState) => ({
    isAuthenticated: state.currentUser.isAuthenticated,
    user: state.currentUser.user,
});

export default connect(mapStateToProps)(LoggedInRoute);
