import { resetCheckoutFormFunction } from '../../utils/resetUtils';
import {
    UPDATE_CHECKOUT_FORM,
    RESET_CHECKOUT_FORM,
    CheckoutFormActionTypes,
} from '../../types/checkoutFormActionTypes';
import { ICheckoutFormState } from '../../../../types';

const DEFAULT_STATE: ICheckoutFormState = {
    checkoutForm: resetCheckoutFormFunction(),
};

const checkoutFormReducer = (
    state = DEFAULT_STATE,
    action: CheckoutFormActionTypes,
) => {
    switch (action.type) {
        case UPDATE_CHECKOUT_FORM:
            return Object.assign({}, state, {
                checkoutForm: {
                    ...state.checkoutForm,
                    [Object.keys(action.checkoutFormState.checkoutForm)[0]]:
                        Object.values(action.checkoutFormState.checkoutForm)[0],
                },
            });
        case RESET_CHECKOUT_FORM:
            return {
                ...state,
                checkoutForm: action.checkoutFormState.checkoutForm,
            };
        default:
            return state;
    }
};

export default checkoutFormReducer;
