import { apiCall, setHeader } from '../../apiRequests/apiCall';
import { AppActions } from '../../types/actions';
import { Dispatch } from 'redux';
import { addMessage } from './messageActions';
import {
    ADD_OTHER_USER_PRODUCT,
    DELETE_OTHER_USER_PRODUCT,
    EDIT_OTHER_USER_PRODUCT,
    SET_OTHER_USER_PRODUCTS,
} from '../../types/userOtherProductsActionTypes';
import { IProductForm, IProduct } from '../../../../types';

/** Public Method */
export function setOtherUserProducts(
    otherUserProducts: IProduct[],
): AppActions {
    return {
        type: SET_OTHER_USER_PRODUCTS,
        otherUserProducts,
    };
}

/** Restricted access to product owners only, for Users */
export function addOtherUserProduct(otherUserProduct: IProduct): AppActions {
    return {
        type: ADD_OTHER_USER_PRODUCT,
        otherUserProduct,
    };
}

export function editOtherUserProduct(otherUserProduct: IProduct): AppActions {
    return {
        type: EDIT_OTHER_USER_PRODUCT,
        otherUserProduct,
    };
}

export function deleteOtherUserProduct(id: string): AppActions {
    return {
        type: DELETE_OTHER_USER_PRODUCT,
        id,
    };
}
/** Public method */
export const fetchOtherUserProducts = (id: string): ThunkResult<void> => {
    setHeader('get', '');
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall(
                'get',
                '/api/users/' + id + '/products?productCategory=Muut tuotteet',
                null,
            )
                .then((res: any) => {
                    const { products } = res;
                    dispatch(setOtherUserProducts(products));
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

/** Restricted access to product owners only, for Users */
export const createOtherUserProduct = (
    formData: IProductForm,
): ThunkResult<void> => {
    setHeader('post', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('post', '/api/products', formData)
                .then((res: any) => {
                    const { product } = res;
                    dispatch(addOtherUserProduct(product));
                    dispatch(
                        addMessage({
                            text: res.message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

export const updateOtherUserProduct = (
    formData: IProductForm,
    productId: string,
): ThunkResult<void> => {
    setHeader('put', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('put', '/api/products/' + productId, formData)
                .then((res: any) => {
                    const { product, message } = res;
                    dispatch(editOtherUserProduct(product));
                    dispatch(
                        addMessage({
                            text: message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

/** Restricted access to product owners only, for Users */
export const deleteOtherUserProductCall = (id: string): ThunkResult<void> => {
    setHeader('delete', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('delete', '/api/products/' + id, {})
                .then((res: any) => {
                    const { message } = res;
                    dispatch(deleteOtherUserProduct(id));
                    dispatch(
                        addMessage({
                            text: message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};
