import React, { FC } from 'react';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    CardActions,
    Grid,
    List,
    ListItem,
    ListItemText,
    Typography,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store/store';
import { IProduct, IUser } from '../../../../../types';
import { getProductName, setPriceTag } from '../../../utils/utils';

const DetailedView: FC = () => {
    const users = useSelector((state: AppState) => state.users);
    return (
        <Grid container={true}>
            <Grid item={true} md={12}>
                <Box p={5}>
                    <Grid container={true}>
                        {users.map((user: IUser) => {
                            return (
                                <Grid item={true} key={user._id}>
                                    <Box p={3}>
                                        <Card
                                            variant="outlined"
                                            className="userCard"
                                        >
                                            <CardHeader
                                                title={
                                                    user.companyName ||
                                                    user.fullName
                                                }
                                                subheader={
                                                    user.business
                                                        ? 'Yritys'
                                                        : 'Yksityis henkilö'
                                                }
                                            />
                                            <CardContent>
                                                <Typography variant="body1">
                                                    Tiedot
                                                </Typography>
                                                <List>
                                                    <ListItem>
                                                        <ListItemText>
                                                            Email: {user.email}
                                                        </ListItemText>
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText>
                                                            Käyttäjänimi:{' '}
                                                            {user.username}
                                                        </ListItemText>
                                                    </ListItem>
                                                </List>
                                            </CardContent>
                                            <CardActions>
                                                <List>
                                                    {user.products.map(
                                                        (product: IProduct) => {
                                                            return (
                                                                <ListItem
                                                                    key={
                                                                        product._id
                                                                    }
                                                                >
                                                                    <ListItemText>
                                                                        {getProductName(
                                                                            product,
                                                                        )}
                                                                        ,{' '}
                                                                        {product.plength +
                                                                            ' ' +
                                                                            product.quality}{' '}
                                                                        -{' '}
                                                                        <strong>
                                                                            Hinta:{' '}
                                                                            {setPriceTag(
                                                                                product.price,
                                                                            )}
                                                                        </strong>
                                                                    </ListItemText>
                                                                </ListItem>
                                                            );
                                                        },
                                                    )}
                                                </List>
                                            </CardActions>
                                        </Card>
                                    </Box>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
};

export default DetailedView;
