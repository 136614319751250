import { ISidebarState } from '../../../../types';
import {
    SideBarActionTypes,
    UPDATE_SIDEBAR,
    RESET_SIDEBAR,
} from '../../types/sidebarActionTypes';

const DEFAULT_STATE: ISidebarState = {
    sidebarProps: {
        showOrders: false,
        showUserInfo: true,
        showAccountSettings: false,
        showWoodProducts: false,
        showOtherProducts: false,
    },
};

const sidebarReducer = (state = DEFAULT_STATE, action: SideBarActionTypes) => {
    switch (action.type) {
        case UPDATE_SIDEBAR:
            return Object.assign({}, state, {
                sidebarProps: {
                    ...state.sidebarProps,
                    [Object.keys(action.sidebarState.sidebarProps)[0]]:
                        Object.values(action.sidebarState.sidebarProps)[0],
                },
            });
        case RESET_SIDEBAR:
            const { sidebarProps } = action.sidebarState;
            return Object.assign({}, state, {
                ...state,
                sidebarProps,
            });
        default:
            return state;
    }
};

export default sidebarReducer;
