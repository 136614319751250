import { ILocalityFormState } from '../../../types';

export const UPDATE_LOCALITY_FORM = 'UPDATE_LOCALITY_FORM';
export const RESET_LOCALITY_FORM = 'RESET_LOCALITY_FORM';

export interface UpdateLocalityForm {
    type: typeof UPDATE_LOCALITY_FORM;
    localityFormState: ILocalityFormState;
}

export interface ResetLocalityForm {
    type: typeof RESET_LOCALITY_FORM;
    localityFormState: ILocalityFormState;
}

export type LocalityFormActionTypes = UpdateLocalityForm | ResetLocalityForm;
