import { IContactFormState } from '../../../../../tools/types';
import {
    ContactFormActionTypes,
    RESET_CONTACT_FORM,
    UPDATE_CONTACT_FORM,
} from '../../types/contactFormActionTypes';
import { resettedContactForm } from '../../utils/resetUtils';

const DEFAULT_STATE: IContactFormState = {
    contactForm: resettedContactForm(),
};

const contactFormReducer = (
    state = DEFAULT_STATE,
    action: ContactFormActionTypes,
) => {
    switch (action.type) {
        case UPDATE_CONTACT_FORM:
            return Object.assign({}, state, {
                contactForm: {
                    ...state.contactForm,
                    [Object.keys(action.contactFormState.contactForm)[0]]:
                        Object.values(action.contactFormState.contactForm)[0],
                },
            });
        case RESET_CONTACT_FORM:
            return {
                ...state,
                productForm: action.contactFormState.contactForm,
            };
        default:
            return state;
    }
};

export default contactFormReducer;
