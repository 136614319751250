import { IAdvertisement } from '../../../../types';
import {
    AdvertisementsActionTypes,
    SET_ADVERTISEMENTS,
    ADD_ADVERTISEMENT,
    UPDATE_ADVERTISEMENT,
    REMOVE_ADVERTISEMENT,
} from '../../types/advertisementActionTypes';

const DEFAULT_STATE: IAdvertisement[] = [];

const advertisementsReducer = (
    state = DEFAULT_STATE,
    action: AdvertisementsActionTypes,
) => {
    switch (action.type) {
        case SET_ADVERTISEMENTS:
            return action.advertisements;
        case ADD_ADVERTISEMENT:
            return [...state, action.advertisement];
        case UPDATE_ADVERTISEMENT:
            return state.map((advertisement: IAdvertisement) => {
                if (advertisement._id === action.advertisement._id) {
                    return {
                        ...advertisement,
                        ...action.advertisement,
                    };
                } else {
                    return advertisement;
                }
            });
        case REMOVE_ADVERTISEMENT:
            return state.filter(({ _id }) => _id !== action.id);
        default:
            return state;
    }
};

export default advertisementsReducer;
