import React, { Component, FormEvent } from 'react';
import {
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    InputBaseComponentProps,
} from '@material-ui/core';
import {
    withStyles,
    createStyles,
    WithStyles,
    Theme,
} from '@material-ui/core/styles';
import { AppState } from '../../store/store';
import { mapDispatchToProps } from '../../types/dispatchActionTypes';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { setSearchForm, setSearchInputValues } from '../../utils/utils';

import './SearchForm.scss';
import { RotateLeft, SearchRounded } from '@material-ui/icons';
import {
    ProductFormOptionLists,
    productSearchFormOptionLists,
    SetQueryKey,
} from '../../formOptionList';
import {
    ProductCategories,
    ICurrentUser,
    IGoogleMapsState,
    ISearchForm,
    ProductNames,
    IUser,
    ILocation,
    IRegion,
    Qualities,
} from '../../../../types';

interface SearchFormStateProps {
    currentUser: ICurrentUser;
    regions: IRegion[];
    searchForm: ISearchForm;
    localities: ILocation[];
    sellers: IUser[];
    googleMapsState: IGoogleMapsState;
}

type SearchFormComponentProps = SearchFormStateProps &
    IDispatchProps &
    RouteComponentProps &
    WithStyles<typeof styles>;

const styles = (theme: Theme) =>
    createStyles({
        form: {
            display: 'flex',
            flexDirection: 'column',
            padding: ' 1rem',
        },
        item: {
            paddingTop: '1rem',
        },
        searchBtn: {
            marginTop: '2rem',
        },
    });

class SearchForm extends Component<SearchFormComponentProps, AppState> {
    public findSellers = (event: FormEvent) => {
        event.preventDefault();
        const { fetchSellersAction, searchForm } = this.props;
        fetchSellersAction(setSearchForm(searchForm));
    };

    public handleChange = (event: InputBaseComponentProps) => {
        const {
            fetchLocalitiesAction,
            updateSearchFormAction,
            fetchSellersAction,
            updateGoogleMapsStateAction,
            searchForm,
        } = this.props;
        updateSearchFormAction(setSearchInputValues(event));
        if (event.target.name === 'region') {
            fetchLocalitiesAction(event.target.value);
        }
        if (event.target.name === 'locality') {
            console.info({
                region: searchForm.region,
                locality: event.target.value,
                name: searchForm.name,
                category: searchForm.category,
                deliveryMethod: searchForm.deliveryMethod,
                sellerId: searchForm.sellerId,
                material:
                    searchForm.category === 'Polttopuut' && searchForm.material
                        ? searchForm.material
                        : searchForm.category === 'Polttopuut'
                        ? 'all'
                        : '',
                quality:
                    searchForm.category === 'Polttopuut' && searchForm.quality
                        ? searchForm.quality
                        : searchForm.category === 'Polttopuut'
                        ? 'all'
                        : '',
            });
            fetchSellersAction({
                region: searchForm.region,
                locality: event.target.value,
                name: searchForm.name,
                category: searchForm.category,
                deliveryMethod: searchForm.deliveryMethod,
                sellerId: searchForm.sellerId,
                material:
                    searchForm.category === 'Polttopuut' && searchForm.material
                        ? searchForm.material
                        : searchForm.category === 'Polttopuut'
                        ? 'all'
                        : '',
                quality:
                    searchForm.category === 'Polttopuut' && searchForm.quality
                        ? searchForm.quality
                        : searchForm.category === 'Polttopuut'
                        ? 'all'
                        : '',
            });
        }
        if (
            event.target.name === 'region' ||
            event.target.name === 'locality'
        ) {
            updateGoogleMapsStateAction({
                lat: Number(event.currentTarget.dataset.lat),
                lng: Number(event.currentTarget.dataset.lng),
                zoom: Number(event.currentTarget.dataset.zoom),
            });
        }
    };

    public render() {
        const { regions, localities, classes, resetSearchFormAction } =
            this.props;
        const { productCategories, deliveryMethods, names } =
            productSearchFormOptionLists;
        const { qualities, materials } = ProductFormOptionLists;
        const {
            category,
            name,
            region,
            locality,
            sellerId,
            deliveryMethod,
            quality,
            material,
        } = this.props.searchForm;
        let key = SetQueryKey(category as ProductCategories);
        return (
            <form onSubmit={this.findSellers} className={classes.form}>
                <Grid container={true}>
                    <Grid item={true} className={classes.item}>
                        <FormControl variant="filled" fullWidth={true}>
                            <InputLabel id="demo-simple-select-required-label">
                                Kategoria
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-required-label"
                                id="demo-simple-select-required"
                                name="category"
                                value={category}
                                onChange={this.handleChange}
                            >
                                <MenuItem value={category}>
                                    <em>Tuotetyyppi</em>
                                </MenuItem>
                                {productCategories.length &&
                                    productCategories.map(
                                        (
                                            category2:
                                                | ProductCategories
                                                | string,
                                        ) => {
                                            return (
                                                <MenuItem
                                                    key={category2}
                                                    value={category2}
                                                >
                                                    {category2}
                                                </MenuItem>
                                            );
                                        },
                                    )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item={true} className={classes.item}>
                        <FormControl variant="filled" fullWidth={true}>
                            <InputLabel id="demo-simple-select-required-label">
                                Tuote
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-required-label"
                                id="demo-simple-select-required"
                                name="name"
                                value={name}
                                onChange={this.handleChange}
                            >
                                <MenuItem value={name}>
                                    <em>Tuotekategoria</em>
                                </MenuItem>
                                {names[key].map(
                                    (name: string | ProductNames) => {
                                        return (
                                            <MenuItem key={name} value={name}>
                                                {name}
                                            </MenuItem>
                                        );
                                    },
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    {category === 'Polttopuut' && (
                        <>
                            <Grid item={true} className={classes.item}>
                                <FormControl variant="filled" fullWidth={true}>
                                    <InputLabel id="demo-simple-select-required-label">
                                        Laatu
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-required-label"
                                        id="demo-simple-select-required"
                                        name="quality"
                                        value={quality}
                                        onChange={this.handleChange}
                                    >
                                        <MenuItem value={quality}>
                                            <em>Laatu</em>
                                        </MenuItem>
                                        {qualities.map(
                                            (name: string | Qualities) => {
                                                return (
                                                    <MenuItem
                                                        key={name}
                                                        value={name}
                                                    >
                                                        {name}
                                                    </MenuItem>
                                                );
                                            },
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item={true} className={classes.item}>
                                <FormControl variant="filled" fullWidth={true}>
                                    <InputLabel id="demo-simple-select-required-label">
                                        Materiaali
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-required-label"
                                        id="demo-simple-select-required"
                                        name="material"
                                        value={material}
                                        onChange={this.handleChange}
                                    >
                                        <MenuItem value={material}>
                                            <em>Materiaali</em>
                                        </MenuItem>
                                        {materials.map(
                                            (name: string | Qualities) => {
                                                return (
                                                    <MenuItem
                                                        key={name}
                                                        value={name}
                                                    >
                                                        {name}
                                                    </MenuItem>
                                                );
                                            },
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </>
                    )}
                    <Grid item={true} className={classes.item}>
                        <FormControl variant="filled" fullWidth={true}>
                            <InputLabel id="demo-simple-select-required-label">
                                Maakunta
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-required-label"
                                id="demo-simple-select-required"
                                name="region"
                                value={region}
                                onChange={this.handleChange}
                            >
                                <MenuItem value={region}>
                                    <em>Maakunta</em>
                                </MenuItem>
                                {!!regions.length &&
                                    regions.map((region: IRegion) => {
                                        return (
                                            <MenuItem
                                                key={region._id}
                                                data-lat={region.coords.lat}
                                                data-lng={region.coords.lng}
                                                data-zoom={region.zoom}
                                                value={region._id}
                                            >
                                                {region.name}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item={true} className={classes.item}>
                        <FormControl variant="filled" fullWidth={true}>
                            <InputLabel id="demo-simple-select-required-label">
                                Paikkakunta
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-required-label"
                                id="demo-simple-select-required"
                                name="locality"
                                value={locality}
                                onChange={this.handleChange}
                            >
                                <MenuItem value={locality}>
                                    <em>Paikkakunta</em>
                                </MenuItem>
                                {!!localities.length &&
                                    localities.map((locality: ILocation) => {
                                        return (
                                            <MenuItem
                                                key={locality._id}
                                                data-lat={locality.coords.lat}
                                                data-lng={locality.coords.lng}
                                                data-zoom={locality.zoom}
                                                value={locality._id}
                                            >
                                                {locality.location}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item={true} className={classes.item}>
                        <FormControl variant="filled" fullWidth={true}>
                            <InputLabel id="demo-simple-select-required-label">
                                Toimitustavat
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-required-label"
                                id="demo-simple-select-required"
                                name="deliveryMethod"
                                value={deliveryMethod}
                                onChange={this.handleChange}
                            >
                                <MenuItem value={sellerId}>
                                    <em>Toimitustapa</em>
                                </MenuItem>
                                {!!deliveryMethods.length &&
                                    deliveryMethods.map(
                                        (deliveryMethod2: string) => {
                                            return (
                                                <MenuItem
                                                    key={deliveryMethod2}
                                                    value={deliveryMethod2}
                                                >
                                                    {deliveryMethod2}
                                                </MenuItem>
                                            );
                                        },
                                    )}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                <div className={classes.searchBtn}>
                    <Button
                        startIcon={<SearchRounded />}
                        type="submit"
                        fullWidth={true}
                        variant="outlined"
                        color="primary"
                        size="large"
                    >
                        Hae
                    </Button>
                </div>
                <div className={classes.searchBtn}>
                    <Button
                        startIcon={<RotateLeft />}
                        type="reset"
                        fullWidth={true}
                        aria-label="nollaa haku asetukset"
                        variant="text"
                        color="secondary"
                        size="large"
                        onClick={resetSearchFormAction}
                    >
                        Uusi haku
                    </Button>
                </div>
            </form>
        );
    }
}

function mapStateToProps(state: AppState): SearchFormStateProps {
    return {
        currentUser: state.currentUser,
        searchForm: state.searchFormState.searchForm,
        regions: state.regions,
        localities: state.localities,
        sellers: state.sellers,
        googleMapsState: state.googleMapsState,
    };
}

export default withRouter(
    connect<SearchFormStateProps, IDispatchProps, {}, AppState>(
        mapStateToProps,
        mapDispatchToProps,
    )(withStyles(styles)(SearchForm)),
);
