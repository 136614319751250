import React, { FC } from 'react';

import './AdStripe.scss';
import { Grid, Box, Typography } from '@material-ui/core';
import { Color } from '../../../../types';

interface IAdStripeProps {
    bgColor: string;
    title: string;
    content: string;
    color: Color;
}

const AdStripe: FC<IAdStripeProps> = ({ bgColor, title, content, color }) => {
    return (
        <Grid container={true}>
            <Grid item={true} xs={12} className={bgColor}>
                <Box py={3} px={2}>
                    <Typography
                        component="h4"
                        variant="h4"
                        align="center"
                        color={color}
                        gutterBottom={true}
                    >
                        {title}
                    </Typography>
                    <Typography
                        variant="h6"
                        align="center"
                        color={color}
                        component="p"
                    >
                        {content}
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    );
};

export default AdStripe;
