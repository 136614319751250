import { IProductFormState } from '../../../types';

export const UPDATE_PRODUCT_FORM = 'UPDATE_PRODUCT_FORM';
export const RESET_PRODUCT_FORM = 'RESET_PRODUCT_FORM';

export interface UpdateProductForm {
    type: typeof UPDATE_PRODUCT_FORM;
    productFormState: IProductFormState;
}

export interface ResetProductForm {
    type: typeof RESET_PRODUCT_FORM;
    productFormState: IProductFormState;
}

export type ProductFormActionTypes = UpdateProductForm | ResetProductForm;
