import { ISearchFormState } from '../../../../types';
import {
    UPDATE_SEARCH_FORM,
    SearchFormActionTypes,
    RESET_SEARCH_FORM,
} from '../../types/searchFormActionTypes';
import { resetSearchFormFunction } from '../../utils/resetUtils';

const DEFAULT_STATE: ISearchFormState = {
    searchForm: resetSearchFormFunction(),
};

const searchFormReducer = (
    state = DEFAULT_STATE,
    action: SearchFormActionTypes,
) => {
    switch (action.type) {
        case UPDATE_SEARCH_FORM:
            return Object.assign({}, state, {
                searchForm: {
                    ...state.searchForm,
                    [Object.keys(action.searchFormState.searchForm)[0]]:
                        Object.values(action.searchFormState.searchForm)[0],
                },
            });
        case RESET_SEARCH_FORM:
            return {
                ...state,
                searchForm: action.searchFormState.searchForm,
            };
        default:
            return state;
    }
};

export default searchFormReducer;
