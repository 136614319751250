import React, { FC } from 'react';

interface ICardFooterProps {
    className: string;
    plain?: boolean;
    profile?: boolean;
    stats?: boolean;
    chart?: boolean;
    children: any;
}

const CardFooter: FC<ICardFooterProps> = (props) => {
    const { className, children, plain, profile, stats, chart, ...rest } =
        props;
    return (
        <div className={className} {...rest}>
            {children}
        </div>
    );
};

export default CardFooter;
