import { ICheckoutFormState } from '../../../types';

export const UPDATE_CHECKOUT_FORM = 'UPDATE_CHECKOUT_FORM';
export const RESET_CHECKOUT_FORM = 'RESET_CHECKOUT_FORM';

export interface UpdateCheckoutForm {
    type: typeof UPDATE_CHECKOUT_FORM;
    checkoutFormState: ICheckoutFormState;
}

export interface ResetCheckoutFormState {
    type: typeof RESET_CHECKOUT_FORM;
    checkoutFormState: ICheckoutFormState;
}

export type CheckoutFormActionTypes =
    | UpdateCheckoutForm
    | ResetCheckoutFormState;
