import { IAdminPanelUiState } from '../../../../../types';
import {
    AdminPanelUiStateActionTypes,
    UPDATE_ADMIN_PANEL_UISTATE,
    RESET_ADMIN_PANEL_UISTATE,
} from '../../../types/admin/adminPanelUiStateActionTypes';

const DEFAULT_STATE: IAdminPanelUiState = {
    adminPanelUiProps: {
        showUsers: false,
        showOrders: false,
        showOrder: false,
        userId: '',
        createUser: false,
        showRegionsAndLocalities: false,
        showMoreDetails: false,
        modalOpen: false,
        fetchData: true,
        showAdminPanel: true,
    },
};

const uiReducer = (
    state = DEFAULT_STATE,
    action: AdminPanelUiStateActionTypes,
) => {
    switch (action.type) {
        case UPDATE_ADMIN_PANEL_UISTATE:
            return Object.assign({}, state, {
                adminPanelUiProps: {
                    ...state.adminPanelUiProps,
                    [Object.keys(
                        action.adminPanelUiState.adminPanelUiProps,
                    )[0]]: Object.values(
                        action.adminPanelUiState.adminPanelUiProps,
                    )[0],
                },
            });
        case RESET_ADMIN_PANEL_UISTATE:
            const { adminPanelUiProps } = action.adminPanelUiState;
            return Object.assign({}, state, {
                ...state,
                adminPanelUiProps,
            });

        default:
            return state;
    }
};

export default uiReducer;
