import { IDeliveryCostFormState } from '../../../../types';
import {
    DeliveryCostFormActionTypes,
    RESET_DELIVERYCOST_FORM,
    UPDATE_DELIVERYCOST_FORM,
} from '../../types/deliveryCostFormActionTypes';
import { resetDeliveryCostFormFunction } from '../../utils/resetUtils';

const DEFAULT_STATE: IDeliveryCostFormState = {
    deliveryCostForm: resetDeliveryCostFormFunction(),
};

const deliveryCostFormReducer = (
    state = DEFAULT_STATE,
    action: DeliveryCostFormActionTypes,
) => {
    switch (action.type) {
        case UPDATE_DELIVERYCOST_FORM:
            return Object.assign({}, state, {
                deliveryCostForm: {
                    ...state.deliveryCostForm,
                    [Object.keys(
                        action.deliveryCostFormState.deliveryCostForm,
                    )[0]]: Object.values(
                        action.deliveryCostFormState.deliveryCostForm,
                    )[0],
                },
            });
        case RESET_DELIVERYCOST_FORM:
            return {
                ...state,
                deliveryCostForm: action.deliveryCostFormState.deliveryCostForm,
            };
        default:
            return state;
    }
};

export default deliveryCostFormReducer;
