import { ILocalityFormState } from '../../../../types';
import {
    LocalityFormActionTypes,
    RESET_LOCALITY_FORM,
    UPDATE_LOCALITY_FORM,
} from '../../types/localityFormActionTypes';
import { resetLocalityFormFunction } from '../../utils/resetUtils';

const DEFAULT_STATE: ILocalityFormState = {
    localityForm: resetLocalityFormFunction(),
};

const localityFormReducer = (
    state = DEFAULT_STATE,
    action: LocalityFormActionTypes,
) => {
    switch (action.type) {
        case UPDATE_LOCALITY_FORM:
            return Object.assign({}, state, {
                localityForm: {
                    ...state.localityForm,
                    [Object.keys(action.localityFormState.localityForm)[0]]:
                        Object.values(action.localityFormState.localityForm)[0],
                },
            });
        case RESET_LOCALITY_FORM:
            return {
                ...state,
                localityForm: action.localityFormState.localityForm,
            };
        default:
            return state;
    }
};

export default localityFormReducer;
