import { IGoogleMapsState } from '../../../../types';
import {
    UPDATE_GOOGLE_MAPS_POSITION,
    GoogleMapsStateActionTypes,
} from '../../types/googleMapsUiStateActionTypes';

const DEFAULT_STATE: IGoogleMapsState = {
    googleMapsProps: {
        zoom: 6,
        lat: 64.948931,
        lng: 26.985438,
    },
};

const googleMapsUiStateReducer = (
    state = DEFAULT_STATE,
    action: GoogleMapsStateActionTypes,
) => {
    switch (action.type) {
        case UPDATE_GOOGLE_MAPS_POSITION:
            return {
                ...state,
                googleMapsProps: action.googleMapsState.googleMapsProps,
            };
        default:
            return state;
    }
};

export default googleMapsUiStateReducer;
