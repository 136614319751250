import React, { Fragment, FC, useState, useEffect } from 'react';
import './Cart.scss';
import {
    Grid,
    TableContainer,
    Paper,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    FormControlLabel,
    Checkbox,
    Table,
    TableFooter,
    Button,
    Typography,
    TextField,
    InputBaseComponentProps,
} from '@material-ui/core';
import { RemoveShoppingCart, Edit, Close } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import ContactInfoForm from './ContactInfoForm';
import { setPriceTag } from '../../utils/utils';
import { mapDispatchToProps } from '../../types/dispatchActionTypes';
import { AppState } from '../../store/store';
import { connect } from 'react-redux';
import { ICart, ICartItem } from '../../../../types';
import { validateCartItemsQuantity } from '../../utils/formInputValidationUtil';

interface IOwnProps {
    activeStep: number | undefined;
    removeItemHandler: (id: string) => void;
    changeactiveStep: (activeStep: number) => void;
    submitOrder: (activeStep: number) => void;
    submitAddress: (activeStep: number) => void;
}

interface ICartTableStateProps {
    cart: ICart;
}

type CartTableProps = ICartTableStateProps & IOwnProps & IDispatchProps;

const CartTable: FC<CartTableProps> = (props: CartTableProps) => {
    const { cart, removeItemHandler, changeactiveStep, addMessageAction } =
        props;
    let { activeStep } = props;
    const [editQuantity, updateEditQuantity] = useState<boolean>(false);
    const [quantity, setQuantity] = useState<number | string>(0);
    const submitHandler = (event: any, item: ICartItem) => {
        event.preventDefault();
        const { editCartAction, cart } = props;
        const cartItem = cart.items.find(
            (itemInFind: ICartItem) => itemInFind._id === item._id,
        ) as ICartItem;
        if ((quantity as number) > cartItem.totalQuantity) {
            addMessageAction({
                text: 'Tuotteen määrää on ei voitu muuttaa, koska sen maksimi määrä on ylitetty. Myyjä o mahdollisesti jättänyt määrän yhteen kuutioon, ja tarkan saatavilla olevan kuutio määrän voi tiedustella myyjältä.',
                bgColor: 'warn',
                visible: true,
            });
            updateEditQuantity(false);
            setQuantity(0);
        } else {
            cartItem.quantity = Number(
                validateCartItemsQuantity(
                    quantity as number,
                    item.totalQuantity,
                ),
            );
            editCartAction(cartItem);
            updateEditQuantity(false);
            setQuantity(0);
        }
    };
    const changeHandler = (event: InputBaseComponentProps) => {
        event.preventDefault();
        setQuantity(event.target.value);
    };
    const addDeliveryCostToCart = (ownerId: string) => {
        const { addDeliveryCostAction } = props;
        addDeliveryCostAction(ownerId);
    };
    useEffect(() => {
        if (
            activeStep === 3 &&
            cart.items &&
            cart.items[0].deliveryMethod === 'Kuljetus' &&
            !cart.deliveryCost
        ) {
            const { addDeliveryCostAction } = props;
            addDeliveryCostAction(cart.items[0].ownerId);
        }
    }, [cart, props, activeStep]);
    return (
        <TableContainer component={Paper}>
            <Table
                aria-label="spanning table"
                style={{
                    display: 'table',
                    tableLayout: 'fixed',
                    width: '100%',
                }}
            >
                <TableHead>
                    <TableRow className="bg-yellow">
                        <TableCell className="th" align="center" colSpan={3}>
                            Tiedot
                        </TableCell>
                        <TableCell className="th">Hinta</TableCell>
                    </TableRow>
                    <TableRow className="bg-yellow th">
                        <TableCell className="th">Tiedot</TableCell>
                        <TableCell align="right" className="th">
                            Määrä
                        </TableCell>
                        <TableCell align="right" className="th">
                            Yksikkö ja Toimitustapa
                        </TableCell>
                        <TableCell align="right" className="th">
                            Summa
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {cart.items.length &&
                        cart.items.map((item) => {
                            return (
                                <TableRow key={item._id}>
                                    {activeStep === 0 && (
                                        <TableCell>
                                            <IconButton
                                                onClick={() =>
                                                    removeItemHandler(item._id)
                                                }
                                            >
                                                <RemoveShoppingCart />
                                                poista
                                            </IconButton>
                                            {item.name}
                                            {' - '}
                                            {item.quality} {item.material}{' '}
                                            {item.plength}
                                        </TableCell>
                                    )}
                                    {activeStep !== 0 && (
                                        <TableCell>
                                            {item.name}
                                            {' - '}
                                            {item.quality} {item.material}{' '}
                                            {item.plength}
                                        </TableCell>
                                    )}
                                    <TableCell align="right">
                                        {editQuantity ? (
                                            <Grid
                                                container={true}
                                                justifyContent="center"
                                                alignContent="center"
                                            >
                                                <form>
                                                    <TextField
                                                        label={item.unit}
                                                        type="number"
                                                        name="quantity"
                                                        value={
                                                            quantity === ''
                                                                ? quantity
                                                                : Number(
                                                                      quantity,
                                                                  ) < 1
                                                                ? item.quantity
                                                                : quantity
                                                        }
                                                        inputMode="numeric"
                                                        onChange={(
                                                            event: InputBaseComponentProps,
                                                        ) =>
                                                            changeHandler(event)
                                                        }
                                                    />
                                                    <IconButton
                                                        onClick={(event) =>
                                                            submitHandler(
                                                                event,
                                                                item,
                                                            )
                                                        }
                                                    >
                                                        <Close />
                                                        Vahvista
                                                    </IconButton>
                                                </form>
                                            </Grid>
                                        ) : (
                                            <Grid
                                                container={true}
                                                justifyContent="center"
                                                alignContent="center"
                                            >
                                                <p>
                                                    <strong>
                                                        {item.quantity}
                                                    </strong>
                                                </p>
                                                {activeStep === 0 && (
                                                    <IconButton
                                                        onClick={() =>
                                                            updateEditQuantity(
                                                                true,
                                                            )
                                                        }
                                                    >
                                                        <Edit />
                                                    </IconButton>
                                                )}
                                            </Grid>
                                        )}
                                    </TableCell>
                                    <TableCell align="right">
                                        {item.unit + ' | ' + item.deliveryType}
                                    </TableCell>
                                    <TableCell align="right">
                                        {setPriceTag(
                                            item.unitPrice * item.quantity,
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    {cart.delivery &&
                        cart.deliveryCost &&
                        cart.items.length /* &&
                        // /^Noutopiste ja kuljetus|Kuljetus$/.test(
                        //     cart.items[0].deliveryMethod,
                         ) */ && (
                            <TableRow>
                                <TableCell>
                                    {cart.deliveryCost?.name
                                        ? `Toimituskulu ${cart.deliveryCost?.name}`
                                        : 'Toimituksesta ja sen hinnasta tulee sopia myyjän kanssa.'}
                                </TableCell>
                                <TableCell>
                                    {cart?.deliveryCost?.distance
                                        ? cart.deliveryCost.distance
                                        : cart.deliveryCost?.quantity}
                                </TableCell>
                                <TableCell align="right">
                                    {cart?.deliveryCost?.distance
                                        ? setPriceTag(
                                              cart.deliveryCost
                                                  .originalPrice as number,
                                          ) + '/km'
                                        : cart?.deliveryCost?.unit}
                                </TableCell>
                                <TableCell align="right">
                                    {setPriceTag(
                                        cart.deliveryCost?.price as number,
                                    )}
                                </TableCell>
                            </TableRow>
                        )}
                    <TableRow>
                        <TableCell rowSpan={3}>
                            <Grid item={true} xs={12}>
                                {activeStep === 2 && (
                                    <>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        cart.items[0]
                                                            .deliveryMethod ===
                                                        'Kuljetus'
                                                            ? true
                                                            : cart.delivery
                                                    }
                                                    readOnly={
                                                        cart.items[0]
                                                            .deliveryMethod ===
                                                        'Kuljetus'
                                                            ? true
                                                            : false
                                                    }
                                                    name="delivery"
                                                    id="delivery"
                                                    onChange={() =>
                                                        addDeliveryCostToCart(
                                                            cart.items[0]
                                                                .ownerId,
                                                        )
                                                    }
                                                    value={cart.delivery}
                                                />
                                            }
                                            label={
                                                <label htmlFor="delivery">
                                                    Haluan toimituksen
                                                </label>
                                            }
                                        />
                                        {cart.delivery &&
                                            !cart.deliveryCost?.name && (
                                                <Typography
                                                    variant="body1"
                                                    color="secondary"
                                                >
                                                    Toimituksesta ja sen
                                                    hinnasta tulee sopia myyjän
                                                    kanssa.
                                                </Typography>
                                            )}
                                    </>
                                )}
                            </Grid>
                        </TableCell>
                        <TableCell rowSpan={3} />
                        <TableCell colSpan={1}>Veroton hinta</TableCell>
                        <TableCell align="right">
                            {cart.totalPriceExcludingTax.toFixed(2)} €
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="right">{`${(0.24 * 100).toFixed(
                            0,
                        )} %`}</TableCell>
                        <TableCell align="right">
                            {cart.tax.toFixed(2)} €
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={1}>Yhteensä</TableCell>
                        <TableCell align="right">
                            {setPriceTag(cart.totalPrice)}
                        </TableCell>
                    </TableRow>
                    {activeStep === 1 && (
                        <Fragment>
                            <TableCell colSpan={4}>
                                <ContactInfoForm />
                            </TableCell>
                        </Fragment>
                    )}
                </TableBody>
                <TableFooter>
                    {activeStep === 0 && (
                        <TableRow>
                            <TableCell align="left">
                                <Link to="/haku">
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                    >
                                        Takaisin
                                    </Button>
                                </Link>
                            </TableCell>
                            <TableCell colSpan={2} />
                            <TableCell align="right">
                                <Button
                                    disabled={
                                        cart.items.length === 0 ? true : false
                                    }
                                    variant="outlined"
                                    color="primary"
                                    onClick={() =>
                                        changeactiveStep((activeStep = 1))
                                    }
                                >
                                    Jatka
                                </Button>
                            </TableCell>
                        </TableRow>
                    )}
                    {activeStep === 1 && (
                        <TableRow>
                            <TableCell align="left">
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() =>
                                        changeactiveStep((activeStep = 0))
                                    }
                                >
                                    Ostoskori
                                </Button>
                            </TableCell>
                            <TableCell colSpan={2} />
                            <TableCell align="right">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() =>
                                        props.submitAddress((activeStep = 2))
                                    }
                                >
                                    Jatka
                                </Button>
                            </TableCell>
                        </TableRow>
                    )}
                    {activeStep === 2 && (
                        <TableRow>
                            <TableCell align="left">
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() =>
                                        changeactiveStep((activeStep = 1))
                                    }
                                >
                                    Yhteystiedot
                                </Button>
                            </TableCell>
                            <TableCell colSpan={2} />
                            <TableCell align="right">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() =>
                                        props?.submitOrder((activeStep = 3))
                                    }
                                >
                                    Vahvista
                                </Button>
                            </TableCell>
                        </TableRow>
                    )}
                </TableFooter>
            </Table>
        </TableContainer>
    );
};

function mapStateToProps(state: AppState): ICartTableStateProps {
    return {
        cart: state.cartState,
    };
}

export default connect<
    ICartTableStateProps,
    IDispatchProps,
    IOwnProps,
    AppState
>(
    mapStateToProps,
    mapDispatchToProps,
)(CartTable);
