import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
    Divider,
    Drawer,
    Hidden,
    IconButton,
    Button,
    Box,
} from '@material-ui/core';
import {
    Menu as MenuIcon,
    Close as CloseIcon,
    Favorite,
    ListAltSharp,
    LocalShipping,
} from '@material-ui/icons';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import {
    makeStyles,
    useTheme,
    Theme,
    createStyles,
} from '@material-ui/core/styles';
import { mapDispatchToProps } from '../../types/dispatchActionTypes';
import { AppState } from '../../store/store';
import SearchForm from '../SearchForm/SearchForm';
import { setUiPropsValues } from '../../utils/utils';
import {
    ICurrentUser,
    ISidebarProps,
    IUiProps,
    IRegion,
    ILocation,
    IProduct,
} from '../../../../types';

interface SideBarStateProps {
    localities: ILocation[];
    currentUser: ICurrentUser;
    uiProps: IUiProps;
    regions: IRegion[];
    sideBarProps: ISidebarProps;
    favorites: IProduct[];
}

interface Props {
    window?: () => Window;
    width?: any;
}

type SideBarProps = IDispatchProps &
    SideBarStateProps &
    RouteComponentProps &
    Props;

const drawerWidth = 270;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        drawer: {
            // backgroundColor: '', specify a color to be used for sidebar background color (desktop sidebar)
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                paddingTop: '4rem',
                flexShrink: 0,
            },
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        menuButton: {
            position: 'fixed',
            zIndex: 1299,
            top: '9rem',
            left: '3rem',
            color: '#fff',
            background: '#FE7F46',
            boxShadow: '0 1px 4px rgba(0,0,0,.3)',
            '&:hover': {
                background: '#fe7434 !important',
            },
            '& span svg': {
                fontSize: '2.5rem',
            },
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
            [theme.breakpoints.down(959)]: {},
            [theme.breakpoints.down(900)]: {
                top: '10.5rem',
            },
            [theme.breakpoints.down(600)]: {
                top: '9.5rem',
            },
        },
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        drawerPaper: {
            // backgroundColor: '', specify a color to be used for sidebar background color (mobile sidebar)
            paddingTop: '1rem',
            width: drawerWidth,
        },
        sidebarTitle: {
            display: 'flex',
            flexDirection: 'column',
            padding: '1rem 1.5rem',
            alignItems: 'center',
            justifyContent: 'spaceBetween',
            '& svg': {
                fontSize: '3.5rem',
                marginRight: '.5rem',
                color: '#767676',
            },
            '& svg.heart': {
                color: '#f50057',
            },
            '& p': {
                fontWeight: 'bold',
            },
        },
        closeIcon: {
            position: 'absolute',
            right: '1rem',
        },
    }),
);

const SideBar = (props: SideBarProps) => {
    const { window, favorites, uiProps, updateUiStateAction } = props;
    const { showFavoriteList } = uiProps;

    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        if (isWidthDown('sm', props.width)) {
            setMobileOpen(!mobileOpen);
        }
    };

    const getAllProducts = () => {
        const { fetchSellersAction } = props;
        fetchSellersAction({
            region: '',
            locality: '',
            deliveryMethod: '',
            sellerId: '',
            category: '',
            name: '',
            quality: 'all',
            material: 'all',
        });
    };

    const drawer = (
        <React.Fragment>
            <Hidden smDown={true} implementation="css">
                <div className={classes.toolbar} />
            </Hidden>
            <div className={classes.sidebarTitle}>
                {!showFavoriteList ? (
                    <Button
                        variant="outlined"
                        color="default"
                        size="large"
                        startIcon={
                            <Favorite className="heart" color="secondary" />
                        }
                        onClick={() =>
                            updateUiStateAction(
                                setUiPropsValues('showFavoriteList', true),
                            )
                        }
                    >
                        Suosikit{' '}
                        {'(' + favorites.length ? favorites.length : 0 + ')'}{' '}
                        kpl
                    </Button>
                ) : (
                    <Button
                        variant="outlined"
                        color="default"
                        size="large"
                        startIcon={<ListAltSharp />}
                        onClick={() =>
                            updateUiStateAction(
                                setUiPropsValues('showFavoriteList', false),
                            )
                        }
                    >
                        Tuotelista
                    </Button>
                )}
                <Box mt={1} />
                <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    startIcon={<LocalShipping />}
                    onClick={getAllProducts}
                >
                    {document.location.pathname === '/haku'
                        ? 'Kaikki myyjät'
                        : 'Kaikki tuotteet'}
                </Button>
                {isWidthDown('sm', props.width) && (
                    <IconButton
                        color="primary"
                        size="small"
                        onClick={handleDrawerToggle}
                        className={classes.closeIcon}
                    >
                        <CloseIcon />
                    </IconButton>
                )}
            </div>
            <Divider />
            <SearchForm />
        </React.Fragment>
    );

    const container =
        window !== undefined ? () => window().document.body : undefined;

    return (
        <div className={classes.root}>
            {isWidthDown('sm', props.width) && !mobileOpen && (
                <IconButton
                    color="primary"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    className={classes.menuButton}
                >
                    <MenuIcon />
                </IconButton>
            )}

            <nav className={classes.drawer} aria-label="mailbox folders">
                <Hidden smUp={true} implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden smDown={true} implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open={true}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
        </div>
    );
};

function mapStateToProps(state: AppState): SideBarStateProps {
    return {
        localities: state.localities,
        regions: state.regions,
        currentUser: state.currentUser,
        uiProps: state.uiState.uiProps,
        sideBarProps: state.sidebarState.sidebarProps,
        favorites: state.favorites,
    };
}

export default withRouter(
    connect<SideBarStateProps, IDispatchProps, {}, AppState>(
        mapStateToProps,
        mapDispatchToProps,
    )(withWidth()(SideBar)),
);
