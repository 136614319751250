import React, { FC, Fragment } from 'react';
import './Loading.scss';
import { Typography, Box } from '@material-ui/core';

interface LoadingProps {
    text: string;
}

const Loading: FC<LoadingProps> = (props: LoadingProps) => {
    const { text } = props;
    return (
        <Fragment>
            <div className="loading-container">
                <div className="cs-loader">
                    <div className="cs-loader-inner">
                        <label>●</label>
                        <label>●</label>
                        <label>●</label>
                        <label>●</label>
                        <label>●</label>
                        <label>●</label>
                        <Box py={3}>
                            <Typography variant="h5">{text}</Typography>
                        </Box>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Loading;
