import { AppActions } from '../../types/actions';
import { SET_CART, RESET_CART } from '../../types/cartActionTypes';
import { apiCall } from '../../apiRequests/apiCall';
import { Dispatch } from 'redux';
import { addMessage } from './messageActions';
import { ICart, ICartItem, ICheckoutForm } from '../../../../types';

export function setCart(cartState: ICart): AppActions {
    return {
        type: SET_CART,
        cartState,
    };
}

export function resetCart(cartState: ICart): AppActions {
    return {
        type: RESET_CART,
        cartState,
    };
}

export const fetchCart = (): ThunkResult<void> => {
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('get', '/api/cart', null)
                .then((res: any) => {
                    const { cart } = res;
                    dispatch(setCart(cart));
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'warning',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};
// tslint:disable-next-line
export const addToCart = (_id: string): ThunkResult<void> => {
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            // tslint:disable-next-line
            return apiCall('post', '/api/cart', { product_id: _id })
                .then((res: any) => {
                    const { cart } = res;
                    dispatch(setCart(cart));
                    dispatch(
                        addMessage({
                            text: 'Tuote onnistuneesti lisätty ostoskoriin!',
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};
export const addAddress = (checkoutForm: ICheckoutForm): ThunkResult<void> => {
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('post', '/api/cart/addaddress', {
                checkoutForm,
            })
                .then((res: any) => {
                    dispatch(setCart(res.cart));
                    dispatch(
                        addMessage({
                            text: res.message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error) => {
                    dispatch(
                        addMessage({
                            text: 'Ups, jotain meni pieleen tietoja lisättäessä.',
                            bgColor: 'warning',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};
// Add deliveryCost to cart
export const addDeliveryCost = (ownerId: string): ThunkResult<void> => {
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('post', '/api/cart/adddeliverycost', {
                ownerId,
            })
                .then((res: any) => {
                    dispatch(setCart(res.cart));
                    dispatch(
                        addMessage({
                            text:
                                res.message ||
                                'Toimituskulutietoja on päivitetty onnistuneesti ostoskoriin.',
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};
// tslint:disable-next-line
export const editCart = (cartItem: ICartItem): ThunkResult<void> => {
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            // tslint:disable-next-line
            return apiCall('put', '/api/cart', cartItem)
                .then((res: any) => {
                    const { cart } = res;
                    dispatch(setCart(cart));
                    dispatch(
                        addMessage({
                            text: 'Tuotteen määrää on onnistuneesti päivitetty!',
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};
// tslint:disable-next-line
export const removeFromCart = (_id: string): ThunkResult<void> => {
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            // tslint:disable-next-line
            return apiCall('patch', '/api/cart', { product_id: _id })
                .then((res: any) => {
                    const { cart } = res;
                    dispatch(setCart(cart));
                    dispatch(
                        addMessage({
                            text: 'Tuote on onnistuneesti poistettu ostoskorista!',
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};
