import { IDeliveryCostFormState } from '../../../types';

export const UPDATE_DELIVERYCOST_FORM = 'UPDATE_DELIVERYCOST_FORM';
export const RESET_DELIVERYCOST_FORM = 'RESET_DELIVERYCOST_FORM';

export interface UpdateDeliveryCostForm {
    type: typeof UPDATE_DELIVERYCOST_FORM;
    deliveryCostFormState: IDeliveryCostFormState;
}

export interface ResetDeliveryCostForm {
    type: typeof RESET_DELIVERYCOST_FORM;
    deliveryCostFormState: IDeliveryCostFormState;
}

export type DeliveryCostFormActionTypes =
    | UpdateDeliveryCostForm
    | ResetDeliveryCostForm;
