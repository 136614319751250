import React, { FC } from 'react';
import { Paper, Grid, Typography, Box } from '@material-ui/core';
import UserAccountiInfoForm from './UserAccountiInfoForm';
import AccountSettingsForm from '../AccountSettings/AccountSettingsForm';
import { IUser } from '../../../../types';

interface IUserAccountInfoCardStateProps {
    user: IUser;
    showAccountSettings?: boolean;
}

const UserAccountInfoCard: FC<IUserAccountInfoCardStateProps> = ({
    user,
    showAccountSettings,
}) => {
    return (
        <Paper>
            <Box p={2}>
                <Grid container={true} justify="space-evenly">
                    <Grid item={true} className="mt-minus3" xs={12}>
                        <Paper className="bg-yellow">
                            <Box p={2}>
                                <Grid container={true} justify="center">
                                    <Grid item={true} xs={12}>
                                        <Typography variant="h5">
                                            {`Muokkaa ${
                                                showAccountSettings
                                                    ? 'käyttäjätilin asetuksia'
                                                    : 'käyttäjätilin tietoja'
                                            }`}
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            gutterBottom={true}
                                        >
                                            Viimeksi muokattu:{' '}
                                            {new Date(
                                                user.updatedAt,
                                            ).toLocaleDateString('fi')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item={true} xs={12}>
                        {!!showAccountSettings ? (
                            <AccountSettingsForm />
                        ) : (
                            <UserAccountiInfoForm />
                        )}
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    );
};

export default UserAccountInfoCard;
