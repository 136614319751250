import React, { FunctionComponent } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, Grid, Paper, Box } from '@material-ui/core';
import PolttopuunLämmitysKuva from '../../assets/images/wood-heating.jpg';

const useStyles = makeStyles((theme: Theme) => ({
    box: {
        padding: '3rem 4rem',
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            padding: '3rem 1.6rem',
        },
    },
    image: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        fontSize: '1.4rem',
        '& img': {
            maxWidth: '100%',
        },
        '& span': {
            marginTop: '0.8rem',
            fontWeight: 'bold',
        },
    },
    list: {
        marginTop: '0.2rem',
        '& li': {
            marginBottom: '0.3rem',
        },
    },
    link: {
        color: '#333',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

const FirewoodInfo: FunctionComponent<{}> = () => {
    const classes = useStyles();

    return (
        <section>
            <Box py={8} px={1}>
                <Grid container={true} justify="center">
                    <Grid item={true} xs={12} md={8}>
                        <Paper>
                            <div className={classes.box}>
                                <Grid container={true} justify="center">
                                    <Grid item={true}>
                                        <Typography variant="h4">
                                            Tulisijan lämmitys
                                        </Typography>
                                        <br />
                                    </Grid>
                                    <Grid item={true}>
                                        <br />
                                        <Typography variant="body1">
                                            Puun poltto on ympäristöystävällinen
                                            lämmitysmuoto. Puu on
                                            ilmastovaikutukseltaan
                                            hiilidioksidineutraali polttoaine.
                                            Poltossa vapautuva hiilidioksidi
                                            sitoutuu uuden metsään kasvuun eikä
                                            lisää hiilidioksidin määrää
                                            ilmakehässä. Puun pienpoltosta
                                            syntyy hiukkaspäästöjä, joiden
                                            määrää voidaan kuitenkin
                                            merkittävästi pienentää muutamalla
                                            yksinkertaisella konstilla.
                                        </Typography>
                                        <br />
                                        <br />
                                        <Typography variant="h6">
                                            TULISIJAN LÄMMITYSOHJE
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            Noudata kuitenkin aina
                                            ensisijaisesti tulisijan valmistajan
                                            antamia ohjeita.
                                        </Typography>
                                        <Typography variant="body1">
                                            <ul className={classes.list}>
                                                <li>
                                                    KÄYTÄ KUIVAA POLTTOPUUTA.
                                                    MIKÄLI POLTTOPUITA
                                                    VARASTOIDAAN ULKONA, TUO
                                                    PUUT SISÄLLE PARI PÄIVÄÄ
                                                    ENNEN KÄYTTÖÄ
                                                </li>
                                                <li>AVAA HORMIPELTI.</li>
                                                <li>
                                                    TYHJENNÄ TUHKA-ASTIA. TUHKAA
                                                    ON LIIKAA JOS SE YLTÄÄ
                                                    ARINAAN ASTI.
                                                </li>
                                                <li>
                                                    LADO PUUT ENSIMMÄISEEN
                                                    PESÄLLISEEN VÄLJÄSTI.
                                                    VAPAATA TILAA ON OLTAVA
                                                    VÄHINTÄÄN 1/3 PESÄN
                                                    KORKEUDESTA.
                                                </li>
                                                <li>
                                                    SYTYTÄ PÄÄLTÄ. KÄYTÄ APUNA
                                                    ESIM. TUOHTA.
                                                </li>
                                                <li>
                                                    LISÄÄ PUITA VASTA KUN
                                                    EDELLISET OVAT PALANEET
                                                    LÄHES LOPPUUN.
                                                </li>
                                                <li>
                                                    SULJE PELTI VASTA KUN
                                                    HIILLOS ON TUMMUNUT.
                                                </li>
                                            </ul>
                                        </Typography>
                                        <br />
                                        <Typography>
                                            Polttopuuta tarvitaan 1 kg sataa
                                            tulisijan painokiloa kohti.
                                            <br />
                                            <em>
                                                Esim. Jos tulisija painaa 1500
                                                kg, tarvitaan tulisijan
                                                lämmittämiseen 15 kg
                                                polttopuuta.
                                            </em>
                                        </Typography>
                                        <br />
                                        <br />
                                        <Typography>
                                            <a
                                                target="_target"
                                                className={classes.link}
                                                href="http://www.hiukkastieto.fi/pienhiukkaslaskuri/"
                                            >
                                                Pienhiukkaslaskurilla
                                            </a>{' '}
                                            voit testata kuinka puhtaasti
                                            lämmität.
                                        </Typography>

                                        <br />
                                        <br />
                                        <Typography>
                                            Lue lisää:
                                            <ul className={classes.list}>
                                                <li>
                                                    <a
                                                        className={classes.link}
                                                        target="_blank"
                                                        href="/downloads/paalta_sytytys.pdf"
                                                    >
                                                        PÄÄLTÄ SYTYTYS
                                                    </a>{' '}
                                                    (pdf-tiedosto 171 kt)
                                                </li>
                                                <li>
                                                    <a
                                                        className={classes.link}
                                                        target="_blank"
                                                        href="/downloads/pokkoapesaan.pdf"
                                                    >
                                                        PÖKKÖÄ PESÄÄN
                                                    </a>
                                                    , Varaavien uunien
                                                    lämmitysohjeita
                                                    (pdf-tiedosto 825 kt)
                                                </li>
                                                <li>
                                                    <a
                                                        className={classes.link}
                                                        target="_blank"
                                                        href="/downloads/polttopuun_kaytto_lammityksessa.pdf"
                                                    >
                                                        PUUN KÄYTTÖ TULISIJOISSA
                                                        YMPÄRISTÖYSTÄVÄLLISESTI
                                                    </a>{' '}
                                                    (pdf-tiedosto 1776 kt)
                                                </li>
                                            </ul>
                                        </Typography>
                                        <br />
                                        <div className={classes.image}>
                                            <img
                                                src={PolttopuunLämmitysKuva}
                                                alt="alt will go here..."
                                            />
                                            <span>
                                                Päältä sytytys. Kuva: Veli-Pekka
                                                Kauppinen, Keski-Suomen
                                                metsäkeskus
                                            </span>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </section>
    );
};

export default FirewoodInfo;
