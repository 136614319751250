import React, { FC } from 'react';
import './AdminPanel.scss';
import { colors } from './SmallCard';

interface CardHeaderProps {
    color: colors;
    className: string;
    plain: boolean;
    stats: boolean;
    icon: boolean;
    children: any;
}

const CardHeader: FC<CardHeaderProps> = (props) => {
    const { className, children, color, ...rest } = props;
    return (
        <div className={className + ' ' + color} {...rest}>
            {children}
        </div>
    );
};

export default CardHeader;
