import { IServiceFormState } from '../../../types';

export const UPDATE_SERVICE_FORM = 'UPDATE_SERVICE_FORM';
export const RESET_SERVICE_FORM = 'RESET_SERVICE_FORM';

export interface UpdateServiceForm {
    type: typeof UPDATE_SERVICE_FORM;
    serviceFormState: IServiceFormState;
}

export interface ResetServiceForm {
    type: typeof RESET_SERVICE_FORM;
    serviceFormState: IServiceFormState;
}

export type ServiceFormActionTypes = UpdateServiceForm | ResetServiceForm;
