import React, { FC } from 'react';
import { Card, InputBaseComponentProps } from '@material-ui/core';
import { mapDispatchToProps } from '../../types/dispatchActionTypes';
import { connect } from 'react-redux';
import { AppState } from '../../store/store';
import { IRegion } from '../../../../types';

interface IFinnishMapStateProps {
    regions: IRegion[];
}

interface IOwnProps {
    handleChange: (
        event: InputBaseComponentProps,
        regionId: string,
        name: 'region',
    ) => void;
}

type FinnishMapProps = IFinnishMapStateProps & IDispatchProps & IOwnProps;

const FinnishMap: FC<FinnishMapProps> = ({ handleChange, regions }) => {
    const handleClick = (event: any, regionId: string | undefined) => {
        event.preventDefault();
        handleChange(event, regionId as string, 'region');
    };
    return (
        <Card>
            <img
                src="https://res.cloudinary.com/halkoliiteri-com/image/upload/v1614714564/kartta_qcpf7o.png"
                alt="halkoliiteri suomen kartta"
                useMap="#Suomi"
            />
            <map name="Suomi">
                <area
                    shape="poly"
                    coords="46,29,51,30,51,29,50,28,51,25,55,24,57,25,65,38,65,42,74,45,77,44,80,44,81,41,86,43,92,46,93,48,96,46,96,42,98,39,102,39,103,35,103,27,106,14,112,6,119,6,124,2,128,2,128,5,132,9,134,11,142,15,143,21,139,28,138,35,134,39,137,40,137,42,134,51,136,61,143,63,152,76,152,80,142,100,149,114,148,115,142,115,138,113,136,118,137,120,139,122,138,125,139,128,135,129,135,131,136,132,133,134,127,134,126,130,122,130,119,137,117,138,107,137,107,134,104,135,102,137,97,137,92,141,87,139,81,134,80,129,75,120,76,113,79,108,80,103,80,101,75,90,78,87,78,84,75,83,74,80,76,79,76,70,78,67,72,60,72,57,67,52,61,50,57,45,49,36"
                    alt="Lappi"
                    title="Lappi"
                    href="/haku"
                    data-lat={
                        regions.find(
                            (region: IRegion) => region.name === 'Lappi',
                        )?.coords.lat
                    }
                    data-lng={
                        regions.find(
                            (region: IRegion) => region.name === 'Lappi',
                        )?.coords.lng
                    }
                    data-zoom={
                        regions.find(
                            (region: IRegion) => region.name === 'Lappi',
                        )?.zoom
                    }
                    onClick={(event: any) =>
                        handleClick(
                            event,
                            regions.find(
                                (region: IRegion) => region.name === 'Lappi',
                            )?._id,
                        )
                    }
                />
                <area
                    shape="poly"
                    coords="93,141,97,138,103,138,105,136,106,138,111,138,112,139,117,139,119,138,122,132,125,132,127,135,135,135,137,132,135,130,139,129,140,128,139,124,140,121,137,119,137,116,138,114,143,116,149,116,157,133,158,138,153,139,153,145,145,143,144,146,140,146,138,148,137,151,131,151,129,153,129,156,124,156,121,157,121,160,122,162,122,165,118,166,115,168,114,170,108,175,110,179,112,182,114,183,114,189,107,195,107,203,105,208,102,208,101,205,98,205,94,202,92,202,91,205,91,206,89,206,87,204,83,197,78,188,75,187,75,189,73,189,70,187,71,185,73,182,82,172,82,170,84,168,84,165,89,163,92,162,94,162,94,160,95,158,94,156,88,160,86,160,85,158,87,157,89,158,95,154,95,146"
                    alt="Pohjois-Pohjanmaa"
                    title="Pohjois-Pohjanmaa"
                    href="/haku"
                    data-lat={
                        regions.find(
                            (region: IRegion) =>
                                region.name === 'Pohjois-Pohjanmaa',
                        )?.coords.lat
                    }
                    data-lng={
                        regions.find(
                            (region: IRegion) =>
                                region.name === 'Pohjois-Pohjanmaa',
                        )?.coords.lng
                    }
                    data-zoom={
                        regions.find(
                            (region: IRegion) =>
                                region.name === 'Pohjois-Pohjanmaa',
                        )?.zoom
                    }
                    onClick={(event: any) =>
                        handleClick(
                            event,
                            regions.find(
                                (region: IRegion) =>
                                    region.name === 'Pohjois-Pohjanmaa',
                            )?._id,
                        )
                    }
                />
                <area
                    shape="poly"
                    coords="109,175,115,170,116,168,118,167,123,165,123,160,122,158,125,157,130,157,130,153,135,152,138,152,139,149,140,147,145,146,145,145,152,145,152,149,151,152,155,152,154,156,151,157,151,164,153,166,157,166,158,172,156,174,158,176,158,178,164,182,165,188,160,197,156,197,155,198,154,194,145,194,145,195,141,195,139,194,137,194,135,198,134,199,133,199,131,196,127,194,125,192,121,191,119,191,116,190,115,189,115,182,112,181"
                    alt="Kainuu"
                    title="Kainuu"
                    href="/haku"
                    data-lat={
                        regions.find(
                            (region: IRegion) => region.name === 'Kainuu',
                        )?.coords.lat
                    }
                    data-lng={
                        regions.find(
                            (region: IRegion) => region.name === 'Kainuu',
                        )?.coords.lng
                    }
                    data-zoom={
                        regions.find(
                            (region: IRegion) => region.name === 'Kainuu',
                        )?.zoom
                    }
                    onClick={(event: any) =>
                        handleClick(
                            event,
                            regions.find(
                                (region: IRegion) => region.name === 'Kainuu',
                            )?._id,
                        )
                    }
                />
                <area
                    shape="poly"
                    coords="68,187,70,188,74,190,75,190,76,189,77,189,88,206,87,208,86,210,86,214,85,216,82,216,81,217,79,216,77,214,77,212,74,211,73,210,72,208,71,207,72,204,71,202,69,201,71,200,73,200,71,198,69,196,67,197,64,196,63,195,60,193,62,192,66,191,68,192"
                    alt="Keski-Pohjanmaa"
                    title="Keski-Pohjanmaa"
                    href="/haku"
                    data-lat={
                        regions.find(
                            (region: IRegion) =>
                                region.name === 'Keski-Pohjanmaa',
                        )?.coords.lat
                    }
                    data-lng={
                        regions.find(
                            (region: IRegion) =>
                                region.name === 'Keski-Pohjanmaa',
                        )?.coords.lng
                    }
                    data-zoom={
                        regions.find(
                            (region: IRegion) =>
                                region.name === 'Keski-Pohjanmaa',
                        )?.zoom
                    }
                    onClick={(event: any) =>
                        handleClick(
                            event,
                            regions.find(
                                (region: IRegion) =>
                                    region.name === 'Keski-Pohjanmaa',
                            )?._id,
                        )
                    }
                />
                <area
                    shape="poly"
                    coords="71,199,68,201,70,203,71,205,70,206,68,205,66,205,64,209,59,209,56,211,56,213,57,215,52,221,52,223,50,225,49,226,43,223,43,228,40,232,39,237,41,239,42,242,40,245,40,248,35,249,34,248,35,243,36,240,35,237,33,236,33,224,35,222,38,221,39,219,39,216,41,214,40,212,35,212,36,209,37,208,39,209,40,211,42,210,43,209,47,208,49,209,53,208,52,204,53,201,57,198,57,195,59,194,61,195,63,197,66,198,69,197"
                    alt="Pohjanmaa"
                    title="Pohjanmaa"
                    href="/haku"
                    data-lat={
                        regions.find(
                            (region: IRegion) => region.name === 'Pohjanmaa',
                        )?.coords.lat
                    }
                    data-lng={
                        regions.find(
                            (region: IRegion) => region.name === 'Pohjanmaa',
                        )?.coords.lng
                    }
                    data-zoom={
                        regions.find(
                            (region: IRegion) => region.name === 'Pohjanmaa',
                        )?.zoom
                    }
                    onClick={(event: any) =>
                        handleClick(
                            event,
                            regions.find(
                                (region: IRegion) =>
                                    region.name === 'Pohjanmaa',
                            )?._id,
                        )
                    }
                />
                <area
                    shape="poly"
                    coords="41,248,41,245,43,241,40,236,41,231,44,229,44,225,46,226,49,227,50,226,53,224,53,221,58,216,58,214,57,213,57,211,58,210,65,209,67,206,69,206,74,213,76,213,77,216,80,218,80,226,81,227,82,230,82,235,80,237,77,237,76,239,74,238,73,236,73,235,70,237,68,238,65,238,62,241,54,241,52,243,49,246,48,248,45,249,42,249,41,249"
                    alt="Etelä-Pohjanmaa"
                    title="Etelä-Pohjanmaa"
                    href="/haku"
                    data-lat={
                        regions.find(
                            (region: IRegion) =>
                                region.name === 'Etelä-Pohjanmaa',
                        )?.coords.lat
                    }
                    data-lng={
                        regions.find(
                            (region: IRegion) =>
                                region.name === 'Etelä-Pohjanmaa',
                        )?.coords.lng
                    }
                    data-zoom={
                        regions.find(
                            (region: IRegion) =>
                                region.name === 'Etelä-Pohjanmaa',
                        )?.zoom
                    }
                    onClick={(event: any) =>
                        handleClick(
                            event,
                            regions.find(
                                (region: IRegion) =>
                                    region.name === 'Etelä-Pohjanmaa',
                            )?._id,
                        )
                    }
                />
                <area
                    shape="poly"
                    coords="81,217,85,217,87,215,87,210,89,207,92,207,92,204,93,203,96,204,97,206,100,206,101,208,104,209,105,212,105,216,106,218,107,221,106,223,103,223,105,226,107,228,109,229,111,233,110,236,110,237,112,238,113,241,112,244,109,244,107,247,107,250,107,252,108,252,108,259,110,261,110,262,108,263,106,264,104,261,102,258,100,258,99,261,96,261,95,264,95,266,93,267,88,267,86,267,86,263,85,261,85,258,85,256,83,255,83,252,85,251,85,248,84,246,81,245,78,244,77,243,76,241,76,239,78,238,81,237,83,236,83,232,83,229,82,226,81,225"
                    alt="Keski-Suomi"
                    title="Keski-Suomi"
                    href="/haku"
                    data-lat={
                        regions.find(
                            (region: IRegion) => region.name === 'Keski-Suomi',
                        )?.coords.lat
                    }
                    data-lng={
                        regions.find(
                            (region: IRegion) => region.name === 'Keski-Suomi',
                        )?.coords.lng
                    }
                    data-zoom={
                        regions.find(
                            (region: IRegion) => region.name === 'Keski-Suomi',
                        )?.zoom
                    }
                    onClick={(event: any) =>
                        handleClick(
                            event,
                            regions.find(
                                (region: IRegion) =>
                                    region.name === 'Keski-Suomi',
                            )?._id,
                        )
                    }
                />
                <area
                    shape="poly"
                    coords="108,195,114,190,116,191,118,192,122,192,124,193,126,194,130,196,132,199,134,201,136,199,138,200,139,204,139,208,141,210,139,214,145,221,144,224,145,225,143,227,140,227,140,230,141,231,138,233,136,237,136,240,136,241,139,243,141,245,138,246,133,244,130,243,126,240,124,237,122,236,117,237,113,237,111,237,111,234,112,231,111,229,108,226,105,224,108,223,108,217,106,216,106,212,106,208,108,203"
                    alt="Pohjois-Savo"
                    title="Pohjois-Savo"
                    href="/haku"
                    data-lat={
                        regions.find(
                            (region: IRegion) => region.name === 'Pohjois-Savo',
                        )?.coords.lat
                    }
                    data-lng={
                        regions.find(
                            (region: IRegion) => region.name === 'Pohjois-Savo',
                        )?.coords.lng
                    }
                    data-zoom={
                        regions.find(
                            (region: IRegion) => region.name === 'Pohjois-Savo',
                        )?.zoom
                    }
                    onClick={(event: any) =>
                        handleClick(
                            event,
                            regions.find(
                                (region: IRegion) =>
                                    region.name === 'Pohjois-Savo',
                            )?._id,
                        )
                    }
                />
                <area
                    shape="poly"
                    coords="136,198,138,195,141,196,146,196,147,195,153,195,154,197,155,199,157,198,159,198,161,200,164,204,167,206,176,212,176,215,181,220,181,224,178,232,163,255,161,255,158,257,156,258,156,254,156,251,153,249,153,244,151,243,150,240,148,238,146,235,144,232,142,231,141,229,141,228,143,228,145,227,146,225,145,223,146,220,140,214,142,209,140,207,139,200"
                    alt="Pohjois-Karjala"
                    title="Pohjois-Karjala"
                    href="/haku"
                    data-lat={
                        regions.find(
                            (region: IRegion) =>
                                region.name === 'Pohjois-Karjala',
                        )?.coords.lat
                    }
                    data-lng={
                        regions.find(
                            (region: IRegion) =>
                                region.name === 'Pohjois-Karjala',
                        )?.coords.lng
                    }
                    data-zoom={
                        regions.find(
                            (region: IRegion) =>
                                region.name === 'Pohjois-Karjala',
                        )?.zoom
                    }
                    onClick={(event: any) =>
                        handleClick(
                            event,
                            regions.find(
                                (region: IRegion) =>
                                    region.name === 'Pohjois-Karjala',
                            )?._id,
                        )
                    }
                />
                <area
                    shape="poly"
                    coords="155,258,155,251,152,250,152,245,150,243,149,241,147,238,141,232,138,235,137,238,137,240,139,242,142,244,142,245,139,247,138,247,128,243,122,237,113,238,114,241,112,245,109,245,108,249,108,251,109,252,109,259,111,261,111,262,107,264,107,267,107,269,109,272,111,275,113,276,115,274,117,274,117,276,120,278,122,277,122,274,120,273,117,271,118,270,123,270,126,271,135,271,138,267,144,267,145,265,150,265,153,261"
                    alt="Etelä-Savo"
                    title="Etelä-Savo"
                    href="/haku"
                    data-lat={
                        regions.find(
                            (region: IRegion) => region.name === 'Etelä-Savo',
                        )?.coords.lat
                    }
                    data-lng={
                        regions.find(
                            (region: IRegion) => region.name === 'Etelä-Savo',
                        )?.coords.lng
                    }
                    data-zoom={
                        regions.find(
                            (region: IRegion) => region.name === 'Etelä-Savo',
                        )?.zoom
                    }
                    onClick={(event: any) =>
                        handleClick(
                            event,
                            regions.find(
                                (region: IRegion) =>
                                    region.name === 'Etelä-Savo',
                            )?._id,
                        )
                    }
                />
                <area
                    shape="poly"
                    coords="162,256,160,256,156,259,153,262,151,265,145,266,145,267,144,268,138,268,135,272,125,272,123,271,118,271,123,274,123,277,123,278,121,278,119,281,121,284,123,287,126,288,128,288,133,293,139,285,142,284,143,280,150,275"
                    alt="Etelä-Karjala"
                    title="Etelä-Karjala"
                    href="/haku"
                    data-lat={
                        regions.find(
                            (region: IRegion) =>
                                region.name === 'Etelä-Karjala',
                        )?.coords.lat
                    }
                    data-lng={
                        regions.find(
                            (region: IRegion) =>
                                region.name === 'Etelä-Karjala',
                        )?.coords.lng
                    }
                    data-zoom={
                        regions.find(
                            (region: IRegion) =>
                                region.name === 'Etelä-Karjala',
                        )?.zoom
                    }
                    onClick={(event: any) =>
                        handleClick(
                            event,
                            regions.find(
                                (region: IRegion) =>
                                    region.name === 'Eteelä-Karjala',
                            )?._id,
                        )
                    }
                />
                <area
                    shape="poly"
                    coords="119,278,116,276,116,275,115,275,115,276,113,277,112,277,110,276,109,275,108,277,107,278,105,279,105,287,106,290,107,291,112,295,110,297,109,299,110,302,114,300,117,300,117,297,122,298,125,299,129,297,132,294,128,289,125,289,123,288,121,286,119,284,118,281,119,279"
                    alt="Kymenlaakso"
                    title="Kymenlaakso"
                    href="/haku"
                    data-lat={
                        regions.find(
                            (region: IRegion) => region.name === 'Kymenlaakso',
                        )?.coords.lat
                    }
                    data-lng={
                        regions.find(
                            (region: IRegion) => region.name === 'Kymenlaakso',
                        )?.coords.lng
                    }
                    data-zoom={
                        regions.find(
                            (region: IRegion) => region.name === 'Kymenlaakso',
                        )?.zoom
                    }
                    onClick={(event: any) =>
                        handleClick(
                            event,
                            regions.find(
                                (region: IRegion) =>
                                    region.name === 'Kymenlaakso',
                            )?._id,
                        )
                    }
                />
                <area
                    shape="poly"
                    coords="101,259,102,259,105,264,106,265,106,269,109,274,107,276,107,277,104,278,104,288,105,291,102,291,99,291,97,292,95,291,94,288,92,289,91,287,89,287,89,285,89,283,89,281,92,279,92,275,87,273,87,269,89,268,94,267,96,266,96,264,96,262,99,262"
                    alt="Päijät-Häme"
                    title="Päijät-Häme"
                    href="/haku"
                    data-lat={
                        regions.find(
                            (region: IRegion) => region.name === 'Päijät-Häme',
                        )?.coords.lat
                    }
                    data-lng={
                        regions.find(
                            (region: IRegion) => region.name === 'Päijät-Häme',
                        )?.coords.lng
                    }
                    data-zoom={
                        regions.find(
                            (region: IRegion) => region.name === 'Päijät-Häme',
                        )?.zoom
                    }
                    onClick={(event: any) =>
                        handleClick(
                            event,
                            regions.find(
                                (region: IRegion) =>
                                    region.name === 'Päijät-Häme',
                            )?._id,
                        )
                    }
                />
                <area
                    shape="poly"
                    coords="86,272,86,268,85,267,85,264,85,262,84,261,84,256,82,254,82,251,84,250,84,248,82,247,79,245,76,243,75,241,75,240,72,237,71,237,70,238,65,239,64,240,62,242,58,242,58,244,57,246,56,248,54,249,56,253,56,255,56,257,55,258,54,260,55,262,53,263,54,265,55,266,54,268,52,269,52,271,53,273,57,274,57,275,57,278,58,280,60,281,63,282,68,282,70,282,70,280,72,278,76,277,76,275,77,274,80,273,85,273"
                    alt="Pirkanmaa"
                    title="Pirkanmaa"
                    href="/haku"
                    data-lat={
                        regions.find(
                            (region: IRegion) => region.name === 'Pirkanmaa',
                        )?.coords.lat
                    }
                    data-lng={
                        regions.find(
                            (region: IRegion) => region.name === 'Pirkanmaa',
                        )?.coords.lng
                    }
                    data-zoom={
                        regions.find(
                            (region: IRegion) => region.name === 'Pirkanmaa',
                        )?.zoom
                    }
                    onClick={(event: any) =>
                        handleClick(
                            event,
                            regions.find(
                                (region: IRegion) =>
                                    region.name === 'Pirkanmaa',
                            )?._id,
                        )
                    }
                />
                <area
                    shape="poly"
                    coords="57,242,54,242,53,244,51,245,49,247,48,249,45,250,41,250,39,249,38,249,36,250,36,253,37,257,37,261,33,279,36,278,38,278,41,280,43,282,45,282,48,282,51,281,54,280,56,279,56,277,56,274,53,274,51,273,51,270,51,268,54,266,52,264,52,262,53,261,53,258,55,257,55,253,54,251,53,250,53,248,55,247,56,246,57,244"
                    alt="Satakunta"
                    title="Satakunta"
                    href="/haku"
                    data-lat={
                        regions.find(
                            (region: IRegion) => region.name === 'Satakunta',
                        )?.coords.lat
                    }
                    data-lng={
                        regions.find(
                            (region: IRegion) => region.name === 'Satakunta',
                        )?.coords.lng
                    }
                    data-zoom={
                        regions.find(
                            (region: IRegion) => region.name === 'Satakunta',
                        )?.zoom
                    }
                    onClick={(event: any) =>
                        handleClick(
                            event,
                            regions.find(
                                (region: IRegion) =>
                                    region.name === 'Satakunta',
                            )?._id,
                        )
                    }
                />
                <area
                    shape="poly"
                    coords="91,278,91,275,86,274,79,274,78,275,77,275,77,278,75,278,71,280,71,282,70,283,69,283,66,283,63,283,61,284,59,287,61,289,64,290,67,290,69,292,72,293,80,295,80,292,86,292,89,291,89,288,88,287,88,285,88,281,90,279"
                    alt="Kanta-Häme"
                    title="Kanta-Häme"
                    href="/haku"
                    data-lat={
                        regions.find(
                            (region: IRegion) => region.name === 'Kanta-Häme',
                        )?.coords.lat
                    }
                    data-lng={
                        regions.find(
                            (region: IRegion) => region.name === 'Kanta-Häme',
                        )?.coords.lng
                    }
                    data-zoom={
                        regions.find(
                            (region: IRegion) => region.name === 'Kanta-Häme',
                        )?.zoom
                    }
                    onClick={(event: any) =>
                        handleClick(
                            event,
                            regions.find(
                                (region: IRegion) =>
                                    region.name === 'Kanta-Häme',
                            )?._id,
                        )
                    }
                />
                <area
                    shape="poly"
                    coords="109,302,108,298,110,295,106,291,104,292,98,292,97,293,95,292,93,290,90,289,90,292,88,293,81,293,81,295,81,296,78,296,71,293,70,296,68,297,69,300,67,303,66,306,63,307,60,308,57,309,54,312,54,314,53,317,55,318,60,316,64,315,70,313,76,312,79,313,81,311,81,309,86,308,88,309,91,307,92,306,96,307,100,307,103,305"
                    alt="Uusimaa"
                    title="Uusimaa"
                    href="/haku"
                    data-lat={
                        regions.find(
                            (region: IRegion) => region.name === 'Uusimaa',
                        )?.coords.lat
                    }
                    data-lng={
                        regions.find(
                            (region: IRegion) => region.name === 'Uusimaa',
                        )?.coords.lng
                    }
                    data-zoom={
                        regions.find(
                            (region: IRegion) => region.name === 'Uusimaa',
                        )?.zoom
                    }
                    onClick={(event: any) =>
                        handleClick(
                            event,
                            regions.find(
                                (region: IRegion) => region.name === 'Uusimaa',
                            )?._id,
                        )
                    }
                />
                <area
                    shape="poly"
                    coords="70,293,65,291,62,291,58,288,58,285,61,283,57,280,55,280,47,283,44,283,42,283,38,279,35,279,32,280,31,287,32,290,31,294,33,296,36,295,38,297,40,300,40,301,40,303,36,303,33,302,30,305,33,307,38,308,40,308,43,306,43,304,42,302,42,301,44,304,47,306,47,308,46,310,46,313,47,316,48,314,48,312,49,313,51,313,51,311,53,312,55,309,58,308,62,306,65,306,68,300,67,296,69,296"
                    alt="Varsinais-Suomi"
                    title="Varsinais-Suomi"
                    href="/haku"
                    data-lat={
                        regions.find(
                            (region: IRegion) =>
                                region.name === 'Varsinais-Suomi',
                        )?.coords.lat
                    }
                    data-lng={
                        regions.find(
                            (region: IRegion) =>
                                region.name === 'Varsinais-Suomi',
                        )?.coords.lng
                    }
                    data-zoom={
                        regions.find(
                            (region: IRegion) =>
                                region.name === 'Varsinais-Suomi',
                        )?.zoom
                    }
                    onClick={(event: any) =>
                        handleClick(
                            event,
                            regions.find(
                                (region: IRegion) =>
                                    region.name === 'Varsinais-Suomi',
                            )?._id,
                        )
                    }
                />
            </map>
        </Card>
    );
};

function mapStateToProps(state: AppState): IFinnishMapStateProps {
    return {
        regions: state.regions,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FinnishMap);
