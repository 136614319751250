import React, { FC } from 'react';
import { Grid, Box, InputBaseComponentProps } from '@material-ui/core';
import SubscriptionCard from './SubscriptionCard';
import { IAdvertisement } from '../../../../types';

interface ISubscriptionCardsStateProps {
    handleChange?: (event: InputBaseComponentProps) => string;
    changeActiveStep?: (nextStep: number) => void;
    advertisements: IAdvertisement[];
}

const SubscriptionCards: FC<ISubscriptionCardsStateProps> = (props) => {
    const { changeActiveStep, handleChange, advertisements } = props;
    return (
        <Box py={3}>
            <Grid container={true} justify="center">
                {advertisements.length &&
                    advertisements.map((advertisement: IAdvertisement) => {
                        return (
                            <SubscriptionCard
                                handleChange={handleChange}
                                clickHandler={changeActiveStep}
                                key={advertisement._id}
                                subscriptionCardItem={advertisement}
                            />
                        );
                    })}
            </Grid>
        </Box>
    );
};

export default SubscriptionCards;
