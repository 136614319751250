import { ILocalitiesByRegion } from '../../../types';

export const SET_LOCALITIES_BY_REGION = 'SET_LOCALITIES_BY_REGION';

export interface SetLocalitiesByRegion {
    type: typeof SET_LOCALITIES_BY_REGION;
    localitiesByRegion: ILocalitiesByRegion[];
}

export type LocalitiesByRegionActionTypes = SetLocalitiesByRegion;
