import { IServiceFormState } from '../../../../types';
import {
    UPDATE_SERVICE_FORM,
    ServiceFormActionTypes,
    RESET_SERVICE_FORM,
} from '../../types/serviceFormActionTypes';
import { resetServiceFormFunction } from '../../utils/resetUtils';

const DEFAULT_STATE: IServiceFormState = {
    serviceForm: resetServiceFormFunction(),
};

const serviceFormReducer = (
    state = DEFAULT_STATE,
    action: ServiceFormActionTypes,
) => {
    switch (action.type) {
        case UPDATE_SERVICE_FORM:
            return Object.assign({}, state, {
                serviceForm: {
                    ...state.serviceForm,
                    [Object.keys(action.serviceFormState.serviceForm)[0]]:
                        Object.values(action.serviceFormState.serviceForm)[0],
                },
            });
        case RESET_SERVICE_FORM:
            return {
                ...state,
                serviceForm: action.serviceFormState.serviceForm,
            };
        default:
            return state;
    }
};

export default serviceFormReducer;
