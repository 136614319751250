import { Chip, Grid } from '@material-ui/core';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import { IProduct } from '../../../../types';
import { AppState } from '../../store/store';
import { mapDispatchToProps } from '../../types/dispatchActionTypes';

interface IFilterBarStateProps {
    products: IProduct[];
}
type SortNames = 'price' | 'createdAt';

type FilterBarProps = IFilterBarStateProps & IDispatchProps;

const FilterBar: FC<FilterBarProps> = ({ products, setProductsAction }) => {
    const sortHandler = (sortName: SortNames, sortValue: number) => {
        function compare(a: IProduct, b: IProduct) {
            if (sortValue === 1) {
                if (a[sortName] < b[sortName]) {
                    return -1;
                }
                if (a[sortName] > b[sortName]) {
                    return 1;
                }
                return 0;
            } else {
                if (a[sortName] < b[sortName]) {
                    return 1;
                }
                if (a[sortName] > b[sortName]) {
                    return -1;
                }
                return 0;
            }
        }
        const sortedProducts = products.slice().sort(compare);
        setProductsAction(sortedProducts);
    };
    return (
        <nav style={{ width: '100%', padding: '0.5rem' }}>
            <Grid
                container={true}
                className="p-2"
                justify="center"
                alignItems="center"
            >
                <Grid item={true} xs={3} md={2}>
                    <Chip
                        label="Halvimmat"
                        variant="outlined"
                        color="primary"
                        onClick={() => sortHandler('price', 1)}
                    />
                </Grid>
                <Grid item={true} xs={3} md={2}>
                    <Chip
                        label="Kalleimmat"
                        variant="outlined"
                        color="primary"
                        onClick={() => sortHandler('price', -1)}
                    />
                </Grid>
                <Grid item={true} xs={3} md={2}>
                    <Chip
                        label="Uusimmat"
                        variant="outlined"
                        color="primary"
                        onClick={() => sortHandler('createdAt', -1)}
                    />
                </Grid>
                <Grid item={true} xs={3} md={2}>
                    <Chip
                        label="Vanhimmat"
                        variant="outlined"
                        color="primary"
                        onClick={() => sortHandler('createdAt', 1)}
                    />
                </Grid>
            </Grid>
        </nav>
    );
};

function mapStateToProps(state: AppState): IFilterBarStateProps {
    return {
        products: state.products,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterBar);
