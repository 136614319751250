import React, { FC } from 'react';
import './Cart.scss';
import { Grid, Button, Box } from '@material-ui/core';
import { RotateLeft } from '@material-ui/icons';
import Order from '../../components/Order/Order';
import { AppState } from '../../store/store';
import { mapDispatchToProps } from '../../types/dispatchActionTypes';
import { connect } from 'react-redux';
import { IOrderTotals, IUiProps, IOrder } from '../../../../types';

interface IOwnProps {
    order: IOrder;
    orderTotals: IOrderTotals;
}

interface IConfirmationStateProps {
    uiProps: IUiProps;
}

type ConfirmationProps = IConfirmationStateProps & IOwnProps & IDispatchProps;

const Confirmation: FC<ConfirmationProps> = (props) => {
    const { order, orderTotals } = props;
    // ResetCartHandler method
    const resetCartHandler = (event: any) => {
        event.preventDefault();
        const { updateUiStateAction, resetCartAction } = props;
        updateUiStateAction({ activeStep: 0 });
        resetCartAction();
    };
    return (
        <Grid container={true} justifyContent="center">
            <Grid item={true} xs={12} md={10}>
                <Order order={order} orderTotals={orderTotals} />
            </Grid>
            <Grid item={true} xs={12}>
                <Box p={2}>
                    <Button
                        onClick={resetCartHandler}
                        startIcon={<RotateLeft />}
                        variant="outlined"
                        color="secondary"
                    >
                        Uusi Tilaus
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
};

function mapStateToProps(state: AppState): IConfirmationStateProps {
    return {
        uiProps: state.uiState.uiProps,
    };
}

export default connect<
    IConfirmationStateProps,
    IDispatchProps,
    IOwnProps,
    AppState
>(
    mapStateToProps,
    mapDispatchToProps,
)(Confirmation);
