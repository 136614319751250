import { IAccount } from '../../../../types';
import {
    AccountActionTypes,
    SET_ACCOUNTS,
    ADD_ACCOUNT,
    UPDATE_ACCOUNT,
    REMOVE_ACCOUNT,
} from '../../types/accountActionTypes';

const DEFAULT_STATE: IAccount[] = [];

const accountReducer = (state = DEFAULT_STATE, action: AccountActionTypes) => {
    switch (action.type) {
        case SET_ACCOUNTS:
            return action.accounts;
        case ADD_ACCOUNT:
            return [...state, action.account];
        case UPDATE_ACCOUNT:
            return state.map((account: IAccount) => {
                if (account._id === action.account._id) {
                    return {
                        ...account,
                        ...action.account,
                    };
                } else {
                    return account;
                }
            });
        case REMOVE_ACCOUNT:
            return state.filter(({ _id }) => _id !== action.id);
        default:
            return state;
    }
};

export default accountReducer;
