import React, { FunctionComponent } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
    Typography,
    Grid,
    Paper,
    Box,
    TableHead,
    TableRow,
    TableContainer,
    Table,
    TableCell,
    TableBody,
} from '@material-ui/core';
import WoodPropertiesImage from '../../assets/images/wood-properties.jpg';

const useStyles = makeStyles((theme: Theme) => ({
    box: {
        padding: '3rem 4rem',
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            padding: '3rem 1.6rem',
        },
    },
    image: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        fontSize: '1.4rem',
        '& img': {
            maxWidth: '100%',
        },
        '& span': {
            marginTop: '0.8rem',
            fontWeight: 'bold',
        },
    },
    link: {
        color: '#333',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

const tableInfo = [
    {
        name: 'Koivu',
        units1: '4,15',
        units2: 410,
        units3: 243,
        units4: 1700,
        units5: 1010,
    },
    {
        name: 'Mänty',
        units1: '4,15',
        units2: 328,
        units3: 195,
        units4: 1360,
        units5: 810,
    },
    {
        name: 'Kuusi',
        units1: '4,10',
        units2: 322,
        units3: 193,
        units4: 1320,
        units5: 790,
    },
    {
        name: 'Leppä',
        units1: '4,05',
        units2: 304,
        units3: 183,
        units4: 1230,
        units5: 740,
    },
    {
        name: 'Haapa',
        units1: '4,00',
        units2: 333,
        units3: 198,
        units4: 1330,
        units5: 790,
    },
];

const UnitsOfMeasurement: FunctionComponent<{}> = () => {
    const classes = useStyles();

    return (
        <section>
            <Box py={8} px={1}>
                <Grid container={true} justify="center">
                    <Grid item={true} xs={12} md={8}>
                        <Paper>
                            <div className={classes.box}>
                                <Grid container={true}>
                                    <Grid item={true}>
                                        <Typography variant="h4">
                                            Polttopuun ominaisuudet
                                        </Typography>
                                        <br />
                                    </Grid>
                                    <Grid item={true}>
                                        <br />
                                        <Typography variant="body1">
                                            <strong>Koostumus</strong>
                                            <br />
                                            Kuivassa polttopuussa on tuhkaa
                                            0,4-1,2%, jäännöshiiltä 11,4-15,6%,
                                            haihtuvia aineita 84-88% ja vettä
                                            15-25%.
                                        </Typography>
                                        <br />
                                        <br />
                                        <div className={classes.image}>
                                            <img
                                                src={WoodPropertiesImage}
                                                alt="halkoliiteri.com Polttopuun ominaisuudet kuva"
                                            />
                                            <span>
                                                Kuva: Eija Alakangas, VTT
                                            </span>
                                        </div>
                                        <br />
                                        <br />
                                        <br />

                                        <Typography variant="body1">
                                            <strong>Lämpöarvo ja tiheys</strong>
                                            <br />
                                            Puun lämpöarvo kertoo, kuinka
                                            tehokkaasti poltettava puu
                                            lämmittää. Lämpöarvoon vaikuttaa
                                            poltettavan puun kosteus. Mitä
                                            kuivempaa puuta, sitä suurempi
                                            lämpöarvo. Kotimaisten puulajien
                                            ominaisuuksissa ei ole suurta eroa
                                            toisiinsa nähden kun verrataan
                                            lämpöarvoa painoyksikköä kohden
                                            (kWh/kg). Puun tiheys (kg/m3)
                                            vaikuttaa siihen paljonko energiaa
                                            on tilavuusyksikköä kohden
                                            (kWh/i-m3, kWh/p-m3). Koska koivun
                                            puuaines on tiheämpää kuin
                                            esimerkiksi männyn tai kuusen, on
                                            sen lämpöarvo tilavuusyksikköä
                                            kohden vastaavasti suurempi.
                                        </Typography>
                                        <br />

                                        <Grid item={true} xs={12}>
                                            <br />
                                            <Typography variant="subtitle2">
                                                Taulukko. Kuivan polttopuun
                                                (kosteus 20 %) lämpöarvo, irto-
                                                ja pinokuutiometripaino ja
                                                energiasisältö puulajeittain.
                                            </Typography>
                                            <br />
                                            <TableContainer component={Paper}>
                                                <Table aria-label="customized table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>
                                                                Puulaji
                                                            </TableCell>
                                                            <TableCell>
                                                                Lämpöarvo
                                                                <br /> kWh/kg
                                                            </TableCell>
                                                            <TableCell>
                                                                Paino <br />
                                                                kg/p-m3
                                                            </TableCell>
                                                            <TableCell>
                                                                Paino <br />
                                                                kg/i-m3
                                                            </TableCell>
                                                            <TableCell>
                                                                Energiasisältö
                                                                <br />
                                                                kWh/p-m3
                                                            </TableCell>
                                                            <TableCell>
                                                                Energiasisältö
                                                                <br />
                                                                kWh/i-m3
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {tableInfo.map(
                                                            (pilke, index) => {
                                                                return (
                                                                    <TableRow
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <TableCell>
                                                                            {
                                                                                pilke.name
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {
                                                                                pilke.units1
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {
                                                                                pilke.units2
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {
                                                                                pilke.units3
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {
                                                                                pilke.units4
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {
                                                                                pilke.units5
                                                                            }
                                                                        </TableCell>
                                                                    </TableRow>
                                                                );
                                                            },
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                        <br />
                                        <br />
                                        <br />
                                        <Typography>
                                            Lue lisää: <br />
                                            <a
                                                className={classes.link}
                                                target="_blank"
                                                href="/downloads/Pilkkeen_ominaisuudet_kytt_ja_varastointi.pdf"
                                            >
                                                Pilkkeen ominaisuudet, käyttö ja
                                                varastointi
                                            </a>{' '}
                                            (pdf)
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </section>
    );
};

export default UnitsOfMeasurement;
