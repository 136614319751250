import { IUser } from '../../../../types';

export const SET_USERS = 'SET_USERS';
export const ADD_USER = 'ADD_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const REMOVE_USER = 'REMOVE_USER';
export const REMOVE_USERS = 'REMOVE_USERS';

export interface SetUsers {
    type: typeof SET_USERS;
    users: IUser[];
}

export interface AddUser {
    type: typeof ADD_USER;
    user: IUser;
}

export interface UpdateUser {
    type: typeof UPDATE_USER;
    user: IUser;
}

export interface RemoveUser {
    type: typeof REMOVE_USER;
    id: string;
}

export interface RemoveUsers {
    type: typeof REMOVE_USERS;
    ids: string[];
}

export type UsersAdminActionTypes =
    | SetUsers
    | AddUser
    | UpdateUser
    | RemoveUser
    | RemoveUsers;
