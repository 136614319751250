import { IProduct } from '../../../../types';
import {
    FavoritesActionTypes,
    REMOVE_FAVORITE,
    SET_FAVORITES,
    ADD_FAVORITE,
} from '../../types/favoritesActionTypes';

const DEFAULT_STATE: IProduct[] = [];

const favoritesReducer = (
    state = DEFAULT_STATE,
    action: FavoritesActionTypes,
) => {
    switch (action.type) {
        case SET_FAVORITES:
            return action.favorites;
        case ADD_FAVORITE:
            return [...state, action.favorite];
        case REMOVE_FAVORITE:
            return state.filter(({ _id }) => _id !== action.id);
        default:
            return state;
    }
};

export default favoritesReducer;
