import { IOrder } from '../../../types';

export const SET_ORDERS = 'SET_ORDERS';
export const UPDATE_ORDERS = 'UPDATE_ORDERS';

export interface SetOrders {
    type: typeof SET_ORDERS;
    orders: IOrder[];
}

export interface UpdateOrders {
    type: typeof UPDATE_ORDERS;
    order: IOrder;
}

export type OrdersActionTypes = SetOrders | UpdateOrders;
