import {
    Box,
    FormControl,
    InputBaseComponentProps,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import React, { FC, useState } from 'react';
import { connect } from 'react-redux';
import { IUser } from '../../../../../types';
import { AccountState, adminPanelOptionLists } from '../../../formOptionList';
import { AppState } from '../../../store/store';
import { mapDispatchToProps } from '../../../types/dispatchActionTypes';

interface IUserListTableStateProps {
    newUsers: IUser[];
}

type UserListTableProps = IUserListTableStateProps & IDispatchProps;

const UserListTable: FC<UserListTableProps> = (props) => {
    const [isActive, setIsActive] = useState<boolean>(false);
    const { newUsers } = props;
    const { accountStates } = adminPanelOptionLists;

    const handleChange = (event: InputBaseComponentProps, id: string) => {
        const { editUserAccountStatusAction } = props;
        setIsActive(
            event.target.value === 'kyllä'
                ? true
                : event.target.value === 'ei'
                ? false
                : isActive,
        );
        editUserAccountStatusAction(
            id,
            event.target.value === 'kyllä'
                ? true
                : event.target.value === 'ei'
                ? false
                : isActive,
            true,
        );
    };
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Nimi</TableCell>
                    <TableCell>Käyttäjä tyyppi</TableCell>
                    <TableCell>Vuosimaksu</TableCell>
                    <TableCell>Aktiivinen</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {newUsers.map((user: IUser) => {
                    return (
                        <TableRow key={user._id}>
                            <TableCell>
                                {user.companyName
                                    ? user.companyName
                                    : user.fullName}
                            </TableCell>
                            <TableCell>
                                {user.business ? 'Yritys' : 'Yksityinen myyjä'}
                            </TableCell>
                            <TableCell>
                                {user.userAccount.paid
                                    ? 'Maksettu'
                                    : 'Maksamatta'}
                            </TableCell>
                            <TableCell>
                                <Box p={1}>
                                    <FormControl
                                        variant="outlined"
                                        fullWidth={true}
                                        required={true}
                                    >
                                        <InputLabel id="demo-simple-select-required-label">
                                            Valitse yksikkö
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-required-label"
                                            id="demo-simple-select-required"
                                            name="isActive"
                                            value={
                                                isActive
                                                    ? 'kyllä'
                                                    : user &&
                                                      user.userAccount.isActive
                                                    ? 'kyllä'
                                                    : 'ei'
                                            }
                                            onChange={(
                                                event: InputBaseComponentProps,
                                            ) => handleChange(event, user._id)}
                                            label="Tilin tilan hallinta"
                                        >
                                            <MenuItem
                                                value={
                                                    isActive
                                                        ? 'kyllä'
                                                        : user &&
                                                          user.userAccount
                                                              .isActive
                                                        ? 'kyllä'
                                                        : 'ei'
                                                }
                                            >
                                                <em>Valitse yksikkö</em>
                                            </MenuItem>
                                            {!!accountStates.length &&
                                                accountStates.map(
                                                    (
                                                        accountState: AccountState,
                                                    ) => {
                                                        return (
                                                            <MenuItem
                                                                key={
                                                                    accountState.text
                                                                }
                                                                value={
                                                                    accountState.text
                                                                }
                                                            >
                                                                {
                                                                    accountState.text
                                                                }
                                                            </MenuItem>
                                                        );
                                                    },
                                                )}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};

function mapStateToProps(state: AppState): IUserListTableStateProps {
    return {
        newUsers: state.newUsers,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserListTable);
