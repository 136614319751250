import {
    SET_NEW_USERS,
    NewUsersActionTypes,
    ADD_NEW_USER,
    UPDATE_NEW_USER,
    REMOVE_NEW_USER,
} from '../../../types/admin/newUsersActionTypes';

const DEFAULT_STATE: any[] = [];

const newUsersReducer = (
    state = DEFAULT_STATE,
    action: NewUsersActionTypes,
) => {
    switch (action.type) {
        case SET_NEW_USERS:
            return action.newUsers;
        case ADD_NEW_USER:
            return [...state, action.newUser];
        case UPDATE_NEW_USER:
            return state.map((user: any) => {
                if (user._id === action.newUser._id) {
                    return {
                        ...user,
                        ...action.newUser,
                    };
                } else {
                    return user;
                }
            });
        case REMOVE_NEW_USER:
            return state.filter(({ _id }) => _id !== action.id);
        default:
            return state;
    }
};

export default newUsersReducer;
