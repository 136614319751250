import React, { FC } from 'react';
import './Stepper.scss';
import { Step, Stepper, StepLabel } from '@material-ui/core';
import { IStep } from '../../../../types';

interface StepperProps {
    steps: IStep[];
    activeStep: number | undefined;
}
const MainStepper: FC<StepperProps> = (props: StepperProps) => {
    const { steps, activeStep } = props;
    return (
        <Stepper activeStep={activeStep} alternativeLabel={true}>
            {steps.map((step) => (
                <Step key={step._id}>
                    <StepLabel>{step.label}</StepLabel>
                </Step>
            ))}
        </Stepper>
    );
};

export default MainStepper;
