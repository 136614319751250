import React, { FC } from 'react';
import {
    Grid,
    Paper,
    Typography,
    TextField,
    Button,
    Box,
    Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExitToApp } from '@material-ui/icons';
import { Link } from 'react-router-dom';

interface LoginFormStateProps {
    handleLogin: (event: any) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    beige: {
        backgroundColor: theme.colors.beige,
    },
    container: {
        padding: '0 1rem',
    },
    button: {
        margin: '1rem 0 1.5rem 0',
    },
    inputContainer: {
        padding: '0.5rem',
    },
}));

const LoginForm: FC<LoginFormStateProps> = (props) => {
    const { handleLogin } = props;
    const classes = useStyles();

    return (
        <Grid container={true} justify="center" className={classes.container}>
            <Paper className="beige">
                <Box py={2}>
                    <Grid item={true}>
                        <Typography variant="h4">
                            <ExitToApp /> Kirjaudu
                        </Typography>
                    </Grid>
                    <form onSubmit={handleLogin}>
                        <Grid container={true} justify="center">
                            <Grid
                                item={true}
                                sm={8}
                                xs={10}
                                className={classes.inputContainer}
                            >
                                <TextField
                                    name="username"
                                    fullWidth={true}
                                    required={true}
                                    id="username-required"
                                    type="text"
                                    label="Käyttäjänimi"
                                />
                            </Grid>
                            <Grid
                                item={true}
                                sm={8}
                                xs={10}
                                className={classes.inputContainer}
                            >
                                <TextField
                                    name="password"
                                    fullWidth={true}
                                    required={true}
                                    id="password-required"
                                    type="password"
                                    label="Salasana"
                                />
                            </Grid>
                            <Grid
                                item={true}
                                xs={8}
                                className={classes.inputContainer}
                            >
                                <Button
                                    className={classes.button}
                                    variant="outlined"
                                    type="submit"
                                    color="primary"
                                >
                                    <ExitToApp /> Kirjaudu
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                    <Grid
                        container={true}
                        justify="center"
                        alignContent="center"
                    >
                        <Grid item={true} xs={11}>
                            <Box my={1}>
                                <Typography variant="body1">
                                    Unohditko salasanan? Voit palauttaa sen{' '}
                                    <Link
                                        className="dark"
                                        to="/kirjaudu/salasananpalautus"
                                    >
                                        tästä
                                    </Link>
                                    .
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item={true} xs={11}>
                            <Typography variant="body1">
                                Uusi myyjä? Rekisteröidy{' '}
                                <Link to="/rekisteroidy" className="dark">
                                    tästä
                                </Link>
                                .
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </Grid>
    );
};

export default LoginForm;
