import React, { FC, useState, useEffect } from 'react';
import {
    IconButton,
    Paper,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Button,
    TableFooter,
    TablePagination,
    useTheme,
} from '@material-ui/core';
import {
    Edit,
    Delete,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    LastPage,
    Image,
    FirstPage,
} from '@material-ui/icons';
import { setPriceTag } from '../../utils/utils';
import { connect } from 'react-redux';
import { AppState } from '../../store/store';
import { mapDispatchToProps } from '../../types/dispatchActionTypes';
import { ICurrentUser, IProduct, ProductCategories } from '../../../../types';

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number,
    ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div
            style={{
                flexShrink: 0,
                marginLeft: theme.spacing(2.5),
            }}
        >
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="ensimmäinen sivu"
            >
                {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="edellinen sivu"
            >
                {theme.direction === 'rtl' ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="seuraava sivu"
            >
                {theme.direction === 'rtl' ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="viimeinen sivu"
            >
                {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
            </IconButton>
        </div>
    );
}

interface IProductListStateProps {
    userProducts: IProduct[];
    currentUser: ICurrentUser;
    otherUserProducts: IProduct[];
}

interface OwnProps {
    productFormHandler: (key: string, value: boolean, id: string) => void;
    deleteProductHandler: (id: string) => void;
    showEditProductForm: boolean | undefined;
    showImageUploadForm?: boolean;
    selectedCategory: ProductCategories;
}

type ProductListProps = IProductListStateProps & OwnProps & IDispatchProps;

const ProductList: FC<ProductListProps> = ({
    userProducts,
    otherUserProducts,
    selectedCategory,
    productFormHandler,
    deleteProductHandler,
    showImageUploadForm,
    showEditProductForm,
    fetchUserProductsAction,
    currentUser,
}) => {
    const visibleProducts = otherUserProducts
        .concat(userProducts)
        .filter((product: IProduct) => {
            return product.category === selectedCategory;
        });
    const [dense, setDense] = useState(false);
    const updateDense = () => {
        setDense(!dense);
    };
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    useEffect(() => {
        fetchUserProductsAction(currentUser.user._id);
    }, [currentUser, fetchUserProductsAction]);

    // const emptyRows =
    //     rowsPerPage -
    //     Math.min(rowsPerPage, visibleProducts.length - page * rowsPerPage);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Paper>
            <Button onClick={updateDense} type="button">
                Muuta kokoa
            </Button>
            <TableContainer aria-label="custom pagination table">
                <Table
                    aria-labelledby="tableTitle"
                    size={dense ? 'small' : 'medium'}
                    aria-label="enhanced table"
                >
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Nimi
                            </TableCell>
                            <TableCell component="th" scope="row">
                                Pituus
                            </TableCell>
                            <TableCell component="th" scope="row">
                                Materiaali
                            </TableCell>
                            <TableCell component="th" scope="row">
                                Laatu
                            </TableCell>
                            <TableCell component="th" scope="row">
                                Toimitusehto
                            </TableCell>
                            <TableCell component="th" scope="row">
                                Hinta
                            </TableCell>
                            <TableCell component="th" scope="row">
                                Yksikkö
                            </TableCell>
                            <TableCell component="th" scope="row">
                                Muokkaa
                            </TableCell>
                            <TableCell component="th" scope="row">
                                Poista
                            </TableCell>
                        </TableRow>
                        {!!visibleProducts.length &&
                            (rowsPerPage > 0
                                ? visibleProducts.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage,
                                  )
                                : visibleProducts
                            ).map((product: IProduct) => {
                                return (
                                    <TableRow key={product._id}>
                                        <TableCell component="th" scope="row">
                                            {product.name}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {product.plength}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {product.material}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {product.quality}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {product.deliveryType}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {setPriceTag(product.price)}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {product.unit}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <IconButton
                                                onClick={() =>
                                                    productFormHandler(
                                                        'showImageUploadForm',
                                                        !showImageUploadForm,
                                                        product._id,
                                                    )
                                                }
                                                edge="end"
                                                aria-label="edit"
                                            >
                                                <Image className="tableIcon tableIcon_orange" />
                                            </IconButton>
                                            <IconButton
                                                onClick={() =>
                                                    productFormHandler(
                                                        'showEditProductForm',
                                                        !showEditProductForm,
                                                        product._id,
                                                    )
                                                }
                                                edge="end"
                                                aria-label="edit"
                                            >
                                                <Edit className="tableIcon tableIcon_orange" />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <IconButton
                                                onClick={() =>
                                                    deleteProductHandler(
                                                        product._id,
                                                    )
                                                }
                                                edge="end"
                                                aria-label="delete"
                                            >
                                                <Delete className="tableIcon tableIcon_red" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[
                                    15,
                                    30,
                                    45,
                                    { label: 'Kaikki', value: -1 },
                                ]}
                                colSpan={9}
                                count={visibleProducts.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'Tuotetta per sivu',
                                    },
                                    native: true,
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Paper>
    );
};

function mapStateToProps(state: AppState): IProductListStateProps {
    return {
        userProducts: state.userProducts,
        otherUserProducts: state.otherUserProducts,
        currentUser: state.currentUser,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
