import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, Grid, Paper, Box } from '@material-ui/core';
import TulisijanInfoKuva from '../../assets/images/wood-2.jpg';

const useStyles = makeStyles((theme: Theme) => ({
    box: {
        padding: '3rem 4rem',
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            padding: '3rem 1.6rem',
        },
    },
    image: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        fontSize: '1.4rem',
        '& img': {
            maxWidth: '100%',
        },
        '& span': {
            marginTop: '0.8rem',
            fontWeight: 'bold',
        },
    },
    link: {
        color: '#333',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

const FirewoodInfo: FunctionComponent<{}> = () => {
    const classes = useStyles();

    return (
        <section>
            <Box py={8} px={1}>
                <Grid container={true} justify="center">
                    <Grid item={true} xs={12} md={8}>
                        <Paper>
                            <div className={classes.box}>
                                <Grid container={true} justify="center">
                                    <Grid item={true}>
                                        <Typography variant="h4">
                                            Polttopuuinfo
                                        </Typography>
                                        <br />
                                    </Grid>
                                    <Grid item={true}>
                                        <br />
                                        <Typography variant="body1">
                                            Tässä osiossa on tietoa polttopuun
                                            yhteydessä käytetyistä{' '}
                                            <Link
                                                className={classes.link}
                                                to="/polttopuuinfo/termit-ja-mittayksikot"
                                            >
                                                termeistä ja mittayksiköistä
                                            </Link>
                                            ,{' '}
                                            <Link
                                                className={classes.link}
                                                to="/polttopuuinfo/polttopuun-ominaisuudet"
                                            >
                                                polttopuun ominaisuuksista
                                            </Link>
                                            ,{' '}
                                            <Link
                                                className={classes.link}
                                                to="/polttopuuinfo/polttopuun-kuivaus-ja-varastointi"
                                            >
                                                polttopuun kuivauksesta ja
                                                varastoinnista
                                            </Link>{' '}
                                            ja{' '}
                                            <Link
                                                className={classes.link}
                                                to="/polttopuuinfo/tulisijan-lammitys"
                                            >
                                                tulisijan lämmityksestä
                                            </Link>
                                            .
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            <Link
                                                className={classes.link}
                                                to="/polttopuuinfo/energiavertailu"
                                            >
                                                Energiavertailu
                                            </Link>{' '}
                                            -sivulla on polttoaineiden ja
                                            lämmitysjärjestelmien
                                            vertailutietoa.
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            <Link
                                                className={classes.link}
                                                to="/polttopuuinfo/energialaskuri"
                                            >
                                                Energialaskurilla
                                            </Link>{' '}
                                            voi laskea polttopuulämmityksen
                                            kustannuksia.
                                        </Typography>
                                        <br />
                                        <br />
                                        <div className={classes.image}>
                                            <img
                                                src={TulisijanInfoKuva}
                                                alt="halkoliiteri.com Tulisijan info"
                                            />
                                            <span>
                                                Koivupilkkeitä. Kuva: Jussi
                                                Somerpalo. Lounais-Suomen
                                                metsäkeskus
                                            </span>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </section>
    );
};

export default FirewoodInfo;
