import {
    UPDATE_PRODUCT_FORM,
    RESET_PRODUCT_FORM,
} from '../../types/productFormActionTypes';
import { AppActions } from '../../types/actions';
import { IProductForm } from '../../../../types';

export function updateProductForm(productForm: IProductForm): AppActions {
    return {
        type: UPDATE_PRODUCT_FORM,
        productFormState: { productForm },
    };
}

export function resetProductForm(productForm: IProductForm): AppActions {
    return {
        type: RESET_PRODUCT_FORM,
        productFormState: { productForm },
    };
}
