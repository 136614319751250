import React, { FC, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Grid, Hidden, ButtonGroup, Button, Box } from '@material-ui/core';
import { AppState } from '../../store/store';
import SideBar from '../../components/SideBar/SideBarProduct';
import './SellersPage.scss';
import './Product.scss';
import { GridOn } from '@material-ui/icons';
import SearchInput from '../../components/SearchInput/SearchInput';
import Modal from '../../components/Modal/Modal';
import SellerCard from '../../components/SellerCard/SellerCard';
import { IProduct, IUser } from '../../../../types';
import MapModal from './MapModal';
import ProductCard from '../../components/ProductCard/ProductCard';

const SellersPage: FC = () => {
    const [showMap, setShowMap] = useState<boolean>(() => false);
    const { sellers, favorites, uiState } = useSelector(
        (state: AppState) => state,
    );
    const { uiProps } = uiState;
    const { showFavoriteList } = uiProps;
    return (
        <section className="sellers">
            {sellers.length && (
                <Hidden mdUp={true}>
                    <ButtonGroup
                        variant="contained"
                        className="sellers--showProductsButton"
                        color="primary"
                        size="large"
                    >
                        <Button
                            startIcon={<GridOn />}
                            component={RouterLink}
                            to="/ilmoitukset"
                        >
                            Tuotteet
                        </Button>
                    </ButtonGroup>
                </Hidden>
            )}
            <SideBar />
            <Grid item={true} xs={12}>
                <Grid container={true}>
                    <Grid item={true} xs={9}>
                        <SearchInput searchSellers={true} />
                    </Grid>
                    <Grid
                        item={true}
                        xs={3}
                        md={3}
                        className="sellers--mapButton"
                    >
                        <Button
                            type="button"
                            variant="outlined"
                            color="primary"
                            onClick={() => setShowMap(() => !showMap)}
                        >
                            {showMap ? 'Sulje kartta' : 'Näytä myyjät kartalla'}
                        </Button>
                    </Grid>
                </Grid>
                <Box my={5} px={3}>
                    <Grid container={true}>
                        {showFavoriteList
                            ? favorites.map((product: IProduct) => {
                                  return (
                                      <Grid
                                          key={product._id}
                                          item={true}
                                          sm={5}
                                          xs={12}
                                          md={12}
                                          className="productGrid"
                                      >
                                          <ProductCard product={product} />
                                      </Grid>
                                  );
                              })
                            : sellers.map((seller: IUser) => {
                                  return (
                                      <Grid
                                          key={seller._id}
                                          item={true}
                                          sm={6}
                                          xs={12}
                                          md={3}
                                      >
                                          <Box p={2}>
                                              <SellerCard seller={seller} />
                                          </Box>
                                      </Grid>
                                  );
                              })}
                    </Grid>
                </Box>
                {uiProps.modalOpen && (
                    <Modal
                        modalOpen={uiProps.modalOpen}
                        editProduct={false}
                        selectedCategory="Muut_tuotteet"
                    />
                )}
                {showMap && (
                    <MapModal
                        showMap={showMap}
                        showMapHandler={() => setShowMap(!showMap)}
                        sellers={sellers}
                    />
                )}
            </Grid>
        </section>
    );
};

export default SellersPage;
