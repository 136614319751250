import React, { FormEvent } from 'react';
import './FrontPage.scss';
import { AppState } from '../../store/store';
import { connect } from 'react-redux';
import { withRouter, Link, RouteComponentProps } from 'react-router-dom';
import {
    Grid,
    Typography,
    Paper,
    IconButton,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    InputBaseComponentProps,
    Box,
    Theme,
} from '@material-ui/core';
import { Pageview } from '@material-ui/icons';
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles';
import { mapDispatchToProps } from '../../types/dispatchActionTypes';
import { LocationSearching } from '@material-ui/icons';
import {
    setSearchForm,
    setSearchInputValues,
    setUiPropsValues,
} from '../../utils/utils';
import { productSearchFormOptionLists } from '../../formOptionList';
import SubscriptionCards from '../../components/SubscriptionCards/SubscriptionCards';
import FinnishMap from '../../components/FinnishMap/FinnishMap';
import AdStripe from '../../components/AdStripe/AdStripe';
import {
    ProductCategories,
    ISearchForm,
    IUiProps,
    IAdvertisement,
    ILocation,
    IRegion,
} from '../../../../types';

interface FrontPageStateProps {
    advertisements: IAdvertisement[];
    localities: ILocation[];
    regions: IRegion[];
    uiState: IUiProps;
    searchForm: ISearchForm;
}

type FrontPageComponentProps = FrontPageStateProps &
    IDispatchProps &
    RouteComponentProps &
    WithStyles<typeof styles>;

const styles = (theme: Theme) =>
    createStyles({
        heroGrid: {
            padding: '0 1.5rem',
        },
        formGrid: {
            [theme.breakpoints.down('sm')]: {
                padding: '0 2rem',
            },
        },
        formControl: {
            width: '100%',
        },
        form: {
            marginTop: '1.9rem',
            marginBottom: '0.5rem',
            padding: '1rem 2rem',
            backgroundColor: '#f7f7f7',
            borderRadius: '5px',
            boxShadow: '0 0px 6px rgba(0,0,0,0.2)',
        },
        btn: {
            marginLeft: 'auto',
        },
    });

class FrontPage extends React.Component<FrontPageComponentProps, AppState> {
    public componentDidMount() {
        const { fetchRegionsAction, fetchAdvertisementsAction } = this.props;
        fetchRegionsAction('');
        fetchAdvertisementsAction('');
    }

    public handleChange = (
        event: InputBaseComponentProps,
        regionId?: string | undefined | null,
        name?: string,
    ) => {
        const {
            fetchLocalitiesAction,
            updateSearchFormAction,
            updateGoogleMapsStateAction,
        } = this.props;
        if (name) {
            updateSearchFormAction(
                setSearchInputValues({ target: { name, value: regionId } }),
            );
        } else {
            updateSearchFormAction(setSearchInputValues(event));
        }
        if (event.target.name === 'region' || regionId) {
            updateGoogleMapsStateAction({
                lat: Number(event.currentTarget.dataset.lat),
                lng: Number(event.currentTarget.dataset.lng),
                zoom: Number(event.currentTarget.dataset.zoom),
            });
            fetchLocalitiesAction(regionId || event.target.value);
        }
    };

    public handleClick = (key: string, value: boolean) => {
        const { updateUiStateAction } = this.props;
        updateUiStateAction(setUiPropsValues(key, value));
    };

    public findProducts = (event: FormEvent) => {
        event.preventDefault();
        const { fetchSellersAction, searchForm } = this.props;
        fetchSellersAction(setSearchForm(searchForm));
        this.props.history.push('/haku');
    };

    public render() {
        const { regions, searchForm, advertisements, classes } = this.props;
        const { category, region } = searchForm;
        const { productCategories } = productSearchFormOptionLists;
        return (
            <section>
                <Grid
                    container={true}
                    justify="center"
                    alignContent="center"
                    id="frontPageHero"
                >
                    <Grid
                        item={true}
                        xs={12}
                        sm={10}
                        lg={8}
                        className={classes.heroGrid}
                    >
                        <Paper className="heroContent transparentBg">
                            <Grid
                                container={true}
                                justify="center"
                                alignContent="center"
                                className="heroContent"
                            >
                                <Grid item={true}>
                                    <Box mb={2}>
                                        <Typography variant="h4">
                                            Ostamassa vai Myymässä polttopuita?
                                        </Typography>
                                    </Box>
                                    <Box mb={2}>
                                        <Typography variant="body1">
                                            <strong>Halkoliiteri.com</strong> on
                                            palvelu, jossa polttopuiden myyjät
                                            ja ostajat kohtaavat{' '}
                                            <strong>Ivalosta Helsinkiin</strong>
                                            .
                                        </Typography>
                                    </Box>
                                    <Typography variant="body1">
                                        Osta polttopuuta! Halkoliiteristä
                                        helposti polttopuut kotiin ja mökille.
                                        Halkoliiterin Muut tuotteet ja Palvelut
                                        -hausta voit etsiä vaikkapa haketta tai
                                        pilkekoneen vuokraajaa. Infosivuilla
                                        tietoa polttopuusta, varastoinnista ja
                                        puulla lämmittämisestä.
                                    </Typography>
                                    <br />
                                    <IconButton
                                        component={Link}
                                        to="/haku"
                                        aria-label="Hae lähin noutopisteesi"
                                        color="secondary"
                                    >
                                        <LocationSearching />
                                        <Typography
                                            className="margin-left-1"
                                            variant="h5"
                                        >
                                            Aloita haku tästä
                                        </Typography>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>

                <Grid container={true} justify="center">
                    <Grid
                        item={true}
                        xs={12}
                        md={10}
                        lg={8}
                        className={classes.formGrid}
                    >
                        <form
                            onSubmit={this.findProducts}
                            className={classes.form}
                        >
                            <Grid
                                container={true}
                                spacing={2}
                                justify="space-around"
                                alignItems="center"
                            >
                                <Grid item={true} sm={4} xs={12}>
                                    <FormControl
                                        className={classes.formControl}
                                        required={true}
                                        variant="filled"
                                    >
                                        <InputLabel id="demo-simple-select-required-label">
                                            Tuotekategoria
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-required-label"
                                            id="demo-simple-select-required"
                                            name="category"
                                            value={category}
                                            onChange={(
                                                event: InputBaseComponentProps,
                                            ) => this.handleChange(event)}
                                        >
                                            <MenuItem value={category}>
                                                <em>Tuotekategoria *</em>
                                            </MenuItem>
                                            {productCategories.map(
                                                (
                                                    category2:
                                                        | string
                                                        | ProductCategories,
                                                ) => {
                                                    return (
                                                        <MenuItem
                                                            key={category2}
                                                            value={category2}
                                                        >
                                                            {category2}
                                                        </MenuItem>
                                                    );
                                                },
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item={true} sm={4} xs={12}>
                                    <FormControl
                                        required={true}
                                        className={classes.formControl}
                                        variant="filled"
                                    >
                                        <InputLabel id="demo-simple-select-required-label">
                                            Maakunta
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-required-label"
                                            id="demo-simple-select-required"
                                            name="region"
                                            value={region}
                                            onChange={(
                                                event: InputBaseComponentProps,
                                            ) => this.handleChange(event)}
                                        >
                                            <MenuItem value={region}>
                                                <em>Maakunta *</em>
                                            </MenuItem>
                                            {!!regions.length &&
                                                regions.map(
                                                    (region: IRegion) => {
                                                        return (
                                                            <MenuItem
                                                                key={region._id}
                                                                data-lat={
                                                                    region
                                                                        .coords
                                                                        .lat
                                                                }
                                                                data-lng={
                                                                    region
                                                                        .coords
                                                                        .lng
                                                                }
                                                                data-zoom={
                                                                    region.zoom
                                                                }
                                                                value={
                                                                    region._id
                                                                }
                                                            >
                                                                {region.name}
                                                            </MenuItem>
                                                        );
                                                    },
                                                )}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item={true} sm={4} xs={12}>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        variant="outlined"
                                        size="large"
                                        className="btn"
                                        endIcon={<Pageview />}
                                    >
                                        Hae
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
                <Box py={2}>
                    <Grid container={true} justify="center">
                        <Grid
                            item={true}
                            xs={12}
                            md={10}
                            lg={8}
                            className={classes.formGrid}
                        >
                            <FinnishMap handleChange={this.handleChange} />
                            <Box my={3} />
                        </Grid>

                        <AdStripe
                            content="Edulliset vuosimaksut, Basic tai Premium tili vaihtoehdot"
                            title="Liity nyt Halkoliiteri.com myyjäksi"
                            bgColor="coral"
                            color="textPrimary"
                        />

                        <Grid item={true} xs={12} md={9}>
                            <SubscriptionCards
                                advertisements={advertisements}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </section>
        );
    }
}
function mapStateToProps(state: AppState): FrontPageStateProps {
    return {
        advertisements: state.advertisements,
        localities: state.localities,
        regions: state.regions,
        uiState: state.uiState.uiProps,
        searchForm: state.searchFormState.searchForm,
    };
}

export default withRouter(
    connect<FrontPageStateProps, IDispatchProps, {}, AppState>(
        mapStateToProps,
        mapDispatchToProps,
    )(withStyles(styles)(FrontPage)),
);
