import { IAdminPanelUiProps } from '../../../../../types';
import { AppActions } from '../../../types/actions';
import {
    UPDATE_ADMIN_PANEL_UISTATE,
    RESET_ADMIN_PANEL_UISTATE,
} from '../../../types/admin/adminPanelUiStateActionTypes';

export function updateAdminPanelUiState(
    adminPanelUiProps: IAdminPanelUiProps,
): AppActions {
    return {
        type: UPDATE_ADMIN_PANEL_UISTATE,
        adminPanelUiState: { adminPanelUiProps },
    };
}

export function resetAdminPanelUiState(
    adminPanelUiProps: IAdminPanelUiProps,
): AppActions {
    return {
        type: RESET_ADMIN_PANEL_UISTATE,
        adminPanelUiState: { adminPanelUiProps },
    };
}
