import React, { Component } from 'react';
import {
    Grid,
    Typography,
    TextField,
    Button,
    FormControl,
    FormHelperText,
    Select,
    MenuItem,
    InputLabel,
    InputBaseComponentProps,
    Box,
} from '@material-ui/core';
import {
    withStyles,
    createStyles,
    WithStyles,
    Theme,
} from '@material-ui/core/styles';
import { AppState } from '../../store/store';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../types/dispatchActionTypes';
import { setInputValues } from '../../utils/utils';
import {
    ICurrentUser,
    ILocation,
    IRegion,
    ISignUpForm,
} from '../../../../types';

interface IUserAccountInfoFormStateProps {
    currentUser: ICurrentUser;
    localities: ILocation[];
    regions: IRegion[];
    signUpForm: ISignUpForm;
}

type UserAccountInfoFormProps = IUserAccountInfoFormStateProps &
    IDispatchProps &
    WithStyles<typeof styles>;

const styles = (theme: Theme) =>
    createStyles({
        marginTop2: {
            marginTop: '2rem',
        },
    });

class UserAccountInfoForm extends Component<
    UserAccountInfoFormProps,
    AppState
> {
    public handleChange = (event: InputBaseComponentProps): string => {
        const { updateSignUpFormAction } = this.props;
        updateSignUpFormAction(setInputValues(event));
        if (event.target.name === 'region') {
            this.getLocalities(event.target.value);
        }
        return event.value;
    };
    public getLocalities = (region: string) => {
        const { fetchLocalitiesAction } = this.props;
        return fetchLocalitiesAction(region);
    };
    public handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        const { updateUserDataAction } = this.props;
        const { _id } = this.props.currentUser.user;
        updateUserDataAction(this.props.signUpForm, _id);
    };
    public render() {
        const { localities, regions, classes } = this.props;
        const { user } = this.props.currentUser;
        const {
            firstname,
            lastname,
            email,
            street,
            socialSecurityNumber,
            zip,
            locality,
            ytunnus,
            region,
            phonenumber,
            website,
            contactPerson,
            companyName,
        } = this.props.signUpForm;
        return (
            <form onSubmit={this.handleSubmit}>
                <Grid container={true}>
                    <Grid item={true} xs={12}>
                        <Box mt={3}>
                            <Typography variant="h6">
                                {user.business
                                    ? 'Yritys- ja Yhteystiedot'
                                    : 'Henkilö- ja Yhteystiedot'}
                            </Typography>
                        </Box>
                    </Grid>
                    {user.business ? (
                        <>
                            <Grid item={true} xs={12} sm={6}>
                                <Box p={2}>
                                    <TextField
                                        name="companyName"
                                        type="text"
                                        required={true}
                                        fullWidth={true}
                                        label="Yrityksen nimi"
                                        id="companyName-required"
                                        value={companyName || user.companyName}
                                        onChange={this.handleChange}
                                    />
                                </Box>
                            </Grid>
                            <Grid item={true} xs={12} sm={6}>
                                <Box p={2}>
                                    <TextField
                                        name="ytunnus"
                                        type="text"
                                        required={true}
                                        fullWidth={true}
                                        label="Y-Tunnus"
                                        id="ytunnus-required"
                                        value={ytunnus || user.ytunnus}
                                        onChange={this.handleChange}
                                    />
                                </Box>
                            </Grid>
                            <Grid item={true} xs={12} sm={6}>
                                <Box p={2}>
                                    <TextField
                                        name="website"
                                        type="text"
                                        fullWidth={true}
                                        label="Kotisuvun osoite"
                                        id="website-required"
                                        value={website || user.website}
                                        onChange={this.handleChange}
                                    />
                                </Box>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item={true} xs={12} sm={6}>
                                <Box p={2}>
                                    <TextField
                                        name="firstname"
                                        type="string"
                                        required={true}
                                        fullWidth={true}
                                        label="Etunimi"
                                        id="firstname-required"
                                        value={
                                            firstname ||
                                            user.contactInformation.firstname
                                        }
                                        onChange={this.handleChange}
                                    />
                                </Box>
                            </Grid>
                            <Grid item={true} xs={12} sm={6}>
                                <Box p={2}>
                                    <TextField
                                        name="lastname"
                                        type="string"
                                        required={true}
                                        fullWidth={true}
                                        label="Sukunimi"
                                        id="lastname-required"
                                        value={
                                            lastname ||
                                            user.contactInformation.lastname
                                        }
                                        onChange={this.handleChange}
                                    />
                                </Box>
                            </Grid>
                            <Grid item={true} xs={12} sm={6}>
                                <Box p={2}>
                                    <TextField
                                        name="companyName"
                                        type="string"
                                        fullWidth={true}
                                        label="Yrityksen nimi"
                                        id="companyName"
                                        value={companyName || user.companyName}
                                        onChange={this.handleChange}
                                    />
                                </Box>
                            </Grid>
                            <Grid item={true} xs={12} sm={6}>
                                <Box p={2}>
                                    <TextField
                                        name="socialSecurityNumber"
                                        type="string"
                                        required={true}
                                        fullWidth={true}
                                        label="Henkilötunnus tai Y-Tunnus"
                                        id="socialSecurityNumber-required"
                                        value={
                                            socialSecurityNumber ||
                                            user.socialSecurityNumber
                                        }
                                        onChange={this.handleChange}
                                    />
                                </Box>
                            </Grid>
                        </>
                    )}
                    <Grid item={true} xs={12} sm={6}>
                        <Box p={2}>
                            <TextField
                                name="phonenumber"
                                type="tel"
                                required={true}
                                fullWidth={true}
                                label="Puhelinnumero"
                                id="phonenumber-required"
                                value={
                                    phonenumber ||
                                    user.contactInformation.phonenumbers[0]
                                }
                                onChange={this.handleChange}
                            />
                        </Box>
                    </Grid>
                    <Grid item={true} xs={12} sm={6}>
                        <Box p={2}>
                            <TextField
                                name="email"
                                type="email"
                                required={true}
                                fullWidth={true}
                                label="Email"
                                id="email-required"
                                value={email || user.email}
                                onChange={this.handleChange}
                            />
                        </Box>
                    </Grid>
                    <Grid item={true} xs={12} sm={6}>
                        <Box p={2}>
                            <TextField
                                name="contactPerson"
                                type="text"
                                required={true}
                                fullWidth={true}
                                label="Yhteyshenkilö"
                                id="contactPerson-required"
                                value={contactPerson || user.contactPerson}
                                onChange={this.handleChange}
                            />
                        </Box>
                    </Grid>
                    <Grid item={true} xs={12}>
                        <Typography variant="h6" className={classes.marginTop2}>
                            Osoitetiedot
                        </Typography>
                    </Grid>
                    <Grid item={true} xs={12} sm={6}>
                        <Box p={2}>
                            <TextField
                                name="street"
                                type="text"
                                required={true}
                                fullWidth={true}
                                label="Katuosoite"
                                id="street-required"
                                value={street || user.address.street}
                                onChange={this.handleChange}
                            />
                        </Box>
                    </Grid>
                    <Grid item={true} xs={12} sm={6}>
                        <Box p={2}>
                            <TextField
                                name="zip"
                                type="text"
                                required={true}
                                fullWidth={true}
                                label="Postinumero"
                                id="zip-required"
                                value={zip || user.address.zip}
                                onChange={this.handleChange}
                            />
                        </Box>
                    </Grid>
                    <Grid item={true} xs={12} sm={6}>
                        <Box p={2}>
                            <FormControl fullWidth={true} required={true}>
                                <InputLabel id="demo-simple-select-required-label">
                                    Maakunta
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-required-label"
                                    id="demo-simple-select-required"
                                    name="region"
                                    value={region || user.address.region._id}
                                    onChange={this.handleChange}
                                >
                                    <MenuItem
                                        value={
                                            region || user.address.region._id
                                        }
                                    >
                                        <em>{user.address.region.name}</em>
                                    </MenuItem>
                                    {!!regions.length &&
                                        regions.map((region2: IRegion) => {
                                            return (
                                                <MenuItem
                                                    key={region2._id}
                                                    value={region2._id}
                                                >
                                                    {region2.name}
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                                <FormHelperText>
                                    Valitse toinen maakunta
                                </FormHelperText>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item={true} xs={12} sm={6}>
                        <Box p={2}>
                            <FormControl fullWidth={true} required={true}>
                                <InputLabel id="demo-simple-select-required-label">
                                    Kunta
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-required-label"
                                    id="demo-simple-select-required"
                                    name="locality"
                                    value={
                                        locality || user.address.locality._id
                                    }
                                    onChange={this.handleChange}
                                >
                                    <MenuItem
                                        value={
                                            locality ||
                                            user.address.locality._id
                                        }
                                    >
                                        <em>
                                            {user.address.locality.location}
                                        </em>
                                    </MenuItem>
                                    {!!localities.length &&
                                        localities.map(
                                            (locality2: ILocation) => {
                                                return (
                                                    <MenuItem
                                                        key={locality2._id}
                                                        value={locality2._id}
                                                    >
                                                        {locality2.location}
                                                    </MenuItem>
                                                );
                                            },
                                        )}
                                </Select>
                                <FormHelperText>
                                    Jos haluat muuttaa kuntaa muualle Suomeen,
                                    muuta ensin maakuntaa
                                </FormHelperText>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item={true} xs={12} className={classes.marginTop2}>
                        <Button
                            type="submit"
                            variant="outlined"
                            color="primary"
                        >
                            Päivitä
                        </Button>
                    </Grid>
                </Grid>
            </form>
        );
    }
}

function mapStateToProps(state: AppState): IUserAccountInfoFormStateProps {
    return {
        currentUser: state.currentUser,
        localities: state.localities,
        regions: state.regions,
        signUpForm: state.signUpFormState.formData,
    };
}

export default connect<
    IUserAccountInfoFormStateProps,
    IDispatchProps,
    {},
    AppState
>(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(UserAccountInfoForm));
