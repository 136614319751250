import React, { Component } from 'react';
import {
    List,
    ListSubheader,
    ListItem,
    Theme,
    createStyles,
    WithStyles,
    withStyles,
    Typography,
    ListItemText,
    ListItemIcon,
} from '@material-ui/core';
import {
    Category,
    Contacts,
    Assignment,
    Dashboard,
    ExitToApp,
    LocalActivityOutlined,
    PersonAdd,
} from '@material-ui/icons';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { AppState } from '../../../store/store';
import { mapDispatchToProps } from '../../../types/dispatchActionTypes';
import { connect } from 'react-redux';
import '../../../components/SideBar/SideBar.scss';
import { setAdminPanelUiStateValues } from '../../../utils/utils';
import { resetAdminPanelUiProps } from '../../../utils/resetUtils';
import { IAdminPanelUiState, ICurrentUser } from '../../../../../types';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            maxWidth: 360,
        },
        nested: {
            paddingLeft: theme.spacing(4),
            color: 'white',
        },
    });

interface IAdminSideBarStateProps {
    currentUser: ICurrentUser;
    adminPanelUiState: IAdminPanelUiState;
}

type AdminSideBarProps = IAdminSideBarStateProps &
    IDispatchProps &
    WithStyles<typeof styles> &
    RouteComponentProps;

class AdminSideBar extends Component<AdminSideBarProps, AppState> {
    public clickHandler = (key: string, value: boolean) => {
        const { updateAdminPanelUiStateAction, resetAdminPanelUiStateAction } =
            this.props;
        resetAdminPanelUiStateAction(resetAdminPanelUiProps());
        updateAdminPanelUiStateAction(setAdminPanelUiStateValues(key, value));
    };
    public logoutHandler = () => {
        const { logoutAction } = this.props;
        logoutAction();
        this.props.history.push('/');
    };
    public render() {
        const { classes, currentUser } = this.props;
        const { role } = currentUser.user;
        return (
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        Hallintapaneeli rooli: {role}
                    </ListSubheader>
                }
                className={classes.root}
            >
                <ListItem>
                    <Typography variant="body1">
                        <Link to="/">Etusivu</Link>
                    </Typography>
                </ListItem>
                <ListItem
                    tabIndex={0}
                    className="clickable"
                    onClick={() => this.clickHandler('showAdminPanel', true)}
                >
                    <ListItemIcon>
                        <Dashboard />
                    </ListItemIcon>
                    <ListItemText primary="Hallintapaneeli" />
                </ListItem>
                <ListItem
                    tabIndex={0}
                    className="clickable"
                    onClick={() => this.clickHandler('showUsers', true)}
                >
                    <ListItemIcon>
                        <Contacts />
                    </ListItemIcon>
                    <ListItemText primary="Käyttäjät" />
                </ListItem>
                <ListItem
                    tabIndex={0}
                    className="clickable"
                    onClick={() => this.clickHandler('createUser', true)}
                >
                    <ListItemIcon>
                        <PersonAdd />
                    </ListItemIcon>
                    <ListItemText primary="Luo käyttäjä" />
                </ListItem>
                <ListItem
                    tabIndex={0}
                    className="clickable"
                    onClick={() => this.clickHandler('showCategories', true)}
                >
                    <ListItemIcon>
                        <Category />
                    </ListItemIcon>
                    <ListItemText primary="Kategoriat" />
                </ListItem>
                <ListItem
                    tabIndex={0}
                    className="clickable"
                    onClick={() => this.clickHandler('showOrders', true)}
                >
                    <ListItemIcon>
                        <Assignment />
                    </ListItemIcon>
                    <ListItemText primary="Tilaukset" />
                </ListItem>
                <ListItem
                    tabIndex={0}
                    className="clickable"
                    onClick={() =>
                        this.clickHandler('showRegionsAndLocalities', true)
                    }
                >
                    <ListItemIcon>
                        <LocalActivityOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Maakunnat ja paikkakunnat" />
                </ListItem>
                <ListItem
                    tabIndex={0}
                    className="clickable"
                    onClick={this.logoutHandler}
                >
                    <ListItemIcon>
                        <ExitToApp />
                    </ListItemIcon>
                    <ListItemText primary="Kirjaudu ulos" />
                </ListItem>
            </List>
        );
    }
}

function mapStateToProps(state: AppState): IAdminSideBarStateProps {
    return {
        currentUser: state.currentUser,
        adminPanelUiState: state.adminPanelUiState,
    };
}

export default withRouter(
    connect<IAdminSideBarStateProps, IDispatchProps, {}, AppState>(
        mapStateToProps,
        mapDispatchToProps,
    )(withStyles(styles)(AdminSideBar)),
);
