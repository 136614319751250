import { IContact } from '../../../../types';
import {
    ContactsActionTypes,
    SET_CONTACTS,
    UPDATE_CONTACT,
} from '../../types/contactsActionTypes';

const DEFAULT_STATE: IContact[] = [];

const contactsReducer = (
    state = DEFAULT_STATE,
    action: ContactsActionTypes,
) => {
    switch (action.type) {
        case SET_CONTACTS:
            return action.contacts;
        case UPDATE_CONTACT:
            return state.map((contact: IContact) => {
                if (contact._id === action.contact._id) {
                    return {
                        ...contact,
                        ...action.contact,
                    };
                } else {
                    return contact;
                }
            });
        default:
            return state;
    }
};

export default contactsReducer;
