import React, { FC, Fragment } from 'react';
import {
    Grid,
    Card,
    CardHeader,
    CardContent,
    Typography,
    Button,
    CardMedia,
    InputBaseComponentProps,
    Box,
    Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import './SubscriptionCard.scss';
import { IAdvertisement } from '../../../../types';

interface SubscriptionCardProps {
    subscriptionCardItem: IAdvertisement;
    clickHandler?: (nextStep: number) => void;
    handleChange?: (event: InputBaseComponentProps) => string;
}

const useStyles = makeStyles((theme: Theme) => ({
    accountPlan: {
        marginTop: '2rem',
        textTransform: 'uppercase',
    },
    test: {
        marginTop: 'auto',
    },
    cardContent: {
        minHeight: '26rem',
    },
}));

const SubscriptionCard: FC<SubscriptionCardProps> = (props) => {
    const { subscriptionCardItem, clickHandler, handleChange } = props;
    const classes = useStyles();

    return (
        <Grid
            key={subscriptionCardItem._id}
            item={true}
            xs={12}
            sm={6}
            lg={5}
            xl={4}
        >
            <Box m={2}>
                <Card className="subscriptionCard">
                    <CardHeader
                        title={
                            <Typography
                                variant="h6"
                                className="subscriptionCardTitle"
                            >
                                {' '}
                                {subscriptionCardItem.title}
                            </Typography>
                        }
                        className="cardHeader"
                    />
                    <CardMedia
                        className="media"
                        image={subscriptionCardItem.image}
                        title={subscriptionCardItem.title}
                    />
                    <CardContent className={classes.cardContent}>
                        <Grid
                            container={true}
                            direction="column"
                            justify="center"
                            alignItems="center"
                        >
                            <Grid item={true}>
                                {subscriptionCardItem.descriptions.map(
                                    (description: any) => {
                                        return (
                                            <Fragment key={description}>
                                                <Typography variant="body1">
                                                    {description}
                                                </Typography>
                                            </Fragment>
                                        );
                                    },
                                )}
                                <div className="coral">
                                    <Typography variant="body1">
                                        Edullinen vuosimaksu, nyt vain
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>
                                            {subscriptionCardItem.priceTag +
                                                ', sis alv 24%'}
                                        </strong>
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="secondary"
                                    >
                                        {subscriptionCardItem.discount
                                            ? subscriptionCardItem.discount.info
                                            : ''}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item={true}>
                                <Typography
                                    color="secondary"
                                    variant="h5"
                                    className={classes.accountPlan}
                                >
                                    {subscriptionCardItem.accountPlan}
                                </Typography>
                                <Grid container={true} justify="center">
                                    <Grid item={true} xs={8}>
                                        <Box mt={4}>
                                            <div
                                                onClick={
                                                    clickHandler !== undefined
                                                        ? () => clickHandler(1)
                                                        : () => 1
                                                }
                                            >
                                                {window.location.pathname ===
                                                '/rekisteroidy' ? (
                                                    <Button
                                                        onClick={handleChange}
                                                        type="button"
                                                        name="accountPlan"
                                                        value={
                                                            subscriptionCardItem.accountPlan
                                                        }
                                                        data-price={
                                                            subscriptionCardItem.price
                                                        }
                                                        data-accuontplan={
                                                            subscriptionCardItem.accountPlan
                                                        }
                                                        variant="outlined"
                                                        color="primary"
                                                    >
                                                        Luo tili
                                                    </Button>
                                                ) : (
                                                    <Link
                                                        className="dark"
                                                        data-price={
                                                            subscriptionCardItem.price
                                                        }
                                                        data-accuonttype={
                                                            subscriptionCardItem.accountPlan
                                                        }
                                                        to="/rekisteroidy"
                                                    >
                                                        <Button
                                                            color="primary"
                                                            variant="outlined"
                                                        >
                                                            {' '}
                                                            Lisätietoa
                                                        </Button>
                                                    </Link>
                                                )}
                                            </div>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </Grid>
    );
};

export default SubscriptionCard;
