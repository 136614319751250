import { IContactFormState } from '../../../types';

export const UPDATE_CONTACT_FORM = 'UPDATE_CONTACT_FORM';
export const RESET_CONTACT_FORM = 'RESET_CONTACT_FORM';

export interface UpdateContactForm {
    type: typeof UPDATE_CONTACT_FORM;
    contactFormState: IContactFormState;
}

export interface ResetContactForm {
    type: typeof RESET_CONTACT_FORM;
    contactFormState: IContactFormState;
}

export type ContactFormActionTypes = UpdateContactForm | ResetContactForm;
