import {
    IAccount,
    IAccountSettingsForm,
    IAdminPanelUiProps,
    IBuyer,
    ICart,
    ICartBuyer,
    ICheckoutForm,
    IContact,
    IContactForm,
    IDeliveryCostForm,
    IFlashMessage,
    ILocalityForm,
    IOrder,
    IOrderTotals,
    IPartialLocality,
    IProductForm,
    IRegionForm,
    IResetPasswordForm,
    ISearchForm,
    IServiceForm,
    ISidebarProps,
    ISignUpForm,
    IUser,
    IUserAccount,
} from '../../../types';

export function resetCurrentUser(): IUser {
    return {
        _id: '',
        email: '',
        username: '',
        fullName: '',
        business: false,
        billingByPhone: false,
        person: false,
        socialSecurityNumber: '',
        companyName: '',
        contactInformation: {
            firstname: '',
            lastname: '',
            phonenumbers: [],
        },
        role: 'user',
        address: resetAddress(),
        createdAt: 0,
        updatedAt: 0,
        products: [],
        regionId: '',
        localityId: '',
        services: [],
        terms: false,
        contactPerson: '',
        userAccount: resetUserAccount(),
        accountDescription: '',
        deliveryMethod: 'Noutopiste ja kuljetus',
        deliveryLocations: [],
        verificationPincode: undefined,
        verificationPincodeExpires: undefined,
    };
}
export function resettedContactForm(): IContactForm {
    return {
        _id: '',
        name: '',
        email: '',
        subject: '',
        message: '',
    };
}
export function resetContact(): IContact {
    return {
        _id: '',
        name: '',
        email: '',
        subject: 'palaute',
        messages: [],
        token: {
            code: '',
            time: new Date(),
        },
        createdAt: 0,
        updatedAt: 0,
        author: resetCurrentUser(),
        admin: resetCurrentUser(),
        status: 'recieved',
    };
}
export function resetUserAccount(): IUserAccount {
    return {
        paid: false,
        isActive: false,
        expires: 0,
        timesActivated: 0,
        account: resetAccount(),
    };
}
export function resetAccount(): IAccount {
    return {
        _id: '',
        price: 0,
        discountedPrice: 0,
        promotedProducts: false,
        accountPlan: 'basic',
        timesActivated: 0,
        createdAt: 0,
        updatedAt: 0,
    };
}
function resetBuyer(): IBuyer {
    return {
        firstname: '',
        lastname: '',
        email: '',
        phonenumber: '',
        address: resetAddress(),
        terms: false,
    };
}
function resetCartBuyer(): ICartBuyer {
    return {
        firstname: '',
        lastname: '',
        email: '',
        phonenumber: '',
        terms: false,
        ...resetCartBuyerAddress(),
    };
}

export function resetOrder(): IOrder {
    return {
        items: [],
        buyer: resetBuyer(),
        seller: resetCurrentUser(),
        includeInAnalyses: false,
        delivery: false,
        deliveryCost: {
            name: '',
            unit: '',
            price: 0,
            tax: 0,
            vat: 2400,
            priceExcludingTax: 0,
        },
        orderNumber: '',
        _id: '',
        status: 'recieved',
        createdAt: 0,
        updatedAt: 0,
    };
}
export function resettedOrderTotals(): IOrderTotals {
    return {
        totalPriceExcludingTax: 0,
        totalTaxes: 0,
        totalPrice: 0,
    };
}
function resetAddress() {
    return {
        street: '',
        zip: '',
        locality: resetLocality(),
        region: resetRegion(),
        coords: {
            lat: 0,
            lng: 0,
            id: '',
        },
    };
}
function resetCartBuyerAddress() {
    return {
        street: '',
        zip: '',
        localityId: '',
        regionId: '',
        coords: {
            lat: 0,
            lng: 0,
            id: '',
        },
    };
}
export function resetCartFunction(): ICart {
    return {
        items: [],
        buyer: resetCartBuyer(),
        totalPrice: 0,
        tax: 0,
        totalPriceExcludingTax: 0,
        totalQuantity: 0,
        delivery: false,
        deliveryCost: null,
    };
}
export function resetLocality(): any {
    return {
        location: '',
        _id: '',
        zoom: 11,
        createdAt: 0,
        updatedAt: 0,
        region: resetRegion(),
        available: false,
        coords: {
            lat: 0,
            lng: 0,
            id: '',
        },
    };
}
export function resetPartialLocality(): IPartialLocality {
    return {
        locality: '',
        _id: '',
    };
}
export function resetRegion(): any {
    return {
        _id: '',
        createdAt: 0,
        updatedAt: 0,
        zoom: 8,
        name: '',
        coords: {
            lat: 0,
            lng: 0,
            id: '',
        },
    };
}
export function emptySignUpForm(): ISignUpForm {
    return {
        formValid: false,
        business: true,
        person: false,
        pincode: 0,
        accountPlan: undefined,
        email: '',
        username: '',
        password: '',
        confPassword: '',
        street: '',
        zip: '',
        locality: '',
        region: '',
        phonenumber: '',
        firstname: '',
        lastname: '',
        terms: true,
        socialSecurityNumber: '',
        ytunnus: '',
        companyName: '',
        website: '',
        contactPerson: '',
        contactByPhone: false,
    };
}
export function resetCheckoutFormFunction(): ICheckoutForm {
    return {
        email: '',
        street: '',
        zip: '',
        locality: '',
        region: '',
        phonenumber: '',
        firstname: '',
        lastname: '',
        terms: false,
    };
}
export function resetProductFormFunction(): IProductForm {
    return {
        name: '',
        category: '',
        price: 0,
        material: '',
        deliveryType: '',
        quality: '',
        quantity: 0,
        vat: 2400,
        plength: '',
        user_id: '',
        description: '',
        unit: '',
        discount: false,
    };
}
export function resetLocalityFormFunction(): ILocalityForm {
    return {
        location: '',
        zoom: 0,
        available: false,
        region: resetRegion(),
        coords: {
            lat: 0,
            lng: 0,
            id: '',
        },
    };
}
export function resetRegionFormFunction(): IRegionForm {
    return {
        name: '',
        zoom: 0,
        coords: {
            lat: 0,
            lng: 0,
            id: '',
        },
    };
}
export function resetServiceFormFunction(): IServiceForm {
    return {
        name: '',
        category: '',
        price: 0,
        quantity: 0,
        vat: 2400,
        unit: '',
        user_id: '',
        description: '',
        discount: false,
    };
}
export function resetAccountSettingsForm(): IAccountSettingsForm {
    return {
        deliveryLocationsSelections: [],
        accountDescription: '',
        deliveryMethod: 'Noutopiste ja kuljetus',
    };
}
export function resetSearchFormFunction(): ISearchForm {
    return {
        deliveryMethod: 'Noutopiste ja kuljetus',
        queryString: '',
        quality: '',
        material: '',
        category: 'Polttopuut',
        name: 'Polttopuu',
        locality: '',
        region: '',
        sellerId: '',
    };
}
export function resetSideBarState(): ISidebarProps {
    return {
        showOrders: false,
        showDeliveryCosts: false,
        showOrder: false,
        showUserInfo: false,
        showAccountSettings: false,
        showOtherProducts: false,
        showWoodProducts: false,
        showServices: false,
    };
}
export function resetAdminPanelUiProps(): IAdminPanelUiProps {
    return {
        showOrders: false,
        showOrder: false,
        showUsers: false,
        showAdminPanel: false,
        createUser: false,
        modalOpen: false,
        showMoreDetails: false,
        showRegionsAndLocalities: false,
        fetchData: false,
    };
}

export function resetDeliveryCostFormFunction(): IDeliveryCostForm {
    return {
        name: '',
        unit: '',
        price: 0,
        owner: '',
        available: true,
        multipliedByQuantity: false,
        edit: false,
        deliveryCostId: undefined,
    };
}

export function emptyResetPasswordForm(): IResetPasswordForm {
    return {
        pincode: 0,
        email: '',
        password: '',
        confPassword: '',
    };
}

export function resetMessage(): IFlashMessage {
    return {
        text: '',
        visible: false,
        bgColor: '',
    };
}

export const getNewOrder: (
    checkoutForm: ICheckoutForm,
    cart: ICart,
    sellerIds: string[],
) => any /** Order */ = (
    checkoutForm: ICheckoutForm,
    cart: ICart,
    sellerIds: string[],
): any /** Order */ => {
    const {
        firstname,
        lastname,
        street,
        zip,
        locality,
        region,
        email,
        phonenumber,
    } = checkoutForm;
    return {
        buyer: {
            firstname,
            lastname,
            email,
            phonenumber,
            address: {
                street,
                zip,
                locality,
                region,
            },
        },
        items: cart.items,
        sellers: sellerIds,
        status: 'recieved',
    };
};
