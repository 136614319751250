import { ILoading } from '../../../../types';
import {
    LoadingActionTypes,
    SET_LOADING,
} from '../../types/loadingActionTypes';

const DEFAULT_STATE: ILoading = {
    isLoading: false,
};

const isLoadingReducer = (
    state = DEFAULT_STATE,
    action: LoadingActionTypes,
) => {
    switch (action.type) {
        case SET_LOADING:
            return {
                ...state,
                isLoading: action.isLoading,
            };
        default:
            return state;
    }
};

export default isLoadingReducer;
