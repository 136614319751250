import { connect } from 'react-redux';
import React, { Component, FormEvent } from 'react';
import {
    TextField,
    Grid,
    InputBaseComponentProps,
    FormControl,
    Box,
    Button,
    InputLabel,
    FormControlLabel,
    Switch,
    Select,
    MenuItem,
    FormHelperText,
} from '@material-ui/core';
import { AppState } from '../../store/store';
import { mapDispatchToProps } from '../../types/dispatchActionTypes';
import { ICurrentUser, IDeliveryCostForm } from '../../../../types';
import { setDeliveryCostFormInputValues } from '../../utils/utils';
import { deliveryCostsOptionLists } from '../../formOptionList';

interface IDeliveryCostFormStateProps {
    deliveryCostForm: IDeliveryCostForm;
    currentUser: ICurrentUser;
}

type DeliveryCostFormProps = IDeliveryCostFormStateProps & IDispatchProps;

class DeliveryCostForm extends Component<DeliveryCostFormProps, AppState> {
    public componentDidMount() {
        const { updateDeliveryCostFormAction, currentUser } = this.props;
        updateDeliveryCostFormAction({ owner: currentUser.user._id });
    }
    public changeHandler = (event: InputBaseComponentProps) => {
        const { updateDeliveryCostFormAction } = this.props;
        updateDeliveryCostFormAction(setDeliveryCostFormInputValues(event));
    };
    public submitHandler = (event: FormEvent) => {
        event.preventDefault();
        const {
            createDeliveryCostsAction,
            deliveryCostForm,
            editDeliveryCostsAction,
        } = this.props;
        if (deliveryCostForm.deliveryCostId) {
            editDeliveryCostsAction(
                deliveryCostForm,
                deliveryCostForm.deliveryCostId,
            );
        } else {
            createDeliveryCostsAction(deliveryCostForm);
        }
    };
    public resetHandler = () => {
        const { resetDeliveryCostFormAction } = this.props;

        resetDeliveryCostFormAction({
            edit: false,
            unit: '',
            name: '',
            price: 0,
            available: true,
            multipliedByQuantity: false,
            deliveryCostId: undefined,
        });
    };
    public render() {
        const { deliveryCostForm } = this.props;
        const { DeliveryCostNames, DeliveryCostUnits } =
            deliveryCostsOptionLists;
        const { name, unit, price, available, edit, multipliedByQuantity } =
            deliveryCostForm;
        return (
            <form onSubmit={this.submitHandler}>
                <Grid container={true}>
                    <Grid item={true} xs={12} md={6}>
                        <Box p={1}>
                            <FormControl
                                variant="outlined"
                                fullWidth={true}
                                required={true}
                            >
                                <InputLabel id="demo-simple-select-required-label">
                                    Nimi
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-required-label"
                                    id="demo-simple-select-required"
                                    name="name"
                                    value={name}
                                    onChange={this.changeHandler}
                                    label="Nimi"
                                >
                                    <MenuItem value={name}>
                                        <em>Nimi</em>
                                    </MenuItem>
                                    {!!DeliveryCostNames.length &&
                                        DeliveryCostNames.map(
                                            (deliveryCostName: string) => {
                                                return (
                                                    <MenuItem
                                                        key={deliveryCostName}
                                                        value={deliveryCostName}
                                                    >
                                                        {deliveryCostName}
                                                    </MenuItem>
                                                );
                                            },
                                        )}
                                </Select>
                                <FormHelperText>Vaadittu kenttä</FormHelperText>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item={true} xs={12} md={6}>
                        <TextField
                            type="number"
                            label="Hinta"
                            required={true}
                            variant="outlined"
                            color="primary"
                            name="price"
                            onInput={this.changeHandler}
                            value={price}
                        />
                    </Grid>
                    <Grid item={true} xs={12} md={6}>
                        <Box p={1}>
                            <FormControl
                                variant="outlined"
                                fullWidth={true}
                                required={true}
                            >
                                <InputLabel id="demo-simple-select-required-label">
                                    Yksikkö
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-required-label"
                                    id="demo-simple-select-required"
                                    name="unit"
                                    value={unit}
                                    onChange={this.changeHandler}
                                    label="Yksikkö"
                                >
                                    <MenuItem value={unit}>
                                        <em>Yksikkö</em>
                                    </MenuItem>
                                    {!!DeliveryCostUnits.length &&
                                        DeliveryCostUnits.map(
                                            (deliveryCostUnit: string) => {
                                                return (
                                                    <MenuItem
                                                        key={deliveryCostUnit}
                                                        value={deliveryCostUnit}
                                                    >
                                                        {deliveryCostUnit}
                                                    </MenuItem>
                                                );
                                            },
                                        )}
                                </Select>
                                <FormHelperText>Vaadittu kenttä</FormHelperText>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item={true} xs={12} md={6}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={available}
                                    onChange={this.changeHandler}
                                    name="available"
                                    value={available ? true : false}
                                    color={available ? 'primary' : 'secondary'}
                                />
                            }
                            label={available ? 'Aktiivinen' : 'Pois käytöstä'}
                        />
                    </Grid>
                    <Grid item={true} xs={12} md={6}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={multipliedByQuantity}
                                    onChange={this.changeHandler}
                                    name="multipliedByQuantity"
                                    value={multipliedByQuantity ? true : false}
                                    color={
                                        multipliedByQuantity
                                            ? 'primary'
                                            : 'secondary'
                                    }
                                />
                            }
                            label={
                                multipliedByQuantity
                                    ? 'Kerrotaan määrällä'
                                    : 'Ei kerrota määrällä'
                            }
                        />
                    </Grid>
                    <Grid item={true} xs={12}>
                        {edit && (
                            <Button
                                onClick={this.resetHandler}
                                variant="outlined"
                                color="secondary"
                                type="reset"
                            >
                                Tyhjennä
                            </Button>
                        )}
                        <Button
                            variant="outlined"
                            color="primary"
                            type="submit"
                        >
                            {edit ? 'Muokkaa' : 'Luo'}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        );
    }
}

function mapStateToProps(state: AppState): IDeliveryCostFormStateProps {
    return {
        deliveryCostForm: state.deliveryCostForm.deliveryCostForm,
        currentUser: state.currentUser,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryCostForm);
