import { IProduct } from '../../../types';

export const SET_OTHER_USER_PRODUCTS = 'SET_OTHER_USER_PRODUCTS';
export const ADD_OTHER_USER_PRODUCT = 'ADD_OTHER_USER_PRODUCT';
export const EDIT_OTHER_USER_PRODUCT = 'EDIT_OTHER_USER_PRODUCT';
export const DELETE_OTHER_USER_PRODUCT = 'DELETE_OTHER_USER_PRODUCT';

export interface SetOtherUserProducts {
    type: typeof SET_OTHER_USER_PRODUCTS;
    otherUserProducts: IProduct[];
}

export interface AddOtherUserProduct {
    type: typeof ADD_OTHER_USER_PRODUCT;
    otherUserProduct: IProduct;
}

export interface EditOtherUserProduct {
    type: typeof EDIT_OTHER_USER_PRODUCT;
    otherUserProduct: IProduct;
}

export interface DeleteOtherUserProduct {
    type: typeof DELETE_OTHER_USER_PRODUCT;
    id: string;
}

export type OtherUserProductActionTypes =
    | SetOtherUserProducts
    | AddOtherUserProduct
    | EditOtherUserProduct
    | DeleteOtherUserProduct;
