import { Dispatch } from 'redux';
import { IUiProps } from '../../../../types';
import { apiCall, setHeader } from '../../apiRequests/apiCall';
import { AppActions } from '../../types/actions';
import { UPDATE_UISTATE } from '../../types/uiActionTypes';
import { setUiPropsValues } from '../../utils/utils';
import { addMessage } from './messageActions';

export function updateUiState(uiProps: IUiProps): AppActions {
    return {
        type: UPDATE_UISTATE,
        uiState: { uiProps },
    };
}

/** Private method */
export const verifyToken = (): ThunkResult<void> => {
    setHeader('get', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('get', '/api/users/verifyToken', null)
                .then((res: any) => {
                    dispatch(
                        updateUiState(setUiPropsValues('responseStatus', 0)),
                    );
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};
