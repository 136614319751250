import { ISidebarProps } from '../../../../types';
import { AppActions } from '../../types/actions';
import { UPDATE_SIDEBAR, RESET_SIDEBAR } from '../../types/sidebarActionTypes';

export function updateSidebar(sidebarProps: ISidebarProps): AppActions {
    return {
        type: UPDATE_SIDEBAR,
        sidebarState: { sidebarProps },
    };
}

export function resetSidebar(sidebarProps: ISidebarProps): AppActions {
    return {
        type: RESET_SIDEBAR,
        sidebarState: { sidebarProps },
    };
}
