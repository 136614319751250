import { apiCall, setHeader } from '../../apiRequests/apiCall';
import { AppActions } from '../../types/actions';
import { Dispatch } from 'redux';
import { addMessage } from './messageActions';
import {
    UPDATE_ACCOUNT,
    ADD_ACCOUNT,
    REMOVE_ACCOUNT,
    SET_ACCOUNTS,
} from '../../types/accountActionTypes';
import { IAccount, IAccountForm } from '../../../../types';

/** Public Method */
export function setAccounts(accounts: IAccount[]): AppActions {
    return {
        type: SET_ACCOUNTS,
        accounts,
    };
}

/** Restricted access to admins only, for Admins */
export function addAccount(account: IAccount): AppActions {
    return {
        type: ADD_ACCOUNT,
        account,
    };
}

export function updateAccount(account: IAccount): AppActions {
    return {
        type: UPDATE_ACCOUNT,
        account,
    };
}

export function removeAccount(id: string): AppActions {
    return {
        type: REMOVE_ACCOUNT,
        id,
    };
}
/** Public method */
export const fetchAccounts = (query: string): ThunkResult<void> => {
    setHeader('get', '');
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('get', '/api/account' + query, null)
                .then((res: any) => {
                    const { accounts } = res;
                    dispatch(setAccounts(accounts));
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

/** Restricted access to admins only, for Admins */
export const createAccount = (formData: IAccountForm): ThunkResult<void> => {
    setHeader('post', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('post', '/api/account', formData)
                .then((res: any) => {
                    const { account } = res;
                    dispatch(addAccount(account));
                    dispatch(
                        addMessage({
                            text: res.message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

export const editAccount = (
    formData: IAccountForm,
    accountId: string,
): ThunkResult<void> => {
    setHeader('put', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('put', '/api/account/' + accountId, formData)
                .then((res: any) => {
                    const { account, message } = res;
                    dispatch(updateAccount(account));
                    dispatch(
                        addMessage({
                            text: message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

/** Restricted access to admins only, for Admins */
export const deleteAccount = (id: string): ThunkResult<void> => {
    setHeader('delete', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('delete', '/api/account/' + id, {})
                .then((res: any) => {
                    const { message } = res;
                    dispatch(removeAccount(id));
                    dispatch(
                        addMessage({
                            text: message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};
