import React, { FC } from 'react';
import { Card, Typography } from '@material-ui/core';
import { AccountBox, Assignment, Contacts, People } from '@material-ui/icons';
import CardIcon from './CardIcon';
import CardHeader from './CardHeader';
import CardFooter from './CardFooter';

interface ISmallCardProps {
    header: string;
    message: string;
    icon: Icon;
    color: colors;
}

export type colors =
    | 'warning'
    | 'success'
    | 'danger'
    | 'info'
    | 'purple'
    | 'rose';
type Icon = 'Assignment' | 'People' | 'AccountBox' | 'Contacts';

const SmallCard: FC<ISmallCardProps> = (props) => {
    const { header, message, icon, color } = props;
    return (
        <Card className="card">
            <CardHeader
                className="card_header"
                color="warning"
                icon={true}
                stats={true}
                plain={true}
            >
                <CardIcon
                    children={
                        icon === 'Assignment' ? (
                            <Assignment />
                        ) : icon === 'People' ? (
                            <People />
                        ) : icon === 'AccountBox' ? (
                            <AccountBox />
                        ) : (
                            <Contacts />
                        )
                    }
                    color={color}
                />
                <Typography variant="h6" className="card_header__heading">
                    {header}
                </Typography>
            </CardHeader>
            <CardFooter className="card_footer">
                <Typography variant="body1">{message}</Typography>
            </CardFooter>
        </Card>
    );
};

export default SmallCard;
