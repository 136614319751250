import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../store/store';
import { AppActions } from './actions';
import {
    fetchLocalities,
    editLocality,
    deleteLocality,
    createLocality,
} from '../store/actions/localitiesActions';
import { addMessage, removeMessage } from '../store/actions/messageActions';
import {
    setCurrentUser,
    removeCurrentUser,
    fetchUser,
    signUpUser,
    fetchProfile,
    updateUserData,
    logout,
    verifyPincode,
    requestResetPasswordPincode,
    requestPasswordReset,
    requestPincodeValidation,
    reFetchUser,
} from '../store/actions/userAuthActions';
import { updateUiState, verifyToken } from '../store/actions/uiActions';
import {
    updateSignUpForm,
    resetSignUpForm,
} from '../store/actions/signUpFormActions';
import {
    createRegion,
    deleteRegion,
    editRegion,
    fetchRegions,
} from '../store/actions/regionActions';
import {
    updateProductForm,
    resetProductForm,
} from '../store/actions/productFormActions';
import {
    createProduct,
    editProduct,
    fetchUserProducts,
    deleteProduct,
    editProductsImage,
} from '../store/actions/userProductAction';
import { updateSidebar, resetSidebar } from '../store/actions/sidebarActions';
import {
    addToCart,
    removeFromCart,
    fetchCart,
    resetCart,
    editCart,
    addDeliveryCost,
    addAddress,
} from '../store/actions/cartActions';
import { updateCheckoutForm } from '../store/actions/checkoutFormActions';
import {
    createOrder,
    fetchOrders,
    fetchOrder,
    updateOrder,
} from '../store/actions/orderActions';
import { fetchSellers } from '../store/actions/sellersActions';
import {
    resetSearchForm,
    updateSearchForm,
} from '../store/actions/searchFormActions';
import {
    updateAdminPanelUiState,
    resetAdminPanelUiState,
} from '../store/actions/admin/adminPanelUiStateActions';
import { updateGoogleMapsState } from '../store/actions/googleMapsStateActions';
import {
    editAccount,
    fetchAccounts,
    createAccount,
    deleteAccount,
} from '../store/actions/accountActions';
import {
    fetchAdvertisements,
    editAdvertisement,
    deleteAdvertisement,
    createAdvertisement,
} from '../store/actions/advertisementsActions';
import {
    resetResetPasswordForm,
    updateResetPasswordForm,
} from '../store/actions/resetPasswordFormActions';
import {
    updateAccountSettingsForm,
    resetAccountSettingsForm,
    updateAccountSettings,
} from '../store/actions/accountSettingsFormActions';
import { fetchLocalitiesByRegion } from '../store/actions/localitiesByRegionActions';
import {
    resetOrderTotals,
    setOrderTotals,
} from '../store/actions/orderTotalsActions';
import {
    resetCartFunction,
    resetSearchFormFunction,
    resettedContactForm,
    resettedOrderTotals,
} from '../utils/resetUtils';
import {
    addFavoriteCall,
    fetchFavorites,
    removeFavoriteCall,
} from '../store/actions/favoritesActions';
import {
    fetchNewUsers,
    fetchUsers,
    editUserAccountStatus,
    deleteUser,
    deleteUsers,
    createUser,
    editUser,
} from '../store/actions/admin/usersActions';
import {
    fetchAdminPanelPageData,
    resetAdminPanelPageState,
    updateAdminPanelPageState,
} from '../store/actions/admin/adminPanelPageActions';
import {
    resetContactForm,
    updateContactForm,
} from '../store/actions/contactFormActions';
import {
    createContact,
    fetchContact,
    fetchContacts,
    fetchTicket,
    respondToContactFromAdmin,
    respondToContact,
    updateContactsStatus,
} from '../store/actions/contactsActions';
import {
    resetServiceForm,
    updateServiceForm,
} from '../store/actions/serviceFormActions';
import {
    createService,
    deleteService,
    editService,
} from '../store/actions/userServiceActions';
import {
    resetLocalityForm,
    updateLocalityForm,
} from '../store/actions/localityFormActions';
import {
    resetRegionForm,
    updateRegionForm,
} from '../store/actions/regionFormActions';
import {
    addToIds,
    removeFromIds,
    resetIds,
    updateIds,
} from '../store/actions/admin/idsActions';
import {
    resetUserForm,
    updateUserForm,
} from '../store/actions/admin/userFormActions';
import {
    ContactStatus,
    IAccountForm,
    IAccountSettingsForm,
    IAccountSettingsFormState,
    IAdminPanelPage,
    IAdminPanelUiProps,
    IAdvertisementForm,
    ICartItem,
    ICheckoutForm,
    IContactForm,
    IFlashMessage,
    IGoogleMapsProps,
    ILocalityForm,
    ILoginData,
    IOrder,
    IUser,
    IOrderTotals,
    IProductForm,
    IRegionForm,
    IResetPasswordForm,
    IResetPasswordFormState,
    ISearchForm,
    IServiceForm,
    ISidebarProps,
    ISignUpForm,
    ISignUpFormState,
    IUiProps,
    IUserForm,
    IUserFormState,
    OrderStatus,
    IDeliveryCostForm,
    IProduct,
} from '../../../types';
import { fetchProducts, setProducts } from '../store/actions/productActions';
import {
    createDeliveryCost,
    deleteDeliveryCost,
    editDeliveryCost,
    fetchDeliveryCosts,
} from '../store/actions/deliveryCostActions';
import {
    resetDeliveryCostForm,
    updateDeliveryCostForm,
} from '../store/actions/deliveryCostFormActions';

export function mapDispatchToProps(
    dispatch: ThunkDispatch<AppState, undefined, AppActions>,
): IDispatchProps {
    return {
        /** VerifyToken action */
        verifyTokenAction() {
            dispatch(verifyToken());
        },
        /** Product actions */
        fetchProductsAction(userId: string) {
            dispatch(fetchProducts(userId));
        },
        setProductsAction(products: IProduct[]) {
            dispatch(setProducts(products));
        },
        fetchUserProductsAction(userId: string) {
            dispatch(fetchUserProducts(userId));
        },
        createProductAction(productForm: IProductForm) {
            dispatch(createProduct(productForm));
        },
        editProductsImageAction(formData: FormData, productId: string) {
            dispatch(editProductsImage(formData, productId));
        },
        editProductAction(productForm: IProductForm, productId: string) {
            dispatch(editProduct(productForm, productId));
        },
        deleteProductAction(id: string) {
            dispatch(deleteProduct(id));
        },
        /** DeliveryCosts actions */
        fetchDeliveryCostsAction(query: string) {
            dispatch(fetchDeliveryCosts(query));
        },
        createDeliveryCostsAction(deliveryCostForm: IDeliveryCostForm) {
            dispatch(createDeliveryCost(deliveryCostForm));
        },
        editDeliveryCostsAction(
            deliveryCostForm: IDeliveryCostForm,
            deliveryCostId: string,
        ) {
            dispatch(editDeliveryCost(deliveryCostForm, deliveryCostId));
        },
        deleteDeliveryCostsAction(deliveryCostId: string) {
            dispatch(deleteDeliveryCost(deliveryCostId));
        },
        updateDeliveryCostFormAction(deliveryCostForm: IDeliveryCostForm) {
            dispatch(updateDeliveryCostForm(deliveryCostForm));
        },
        resetDeliveryCostFormAction(deliveryCostForm: IDeliveryCostForm) {
            dispatch(resetDeliveryCostForm(deliveryCostForm));
        },
        /** Services actions */
        createServiceAction(serviceForm: IServiceForm) {
            dispatch(createService(serviceForm));
        },
        editServiceAction(serviceForm: IServiceForm, serviceId: string) {
            dispatch(editService(serviceForm, serviceId));
        },
        deleteServiceAction(id: string) {
            dispatch(deleteService(id));
        },
        /** Favorites actions */
        fetchFavoritesAction() {
            dispatch(fetchFavorites());
        },
        addFavoriteAction(id: string) {
            dispatch(addFavoriteCall(id));
        },
        removeFavoriteAction(id: string) {
            dispatch(removeFavoriteCall(id));
        },
        /** Product Formn actions */
        updateProductFormAction(productForm: IProductForm) {
            dispatch(updateProductForm(productForm));
        },
        resetProductFormAction(productForm: IProductForm) {
            dispatch(resetProductForm(productForm));
        },
        /** Service Formn actions */
        updateServiceFormAction(serviceForm: IServiceForm) {
            dispatch(updateServiceForm(serviceForm));
        },
        resetServiceFormAction(serviceForm: IServiceForm) {
            dispatch(resetServiceForm(serviceForm));
        },
        /** Locality actions */
        fetchLocalitiesAction(query: string) {
            dispatch(fetchLocalities(query));
        },
        createLocalityAction(localityForm: ILocalityForm) {
            dispatch(createLocality(localityForm));
        },
        editLocalityAction(localityForm: ILocalityForm, localityId: string) {
            dispatch(editLocality(localityForm, localityId));
        },
        deleteLocalityAction(id: string) {
            dispatch(deleteLocality(id));
        },
        fetchLocalitiesByRegionAction() {
            dispatch(fetchLocalitiesByRegion());
        },
        /** LovalityForm Actions */
        updateLocalityFormAction(localityForm: ILocalityForm) {
            dispatch(updateLocalityForm(localityForm));
        },
        resetLocalityFormAction(localityForm: ILocalityForm) {
            dispatch(resetLocalityForm(localityForm));
        },
        /** Account Actions */
        fetchAccountsAction(query: string) {
            dispatch(fetchAccounts(query));
        },
        createAccountAction(accountForm: IAccountForm) {
            dispatch(createAccount(accountForm));
        },
        editAccountAction(accountForm: IAccountForm, accountId: string) {
            dispatch(editAccount(accountForm, accountId));
        },
        deleteAccountAction(id: string) {
            dispatch(deleteAccount(id));
        },
        /** Advertisements Actions */
        fetchAdvertisementsAction(query: string) {
            dispatch(fetchAdvertisements(query));
        },
        createAdvertisementAction(advertisementForm: IAdvertisementForm) {
            dispatch(createAdvertisement(advertisementForm));
        },
        editAdvertisementAction(
            advertisementForm: IAdvertisementForm,
            advertisementId: string,
        ) {
            dispatch(editAdvertisement(advertisementForm, advertisementId));
        },
        deleteAdvertisementAction(id: string) {
            dispatch(deleteAdvertisement(id));
        },
        /** Region Actions */
        fetchRegionsAction(query: string) {
            dispatch(fetchRegions(query));
        },
        createRegionAction(regionForm: IRegionForm) {
            dispatch(createRegion(regionForm));
        },
        editRegionAction(regionForm: IRegionForm, regionId: string) {
            dispatch(editRegion(regionForm, regionId));
        },
        deleteRegionAction(id: string) {
            dispatch(deleteRegion(id));
        },
        /** RegionForm Actions */
        updateRegionFormAction(regionForm: IRegionForm) {
            dispatch(updateRegionForm(regionForm));
        },
        resetRegionFormAction(regionForm: IRegionForm) {
            dispatch(resetRegionForm(regionForm));
        },
        /** Sellers Action Types */
        fetchSellersAction(query: ISearchForm) {
            dispatch(fetchSellers(query));
        },
        /** Message Actions */
        addMessageAction(message: IFlashMessage) {
            dispatch(addMessage(message));
        },
        removeMessageAction(message: IFlashMessage) {
            dispatch(removeMessage(message));
        },
        /** Current User actions */
        setCurrentUserAction(
            user: IUser,
            isAuthenticated: boolean,
            isAdmin: boolean,
        ) {
            dispatch(setCurrentUser(user, isAuthenticated, isAdmin));
        },
        removeCurrentUserAction(
            user: IUser,
            isAuthenticated: boolean,
            isAdmin: boolean,
        ) {
            dispatch(removeCurrentUser(user, isAuthenticated, isAdmin));
        },
        signUpUserAction(formData: ISignUpForm) {
            dispatch(signUpUser(formData));
        },
        verifyPincodeAction(formData: ISignUpForm) {
            dispatch(verifyPincode(formData));
        },
        updateUserDataAction(formData: ISignUpForm, id: string) {
            dispatch(updateUserData(formData, id));
        },
        loginAction(loginData: ILoginData) {
            dispatch(fetchUser(loginData));
        },
        fetchUserAction(username: string) {
            dispatch(fetchProfile(username));
        },
        reFetchUserAction(userId: string) {
            dispatch(reFetchUser(userId));
        },
        fetchProfileAction(id: string) {
            dispatch(fetchProfile(id));
        },
        logoutAction() {
            dispatch(logout());
        },
        /** password recovery form actions */
        updateResetPasswordFormAction(resetPasswordForm: IResetPasswordForm) {
            dispatch(updateResetPasswordForm(resetPasswordForm));
        },
        resetResetPasswordFormAction(
            resetPasswordFormState: IResetPasswordFormState,
        ) {
            dispatch(resetResetPasswordForm(resetPasswordFormState));
        },
        requestResetPasswordPincodeAction(
            resetPasswordForm: IResetPasswordForm,
        ) {
            dispatch(requestResetPasswordPincode(resetPasswordForm));
        },
        requestPasswordResetAction(resetPasswordForm: IResetPasswordForm) {
            dispatch(requestPasswordReset(resetPasswordForm));
        },
        requestPincodeValidationAction(resetPasswordForm: IResetPasswordForm) {
            dispatch(requestPincodeValidation(resetPasswordForm));
        },
        /** Ui action */
        updateUiStateAction(uiProps: IUiProps) {
            dispatch(updateUiState(uiProps));
        },
        updateSideBarAction(sidebarProps: ISidebarProps) {
            dispatch(updateSidebar(sidebarProps));
        },
        resetSideBarAction(sidebarProps: ISidebarProps) {
            dispatch(resetSidebar(sidebarProps));
        },
        /** SignUp Form Actions */
        updateSignUpFormAction(formData: ISignUpForm) {
            dispatch(updateSignUpForm(formData));
        },
        resetSignUpFormAction(signUpFormState: ISignUpFormState) {
            dispatch(resetSignUpForm(signUpFormState));
        },
        /** UserForm Actions */
        updateUserFormAction(userForm: IUserForm) {
            dispatch(updateUserForm(userForm));
        },
        resetUserFormAction(userFormState: IUserFormState) {
            dispatch(resetUserForm(userFormState));
        },
        createUserAction(userForm: IUserForm) {
            dispatch(createUser(userForm));
        },
        /** AccountSettingsForm actions */
        updateAccountSettingsAction(
            accountSettingsForm: IAccountSettingsForm,
            accountId: string,
        ) {
            dispatch(updateAccountSettings(accountSettingsForm, accountId));
        },
        updateAccountSettingsFormAction(
            accountSettingsForm: IAccountSettingsForm,
        ) {
            dispatch(updateAccountSettingsForm(accountSettingsForm));
        },
        resetAccountSettingsFormAction(
            accountSettingsFormState: IAccountSettingsFormState,
        ) {
            dispatch(resetAccountSettingsForm(accountSettingsFormState));
        },
        /** Search form action */
        updateSearchFormAction(searchForm: ISearchForm) {
            dispatch(updateSearchForm(searchForm));
        },
        resetSearchFormAction() {
            dispatch(
                resetSearchForm({ searchForm: resetSearchFormFunction() }),
            );
        },
        /** GoogleMaps State */
        updateGoogleMapsStateAction(googleMapsProps: IGoogleMapsProps) {
            dispatch(updateGoogleMapsState(googleMapsProps));
        },
        /** Cart actions */
        fetchCartAction() {
            dispatch(fetchCart());
        },
        addToCartAction(id: string) {
            dispatch(addToCart(id));
        },
        addAddressAction(checkoutForm: ICheckoutForm) {
            dispatch(addAddress(checkoutForm));
        },
        addDeliveryCostAction(ownerId: string) {
            dispatch(addDeliveryCost(ownerId));
        },
        removeFromCartAction(id: string) {
            dispatch(removeFromCart(id));
        },
        editCartAction(cartItem: ICartItem) {
            dispatch(editCart(cartItem));
        },
        resetCartAction() {
            dispatch(resetCart(resetCartFunction()));
        },
        updateCheckoutFormAction(checkoutForm: ICheckoutForm) {
            dispatch(updateCheckoutForm(checkoutForm));
        },
        /** Order Actions */
        createOrderAction(order: IOrder) {
            dispatch(createOrder(order));
        },
        fetchOrderAction(id: string) {
            dispatch(fetchOrder(id));
        },
        updateOrderAction(id: string, status: OrderStatus) {
            dispatch(updateOrder(id, status));
        },
        fetchOrdersAction(query: string) {
            dispatch(fetchOrders(query));
        },
        /** OrderTotals Actions */
        setOrderTotalsAction(orderTotals: IOrderTotals) {
            dispatch(setOrderTotals(orderTotals));
        },
        resetOrderTotalsAction() {
            dispatch(resetOrderTotals(resettedOrderTotals()));
        },
        /** Contacts Actions */
        fetchTicketAction(id: string, token: string) {
            dispatch(fetchTicket(id, token));
        },
        fetchContactsAction(query: string) {
            dispatch(fetchContacts(query));
        },
        fetchContactAction(id: string) {
            dispatch(fetchContact(id));
        },
        createContactAction(contactForm: IContactForm) {
            dispatch(createContact(contactForm));
        },
        editContactAction(id: string, status: ContactStatus) {
            dispatch(updateContactsStatus(id, status));
        },
        respondToContactFromAdminAction(id: string, message: string) {
            dispatch(respondToContactFromAdmin(id, message));
        },
        respondToContactAction(
            id: string,
            message: string,
            userName: string,
            token: string,
        ) {
            dispatch(respondToContact(id, message, userName, token));
        },
        updateContactFormAction(contactForm: IContactForm) {
            dispatch(updateContactForm(contactForm));
        },
        resetContactFormAction() {
            dispatch(resetContactForm(resettedContactForm()));
        },
        /** ADMIN */
        /** AdminPanelUiState */
        updateAdminPanelUiStateAction(adminPanelUiProps: IAdminPanelUiProps) {
            dispatch(updateAdminPanelUiState(adminPanelUiProps));
        },
        resetAdminPanelUiStateAction(adminPanelUiProps: IAdminPanelUiProps) {
            dispatch(resetAdminPanelUiState(adminPanelUiProps));
        },
        fetchUsersAction(query: string) {
            dispatch(fetchUsers(query));
        },
        editUserAction(userForm: IUserForm, userId: string) {
            dispatch(editUser(userForm, userId));
        },
        editUserAccountStatusAction(
            id: string,
            active: boolean,
            newUser?: boolean,
        ) {
            dispatch(editUserAccountStatus(id, active, newUser));
        },
        deleteUserAction(id: string) {
            dispatch(deleteUser(id));
        },
        deleteUsersAction(ids: string[]) {
            dispatch(deleteUsers(ids));
        },
        updateAdminPanelPageAction(adminPanelPage: IAdminPanelPage) {
            dispatch(updateAdminPanelPageState(adminPanelPage));
        },
        resetAdminPanelPageAction(adminPanelPage: IAdminPanelPage) {
            dispatch(resetAdminPanelPageState(adminPanelPage));
        },
        fetchAdminPanelPageDataAction() {
            dispatch(fetchAdminPanelPageData());
        },
        fetchNewUsersAction() {
            dispatch(fetchNewUsers(''));
        },
        /** Ids Actions */
        addToIdsAction(id: string) {
            dispatch(addToIds(id));
        },
        removeFromIdsAction(id: string) {
            dispatch(removeFromIds(id));
        },
        updateIdsAction(ids: string[]) {
            dispatch(updateIds(ids));
        },
        resetIdsAction(ids: []) {
            dispatch(resetIds(ids));
        },
    };
}
