import { IUserFormState } from '../../../../types';

export const UPDATE_USER_FORM = 'UPDATE_USER_FORM';
export const RESET_USER_FORM = 'RESET_USER_FORM';

export interface UpdateUserForm {
    type: typeof UPDATE_USER_FORM;
    userFormState: IUserFormState;
}

export interface ResetUserFormState {
    type: typeof RESET_USER_FORM;
    userFormState: IUserFormState;
}

export type UserFormActionTypes = UpdateUserForm | ResetUserFormState;
