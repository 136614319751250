import React, { Component, Fragment } from 'react';
import './Cart.scss';
import { AppState } from '../../store/store';
import { mapDispatchToProps } from '../../types/dispatchActionTypes';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Typography, Grid, Theme } from '@material-ui/core';
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles';
import CartTable from './CartTable';
import MainStepper from '../../components/Stepper/MainStepper';
import { setUiPropsValues } from '../../utils/utils';
import Confirmation from './Confirmation';
import { getNewOrder } from '../../utils/resetUtils';
import {
    ICart,
    ICartItem,
    ICheckoutForm,
    IOrderTotals,
    IStep,
    IUiProps,
    IUser,
    IOrder,
} from '../../../../types';

interface CartStateProps {
    cart: ICart;
    uiProps: IUiProps;
    currentUser: IUser;
    checkoutForm: ICheckoutForm;
    order: IOrder;
    orderTotals: IOrderTotals;
}

const steps: IStep[] = [
    { label: 'Ostoskori', _id: 0 },
    { label: 'Yhteystiedot', _id: 1 },
    { label: 'Toimitus', _id: 2 },
    { label: 'Vahvistus', _id: 3 },
];
type CartProps = CartStateProps &
    IDispatchProps &
    RouteComponentProps &
    WithStyles<typeof styles>;

const styles = (theme: Theme) =>
    createStyles({
        mainContainer: {
            marginTop: '7rem',
        },
        mainGrid: {
            marginBottom: '14rem',
        },
    });

class Cart extends Component<CartProps, AppState> {
    // tslint:disable-next-line
    public removeItemHandler = (_id: string) => {
        const { removeFromCartAction } = this.props;
        // tslint:disable-next-line
        removeFromCartAction(_id);
    };
    public showCheckOutHandler = (activeStep: number | undefined) => {
        const { updateUiStateAction } = this.props;
        updateUiStateAction(setUiPropsValues('activeStep', activeStep));
    };
    public submitOrder = (activeStep: number | undefined) => {
        const { cart, checkoutForm } = this.props;
        const { createOrderAction, updateUiStateAction } = this.props;
        const sellerIds = cart.items.map((item: ICartItem) => {
            return item.ownerId;
        });
        const newOrder = getNewOrder(checkoutForm, cart, sellerIds);
        createOrderAction(newOrder);
        updateUiStateAction(setUiPropsValues('activeStep', activeStep));
    };
    public submitAddress = (activeStep: number | undefined) => {
        const { checkoutForm } = this.props;
        const { addAddressAction, updateUiStateAction } = this.props;
        updateUiStateAction(setUiPropsValues('activeStep', activeStep));
        addAddressAction(checkoutForm);
    };
    public render() {
        const { order, classes, orderTotals } = this.props;
        const { activeStep } = this.props.uiProps;

        return (
            <Fragment>
                <section id="cart" className="py-2">
                    <Grid
                        container={true}
                        justify="center"
                        className={classes.mainContainer}
                    >
                        <Grid
                            item={true}
                            xs={12}
                            sm={10}
                            lg={9}
                            className={classes.mainGrid}
                        >
                            <Typography variant="h4">Ostoskori</Typography>
                            <MainStepper
                                activeStep={activeStep}
                                steps={steps}
                            />
                            {activeStep === 0 && (
                                <CartTable
                                    activeStep={activeStep}
                                    removeItemHandler={this.removeItemHandler}
                                    changeactiveStep={this.showCheckOutHandler}
                                    submitOrder={this.submitOrder}
                                    edit
                                    submitAddress={this.submitAddress}
                                />
                            )}
                            {activeStep === 1 && (
                                <CartTable
                                    activeStep={activeStep}
                                    removeItemHandler={this.removeItemHandler}
                                    changeactiveStep={this.showCheckOutHandler}
                                    submitOrder={this.submitOrder}
                                    submitAddress={this.submitAddress}
                                />
                            )}
                            {activeStep === 2 && (
                                <CartTable
                                    activeStep={activeStep}
                                    removeItemHandler={this.removeItemHandler}
                                    changeactiveStep={this.showCheckOutHandler}
                                    submitOrder={this.submitOrder}
                                    submitAddress={this.submitAddress}
                                />
                            )}
                            {activeStep === 3 && (
                                <Confirmation
                                    order={order}
                                    orderTotals={orderTotals}
                                />
                            )}
                        </Grid>
                    </Grid>
                </section>
            </Fragment>
        );
    }
}

function mapStateToProps(state: AppState): CartStateProps {
    return {
        cart: state.cartState,
        uiProps: state.uiState.uiProps,
        checkoutForm: state.checkoutFormState.checkoutForm,
        currentUser: state.currentUser.user,
        order: state.order,
        orderTotals: state.orderTotals,
    };
}

export default withRouter(
    connect<CartStateProps, IDispatchProps, {}, AppState>(
        mapStateToProps,
        mapDispatchToProps,
    )(withStyles(styles)(Cart)),
);
