import {
    ADD_TO_IDS,
    IdsActionTypes,
    REMOVE_FROM_IDS,
    RESET_IDS,
    UPDATE_IDS,
} from '../../../types/admin/idsActionTypes';

const DEFAULT_STATE: (string | string[])[] = [];

const idsReducer = (state = DEFAULT_STATE, action: IdsActionTypes) => {
    switch (action.type) {
        case ADD_TO_IDS:
            return !state.includes(action.id) ? [...state, action.id] : state;
        case REMOVE_FROM_IDS:
            return state.filter((id) => id !== action.id);
        case UPDATE_IDS:
            return [...state, action.ids];
        case RESET_IDS:
            return action.ids;
        default:
            return state;
    }
};

export default idsReducer;
