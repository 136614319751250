import {
    Box,
    Button,
    Grid,
    InputBaseComponentProps,
    TextField,
    Typography,
} from '@material-ui/core';
import React, { Component, FormEvent } from 'react';
import { connect } from 'react-redux';
import { ILocalityForm, ILocation } from '../../../../../../../types';
import { AppState } from '../../../../../store/store';
import { mapDispatchToProps } from '../../../../../types/dispatchActionTypes';
import { setLocalityFormInputValues } from '../../../../../utils/utils';

interface ILocalitiesFormStateProps {
    localities: ILocation[];
    localityForm: ILocalityForm;
}

interface IOwnProps {
    editLocality?: boolean;
    localityId?: string;
}

type LocalityFormProps = ILocalitiesFormStateProps & IOwnProps & IDispatchProps;

class LocalityForm extends Component<LocalityFormProps, AppState> {
    public changeHandler = (event: InputBaseComponentProps) => {
        const { updateLocalityFormAction } = this.props;
        updateLocalityFormAction(setLocalityFormInputValues(event));
    };
    public handleSubmit = (event: FormEvent, regionId: string) => {
        event.preventDefault();
        const { createLocalityAction, editLocalityAction, localityForm } =
            this.props;
        if (regionId) {
            editLocalityAction(localityForm, regionId);
        } else {
            createLocalityAction(localityForm);
        }
    };
    public render() {
        const { localityId, editLocality, localities } = this.props;
        const { location, zoom } = this.props.localityForm;
        let locality: any;
        if (editLocality) {
            locality = localities.find(
                (localityInFind: ILocation) =>
                    localityInFind._id === localityId,
            );
        }
        return (
            <Grid container={true} justify="center">
                <Grid item={true} xs={12} md={6}>
                    <Typography variant="h5">Lisää Paikkakunta</Typography>
                </Grid>
                <Grid item={true} xs={12} md={6}>
                    <form>
                        <Box p={2}>
                            <Grid container={true}>
                                <Grid item={true} md={6} xs={12}>
                                    <Box p={1}>
                                        <TextField
                                            type="text"
                                            name="location"
                                            value={location}
                                            onChange={this.changeHandler}
                                            label="Paikkakunnan nimi *"
                                            required={true}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item={true} md={6} xs={12}>
                                    <Box p={1}>
                                        <TextField
                                            type="number"
                                            name="zoom"
                                            value={zoom}
                                            onChange={this.changeHandler}
                                            label="Zoom *"
                                            required={true}
                                        />
                                    </Box>
                                    <Grid item={true} md={12} xs={12}>
                                        <Box p={1}>
                                            <Button
                                                type="submit"
                                                onClick={(event: FormEvent) =>
                                                    this.handleSubmit(
                                                        event,
                                                        locality?._id,
                                                    )
                                                }
                                                color="primary"
                                                variant="outlined"
                                            >
                                                {locality
                                                    ? 'Muokkaa maakuntaa'
                                                    : 'Luo maakunta'}
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </form>
                </Grid>
            </Grid>
        );
    }
}

function mapStateToProps(state: AppState): ILocalitiesFormStateProps {
    return {
        localityForm: state.localityFormState.localityForm,
        localities: state.localities,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LocalityForm);
