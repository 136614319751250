import { IAdminPanelUiState } from '../../../../types';

export const UPDATE_ADMIN_PANEL_UISTATE = 'UPDATE_ADMIN_PANEL_UISTATE';
export const RESET_ADMIN_PANEL_UISTATE = 'RESET_ADMIN_PANEL_UISTATE';

export interface UpdateAdminPanelUiState {
    type: typeof UPDATE_ADMIN_PANEL_UISTATE;
    adminPanelUiState: IAdminPanelUiState;
}

export interface ResetAdminPanelUiProps {
    type: typeof RESET_ADMIN_PANEL_UISTATE;
    adminPanelUiState: IAdminPanelUiState;
}

export type AdminPanelUiStateActionTypes =
    | UpdateAdminPanelUiState
    | ResetAdminPanelUiProps;
