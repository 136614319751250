import { IRegionForm } from '../../../../types';
import { AppActions } from '../../types/actions';
import {
    RESET_REGION_FORM,
    UPDATE_REGION_FORM,
} from '../../types/regionFormActionTypes';

export function updateRegionForm(regionForm: IRegionForm): AppActions {
    return {
        type: UPDATE_REGION_FORM,
        regionFormState: { regionForm },
    };
}

export function resetRegionForm(regionForm: IRegionForm): AppActions {
    return {
        type: RESET_REGION_FORM,
        regionFormState: { regionForm },
    };
}
