import React, { FunctionComponent, useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
    Typography,
    Grid,
    Paper,
    Box,
    TableHead,
    TableRow,
    TableContainer,
    Table,
    TableCell,
    TableBody,
    Button,
    InputAdornment,
    FormHelperText,
    OutlinedInput,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core';
import { RotateLeft } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
    box: {
        padding: '3rem 4rem',
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            padding: '3rem 1.6rem',
        },
    },
    image: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        fontSize: '1.4rem',
        '& img': {
            maxWidth: '100%',
        },
        '& span': {
            marginTop: '0.8rem',
            fontWeight: 'bold',
        },
    },
    list: {
        marginTop: '0.2rem',
        '& li': {
            marginBottom: '0.3rem',
        },
    },
    link: {
        color: '#333',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    unitSub: {
        fontSize: '90%',
        position: 'absolute',
        paddingLeft: '3px',
    },
    formControl: {
        marginRight: '1rem',
    },
    inputAdornment: {
        opacity: 0.6,
        '& span': {
            fontSize: '80%',
            marginTop: '-9px',
        },
    },
    submitBtn: {
        marginLeft: '1rem',
    },
    resultBox: {
        marginTop: '3rem',
        background: '#DFDFDF',
        border: '1px solid #CFCFCF',
        width: '100%',
        height: '15rem',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '1.8rem',
        '& strong': {
            marginBottom: '2rem',
        },
    },
}));

const tableInfo = [
    {
        name: 'Koivu',
        unit1: '4,15',
        unit2: '1010',
        unit3: '1700',
    },
    {
        name: 'Mänty',
        unit1: '4,15',
        unit2: '810',
        unit3: '1360',
    },
    {
        name: 'Kuusi',
        unit1: '4,10',
        unit2: '790',
        unit3: '1320',
    },
    {
        name: 'Leppä',
        unit1: '4,05',
        unit2: '740',
        unit3: '1230',
    },
    {
        name: 'Haapa',
        unit1: '4,00',
        unit2: '790',
        unit3: '1330',
    },
];

const secondTableInfo = [
    {
        name: 'Avotakka',
        value: '< 30%',
    },
    {
        name: 'Takkauuni',
        value: '80-85%',
    },
    {
        name: 'Leivinuuni',
        value: '80-85%',
    },
    {
        name: 'Liesi, kiuas',
        value: '50-70%',
    },
    {
        name: 'Pellettitakka',
        value: '75-90%',
    },
];

const UnitsOfMeasurement: FunctionComponent<{}> = () => {
    const classes = useStyles();
    /** Values */
    const [pilkelaji, setPilkelaji] = useState<number>(() => 0);
    const [unit, setUnit] = useState<number>(() => 0);
    const [amount, setAmount] = useState<number | null>(() => null);
    const [price, setPrice] = useState<number | null>(() => null);
    const [efficiency, setEfficiency] = useState<number | null>(() => null);

    const [result, setResult] = useState<number>(() => 0);
    const [priceResult, setPriceResult] = useState<number>(() => 0);

    // When an input value changes
    const calculateEnergy = (event: any) => {
        if (event.target.name === 'pilkelaji') {
            setPilkelaji(parseInt(event.target.value));
        }

        if (event.target.name === 'unit') {
            setUnit(event.target.value as number);
        }
        if (event.target.name === 'amount') {
            setAmount(parseFloat(event.target.value));
        }

        if (event.target.name === 'price') {
            setPrice(parseFloat(event.target.value));
        }

        if (event.target.name === 'efficiency') {
            setEfficiency(parseFloat(event.target.value));
        }
    };

    useEffect(() => {
        // Calculate the result if all the data is given.
        if (
            pilkelaji !== 0 &&
            unit !== 0 &&
            !!amount &&
            0 < amount &&
            !!price &&
            0 < price &&
            !!efficiency &&
            0 < efficiency
        ) {
            const kWhByWood: number[] = [];
            let kWhResult: number = 0;
            let kWhPriceResult: number = 0;

            if (unit === 1) {
                // irto
                kWhByWood[1] = 1010; // Koivu
                kWhByWood[2] = 740; // Leppä
                kWhByWood[3] = 790; // Haapa
                kWhByWood[4] = 800; // Havusekapuu
                kWhByWood[5] = 790; // Sekapuu
                kWhByWood[6] = 790; // Kuusi
                kWhByWood[7] = 790; // Lehtisekapuu
                kWhByWood[8] = 810; // Mänty
            } else if (unit === 2) {
                // pino
                kWhByWood[1] = 1700; // Koivu
                kWhByWood[2] = 1230; // Leppä
                kWhByWood[3] = 1330; // Haapa
                kWhByWood[4] = 1340; // Havusekapuu
                kWhByWood[5] = 1330; // Sekapuu
                kWhByWood[6] = 1320; // Kuusi
                kWhByWood[7] = 1330; // Lehtisekapuu
                kWhByWood[8] = 1360; // Mänty
            }

            kWhResult = (amount as number) * kWhByWood[pilkelaji];
            kWhPriceResult =
                (price as number) /
                (kWhByWood[pilkelaji] * ((efficiency as number) / 100));

            setResult(Math.round(kWhResult * 100) / 100);
            setPriceResult(Math.round(kWhPriceResult * 1000) / 1000);
        }
    }, [pilkelaji, unit, amount, price, efficiency]);

    // Reset the form
    const resetForm = () => {
        setPilkelaji(0);
        setUnit(0);
        setAmount(null);
        setPrice(null);
        setEfficiency(null);
        setResult(0);
        setPriceResult(0);
    };

    return (
        <section>
            <Box py={8} px={1}>
                <Grid container={true} justify="center">
                    <Grid item={true} xs={12} md={8}>
                        <Paper>
                            <div className={classes.box}>
                                <Grid container={true}>
                                    <Grid item={true}>
                                        <Typography variant="h4">
                                            Energialaskuri
                                        </Typography>
                                        <br />
                                    </Grid>
                                    <Grid item={true}>
                                        <br />
                                        <Typography variant="body1">
                                            Energialaskurin avulla voi laskea
                                            kuivan (kosteus 20 %) polttopuun
                                            energiasisällön ja -hinnan
                                            pilkelajin ja määrän mukaan.
                                            Energialaskurissa käytetään oheisen
                                            taulukon mukaisia lämpöarvoja
                                        </Typography>
                                        <br />
                                        <br />
                                        <Grid item={true} xs={12}>
                                            <Typography variant="subtitle2">
                                                Taulukko. Puulajien lämpöarvot
                                            </Typography>
                                            <br />
                                            <TableContainer component={Paper}>
                                                <Table aria-label="customized table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>
                                                                Puulaji
                                                            </TableCell>
                                                            <TableCell>
                                                                Lämpöarvo kWh/kg
                                                            </TableCell>
                                                            <TableCell>
                                                                Energiasisältö
                                                                kWh/i-m
                                                                <sup>3</sup>
                                                            </TableCell>
                                                            <TableCell>
                                                                Energiasisältö
                                                                kWh/p-m
                                                                <sup>3</sup>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {tableInfo.map(
                                                            (item, index) => {
                                                                return (
                                                                    <TableRow
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <TableCell>
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {
                                                                                item.unit1
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {
                                                                                item.unit2
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {
                                                                                item.unit3
                                                                            }
                                                                        </TableCell>
                                                                    </TableRow>
                                                                );
                                                            },
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                        <br />
                                        <br />
                                        <Typography>
                                            Energialaskurissa käytetään
                                            sekapuulle oletuksena seuraavia
                                            puulajiosuuksia ja energiasisältöja.
                                        </Typography>
                                        <br />
                                        <Typography>
                                            Sekapuu: koivu 10%, muu lehtipuu 45
                                            %, havupuu 45 % - 790 kWh/i-m3, 1330
                                            kWh/p-m3
                                        </Typography>
                                        <Typography>
                                            Lehtisekapuu: koivu 10 %, muu
                                            lehtipuu 90 % - 790 kWh/i-m3, 1330
                                            kWh/p-m3
                                        </Typography>
                                        <Typography>
                                            Havusekapuu: mänty 50 %, kuusi 50 %
                                            - 800 kWh/i-m3, 1340 kWh/p-m3
                                        </Typography>
                                        <br />
                                        <Typography>
                                            Halkoliiterissä myytävälle
                                            sekapuulle ei ole määritelty
                                            puulajiosuuksia, joten
                                            puulajiosuudet voivat vaihdella
                                            myyjäkohtaisesti.
                                        </Typography>
                                        <br />
                                        <br />
                                        <br />

                                        <form>
                                            <Grid container={true} spacing={2}>
                                                <Grid
                                                    item={true}
                                                    sm={6}
                                                    xs={12}
                                                >
                                                    <FormControl
                                                        variant="outlined"
                                                        className={
                                                            classes.formControl
                                                        }
                                                        fullWidth={true}
                                                    >
                                                        <InputLabel id="select-label-1">
                                                            Pilkelaji
                                                        </InputLabel>
                                                        <Select
                                                            labelId="select-label-1"
                                                            name="pilkelaji"
                                                            value={pilkelaji}
                                                            label="Pilkelaji"
                                                            onChange={
                                                                calculateEnergy
                                                            }
                                                        >
                                                            <MenuItem value={0}>
                                                                <em>
                                                                    - Valitse -
                                                                </em>
                                                            </MenuItem>
                                                            <MenuItem value={1}>
                                                                Koivu
                                                            </MenuItem>
                                                            <MenuItem value={2}>
                                                                Leppä
                                                            </MenuItem>
                                                            <MenuItem value={3}>
                                                                Haapa
                                                            </MenuItem>
                                                            <MenuItem value={4}>
                                                                Havusekapuu
                                                            </MenuItem>
                                                            <MenuItem value={5}>
                                                                Sekapuu
                                                            </MenuItem>
                                                            <MenuItem value={6}>
                                                                Kuusi
                                                            </MenuItem>
                                                            <MenuItem value={7}>
                                                                Lehtisekapuu
                                                            </MenuItem>
                                                            <MenuItem value={8}>
                                                                Mänty
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid
                                                    item={true}
                                                    sm={6}
                                                    xs={12}
                                                >
                                                    <FormControl
                                                        variant="outlined"
                                                        fullWidth={true}
                                                    >
                                                        <InputLabel id="select-label-2">
                                                            Mittayksikkö
                                                        </InputLabel>
                                                        <Select
                                                            labelId="select-label-2"
                                                            name="unit"
                                                            value={unit}
                                                            label="Mittayksikkö"
                                                            onChange={
                                                                calculateEnergy
                                                            }
                                                        >
                                                            <MenuItem value={0}>
                                                                <em>
                                                                    - Valitse -
                                                                </em>
                                                            </MenuItem>
                                                            <MenuItem value={1}>
                                                                Irto-m³
                                                            </MenuItem>
                                                            <MenuItem value={2}>
                                                                Pino-m³
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid
                                                    item={true}
                                                    sm={4}
                                                    xs={12}
                                                >
                                                    <FormControl
                                                        variant="outlined"
                                                        fullWidth={true}
                                                    >
                                                        <OutlinedInput
                                                            type="number"
                                                            name="amount"
                                                            onChange={
                                                                calculateEnergy
                                                            }
                                                            value={amount}
                                                            endAdornment={
                                                                unit !== 0 && (
                                                                    <InputAdornment
                                                                        position="end"
                                                                        className={
                                                                            classes.inputAdornment
                                                                        }
                                                                    >
                                                                        {unit ===
                                                                        1
                                                                            ? 'irto'
                                                                            : 'pino'}
                                                                        -m
                                                                        <span>
                                                                            3
                                                                        </span>
                                                                    </InputAdornment>
                                                                )
                                                            }
                                                            inputProps={{
                                                                'aria-label':
                                                                    'Määrä',
                                                            }}
                                                        />
                                                        <FormHelperText>
                                                            Määrä
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid
                                                    item={true}
                                                    sm={4}
                                                    xs={12}
                                                >
                                                    <FormControl
                                                        variant="outlined"
                                                        fullWidth={true}
                                                    >
                                                        <OutlinedInput
                                                            type="number"
                                                            name="price"
                                                            onChange={
                                                                calculateEnergy
                                                            }
                                                            value={price}
                                                            endAdornment={
                                                                <InputAdornment
                                                                    position="end"
                                                                    className={
                                                                        classes.inputAdornment
                                                                    }
                                                                >
                                                                    € /{' '}
                                                                    {unit !==
                                                                        0 && (
                                                                        <React.Fragment>
                                                                            {unit ===
                                                                            1
                                                                                ? 'irto'
                                                                                : 'pino'}
                                                                            -m
                                                                            <span>
                                                                                3
                                                                            </span>
                                                                        </React.Fragment>
                                                                    )}
                                                                </InputAdornment>
                                                            }
                                                            inputProps={{
                                                                'aria-label':
                                                                    'Hinta',
                                                            }}
                                                        />
                                                        <FormHelperText>
                                                            Hinta
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid
                                                    item={true}
                                                    sm={4}
                                                    xs={12}
                                                >
                                                    <FormControl
                                                        variant="outlined"
                                                        fullWidth={true}
                                                    >
                                                        <OutlinedInput
                                                            type="number"
                                                            onChange={
                                                                calculateEnergy
                                                            }
                                                            value={efficiency}
                                                            name="efficiency"
                                                            inputProps={{
                                                                'aria-label':
                                                                    'Hyötysuhde',
                                                            }}
                                                        />
                                                        <FormHelperText>
                                                            Hyötysuhde
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <br />
                                            <Grid
                                                container={true}
                                                justify="flex-end"
                                            >
                                                <Button
                                                    startIcon={<RotateLeft />}
                                                    type="reset"
                                                    variant="text"
                                                    color="secondary"
                                                    size="large"
                                                    onClick={resetForm}
                                                >
                                                    Tyhjää
                                                </Button>
                                            </Grid>
                                        </form>

                                        <Grid
                                            container={true}
                                            justify="space-between"
                                        >
                                            <Grid
                                                item={true}
                                                md={5}
                                                sm={4}
                                                xs={12}
                                            >
                                                <div
                                                    className={
                                                        classes.resultBox
                                                    }
                                                >
                                                    <strong>
                                                        Polttopuun
                                                        energiasisältö
                                                    </strong>
                                                    <span>{result} kWh</span>
                                                </div>
                                            </Grid>
                                            <Grid
                                                item={true}
                                                md={5}
                                                sm={4}
                                                xs={12}
                                            >
                                                <div
                                                    className={
                                                        classes.resultBox
                                                    }
                                                >
                                                    <strong>
                                                        Lämpöenergian hinta
                                                    </strong>
                                                    <span>
                                                        {priceResult} €/kWh
                                                    </span>
                                                </div>
                                            </Grid>
                                        </Grid>

                                        <Grid item={true} xs={12} sm={4}>
                                            <br />
                                            <br />
                                            <br />
                                            <TableContainer component={Paper}>
                                                <Table aria-label="customized table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>
                                                                Tulisija
                                                            </TableCell>
                                                            <TableCell>
                                                                Tulisija
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {secondTableInfo.map(
                                                            (item, index) => {
                                                                return (
                                                                    <TableRow
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <TableCell>
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {
                                                                                item.value
                                                                            }
                                                                        </TableCell>
                                                                    </TableRow>
                                                                );
                                                            },
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </section>
    );
};

export default UnitsOfMeasurement;
