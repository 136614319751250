import { resetCurrentUser } from '../../utils/resetUtils';
import {
    UserActionTypes,
    SET_CURRENT_USER,
    REMOVE_CURRENT_USER,
} from '../../types/userActionTypes';
import { ICurrentUser } from '../../../../types';

const DEFAULT_STATE: ICurrentUser = {
    isAuthenticated: false,
    isAdmin: false,
    user: resetCurrentUser(),
};

const currentUserReducer = (state = DEFAULT_STATE, action: UserActionTypes) => {
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                isAuthenticated: action.isAuthenticated,
                isAdmin: action.isAdmin,
                user: action.user,
            };
        case REMOVE_CURRENT_USER:
            return {
                ...state,
                user: action.user,
                isAdmin: action.isAdmin,
                isAuthenticated: action.isAuthenticated,
            };
        default:
            return state;
    }
};

export default currentUserReducer;
