import { Card, CardContent, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import CardHeader from './CardHeader';
import UserListTable from './UserListTable';

const NewUsersTabsCard: FC = () => {
    return (
        <Card className="card">
            <CardHeader
                className="card_fullWidthHeader"
                color="warning"
                plain={true}
                stats={true}
                icon={true}
            >
                <Typography
                    className="card_fullWidthHeader__heading"
                    variant="h6"
                >
                    Uusien Tilien hallinta
                </Typography>
                <Typography
                    className="card_fullWidthHeader__paragraph"
                    variant="body1"
                >
                    Tilin aktivointi
                </Typography>
            </CardHeader>
            <CardContent>
                <UserListTable />
            </CardContent>
        </Card>
    );
};

export default NewUsersTabsCard;
