import { IContactForm } from '../../../../types';
import { AppActions } from '../../types/actions';
import {
    RESET_CONTACT_FORM,
    UPDATE_CONTACT_FORM,
} from '../../types/contactFormActionTypes';

export function updateContactForm(contactForm: IContactForm): AppActions {
    return {
        type: UPDATE_CONTACT_FORM,
        contactFormState: { contactForm },
    };
}

export function resetContactForm(contactForm: IContactForm): AppActions {
    return {
        type: RESET_CONTACT_FORM,
        contactFormState: { contactForm },
    };
}
