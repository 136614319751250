import React, { Fragment, FC } from 'react';
import './ProductCard.scss';
import {
    Typography,
    Card,
    CardHeader,
    IconButton,
    Avatar,
    CardContent,
    CardActions,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Box,
    InputBaseComponentProps,
    Badge,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
    LocalShipping,
    Favorite,
    FavoriteBorder,
    AddShoppingCart,
    LocationOn,
    Apps,
    StarBorderOutlined,
    Business,
    Person,
    LocalOffer,
} from '@material-ui/icons';
import { setSellerName, getProductName, setPriceTag } from '../../utils/utils';
import { AppState } from '../../store/store';
import { mapDispatchToProps } from '../../types/dispatchActionTypes';
import { connect } from 'react-redux';
import { ICurrentUser, IProduct } from '../../../../types';

interface OwnProps {
    product: IProduct;
    removeFromFavorites?: boolean;
}

interface IProductCardStateProps {
    currentUser: ICurrentUser;
}

type ProductCardProps = IProductCardStateProps & OwnProps & IDispatchProps;

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        '& .cardHeader .MuiCardHeader-content': {
            flex: ' 0 1 auto',
        },
        '& .locationButton': {
            cursor: 'pointer',
        },
    },
    listItemAvatar: {
        minWidth: '3rem',
    },
    cardActions: {
        justifyContent: 'space-between',
        padding: '0 2rem 1rem 1rem',
    },
}));

const ProductCard: FC<ProductCardProps> = (props) => {
    const {
        product,
        fetchSellersAction,
        updateGoogleMapsStateAction,
        removeFromFavorites,
    } = props;
    const classes = useStyles();
    const updateMap = (event: InputBaseComponentProps, ownerId: string) => {
        fetchSellersAction({
            regionId: undefined,
            localityId: undefined,
            category: undefined,
            deliveryMethod: undefined,
            sellerId: ownerId,
        });
        updateGoogleMapsStateAction({
            lat: Number(event.currentTarget?.dataset.lat),
            lng: Number(event.currentTarget?.dataset.lng),
            zoom: Number(event.currentTarget?.dataset.zoom),
        });
    };
    const addToCartHandler = (id: string) => {
        const { addToCartAction } = props;
        addToCartAction(id);
    };
    const handleAddToFavorites = (id: string) => {
        const { addFavoriteAction } = props;
        addFavoriteAction(id);
    };

    const handleRemoveFromFavorites = (id: string) => {
        const { removeFavoriteAction } = props;
        removeFavoriteAction(id);
    };
    const handleFavoritesList = (id: string) => {
        if (!!removeFromFavorites) {
            handleRemoveFromFavorites(id);
        } else {
            handleAddToFavorites(id);
        }
    };
    return (
        <Box py={2}>
            <Card className={'productCard ' + classes.card}>
                <CardHeader
                    className={
                        product.category === 'Polttopuut'
                            ? 'cardHeader cardHeader_coral'
                            : product.category === 'Muut_tuotteet'
                            ? 'cardHeader cardHeader_indianred'
                            : 'cardHeader cardHeader_teal'
                    }
                    avatar={
                        <Avatar
                            aria-label={product.category}
                            className="primary"
                        >
                            {product.category === 'Polttopuut' ? (
                                <LocalShipping className="default-icon-size colorDefault colorDefault_coral" />
                            ) : product.category === 'Muut_tuotteet' ? (
                                <Apps className="default-icon-size colorDefault colorDefault_indianred" />
                            ) : (
                                <StarBorderOutlined className="default-icon-size colorDefault colorDefault_teal" />
                            )}
                        </Avatar>
                    }
                    title={
                        <Typography
                            variant={product.discount ? 'h5' : 'h6'}
                            className="productCardTitle"
                        >
                            {' '}
                            {getProductName(product)}
                            {product.discount && (
                                <IconButton aria-label="Tarjouserä">
                                    <Badge
                                        color="secondary"
                                        badgeContent="Tarjouserä"
                                    >
                                        <LocalOffer
                                            fontSize="large"
                                            color="secondary"
                                        />
                                    </Badge>
                                </IconButton>
                            )}
                        </Typography>
                    }
                />
                <CardContent>
                    <List>
                        <ListItem>
                            <ListItemAvatar>
                                {product.owner.business ? (
                                    <Badge badgeContent="Yritys" color="error">
                                        <Business fontSize="large" />
                                    </Badge>
                                ) : (
                                    <Person />
                                )}
                            </ListItemAvatar>
                            <ListItemText
                                className={product.discount ? 'content' : ''}
                                primary={setSellerName(product.owner)}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                className={product.discount ? 'content' : ''}
                                primary={'Tuotekategoria: ' + product.category}
                            />
                        </ListItem>
                        {product.category === 'Polttopuut' ? (
                            <Fragment>
                                <ListItem>
                                    <ListItemText
                                        className={
                                            product.discount ? 'content' : ''
                                        }
                                        primary={'Laatu: ' + product.quality}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        className={
                                            product.discount ? 'content' : ''
                                        }
                                        primary={'Pituus: ' + product.plength}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        className={
                                            product.discount ? 'content' : ''
                                        }
                                        primary={
                                            'Yksikkö: ' +
                                            product.unit +
                                            ' ja Toimitustapa: ' +
                                            product.deliveryType
                                        }
                                    />
                                </ListItem>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <ListItem>
                                    <ListItemText
                                        className={
                                            product.discount
                                                ? ' scrollable content'
                                                : 'scrollable'
                                        }
                                        primary={
                                            'Kuvaus: ' + product.description
                                        }
                                    />
                                </ListItem>
                            </Fragment>
                        )}
                        <ListItem
                            className="locationButton"
                            tabIndex={0}
                            data-lat={product.owner.address.coords.lat}
                            data-lng={product.owner.address.coords.lng}
                            data-zoom={product.owner.address.locality.zoom}
                            onClick={(event: InputBaseComponentProps) => {
                                updateMap(event, product.owner._id);
                            }}
                        >
                            <ListItemAvatar className={classes.listItemAvatar}>
                                <LocationOn />
                            </ListItemAvatar>
                            <ListItemText
                                className={product.discount ? 'content' : ''}
                                primary={
                                    product.owner.address.locality.region.name +
                                    ', ' +
                                    product.owner.address.locality.location
                                }
                            />
                        </ListItem>
                    </List>
                    <Typography className="description" variant="body1">
                        Kuvaus: {product.description}
                    </Typography>
                </CardContent>
                <CardActions
                    disableSpacing={true}
                    className={classes.cardActions}
                >
                    <IconButton
                        aria-label="add to favorites"
                        onClick={() => handleFavoritesList(product._id)}
                    >
                        {!!removeFromFavorites ? (
                            <Favorite color="secondary" />
                        ) : (
                            <FavoriteBorder color="secondary" />
                        )}
                    </IconButton>
                    <IconButton
                        aria-label="add to cart"
                        onClick={() => addToCartHandler(product._id)}
                        className="addToCartButton"
                    >
                        <AddShoppingCart />
                        {'  ' + setPriceTag(product.price)}
                    </IconButton>
                </CardActions>
            </Card>
        </Box>
    );
};

function mapStateToProps(state: AppState): IProductCardStateProps {
    return {
        currentUser: state.currentUser,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
