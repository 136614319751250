import React, { Component, FormEvent } from 'react';
import { AppState } from '../../store/store';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../types/dispatchActionTypes';
import {
    Typography,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    InputBaseComponentProps,
    Box,
    TextField,
    Button,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import {
    withStyles,
    createStyles,
    WithStyles,
    Theme,
} from '@material-ui/core/styles';
import { AccountSettingsOptionLists } from '../../formOptionList';
import {
    manageSelectedLocationCheckboxes,
    setAccountSettingsFormInputValues,
} from '../../utils/utils';
import {
    IAccountSettingsForm,
    ICurrentUser,
    ILocalitiesByRegion,
    IPartialLocality,
    IUiProps,
} from '../../../../types';

interface IAccounntSettingsFormStateProps {
    accountSettingsForm: IAccountSettingsForm;
    currentUser: ICurrentUser;
    uiProps: IUiProps;
    localitiesByRegion: ILocalitiesByRegion[];
}

type AccountSettingsFormProps = IAccounntSettingsFormStateProps &
    IDispatchProps &
    WithStyles<typeof styles>;

const styles = (theme: Theme) =>
    createStyles({
        title: {
            marginTop: '2rem',
        },
    });

class AccountSettingsForm extends Component<
    AccountSettingsFormProps,
    AppState
> {
    public componentDidMount() {
        const {
            currentUser,
            accountSettingsForm,
            updateAccountSettingsFormAction,
            reFetchUserAction,
        } = this.props;
        if (!currentUser.user.deliveryMethod) {
            reFetchUserAction(currentUser.user._id);
        } else {
            const deliveryLocationsSelectionsIds: string[] =
                currentUser.user.deliveryLocations.map(
                    (deliveryLocation: any) => {
                        return deliveryLocation._id;
                    },
                );
            const allSelectedDeliveryLocations =
                accountSettingsForm.deliveryLocationsSelections.concat(
                    deliveryLocationsSelectionsIds.filter(
                        (id: string) =>
                            !accountSettingsForm.deliveryLocationsSelections.includes(
                                id,
                            ),
                    ),
                );
            updateAccountSettingsFormAction({
                deliveryLocationsSelections: allSelectedDeliveryLocations,
            });
        }
    }
    public submitHandler = (event: FormEvent) => {
        event.preventDefault();
        const { updateAccountSettingsAction, accountSettingsForm } = this.props;
        const { _id } = this.props.currentUser.user;
        updateAccountSettingsAction(accountSettingsForm, _id);
    };
    public handleChange = (event: InputBaseComponentProps) => {
        event.preventDefault();
        const { updateAccountSettingsFormAction, accountSettingsForm } =
            this.props;
        const { deliveryLocationsSelections } = accountSettingsForm;
        if (event.target.name === 'locality') {
            updateAccountSettingsFormAction({
                deliveryLocationsSelections: manageSelectedLocationCheckboxes(
                    event.target.value,
                    deliveryLocationsSelections,
                ),
            });
        } else {
            updateAccountSettingsFormAction(
                setAccountSettingsFormInputValues(event),
            );
        }
        return event.value;
    };
    public render() {
        const { deliveryMethods } = AccountSettingsOptionLists;
        const {
            currentUser,
            accountSettingsForm,
            localitiesByRegion,
            classes,
        } = this.props;
        const {
            deliveryMethod,
            accountDescription,
            deliveryLocationsSelections,
        } = accountSettingsForm;
        const renderLocalities = (
            localities: IPartialLocality[],
        ): JSX.Element[] => {
            const renderableLocalities = localities.map(
                (locality: IPartialLocality) => {
                    return (
                        <FormControlLabel
                            key={locality._id}
                            control={
                                <Checkbox
                                    tabIndex={0}
                                    checked={
                                        deliveryLocationsSelections.indexOf(
                                            locality._id,
                                        ) >= 0
                                            ? true
                                            : false
                                    }
                                    onChange={this.handleChange}
                                    name="locality"
                                    value={locality._id}
                                />
                            }
                            label={locality.locality}
                            className="labelText"
                            color="primary"
                        />
                    );
                },
            );
            return renderableLocalities;
        };
        return (
            <form onSubmit={this.submitHandler}>
                <Grid container={true} justify="center">
                    <Grid item={true} xs={12}>
                        <Typography variant="h6" className={classes.title}>
                            Tilin kuvaus ja toimitustapa
                        </Typography>
                    </Grid>
                    <Grid item={true} xs={12} sm={6}>
                        <Box p={1}>
                            <FormControl
                                variant="outlined"
                                fullWidth={true}
                                required={true}
                            >
                                <InputLabel id="demo-simple-select-required-label">
                                    Valitse Toimitustapa
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-required-label"
                                    id="demo-simple-select-required"
                                    name="deliveryMethod"
                                    value={
                                        deliveryMethod
                                            ? deliveryMethod
                                            : currentUser.user.deliveryMethod
                                            ? currentUser.user.deliveryMethod
                                            : deliveryMethod
                                    }
                                    onChange={this.handleChange}
                                    label="Valitse Toimitustapa"
                                >
                                    <MenuItem
                                        value={
                                            deliveryMethod
                                                ? deliveryMethod
                                                : currentUser.user
                                                      .deliveryMethod
                                                ? currentUser.user
                                                      .deliveryMethod
                                                : deliveryMethod
                                        }
                                    >
                                        <em>Valitse Toimitusmuoto</em>
                                    </MenuItem>
                                    {!!deliveryMethods.length &&
                                        deliveryMethods.map(
                                            (deliveryMethod2: string) => {
                                                return (
                                                    <MenuItem
                                                        key={deliveryMethod2}
                                                        value={deliveryMethod2}
                                                    >
                                                        {deliveryMethod2}
                                                    </MenuItem>
                                                );
                                            },
                                        )}
                                </Select>
                                <FormHelperText>Vaadittu kenttä</FormHelperText>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item={true} xs={12} sm={6}>
                        <Box p={1}>
                            <TextField
                                multiline={true}
                                name="accountDescription"
                                label="Kuvaus"
                                rows="6"
                                variant="outlined"
                                onChange={this.handleChange}
                                value={
                                    accountDescription
                                        ? accountDescription
                                        : currentUser.user.accountDescription
                                }
                                fullWidth={true}
                            />
                        </Box>
                    </Grid>
                    <Grid item={true} xs={12}>
                        <Typography variant="h6" className={classes.title}>
                            Toimituspaikkakunnat
                        </Typography>
                    </Grid>
                    {localitiesByRegion.map(
                        (localitiesByRegionItem: ILocalitiesByRegion) => {
                            return (
                                <Grid
                                    key={localitiesByRegionItem._id.region}
                                    item={true}
                                    xs={12}
                                    md={6}
                                >
                                    <Box p={1}>
                                        <fieldset>
                                            <legend>
                                                {
                                                    localitiesByRegionItem._id
                                                        .region
                                                }
                                            </legend>
                                            {renderLocalities(
                                                localitiesByRegionItem.localities,
                                            )}
                                        </fieldset>
                                    </Box>
                                </Grid>
                            );
                        },
                    )}
                    <Grid item={true} xs={12} className={classes.title}>
                        <Button
                            type="submit"
                            color="primary"
                            variant="outlined"
                        >
                            Tallenna
                        </Button>
                    </Grid>
                </Grid>
            </form>
        );
    }
}

function mapStateToProps(state: AppState): IAccounntSettingsFormStateProps {
    return {
        accountSettingsForm: state.accountSettingsFormState.accountSettingsForm,
        currentUser: state.currentUser,
        uiProps: state.uiState.uiProps,
        localitiesByRegion: state.localitiesByRegion,
    };
}
export default connect<
    IAccounntSettingsFormStateProps,
    IDispatchProps,
    {},
    AppState
>(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(AccountSettingsForm));
