import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { AppState } from '../../store/store';
import { Grid, Box } from '@material-ui/core';
import SideBar from '../../components/SideBar/SideBar';
import './Profile.scss';
import ShowProfile from '../../components/ShowProfile/ShowProfile';
import ManageProducts from '../../components/ManageProducts/ManageProducts';
import { mapDispatchToProps } from '../../types/dispatchActionTypes';
import AccountSettings from '../../components/AccountSettings/AccountSettings';
import SortMenuBar from '../../components/ManageProducts/SortMenuBar';
import OrderList from '../../components/Order/OrderList';
import Order from '../../components/Order/Order';
import { setSideBarValues } from '../../utils/utils';
import { resetSideBarState } from '../../utils/resetUtils';
import {
    ICurrentUser,
    ILocalitiesByRegion,
    IFlashMessage,
    IOrderTotals,
    ISidebarProps,
    IUiProps,
    OrderStatus,
    IOrder,
    IProduct,
    ILocation,
} from '../../../../types';
import DeliveryCostsList from '../../components/DeliveryCosts/DeliveryCostsList';

interface ProfileStateProps {
    message: IFlashMessage;
    currentUser: ICurrentUser;
    order: IOrder;
    orderTotals: IOrderTotals;
    isAuthenticated: boolean;
    uiProps: IUiProps;
    sideBarProps: ISidebarProps;
    localitiesByRegion: ILocalitiesByRegion[];
    userProducts: IProduct[];
    userServices: IProduct[];
    localities: ILocation[];
}

type ProfileProps = ProfileStateProps & IDispatchProps & RouteComponentProps;

class Profile extends Component<ProfileProps, AppState> {
    public returnOrders = () => {
        const { sideBarProps, updateSideBarAction, resetSideBarAction } =
            this.props;
        const { showOrders } = sideBarProps;
        resetSideBarAction(resetSideBarState());
        updateSideBarAction(
            setSideBarValues('showOrders', showOrders ? false : true),
        );
    };
    public componentDidUpdate() {
        const { uiProps, logoutAction } = this.props;
        if (uiProps.responseStatus === 401) {
            logoutAction();
            this.props.history.push('/kirjaudu');
        }
    }
    public updateOrderStatus = (id: string, status: OrderStatus) => {
        const { updateOrderAction } = this.props;
        updateOrderAction(id, status);
    };
    public render() {
        const { order, orderTotals } = this.props;
        const { user } = this.props.currentUser;
        const {
            showUserInfo,
            showWoodProducts,
            showOtherProducts,
            showOrders,
            showOrder,
            showDeliveryCosts,
            showServices,
            showAccountSettings,
        } = this.props.sideBarProps;
        return (
            <Fragment>
                <section className="profileContainer">
                    <SideBar />
                    <Grid container={true} justify="center">
                        <Grid item={true} xs={12}>
                            <SortMenuBar
                                title={`Profiili /
                                    ${
                                        showUserInfo
                                            ? ' Käyttäjätiedot'
                                            : showWoodProducts
                                            ? ' Polttopuut'
                                            : showOtherProducts
                                            ? 'Muut_tuotteet'
                                            : showServices
                                            ? 'Palvelut'
                                            : showAccountSettings
                                            ? 'Käyttäjätilin asetukset'
                                            : showDeliveryCosts
                                            ? 'Toimituskulut'
                                            : 'Tilaukset'
                                    }`}
                            />
                            <Box px={2} py={2} mt={5}>
                                {!!showUserInfo && <ShowProfile user={user} />}
                                {!!showAccountSettings && (
                                    <AccountSettings user={user} />
                                )}
                                {!!showDeliveryCosts && <DeliveryCostsList />}
                                {!!showWoodProducts && (
                                    <ManageProducts selectedCategory="Polttopuut" />
                                )}
                                {!!showOtherProducts && (
                                    <ManageProducts selectedCategory="Muut_tuotteet" />
                                )}
                                {!!showServices && (
                                    <ManageProducts selectedCategory="Palvelut" />
                                )}
                                {!!showOrders && <OrderList />}
                                {!!showOrder && (
                                    <Order
                                        order={order}
                                        orderTotals={orderTotals}
                                        user={user}
                                        returnOrders={this.returnOrders}
                                        updateOrderStatus={
                                            this.updateOrderStatus
                                        }
                                    />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </section>
            </Fragment>
        );
    }
}

function mapStateToProps(state: AppState): ProfileStateProps {
    return {
        currentUser: state.currentUser,
        order: state.order,
        orderTotals: state.orderTotals,
        message: state.message.message,
        isAuthenticated: state.currentUser.isAuthenticated,
        uiProps: state.uiState.uiProps,
        sideBarProps: state.sidebarState.sidebarProps,
        userProducts: state.userProducts,
        userServices: state.userServices,
        localities: state.localities,
        localitiesByRegion: state.localitiesByRegion,
    };
}

export default withRouter(
    connect<ProfileStateProps, IDispatchProps, {}, AppState>(
        mapStateToProps,
        mapDispatchToProps,
    )(Profile),
);
