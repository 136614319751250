import { IProduct } from '../../../types';

export const SET_USER_PRODUCTS = 'SET_USER_PRODUCTS';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';

export interface SetUserProducts {
    type: typeof SET_USER_PRODUCTS;
    userProducts: IProduct[];
}

export interface AddProduct {
    type: typeof ADD_PRODUCT;
    product: IProduct;
}

export interface UpdateProduct {
    type: typeof UPDATE_PRODUCT;
    product: IProduct;
}

export interface RemoveProduct {
    type: typeof REMOVE_PRODUCT;
    id: string;
}

export type UserProductActionTypes =
    | SetUserProducts
    | AddProduct
    | UpdateProduct
    | RemoveProduct;
