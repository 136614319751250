import {
    Box,
    Button,
    FormControl,
    Grid,
    InputBaseComponentProps,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import React, { Component, FormEvent } from 'react';
import { connect } from 'react-redux';
import { IContactForm } from '../../../../types';
import { contactFormOptionLists } from '../../formOptionList';
import { AppState } from '../../store/store';
import { mapDispatchToProps } from '../../types/dispatchActionTypes';
import { setContactFormInputValues } from '../../utils/utils';

interface IContactFormStateProps {
    contactForm: IContactForm;
}

type ContactFormProps = IContactFormStateProps & IDispatchProps;

class ContactForm extends Component<ContactFormProps, AppState> {
    public handleChange = (event: InputBaseComponentProps) => {
        const { updateContactFormAction } = this.props;
        updateContactFormAction(setContactFormInputValues(event));
    };
    public handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        const { resetContactFormAction, createContactAction, contactForm } =
            this.props;
        createContactAction(contactForm);
        resetContactFormAction();
    };
    public render() {
        const { email, _id, message, name, subject } = this.props.contactForm;
        const { subjects } = contactFormOptionLists;
        return (
            <Box p={2}>
                <form>
                    <input type="hidden" name="userId" value={_id} />
                    <Grid container={true} justify="center" alignItems="center">
                        <Grid item={true} md={6} sm={12}>
                            <Box p={1}>
                                <TextField
                                    variant="outlined"
                                    name="name"
                                    value={name}
                                    onChange={this.handleChange}
                                    fullWidth={true}
                                    required={true}
                                    label="Etu- ja Sukunimi"
                                    type="name"
                                />
                            </Box>
                        </Grid>
                        <Grid item={true} md={6} sm={12}>
                            <Box p={1}>
                                <TextField
                                    variant="outlined"
                                    name="email"
                                    value={email}
                                    onChange={this.handleChange}
                                    fullWidth={true}
                                    required={true}
                                    label="Sähköposti"
                                    type="email"
                                />
                            </Box>
                        </Grid>
                        <Grid item={true} md={6} sm={12}>
                            <Box p={1}>
                                <FormControl
                                    variant="outlined"
                                    fullWidth={true}
                                    required={true}
                                >
                                    <InputLabel id="demo-simple-select-required-label">
                                        Valitse aihe
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-required-label"
                                        id="demo-simple-select-required"
                                        name="subject"
                                        value={subject}
                                        onChange={this.handleChange}
                                        label="Valitse aihe"
                                    >
                                        <MenuItem value={subject}>
                                            <em>Valitse Aihe</em>
                                        </MenuItem>
                                        {!!subjects.length &&
                                            subjects.map((subject2: string) => {
                                                return (
                                                    <MenuItem
                                                        key={subject2}
                                                        value={subject2}
                                                    >
                                                        {subject2}
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item={true} sm={12} md={6}>
                            <Box p={1}>
                                <TextField
                                    variant="outlined"
                                    name="message"
                                    value={message}
                                    onChange={this.handleChange}
                                    fullWidth={true}
                                    required={true}
                                    multiline={true}
                                    rows={4}
                                    label="Viesti"
                                    type="text"
                                />
                            </Box>
                        </Grid>
                        <Grid item={true} md={12}>
                            <Box p={1}>
                                <Button
                                    onClick={this.handleSubmit}
                                    type="button"
                                    variant="outlined"
                                    color="primary"
                                    size="medium"
                                >
                                    Lähetä
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        );
    }
}

function mapStateToProps(state: AppState): IContactFormStateProps {
    return {
        contactForm: state.contactForm.contactForm,
    };
}

export default connect<IContactFormStateProps, IDispatchProps, {}, AppState>(
    mapStateToProps,
    mapDispatchToProps,
)(ContactForm);
