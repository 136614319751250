import {
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    Box,
    IconButton,
} from '@material-ui/core';
import React, { FC, useRef } from 'react';
import { useSelector } from 'react-redux';
import { IUser } from '../../../../types';
import GoogleMapsWrapper from '../../components/GoogleMaps/GoogleMaps';
import SellerCard from '../../components/SellerCard/SellerCard';
import { AppState } from '../../store/store';
import { Close } from '@material-ui/icons';
import './MapModal.scss';
import SearchInput from '../../components/SearchInput/SearchInput';

interface IMapModalProps {
    sellers: IUser[];
    showMap: boolean;
    showMapHandler: () => void;
}

const MapModal: FC<IMapModalProps> = ({ showMap, sellers, showMapHandler }) => {
    const mapGrid = useRef();
    const googleMapsState = useSelector(
        (state: AppState) => state.googleMapsState,
    );
    return (
        <Dialog
            open={showMap}
            onClose={showMapHandler}
            fullScreen={true}
            className="mapModal"
        >
            <DialogTitle className="mapModal__header">
                <Grid
                    className="mapModal__header__container"
                    container={true}
                    justify="flex-end"
                >
                    <Grid item={true} xs={11}>
                        <SearchInput searchSellers={true} />
                    </Grid>
                    <Grid
                        className="mapModal__header__container__item"
                        item={true}
                        xs={1}
                    >
                        <IconButton
                            size="medium"
                            color="secondary"
                            onClick={showMapHandler}
                        >
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent className="mapModal__body">
                <Grid container={true}>
                    <Grid
                        item={true}
                        xs={12}
                        md={3}
                        className="mapModal__body__sellers"
                    >
                        {sellers &&
                            sellers.map((seller: IUser) => {
                                return (
                                    <Box p={1} key={seller._id}>
                                        <SellerCard
                                            seller={seller}
                                        ></SellerCard>
                                    </Box>
                                );
                            })}
                    </Grid>
                    <Grid
                        item={true}
                        className="mapModal__body__mapGrid"
                        ref={mapGrid as any}
                        sm={12}
                        md={9}
                        lg={9}
                    >
                        <GoogleMapsWrapper googleMapsState={googleMapsState} />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default MapModal;
