import React, { Fragment, FC } from 'react';
import { Grid, Button, Box } from '@material-ui/core';
import './ShowProfile.scss';
import { FileCopy } from '@material-ui/icons';
import { makeStyles, Theme } from '@material-ui/core/styles';
import UserAccountInfoCard from './UserAccountInfoCard';
import UserCard from './UserCard';
import { IUser } from '../../../../types';

interface IShowProfileStateProps {
    user: IUser;
}

const useStyles = makeStyles((theme: Theme) => ({
    userAccount: {
        padding: '0 2rem',
        [theme.breakpoints.down('xs')]: {
            padding: '0',
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: '10rem',
        },
    },
    userCard: {
        padding: '0 2rem',
        [theme.breakpoints.down('xs')]: {
            padding: '0',
        },
        [theme.breakpoints.down('md')]: {
            marginTop: '4rem',
            marginBottom: '8rem',
        },
    },
}));

const ShowProfile: FC<IShowProfileStateProps> = ({ user }) => {
    const classes = useStyles();

    return (
        <Fragment>
            <Grid container={true} className="p-2 profile">
                <Grid container={true}>
                    <Grid item={true} md={12} lg={8}>
                        <div className={classes.userAccount}>
                            <UserAccountInfoCard user={user} />
                        </div>
                    </Grid>
                    <Grid item={true} md={12} lg={4}>
                        <div className={classes.userCard}>
                            <UserCard user={user} />
                            <Box mt={3}>
                                <Button color="primary" variant="outlined">
                                    <FileCopy />{' '}
                                    <a
                                        style={{ color: 'black' }}
                                        download="halkoliiteri.com yrittäjän opas"
                                        href="/downloads/halkoliiteri_yrittajan_opas.pdf"
                                    >
                                        Yrittäjän opas
                                    </a>
                                </Button>
                            </Box>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    );
};
export default ShowProfile;
