import {
    UPDATE_USER_FORM,
    RESET_USER_FORM,
} from '../../../types/admin/userFormActionTypes';
import { AppActions } from '../../../types/actions';
import { IUserForm, IUserFormState } from '../../../../../types';

export function updateUserForm(userForm: IUserForm): AppActions {
    return {
        type: UPDATE_USER_FORM,
        userFormState: { userForm },
    };
}

export function resetUserForm(userFormState: IUserFormState): AppActions {
    return {
        type: RESET_USER_FORM,
        userFormState,
    };
}
