import {
    UPDATE_RESET_PASSWORD_FORM,
    RESET_RESET_PASSWORD_FORM,
} from '../../types/resetPasswordFormActionTypes';
import { AppActions } from '../../types/actions';
import { IResetPasswordForm, IResetPasswordFormState } from '../../../../types';

export function updateResetPasswordForm(
    resetPasswordForm: IResetPasswordForm,
): AppActions {
    return {
        type: UPDATE_RESET_PASSWORD_FORM,
        resetPasswordFormState: { resetPasswordForm },
    };
}

export function resetResetPasswordForm(
    resetPasswordFormState: IResetPasswordFormState,
): AppActions {
    return {
        type: RESET_RESET_PASSWORD_FORM,
        resetPasswordFormState,
    };
}
