import React, { FC, useState } from 'react';
import {
    IconButton,
    Paper,
    TableContainer,
    TableCell,
    TableBody,
    TableRow,
    Table,
    Button,
    TableFooter,
    TablePagination,
    useTheme,
} from '@material-ui/core';
import {
    Edit,
    Delete,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    LastPage,
    FirstPage,
} from '@material-ui/icons';
import { setPriceTag } from '../../utils/utils';
import { AppState } from '../../store/store';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../types/dispatchActionTypes';
import { ICurrentUser, IProduct } from '../../../../types';

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number,
    ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div
            style={{
                flexShrink: 0,
                marginLeft: theme.spacing(2.5),
            }}
        >
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="ensimmäinen sivu"
            >
                {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="edellinen sivu"
            >
                {theme.direction === 'rtl' ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="seuraava sivu"
            >
                {theme.direction === 'rtl' ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="viimeinen sivu"
            >
                {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
            </IconButton>
        </div>
    );
}

interface IServiceListStateProps {
    userServices: IProduct[];
    currentUser: ICurrentUser;
}

interface OwnProps {
    serviceFormHandler: (key: string, value: boolean, id: string) => void;
    deleteServiceHandler: (id: string) => void;
    showEditServiceForm: boolean | undefined;
}

type ServiceListProps = IServiceListStateProps & OwnProps & IDispatchProps;

const ServiceList: FC<ServiceListProps> = ({
    userServices,
    serviceFormHandler,
    deleteServiceHandler,
    showEditServiceForm,
}) => {
    const [dense, setDense] = useState(false);
    const updateDense = () => {
        setDense(!dense);
    };
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);

    // const emptyRows =
    //     rowsPerPage -
    //     Math.min(rowsPerPage, userServices.length - page * rowsPerPage);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Paper>
            <Button onClick={updateDense} type="button">
                Muuta kokoa
            </Button>
            <TableContainer aria-label="custom pagination table">
                <Table
                    aria-labelledby="tableTitle"
                    size={dense ? 'small' : 'medium'}
                    aria-label="enhanced table"
                >
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Nimi
                            </TableCell>
                            <TableCell component="th" scope="row">
                                Hinta
                            </TableCell>
                            <TableCell component="th" scope="row">
                                Yksikkö
                            </TableCell>
                            <TableCell component="th" scope="row">
                                Kuvaus
                            </TableCell>
                            <TableCell component="th" scope="row">
                                Muokkaa
                            </TableCell>
                            <TableCell component="th" scope="row">
                                Poista
                            </TableCell>
                        </TableRow>
                        {!!userServices.length &&
                            userServices.map((service: IProduct) => {
                                return (
                                    <TableRow key={service._id}>
                                        <TableCell component="th" scope="row">
                                            {service.name}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {setPriceTag(service.price)}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {service.unit}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {service.description}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <IconButton
                                                onClick={() =>
                                                    serviceFormHandler(
                                                        'showEditServiceForm',
                                                        !showEditServiceForm,
                                                        service._id,
                                                    )
                                                }
                                                edge="end"
                                                aria-label="edit"
                                            >
                                                <Edit className="tableIcon tableIcon_orange" />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <IconButton
                                                onClick={() =>
                                                    deleteServiceHandler(
                                                        service._id,
                                                    )
                                                }
                                                edge="end"
                                                aria-label="delete"
                                            >
                                                <Delete className="tableIcon tableIcon_red" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[
                                    15,
                                    30,
                                    45,
                                    { label: 'Kaikki', value: -1 },
                                ]}
                                colSpan={6}
                                count={userServices.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'Palvelua per sivu',
                                    },
                                    native: true,
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Paper>
    );
};

function mapStateToProps(state: AppState): IServiceListStateProps {
    return {
        userServices: state.userServices,
        currentUser: state.currentUser,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceList);
