import { IUser } from '../../../types';

export const SET_SELLERS = 'SET_SELLERS';
export const SET_SELLER = 'SET_SELLER';

export interface SetSellers {
    type: typeof SET_SELLERS;
    sellers: IUser[];
}
export interface SetSeller {
    type: typeof SET_SELLER;
    seller: IUser;
}
export type SellerActionTypes = SetSellers | SetSeller;
