import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, Grid, Paper, Box } from '@material-ui/core';
import WoodMeasurmentsImage from '../../assets/images/wood-measurements.jpg';
import WoodMeasurmentsImage2 from '../../assets/images/wood-measurements-2.jpg';

const useStyles = makeStyles((theme: Theme) => ({
    box: {
        padding: '3rem 4rem',
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            padding: '3rem 1.6rem',
        },
    },
    image: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        fontSize: '1.4rem',
        '& img': {
            maxWidth: '100%',
        },
        '& span': {
            marginTop: '0.8rem',
            fontWeight: 'bold',
        },
    },
    link: {
        color: '#333',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

const UnitsOfMeasurement: FunctionComponent<{}> = () => {
    const classes = useStyles();

    return (
        <section>
            <Box py={8} px={1}>
                <Grid container={true} justify="center">
                    <Grid item={true} xs={12} md={8}>
                        <Paper>
                            <div className={classes.box}>
                                <Grid container={true}>
                                    <Grid item={true}>
                                        <Typography variant="h4">
                                            Termit ja mittayksiköt
                                        </Typography>
                                        <br />
                                    </Grid>
                                    <Grid item={true}>
                                        <br />
                                        <Typography variant="body1">
                                            <strong>Polttopuu</strong> on
                                            katkottu ja halottu uunivalmis
                                            polttopuu, jota käytetään
                                            kotitalouksien puulla
                                            lämmitettävissä laitteissa, kuten
                                            liesissä, takoissa ja
                                            keskuslämmityskattiloissa. Polttopuu
                                            on tavallisesti katkottu yhtenäiseen
                                            mittaan, tavallisesti 15 cm:stä 100
                                            cm:iin.
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            <strong>Pilke</strong> (klapi, lapu,
                                            klappi) on karsitusta pyöreästä
                                            puusta valmistettu 0,2 – 0,5 –
                                            metriseksi katkaistu ja halkaistu
                                            tai aisattu polttopuu. Yleisimmin
                                            pilke pilkotaan 25:n, 33:n tai 50
                                            cm:n pituuteen. Lyhyemmät, 25 ja 33
                                            cm pitkät polttopuut on yleensä
                                            tarkoitettu saunan ja takkojen
                                            lämmittämiseen, kun taas pidemmät,
                                            50 cm pitkät puut ovat
                                            leivinuuneissa ja
                                            keskuslämmityskattiloissa
                                            käytettäviä polttopuita.
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            <strong>Halko</strong> on noin
                                            metrin mittainen, kahteen tai
                                            neljään osaan halkaistu tai aisattu
                                            polttopuu. Halkoa käytetään
                                            sujuvamman käsiteltävyytensä
                                            ansiosta välivaiheena pilkkeen
                                            teossa.
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            Pilkkeen halkaisijan (D) ja pituuden
                                            (L) määritys.
                                        </Typography>
                                        <br />
                                        <br />
                                        <div className={classes.image}>
                                            <img
                                                src={WoodMeasurmentsImage}
                                                alt="halkoliiteri.com polttopuun mittasuhteet kuva"
                                            />
                                            <span>
                                                Kuva: Eija Alakangas, VTT
                                            </span>
                                        </div>
                                        <br />
                                        <br />
                                        <br />
                                        <Typography variant="body1">
                                            <strong>Kuutiometri</strong> (m3) on
                                            kuutio, jonka jokainen sivu on yhden
                                            metrin mittainen.
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            <strong>Kiintokuutiometri</strong>{' '}
                                            (k-m3) on kuutio, joka on umpipuuta.
                                            Kiintokuutiometristä käytetään myös
                                            nimeä kiintomotti.
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            <strong>Pinokuutiometri</strong>{' '}
                                            (p-m3) tarkoittaa kuutiometrin
                                            kokoista polttopuupinoa.
                                            Pinokuutiometristä käytetään myös
                                            nimeä pinomotti.
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            <strong>Irtokuutiometri</strong>{' '}
                                            (i-m3) tarkoittaa kuutiometriä
                                            vastaavaa määrää polttopuuta
                                            heiteltynä esim. traktorin lavalle.
                                            Irtokuutiometristä käytetään myös
                                            nimeä heittokuutiometri tai
                                            heittomotti.
                                        </Typography>
                                        <br />
                                        <br />
                                        <br />
                                        <div className={classes.image}>
                                            <img
                                                src={WoodMeasurmentsImage2}
                                                alt="halkoliiteri.com polttopuun mittasuhteet kuva 2"
                                            />
                                        </div>
                                        <br />
                                        <br />
                                        <br />

                                        <Typography variant="body1">
                                            Tutustu Halkoliiterin{' '}
                                            <Link
                                                to="/kayttoehdot"
                                                className={classes.link}
                                            >
                                                myyntiehtoihin
                                            </Link>
                                            .
                                        </Typography>
                                        <br />
                                        <br />
                                        <Typography variant="body1">
                                            Lähteet: <br />
                                            Lähde: Alakangas, E. Uunivalmiin
                                            polttopuun laatuvaatimukset EN
                                            14961-5 standardin mukaan. 2011.
                                            Sappinen, M. Pilkkeen ominaisuudet,
                                            käyttö ja varastointi. 2007.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </section>
    );
};

export default UnitsOfMeasurement;
