import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
    Divider,
    Drawer,
    Hidden,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@material-ui/core';
import {
    Menu as MenuIcon,
    ContactPhone,
    AccountCircle,
    Settings,
    LocalShipping,
    TrafficRounded,
    Apps,
    Stars,
    Assignment,
    ExitToApp,
    Close as CloseIcon,
} from '@material-ui/icons';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import {
    makeStyles,
    useTheme,
    Theme,
    createStyles,
} from '@material-ui/core/styles';
import { mapDispatchToProps } from '../../types/dispatchActionTypes';
import { AppState } from '../../store/store';
import { setSideBarValues } from '../../utils/utils';
import { resetSideBarState } from '../../utils/resetUtils';
import {
    ICurrentUser,
    ILocation,
    IRegion,
    ISidebarProps,
    IUiProps,
} from '../../../../types';

interface SideBarStateProps {
    localities: ILocation[];
    currentUser: ICurrentUser;
    uiProps: IUiProps;
    regions: IRegion[];
    sideBarProps: ISidebarProps;
}

interface Props {
    window?: () => Window;
    width?: any;
}

type SideBarProps = IDispatchProps &
    SideBarStateProps &
    RouteComponentProps &
    Props;

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        drawer: {
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                paddingTop: '4rem',
                flexShrink: 0,
            },
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        menuButton: {
            position: 'fixed',
            zIndex: 1299,
            top: '7.8rem',
            right: '3rem',
            color: '#fff',
            background: '#FE7F46',
            borderRadius: '500px !important',
            boxShadow: '0 1px 4px rgba(0,0,0,.3)',
            '&:hover': {
                background: '#fe7434 !important',
            },
            '& span svg': {
                fontSize: '2.5rem',
            },
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
            [theme.breakpoints.down(959)]: {},
            [theme.breakpoints.down(900)]: {
                top: '9rem',
            },
            [theme.breakpoints.down(600)]: {
                top: '8rem',
                right: '2rem',
            },
        },
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        drawerPaper: {
            paddingTop: '1rem',
            width: drawerWidth,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
        sidebarTitle: {
            display: 'flex',
            flexDirection: 'column',
            padding: '1rem 1.5rem',
            alignItems: 'center',
            justifyContent: 'center',
            '& svg': {
                fontSize: '3.5rem',
                marginRight: '.5rem',
                color: '#767676',
            },
            '& p': {
                fontWeight: 'bold',
            },
        },
        list: {
            padding: '1.5rem .5rem !important',
        },
        closeIcon: {
            position: 'absolute',
            right: '1rem',
        },
    }),
);

const SideBar = (props: SideBarProps) => {
    const { currentUser, sideBarProps, window } = props;
    const {
        showAccountSettings,
        showWoodProducts,
        showOtherProducts,
        showServices,
        showOrders,
        showDeliveryCosts,
        showUserInfo,
    } = sideBarProps;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        if (isWidthDown('sm', props.width)) {
            setMobileOpen(!mobileOpen);
        }
    };

    const toggleProfilePages = (key: string, value: boolean) => {
        const { updateSideBarAction, resetSideBarAction } = props;
        resetSideBarAction(resetSideBarState());
        updateSideBarAction(setSideBarValues(key, value));
    };

    const logoutHandler = () => {
        const { logoutAction } = props;
        logoutAction();
        props.history.push('/');
    };

    const drawer = (
        <React.Fragment>
            <Hidden smDown={true} implementation="css">
                <div className={classes.toolbar} />
            </Hidden>
            <div className={classes.sidebarTitle}>
                <AccountCircle />
                <Typography variant="body1">
                    {currentUser.user.username}
                </Typography>
                <Typography variant="body1">
                    Tilityyppi:{' '}
                    {currentUser.user.userAccount.account.accountPlan}
                </Typography>
                {/* <Typography variant="body1">
                    Uusittava:{' '}
                    {new Date(
                        currentUser.user.userAccount.expires,
                    ).toLocaleDateString('fi')}
                </Typography> */}
                {isWidthDown('sm', props.width) && (
                    <IconButton
                        color="primary"
                        size="small"
                        className={classes.closeIcon}
                        onClick={handleDrawerToggle}
                    >
                        <CloseIcon />
                    </IconButton>
                )}
            </div>
            <Divider />
            <List className={classes.list}>
                <ListItem
                    onClick={() => {
                        toggleProfilePages(
                            'showUserInfo',
                            !showUserInfo ? true : true,
                        );
                        handleDrawerToggle();
                    }}
                    button={true}
                >
                    <ListItemIcon>
                        <ContactPhone />
                    </ListItemIcon>
                    <ListItemText primary="Käyttäjätiedot" />
                </ListItem>
                <ListItem
                    onClick={() => {
                        toggleProfilePages(
                            'showAccountSettings',
                            !showAccountSettings ? true : true,
                        );
                        handleDrawerToggle();
                    }}
                    button={true}
                >
                    <ListItemIcon>
                        <Settings />
                    </ListItemIcon>
                    <ListItemText primary="Tilinhallinta" />
                </ListItem>
                <ListItem
                    onClick={() => {
                        toggleProfilePages(
                            'showDeliveryCosts',
                            !showDeliveryCosts ? true : true,
                        );
                        handleDrawerToggle();
                    }}
                    button={true}
                >
                    <ListItemIcon>
                        <TrafficRounded />
                    </ListItemIcon>
                    <ListItemText primary="Toimitus kustannukset" />
                </ListItem>
                <ListItem
                    onClick={() => {
                        toggleProfilePages(
                            'showWoodProducts',
                            !showWoodProducts ? true : true,
                        );
                        handleDrawerToggle();
                    }}
                    button={true}
                >
                    <ListItemIcon>
                        <LocalShipping />
                    </ListItemIcon>
                    <ListItemText primary="Polttopuut" />
                </ListItem>
                <ListItem
                    onClick={() => {
                        toggleProfilePages(
                            'showOtherProducts',
                            !showOtherProducts ? true : true,
                        );
                        handleDrawerToggle();
                    }}
                    button={true}
                >
                    <ListItemIcon>
                        <Apps />
                    </ListItemIcon>
                    <ListItemText primary="Muut tuotteet" />
                </ListItem>
                <ListItem
                    onClick={() => {
                        toggleProfilePages(
                            'showServices',
                            !showServices ? true : true,
                        );
                        handleDrawerToggle();
                    }}
                    button={true}
                >
                    <ListItemIcon>
                        <Stars />
                    </ListItemIcon>
                    <ListItemText primary="Palvelut" />
                </ListItem>
                <ListItem
                    onClick={() => {
                        toggleProfilePages(
                            'showOrders',
                            !showOrders ? true : true,
                        );
                        handleDrawerToggle();
                    }}
                    button={true}
                >
                    <ListItemIcon>
                        <Assignment />
                    </ListItemIcon>
                    <ListItemText primary="Tilaukset" />
                </ListItem>
                <ListItem button={true} onClick={logoutHandler}>
                    <ListItemIcon>
                        <ExitToApp />
                    </ListItemIcon>
                    <ListItemText primary="Kirjaudu ulos" />
                </ListItem>
            </List>
        </React.Fragment>
    );

    const container =
        window !== undefined ? () => window().document.body : undefined;

    return (
        <div className={classes.root}>
            {isWidthDown('sm', props.width) && !mobileOpen && (
                <IconButton
                    color="primary"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    className={classes.menuButton}
                >
                    <MenuIcon />
                </IconButton>
            )}

            <nav className={classes.drawer} aria-label="mailbox folders">
                <Hidden smUp={true} implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden smDown={true} implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open={true}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
        </div>
    );
};

function mapStateToProps(state: AppState): SideBarStateProps {
    return {
        localities: state.localities,
        regions: state.regions,
        currentUser: state.currentUser,
        uiProps: state.uiState.uiProps,
        sideBarProps: state.sidebarState.sidebarProps,
    };
}

export default withRouter(
    connect<SideBarStateProps, IDispatchProps, {}, AppState>(
        mapStateToProps,
        mapDispatchToProps,
    )(withWidth()(SideBar)),
);
