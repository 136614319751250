import { apiCall, setHeader } from '../../apiRequests/apiCall';
import { SET_PRODUCTS } from '../../types/productActionTypes';
import { AppActions } from '../../types/actions';
import { Dispatch } from 'redux';
import { addMessage } from './messageActions';
import { IProduct } from '../../../../types';

/** Public Method */
export function setProducts(products: IProduct[]): AppActions {
    return {
        type: SET_PRODUCTS,
        products,
    };
}
/** Public method */
export const fetchProducts = (userId: string): ThunkResult<void> => {
    setHeader('get', '');
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('get', '/api/products?owner_id=' + userId, null)
                .then((res: any) => {
                    const { products } = res;
                    dispatch(setProducts(products));
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};
