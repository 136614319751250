import React, { Component, FormEvent } from 'react';
import '../SignIn.scss';
import { AppState } from '../../../store/store';
import {
    FormControl,
    TextField,
    Grid,
    Box,
    InputBaseComponentProps,
    Paper,
    Typography,
    Button,
} from '@material-ui/core';
import { setInputValues } from '../../../utils/utils';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../../types/dispatchActionTypes';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ISignUpForm, IUiProps } from '../../../../../types';

interface IPinCodeFormStateProps {
    signUpForm: ISignUpForm;
    uiProps: IUiProps;
}

type PinCodeFormProps = IPinCodeFormStateProps &
    IDispatchProps &
    RouteComponentProps;

class PinCodeForm extends Component<PinCodeFormProps, AppState> {
    public changeHandler = (event: InputBaseComponentProps) => {
        const { updateSignUpFormAction } = this.props;
        updateSignUpFormAction(setInputValues(event));
    };

    public submitHandler = (event: FormEvent) => {
        event.preventDefault();
        const { signUpForm, verifyPincodeAction } = this.props;
        verifyPincodeAction(signUpForm);
        this.props.history.push('/kirjaudu');
    };

    public render() {
        const { pincode } = this.props.signUpForm;
        return (
            <Paper>
                <form onSubmit={this.submitHandler}>
                    <Grid container={true} justify="center">
                        <Grid item={true} xs={12}>
                            <Typography variant="h4">
                                Vahvista sähköposti tai puhelinnumero
                            </Typography>
                        </Grid>
                        <Grid item={true} xs={10} md={8}>
                            <Box py={3}>
                                <FormControl>
                                    <TextField
                                        autoFocus={true}
                                        label="Pincode"
                                        name="pincode"
                                        id="pincode"
                                        required={true}
                                        type="number"
                                        onChange={this.changeHandler}
                                        value={pincode === 0 ? '' : pincode}
                                    />
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item={true} xs={10} md={6}>
                            <Box py={3}>
                                <Button
                                    type="button"
                                    variant="outlined"
                                    color="secondary"
                                >
                                    Alkuun
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item={true} xs={10} md={6}>
                            <Box py={3}>
                                <Button
                                    type="submit"
                                    variant="outlined"
                                    color="primary"
                                >
                                    Varmenna
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        );
    }
}

function mapStateToProps(state: AppState): IPinCodeFormStateProps {
    return {
        uiProps: state.uiState.uiProps,
        signUpForm: state.signUpFormState.formData,
    };
}

export default withRouter(
    connect<IPinCodeFormStateProps, IDispatchProps, {}, AppState>(
        mapStateToProps,
        mapDispatchToProps,
    )(PinCodeForm),
);
