import { Box, Grid } from '@material-ui/core';
import React, { FC } from 'react';
import SignUpForm from '../../SignIn/AuthForm/SignUpForm';

const CreateUser: FC = () => {
    return (
        <Grid container={true} justify="center">
            <Grid item={true} md={12}>
                <Box p={2}>
                    <SignUpForm createUser={true} />
                </Box>
            </Grid>
        </Grid>
    );
};

export default CreateUser;
