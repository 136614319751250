import { Link as RouterLink } from 'react-router-dom';
import { Button, Hidden, Grid, makeStyles } from '@material-ui/core';
import { Map } from '@material-ui/icons';
import FilterBar from './FilterBar';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import ProductCard from '../../components/ProductCard/ProductCard';
import SearchInput from '../../components/SearchInput/SearchInput';
import SideBarProduct from '../../components/SideBar/SideBarProduct';
import { AppState } from '../../store/store';
import { mapDispatchToProps } from '../../types/dispatchActionTypes';
import { IUiProps, IProduct } from '../../../../types';

interface IProductsGridStateProps {
    favorites: IProduct[];
    products: IProduct[];
    uiProps: IUiProps;
}

type ProductsGridProps = IProductsGridStateProps & IDispatchProps;

const useStyles = makeStyles({
    root: {
        display: 'flex',
    },
    productsContainer: {
        padding: '0 2rem',
        margin: 0,
    },
    showProductsButton: {
        position: 'fixed',
        zIndex: 9,
        bottom: '2rem',
        left: '2rem',
        width: '16rem',
        height: '6rem',
        '& svg': {
            marginRight: '.5rem',
        },
    },
});

const ProductsGrid: FC<ProductsGridProps> = (props) => {
    const { favorites, products, uiProps } = props;
    const { showFavoriteList } = uiProps;
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Hidden mdUp={true}>
                <Button
                    component={RouterLink}
                    to="/haku"
                    variant="contained"
                    size="large"
                    color="primary"
                    className={classes.showProductsButton}
                    // onClick={this.onShowProductsClick}
                    startIcon={<Map />}
                >
                    Myyjät
                </Button>
            </Hidden>
            <SideBarProduct />
            <Grid container={true}>
                <SearchInput searchSellers={true} />
                <FilterBar />
                <Grid
                    container={true}
                    spacing={3}
                    className={classes.productsContainer}
                    justify="space-evenly"
                    alignItems="flex-start"
                >
                    {showFavoriteList
                        ? favorites.map((item: IProduct) => {
                              return (
                                  <Grid
                                      item={true}
                                      xs={12}
                                      sm={item.discount ? 12 : 6}
                                      md={item.discount ? 8 : 4}
                                      lg={item.discount ? 6 : 3}
                                      key={item._id}
                                  >
                                      <ProductCard
                                          removeFromFavorites={true}
                                          product={item}
                                      />
                                  </Grid>
                              );
                          })
                        : products.map((item: IProduct) => {
                              return (
                                  <Grid
                                      item={true}
                                      xs={12}
                                      sm={item.discount ? 12 : 6}
                                      md={item.discount ? 8 : 4}
                                      lg={item.discount ? 6 : 3}
                                      key={item._id}
                                  >
                                      <ProductCard
                                          removeFromFavorites={
                                              favorites.find(
                                                  (productInFind: IProduct) =>
                                                      productInFind._id ===
                                                      item._id,
                                              )
                                                  ? true
                                                  : false
                                          }
                                          product={item}
                                      />
                                  </Grid>
                              );
                          })}
                </Grid>
            </Grid>
        </div>
    );
};

function mapStateToProps(state: AppState): IProductsGridStateProps {
    return {
        favorites: state.favorites,
        products: state.products,
        uiProps: state.uiState.uiProps,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsGrid);
