import { createMuiTheme } from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createMuiTheme' {
    interface Theme {
        colors?: any;
    }
    // allow configuration using `createMuiTheme`
    interface ThemeOptions {
        colors?: any;
    }
}

export default createMuiTheme({
    // Customs
    colors: {
        beige: 'beige',
        forestGreen80: 'rgba(0, 112, 56, 0.8)',
    },
    // Material UIs
    typography: {
        htmlFontSize: 10,
        body1: { color: '#2d3544' },
    },
    zIndex: {
        drawer: 1001,
    },
    // Components Overrides
    overrides: {
        MuiStepper: {
            root: {
                padding: '2.4rem 0',
            },
        },
    },
});
