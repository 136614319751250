import { apiCall, setHeader } from '../../apiRequests/apiCall';
import { SET_SELLERS } from '../../types/sellersActionTypes';
import { AppActions } from '../../types/actions';
import { Dispatch } from 'redux';
import { addMessage } from './messageActions';
import { updateGoogleMapsState } from './googleMapsStateActions';
import { setProducts } from './productActions';
import { ISearchForm, IUser, ProductNames } from '../../../../types';

/** Public Method */
export function setSellers(sellers: IUser[]): AppActions {
    return {
        type: SET_SELLERS,
        sellers,
    };
}

/** Public method */
export const fetchSellers = (query: ISearchForm): ThunkResult<void> => {
    setHeader('get', '');
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall(
                'get',
                '/api/sellers?region=' +
                    (query.region || '') +
                    '&locality=' +
                    (query.locality || '') +
                    '&deliveryMethod=' +
                    (query.deliveryMethod || '') +
                    '&sellerId=' +
                    (query.sellerId || '') +
                    '&queryString=' +
                    (query.queryString || '') +
                    '&category=' +
                    (query.category || '') +
                    '&name=' +
                    (query.name || '') +
                    '&quality=' +
                    query.quality +
                    '&material=' +
                    query.material,
                null,
            )
                .then((res: any) => {
                    const { sellers, products } = res;
                    dispatch(setSellers(sellers));
                    if (sellers.length === 1) {
                        dispatch(setProducts(products));
                        dispatch(
                            updateGoogleMapsState({
                                lat: sellers[0].address.locality.coords.lat,
                                lng: sellers[0].address.locality.coords.lng,
                                zoom: sellers[0].address.locality.zoom,
                            }),
                        );
                    } else if (products.length > 0) {
                        dispatch(setProducts(products));
                    } else {
                        dispatch(
                            addMessage({
                                text: `Valitettavasti yhtään hakutulosta ei löytynyt ${
                                    query.queryString
                                        ? query.queryString
                                        : ((query.category +
                                              ', ' +
                                              query.name) as ProductNames)
                                }`,
                                bgColor: 'warning',
                                visible: true,
                            }),
                        );
                    }
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};
