import React, { FunctionComponent } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, Grid, Paper, Box } from '@material-ui/core';
import './ServiceTerms.scss';

interface ServiceTermsProps {
    agreed: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    box: {
        padding: '3rem',
        [theme.breakpoints.down('sm')]: {
            padding: '3rem 1.6rem',
        },
    },
}));

const ServiceTerms: FunctionComponent<ServiceTermsProps> = () => {
    const classes = useStyles();

    return (
        <section>
            <Box py={8} px={1}>
                <Grid container={true} justify="center">
                    <Grid item={true} xs={12} md={8}>
                        <Paper>
                            <div className={classes.box}>
                                <Grid container={true} justify="center">
                                    <Grid item={true}>
                                        <Typography variant="h4">
                                            Halkoliiteri.com -verkkopalvelun
                                            sopimusehdot
                                        </Typography>
                                        <br />
                                    </Grid>
                                    <Grid item={true}>
                                        <Typography
                                            className="colorBlack"
                                            variant="h5"
                                        >
                                            1. Sopimusehtojen soveltaminen
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            Näitä sopimusehtoja sovelletaan
                                            Halkoliiteri.com -verkkopalveluun
                                            (myöhemmin Halkoliiteri). Näitä
                                            sopimusehtoja sovelletaan, ellei
                                            Asiakkaan ja{' '}
                                            <a
                                                className="dark"
                                                href="https://www.venpoenergia.fi"
                                            >
                                                Venpo Energia Oy:n
                                            </a>{' '}
                                            välillä ole kirjallisesti toisin
                                            sovittu.
                                        </Typography>
                                        <br />
                                        <Typography
                                            className="colorBlack"
                                            variant="h5"
                                        >
                                            2. Sopimuksen kohde
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            Näissä sopimusehdoissa sovitaan
                                            Asiakkaan käyttöoikeudesta ja
                                            velvollisuuksista Halkoliiteriin.
                                            Tällä sopimuksella Asiakas saa
                                            oikeuden käyttää Halkoliiteri.com
                                            -verkkopalvelua.
                                        </Typography>
                                        <br />
                                        <Typography
                                            className="colorBlack"
                                            variant="h5"
                                        >
                                            3. Venpo Energia Oy:n velvollisuudet
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            Venpo Energia Oy toimittaa palvelun
                                            viipymättä vastaanotettuaan
                                            Asiakkaan tilauksen. Venpo Energia
                                            Oy ei vastaa kolmansista tahoista
                                            johtuvista viivästyksistä palvelun
                                            toimittamisessa, mutta sitoutuu
                                            siirtämään Asiakkaalle mahdolliset
                                            kolmansilta tahoilta viivästyksestä
                                            saatavat hyvitykset.
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            Venpo Energia Oy luovuttaa
                                            Asiakkaalle käyttäjätunnuksen ja
                                            salasanan, joiden avulla Asiakas
                                            pääsee muuttamaan omia tietojaan ja
                                            päivittämään omien myyntieriensä
                                            tietoja.
                                        </Typography>
                                        <br />
                                        <Typography
                                            className="colorBlack"
                                            variant="h5"
                                        >
                                            4. Asiakkaan velvollisuudet
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            Asiakas vastaa siitä, että hän
                                            ilmoittaa Halkoliiterissä vain
                                            sellaisista tuotteista, joita hän
                                            pystyy toimittamaan viivytyksettä.
                                            Asiakas sitoutuu toimittamaan
                                            myymiään palveluja ja/tai tuotteita
                                            sille toimialueelle, jonka hän on
                                            määrittänyt Halkoliiteriin.
                                            Asiakkaan on viipymättä poistettava
                                            Halkoliiterin ilmoituksesta
                                            sellaiset tuotteet, joita hän ei
                                            enää toimita tai joiden sopimusta ei
                                            voida suorittaa viipymättä. Mikäli
                                            Asiakkaalla ei ole käytettävissään
                                            Internet-yhteyttä, jolla tietojen
                                            päivitys voitaisiin tehdä, Asiakas
                                            voi pyytää sivuston ylläpitäjää
                                            päivittämään tiedot puolestaan.
                                            Sivuston ylläpitäjän tekemä päivitys
                                            on maksullista. Minimiveloitus on 10
                                            € (alv 0 %).
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            Asiakkaan tulee säilyttää
                                            käyttäjätunnuksensa ja salasanansa
                                            niin, että ne eivät voi joutua
                                            ulkopuolisten tietoon. Asiakas voi
                                            luovuttaa tunnuksensa
                                            asiamiehelleen, joka ylläpitää
                                            asiakkaan tietoja
                                            Internet-kauppapaikassa.
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            Asiakas vastaa siitä, että hänellä
                                            on riittävän usein tuotetut
                                            varmuuskopiot Halkoliiteriin
                                            talletetuista tiedostoista. Venpo
                                            Energia Oy ei vastaa tiedon
                                            säilymisestä palveluissa. Asiakkaan
                                            tulee ottaa toiminnassaan huomioon
                                            Venpo Energia Oy:n mahdollisia
                                            palvelun muutoksia koskevat
                                            ilmoitukset.
                                        </Typography>
                                        <br />
                                        <Typography
                                            className="colorBlack"
                                            variant="h5"
                                        >
                                            5. Palvelun käyttö
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            5.1. Halkoliiterin käyttöoikeus
                                            Kaikilla tämän sopimusehdon
                                            hyväksyneillä ja kulloinkin voimassa
                                            olevan osallistumismaksun
                                            maksaneilla on oikeus käyttää
                                            Halkoliiteri -verkkopalvelua.
                                            Käyttäjätunnukset sivustoon myöntää
                                            Venpo Energia Oy.
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            Vuosimaksu on 120€/alv 0%, laskutus
                                            helmikuussa. Vuosimaksuun lisätään
                                            ALV 24%. Kesken kalenterivuotta
                                            liittyminen 10€+alv vuoden jäljellä
                                            olevat kuukaudet.
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            5.2. Osallistumisoikeus
                                            Halkoliiteriin voivat liittyä
                                            Suomessa arvonlisäverovelvolliseksi
                                            rekisteröityneet luonnolliset
                                            henkilöt, yritykset ja yhdistykset.
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            Venpo Energia Oy:llä on oikeus
                                            keskeyttää palveluntarjonta, muuttaa
                                            sitä tai irtisanoa sopimus, jos
                                            Asiakkaan palvelun käyttö ei ole
                                            sopimuksen mukaista. Venpo Energia
                                            Oy:n tulee kuitenkin
                                            mahdollisuuksien mukaan
                                            tapauskohtaisesti neuvotella
                                            asiakkaan kanssa ongelmatilanteiden
                                            ratkaisemisesta.
                                        </Typography>
                                        <br />
                                        <Typography
                                            className="colorBlack"
                                            variant="h5"
                                        >
                                            6. Sopimuskausi ja palvelun
                                            irtisanominen
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            Sopimuskauden pituus on yksi (1)
                                            kalenterivuosi (1.1. - 31.12.),
                                            ellei erikseen ole muuta
                                            kirjallisesti sovittu. Sopimus
                                            jatkuu automaattisesti sopimuskauden
                                            päätyttyä uudella sopimuskaudella,
                                            ellei sopimusta ole irtisanottu
                                            viimeistään yhtä (1) viikkoa ennen
                                            sopimuskauden päättymistä.
                                            Irtisanoutumistapauksissa ei
                                            maksettua vuosimaksua palauteta.
                                            Mikäli Asiakas liittyy
                                            Halkoliiteriin kesken
                                            kalenterivuotta, laskutetaan häneltä
                                            osallistumismaksua
                                            sopimuksentekopäivästä vuoden
                                            loppuun olevien päivien mukaan.
                                            Asiakkaan käyttöoikeus
                                            Halkoliiteriin päättyy heti, jos
                                            asiakkaan arvonlisäverovelvollisuus
                                            päättyy. Asiakkaan tulee tällöin
                                            ilmoittaa asiasta viipymättä Venpo
                                            Energia Oy:lle.
                                        </Typography>
                                        <br />
                                        <Typography
                                            className="colorBlack"
                                            variant="h5"
                                        >
                                            7. Vastuunrajoitus
                                        </Typography>
                                        <br />
                                        <Typography variant="body1">
                                            Asiakas vastaa omien tietojensa
                                            oikeellisuudesta ja siitä, että
                                            sivulla ei ole kaupan sellaista
                                            polttopuuta, joka on häneltä
                                            loppunut. Venpo Energia Oy vastaa
                                            ainoastaan vahingoista, jotka
                                            johtuvat sen omasta tahallisesta tai
                                            törkeän tuottamuksellisesta
                                            toiminnasta. Vastuu rajoittuu
                                            kuitenkin aina enintään
                                            sopimuskauden vuosimaksua vastaavaan
                                            summaan. Vahingonkorvausta on
                                            vaadittava Venpo Energia Oy:ltä
                                            kuukauden kuluessa siitä, kun
                                            vahingonkorvauksen perusteena oleva
                                            virhe havaittiin tai se olisi
                                            pitänyt havaita tai
                                            viivästystapauksissa siitä, kun
                                            sopimus on tapahtunut. Välillisiä,
                                            varallisuuteen kohdistuvia, ennalta
                                            arvaamattomia tai yllättäviä
                                            vahinkoja Venpo Energia Oy ei
                                            korvaa. Venpo Energia Oy on
                                            hyväksynyt sopimusehdot 1.1.2019
                                            Nämä sopimusehdot otetaan käyttöön
                                            1.1.2019
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </section>
    );
};

export default ServiceTerms;
