import {
    UPDATE_ACCOUNT_SETTINGS_FORM,
    RESET_ACCOUNT_SETTINGS_FORM,
} from '../../types/accountSettingsFormTypes';
import { AppActions } from '../../types/actions';
import { setHeader, apiCall } from '../../apiRequests/apiCall';
import { Dispatch } from 'redux';
import { addMessage } from './messageActions';
import { validateUserRole } from '../../utils/utils';
import { setCurrentUser } from './userAuthActions';
import {
    IAccountSettingsForm,
    IAccountSettingsFormState,
} from '../../../../types';

export function updateAccountSettingsForm(
    accountSettingsForm: IAccountSettingsForm,
): AppActions {
    return {
        type: UPDATE_ACCOUNT_SETTINGS_FORM,
        accountSettingsFormState: { accountSettingsForm },
    };
}

export function resetAccountSettingsForm(
    accountSettingsFormState: IAccountSettingsFormState,
): AppActions {
    return {
        type: RESET_ACCOUNT_SETTINGS_FORM,
        accountSettingsFormState,
    };
}

export const updateAccountSettings = (
    accountSettingsForm: IAccountSettingsForm,
    accountId: string,
): ThunkResult<void> => {
    setHeader('patch', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall(
                'patch',
                '/api/users/' + accountId + '/accountsettings',
                accountSettingsForm,
            )
                .then((res: any) => {
                    const { user, message } = res;
                    dispatch(
                        setCurrentUser(user, true, validateUserRole(user.role)),
                    );
                    dispatch(
                        addMessage({
                            text: message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};
