import React, { FC } from 'react';
import './PublicProfile.scss';
import { makeStyles } from '@material-ui/core/styles';
import { setSellerName } from '../../utils/utils';
import UserIcon from '../../assets/images/user-icon.svg';
import { ILocation, IUser } from '../../../../types';

const useStyles = makeStyles(() => ({
    root: {},
    header: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '2.2rem',
        marginBottom: '1.5rem',
        marginTop: '.5rem',
        '& svg': {
            fontSize: '2.6rem',
        },
    },
    avatar: {
        marginRight: '1rem',
        width: '5rem',
    },
    address: {
        marginBottom: '.6rem',
        marginTop: '2rem',
        fontSize: '1.6rem',
        display: 'block',
        textAlign: 'center',
    },
    email: {
        display: 'block',
        textAlign: 'center',
        marginTop: '2rem',
        fontSize: '1.6rem',
        textDecoration: 'none',
        color: '#FE7F46',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    text: {
        fontSize: '1.6rem',
        marginTop: '2.5rem',
        marginLeft: '1rem',
        textAlign: 'left',
    },
    button: {
        margin: '2.5rem auto 0 auto',
        display: 'block',
    },
    deliveryLocations: {
        wordBreak: 'break-all',
    },
}));

interface OwnProps {
    currentUser: IUser;
}

type PublicProfileProps = OwnProps;

const PublicProfile: FC<PublicProfileProps> = ({ currentUser }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <img
                    src={UserIcon}
                    className={classes.avatar}
                    alt="user placeholder"
                />
                <span>{setSellerName(currentUser)}</span>
            </div>
            <span className={classes.address}>
                {`${currentUser.address.street}, ${currentUser.address.zip} ${currentUser.address.locality.location}`}
            </span>
            <a
                className={classes.email}
                href={'mailto:' + currentUser.email + '?subject=Tajouspyyntö'}
            >
                {currentUser.email}
            </a>
            <div className={classes.text}>
                <p>Tuotteet ja palvelut: {currentUser.products.length} kpl</p>
                <p>Toimitustapa: {currentUser.deliveryMethod}</p>
                <p className={classes.deliveryLocations}>
                    Toimituspaikkakunnat:{' '}
                    {currentUser.deliveryLocations.length === 320 ? (
                        <p>Koko Suomi</p>
                    ) : (
                        currentUser.deliveryLocations?.map(
                            (
                                deliveryLocation: ILocation,
                                index: number,
                                arr: ILocation[],
                            ) => {
                                return (
                                    <span key={deliveryLocation._id}>
                                        {index === arr.length - 1
                                            ? deliveryLocation.location + ''
                                            : deliveryLocation.location + ', '}
                                    </span>
                                );
                            },
                        )
                    )}
                </p>
                {currentUser.accountDescription && (
                    <>
                        <p>
                            <strong>Kuvaus:</strong>
                        </p>
                        <p>{currentUser.accountDescription}</p>
                    </>
                )}
            </div>
        </div>
    );
};

export default PublicProfile;
