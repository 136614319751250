import { IDeliveryCost } from '../../../../types';
import {
    ADD_DELIVERYCOST,
    DeliveryCostActionTypes,
    REMOVE_DELIVERYCOST,
    SET_DELIVERYCOSTS,
    UPDATE_DELIVERYCOST,
} from '../../types/deliveryCostActionTypes';

const DEFAULT_STATE: IDeliveryCost[] = [];

const deliveryCostReducer = (
    state = DEFAULT_STATE,
    action: DeliveryCostActionTypes,
) => {
    switch (action.type) {
        case SET_DELIVERYCOSTS:
            return action.deliveryCosts;
        case ADD_DELIVERYCOST:
            return [...state, action.deliveryCost];
        case UPDATE_DELIVERYCOST:
            return state.map((deliveryCost: IDeliveryCost) => {
                if (deliveryCost._id === action.deliveryCost._id) {
                    return {
                        ...deliveryCost,
                        ...action.deliveryCost,
                    };
                } else {
                    return deliveryCost;
                }
            });
        case REMOVE_DELIVERYCOST:
            return state.filter(({ _id }) => _id !== action.id);
        default:
            return state;
    }
};

export default deliveryCostReducer;
