import React from 'react';
import { AppState } from '../../../store/store';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import './AuthForm.scss';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { mapDispatchToProps } from '../../../types/dispatchActionTypes';
import LoginForm from './LoginForm';
import SignUpForm from './SignUpForm';
import { ICurrentUser, ISignUpForm, IUiProps } from '../../../../../types';

interface AuthFormStateProps {
    currentUser: ICurrentUser;
    signUpForm: ISignUpForm;
    uiProps: IUiProps;
}

interface OwnProps {
    login: boolean;
}

type AuthFormProps = AuthFormStateProps &
    IDispatchProps &
    OwnProps &
    RouteComponentProps;

class AuthForm extends React.Component<AuthFormProps, AppState> {
    public componentDidMount() {
        const { fetchAccountsAction } = this.props;
        fetchAccountsAction('');
    }
    public handleLogin = (event: any) => {
        event.preventDefault();
        const { loginAction } = this.props;
        loginAction({
            username: event.target[0].value,
            password: event.target[1].value,
        });
    };
    public componentDidUpdate() {
        const { isAuthenticated } = this.props.currentUser;
        // tslint:disable-next-line
        !!isAuthenticated && this.props.history.push('/profiili');
    }
    public render() {
        const { login } = this.props;

        return (
            <section>
                <Grid
                    container={true}
                    alignContent="center"
                    className="full-height"
                    justifyContent="center"
                >
                    {login && <LoginForm handleLogin={this.handleLogin} />}
                    {!login && <SignUpForm />}
                </Grid>
            </section>
        );
    }
}
function mapStateToProps(state: AppState): AuthFormStateProps {
    return {
        currentUser: state.currentUser,
        uiProps: state.uiState.uiProps,
        signUpForm: state.signUpFormState.formData,
    };
}

export default withRouter(
    connect<AuthFormStateProps, IDispatchProps, OwnProps, AppState>(
        mapStateToProps,
        mapDispatchToProps,
    )(AuthForm),
);
