import React, { Fragment, Component } from 'react';
import { Grid, Button } from '@material-ui/core';
import {
    withStyles,
    createStyles,
    WithStyles,
    Theme,
} from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { AppState } from '../../store/store';
import { mapDispatchToProps } from '../../types/dispatchActionTypes';
import { setUiPropsValues } from '../../utils/utils';
import './ManageProducts.scss';
import ProductList from './ProductList';
import ServiceList from './ServiceList';
import Modal from '../Modal/Modal';
import { Add } from '@material-ui/icons';
import {
    ProductCategories,
    IUiProps,
    IUser,
    IProduct,
} from '../../../../types';

interface OwnProps {
    selectedCategory: ProductCategories;
}

interface ManageProductsStateProps {
    userServices: IProduct[];
    userProducts: IProduct[];
    otherUserProducts: IProduct[];
    user: IUser;
    uiProps: IUiProps;
}

type ManageProductsProps = IDispatchProps &
    ManageProductsStateProps &
    OwnProps &
    WithStyles<typeof styles>;

const styles = (theme: Theme) =>
    createStyles({
        btnGrid: {
            marginBottom: '2rem',
        },
    });

class ManageProducts extends Component<ManageProductsProps, AppState> {
    public productFormHandler = (key: string, value: boolean, id: string) => {
        const {
            updateUiStateAction,
            userProducts,
            otherUserProducts,
            user,
            resetProductFormAction,
        } = this.props;
        const product = otherUserProducts
            .concat(userProducts)
            .find((productInFind) => productInFind._id === id);
        console.log(key, value);
        updateUiStateAction(setUiPropsValues(key, value));
        updateUiStateAction({ itemId: id });
        updateUiStateAction({ modalOpen: true });
        resetProductFormAction({
            name: product?.name || '',
            category: product?.category || 'Polttopuut',
            plength: product?.plength || '',
            price: product?.price || 0,
            unit: product?.unit || '',
            material: product?.material || '',
            deliveryType: product?.deliveryType || '',
            quality: product?.quality || '',
            user_id: user._id,
            description: product?.description || '',
            quantity: product?.quantity || 1,
            vat: 2400,
        });
    };

    public serviceFormHandler = (key: string, value: boolean, id: string) => {
        const {
            updateUiStateAction,
            userServices,
            user,
            resetServiceFormAction,
        } = this.props;
        const service = userServices.find(
            (serviceInFind: IProduct) => serviceInFind._id === id,
        );
        updateUiStateAction(setUiPropsValues(key, value));
        updateUiStateAction({ itemId: id });
        updateUiStateAction({ modalOpen: true });
        resetServiceFormAction({
            name: service?.name || '',
            category: service?.category || 'Palvelut',
            unit: service?.unit || '',
            price: service?.price || 0,
            description: service?.description || '',
            user_id: user._id,
            quantity: service?.quantity || 100000,
            vat: 2400,
        });
    };

    public deleteProductHandler = (id: string) => {
        const { deleteProductAction } = this.props;
        deleteProductAction(id);
    };

    public deleteServiceHandler = (id: string) => {
        const { deleteServiceAction } = this.props;
        deleteServiceAction(id);
    };

    public modalOpenHandler = (open: boolean) => {
        const { updateUiStateAction } = this.props;
        updateUiStateAction({ modalOpen: open });
    };

    public render() {
        const { uiProps, selectedCategory, classes } = this.props;
        const {
            showEditProductForm,
            showEditServiceForm,
            modalOpen,
            showImageUploadForm,
        } = uiProps;
        return (
            <Fragment>
                <Grid container={true} justify="center">
                    <Grid item={true} xs={12} className={classes.btnGrid}>
                        <Button
                            type="button"
                            onClick={() => this.modalOpenHandler(true)}
                            color="primary"
                            variant="outlined"
                        >
                            <Add />
                            {selectedCategory === 'Palvelut'
                                ? ' Luo palvelu'
                                : selectedCategory === 'Polttopuut'
                                ? ' Luo polttopuu'
                                : 'Luo tuote'}
                        </Button>
                    </Grid>
                    <Grid item={true} xs={12} md={10}>
                        <Modal
                            selectedCategory={selectedCategory}
                            editProduct={
                                showEditProductForm
                                    ? true
                                    : showEditServiceForm
                                    ? true
                                    : false
                            }
                            modalOpen={
                                modalOpen === undefined ? true : modalOpen
                            }
                            showProductForm={
                                selectedCategory !== 'Palvelut' &&
                                !showImageUploadForm
                                    ? true
                                    : false
                            }
                            showServiceForm={
                                selectedCategory === 'Palvelut' ? true : false
                            }
                            showImageUpload={showImageUploadForm}
                        />
                    </Grid>
                </Grid>
                <Grid container={true} justify="center">
                    <Grid item={true} xs={12} md={12}>
                        {selectedCategory !== 'Palvelut' && (
                            <ProductList
                                selectedCategory={selectedCategory}
                                deleteProductHandler={this.deleteProductHandler}
                                productFormHandler={this.productFormHandler}
                                showEditProductForm={showEditProductForm}
                            />
                        )}
                        {selectedCategory === 'Palvelut' && (
                            <ServiceList
                                deleteServiceHandler={this.deleteServiceHandler}
                                serviceFormHandler={this.serviceFormHandler}
                                showEditServiceForm={showEditServiceForm}
                            />
                        )}
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
}
function mapStateToProps(state: AppState): ManageProductsStateProps {
    return {
        userProducts: state.userProducts,
        userServices: state.userServices,
        otherUserProducts: state.otherUserProducts,
        user: state.currentUser.user,
        uiProps: state.uiState.uiProps,
    };
}
export default connect<
    ManageProductsStateProps,
    IDispatchProps,
    OwnProps,
    AppState
>(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(ManageProducts));
