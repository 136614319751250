import { IAccountSettingsFormState } from '../../../types';

export const UPDATE_ACCOUNT_SETTINGS_FORM = 'UPDATE_ACCOUNT_SETTINGS_FORM';
export const RESET_ACCOUNT_SETTINGS_FORM = 'RESET_ACCOUNT_SETTINGS_FORM';

export interface UpdateAccuntSettingsForm {
    type: typeof UPDATE_ACCOUNT_SETTINGS_FORM;
    accountSettingsFormState: IAccountSettingsFormState;
}

export interface ResetAccuntSettingsFormState {
    type: typeof RESET_ACCOUNT_SETTINGS_FORM;
    accountSettingsFormState: IAccountSettingsFormState;
}

export type AccountSettingsFormActionTypes =
    | UpdateAccuntSettingsForm
    | ResetAccuntSettingsFormState;
