import React, { FC } from 'react';
import './SellerCard.scss';
import {
    Typography,
    Card,
    CardHeader,
    Avatar,
    CardContent,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Box,
    InputBaseComponentProps,
    Badge,
    CardActions,
    IconButton,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
    LocationOn,
    Business,
    Face,
    OpenInNew,
    LocalShipping,
} from '@material-ui/icons';
import { AppState } from '../../store/store';
import { mapDispatchToProps } from '../../types/dispatchActionTypes';
import { connect } from 'react-redux';
import { setUiPropsValues } from '../../utils/utils';
import { ICurrentUser, IUiProps, IUser } from '../../../../types';

interface OwnProps {
    seller: IUser;
}

interface ISellerCardStateProps {
    currentUser: ICurrentUser;
    uiProps: IUiProps;
}

type SellerCardProps = ISellerCardStateProps & OwnProps & IDispatchProps;

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        cursor: 'pointer',
        '& .cardHeader .MuiCardHeader-content': {
            flex: ' 0 1 auto',
        },
        '& .locationButton': {
            cursor: 'pointer',
        },
    },
    listItemAvatar: {
        minWidth: '3rem',
    },
    cardActions: {
        justifyContent: 'space-between',
        padding: '0 2rem 1rem 1rem',
    },
}));

const SellerCard: FC<SellerCardProps> = (props) => {
    const { seller, updateGoogleMapsStateAction, uiProps } = props;
    const { modalOpen } = uiProps;
    const classes = useStyles();
    const updateMap = (event: InputBaseComponentProps, ownerId: string) => {
        updateGoogleMapsStateAction({
            lat: Number(event.currentTarget?.dataset.lat),
            lng: Number(event.currentTarget?.dataset.lng),
            zoom: Number(event.currentTarget?.dataset.zoom),
        });
    };
    const openModalHandler = (sellerId: string, showModal: boolean) => {
        const { updateUiStateAction } = props;
        updateUiStateAction(setUiPropsValues('sellerId', sellerId));
        updateUiStateAction(setUiPropsValues('modalOpen', showModal));
    };
    return (
        <Box py={2}>
            <Card
                className={'productCard ' + classes.card}
                onClick={() => openModalHandler(seller._id, !modalOpen)}
            >
                <CardHeader
                    className={
                        seller.business
                            ? 'cardHeader cardHeader_purpleColor'
                            : 'cardHeader cardHeader_orange'
                    }
                    avatar={
                        seller.business ? (
                            <Badge badgeContent="Yritys" color="error">
                                <Avatar
                                    aria-label={seller.companyName}
                                    className="primary"
                                >
                                    <Business className="default-icon-size colorDefault colorDefault_purpleColor" />
                                </Avatar>
                            </Badge>
                        ) : (
                            <Avatar
                                aria-label={seller.fullName}
                                className="primary"
                            >
                                <Face className="default-icon-size colorDefault colorDefault_orange" />
                            </Avatar>
                        )
                    }
                    title={
                        <Typography
                            variant={seller.companyName ? 'h5' : 'h6'}
                            className="sellerCardTitle"
                        >
                            {seller.companyName || seller.fullName}
                        </Typography>
                    }
                    subheader={
                        seller.business ? (
                            <Typography
                                variant="body1"
                                className="sellerCardTitle"
                            >
                                yritys
                            </Typography>
                        ) : (
                            <Typography
                                variant="body1"
                                className="sellerCardTitle"
                            >
                                yksityinen myyjä
                            </Typography>
                        )
                    }
                />
                <CardContent>
                    <List>
                        <ListItem
                            className="locationButton"
                            tabIndex={0}
                            data-lat={seller.address.coords.lat}
                            data-lng={seller.address.coords.lng}
                            data-zoom={seller.address.locality.zoom}
                            onClick={(event: InputBaseComponentProps) => {
                                event.stopPropagation();
                                updateMap(event, seller._id);
                            }}
                        >
                            <ListItemAvatar className={classes.listItemAvatar}>
                                <LocationOn />
                            </ListItemAvatar>
                            <ListItemText
                                primary={`${
                                    seller.business
                                        ? seller.companyName
                                        : seller.fullName
                                } sijainti kartalla`}
                                secondary={`${seller.address.region.name}, ${seller.address.locality.location}`}
                            />
                        </ListItem>
                        {seller.website && (
                            <ListItem>
                                <ListItem>
                                    <ListItemText
                                        className="black"
                                        primary={
                                            <a
                                                href={`${seller.website}`}
                                                target="__blank"
                                            >
                                                {seller.website}
                                            </a>
                                        }
                                    />
                                </ListItem>
                            </ListItem>
                        )}
                        <ListItem>
                            <ListItemAvatar>
                                <LocalShipping />
                            </ListItemAvatar>
                            <ListItemText
                                primary={`Tuotteet ja palvelut: ${seller.products.length} kpl`}
                            />
                        </ListItem>
                    </List>
                    <List>
                        <ListItem>
                            <ListItemText primary="Näytä toimituspaikkakunnat ->" />
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                Kuvaus:
                                <br />
                                {seller.accountDescription}
                            </ListItemText>
                        </ListItem>
                    </List>
                </CardContent>
                <CardActions>
                    <IconButton
                        aria-label="add to favorites"
                        onClick={() => openModalHandler(seller._id, !modalOpen)}
                    >
                        <OpenInNew color="primary" />
                    </IconButton>
                </CardActions>
            </Card>
        </Box>
    );
};

function mapStateToProps(state: AppState): ISellerCardStateProps {
    return {
        currentUser: state.currentUser,
        uiProps: state.uiState.uiProps,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SellerCard);
