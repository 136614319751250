import React, { FC, useState } from 'react';
import {
    TableRow,
    FormControl,
    TableCell,
    Select,
    Checkbox,
    MenuItem,
    InputBaseComponentProps,
    InputLabel,
    Typography,
    Button,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { AppState } from '../../../store/store';
import { mapDispatchToProps } from '../../../types/dispatchActionTypes';
import { AccountState, adminPanelOptionLists } from '../../../formOptionList';
import { setAdminPanelUiStateValues } from '../../../utils/utils';
import { Edit } from '@material-ui/icons';
import { IAdminPanelUiProps, IUser } from '../../../../../types';

interface IUserRowStateProps {
    ids: (string | string[])[];
    adminPanelUiProps: IAdminPanelUiProps;
}

interface OwnProps {
    user: IUser;
    index: number;
}

type UserRowProps = IUserRowStateProps & OwnProps & IDispatchProps;

const UserRow: FC<UserRowProps> = (props) => {
    const {
        user,
        index,
        ids,
        editUserAccountStatusAction,
        removeFromIdsAction,
        addToIdsAction,
    } = props;
    const [isActive, setIsActive] = useState<boolean>(ids.includes(user._id));
    const { accountStates } = adminPanelOptionLists;
    const labelId = `enhanced-table-checkbox-${index}`;

    const handleChange = (id: string, active: boolean) => {
        if ((isActive && ids.length === 0) || ids.includes(id)) {
            removeFromIdsAction(id);
        } else {
            addToIdsAction(id);
        }
        setIsActive(active);
    };
    const modalOpenHandler = (userId: string) => {
        const { adminPanelUiProps, updateAdminPanelUiStateAction } = props;
        const { modalOpen } = adminPanelUiProps;
        updateAdminPanelUiStateAction(
            setAdminPanelUiStateValues('modalOpen', !modalOpen),
        );
        updateAdminPanelUiStateAction(
            setAdminPanelUiStateValues('userId', userId),
        );
    };
    const handleEditUserAccountStatus = (
        event: InputBaseComponentProps,
        id: string,
    ) => {
        editUserAccountStatusAction(
            id,
            event.target.value === 'kyllä'
                ? true
                : event.target.value === 'ei'
                ? false
                : isActive,
        );
    };
    return (
        <>
            <TableRow
                hover={true}
                role="checkbox"
                aria-checked={isActive}
                tabIndex={-1}
                key={user._id}
                selected={isActive}
            >
                <TableCell
                    padding="checkbox"
                    onClick={() => handleChange(user._id, !isActive)}
                >
                    {index + ' '}
                    <Checkbox
                        checked={isActive}
                        inputProps={{
                            'aria-labelledby': labelId,
                        }}
                        value={isActive}
                    />
                </TableCell>
                <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    padding="none"
                >
                    {user.companyName || user.fullName}{' '}
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => modalOpenHandler(user._id)}
                    >
                        <Edit />
                    </Button>
                </TableCell>
                <TableCell align="left">
                    {user.address.locality.location}
                </TableCell>
                <TableCell align="left">
                    {user.business ? 'Yritys' : 'Yksityis henkilö'}
                </TableCell>
                <TableCell>{user.username}</TableCell>
                {user.userAccount.account && (
                    <TableCell align="left">
                        {user.userAccount.account.accountPlan}
                    </TableCell>
                )}
                <TableCell align="left">
                    <Typography
                        color={user.userAccount.paid ? 'primary' : 'secondary'}
                        variant="body2"
                    >
                        {user.userAccount.paid ? 'Maksettu' : 'Ei ole maksettu'}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <FormControl
                        variant="outlined"
                        fullWidth={true}
                        required={true}
                    >
                        <InputLabel id="demo-simple-select-required-label">
                            Valitse yksikkö
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-required-label"
                            id="demo-simple-select-required"
                            name="isActive"
                            value={
                                isActive
                                    ? 'kyllä'
                                    : user && user.userAccount.isActive
                                    ? 'kyllä'
                                    : 'ei'
                            }
                            onChange={(event: InputBaseComponentProps) =>
                                handleEditUserAccountStatus(event, user._id)
                            }
                            label="Tilin tilan hallinta"
                        >
                            <MenuItem
                                value={
                                    isActive
                                        ? 'kyllä'
                                        : user && user.userAccount.isActive
                                        ? 'kyllä'
                                        : 'ei'
                                }
                            >
                                <em>Valitse yksikkö</em>
                            </MenuItem>
                            {!!accountStates.length &&
                                accountStates.map(
                                    (accountState: AccountState) => {
                                        return (
                                            <MenuItem
                                                key={accountState.text}
                                                value={accountState.text}
                                            >
                                                {accountState.text}
                                            </MenuItem>
                                        );
                                    },
                                )}
                        </Select>
                    </FormControl>
                </TableCell>
            </TableRow>
        </>
    );
};

function mapStateToProps(state: AppState): IUserRowStateProps {
    return {
        ids: state.ids,
        adminPanelUiProps: state.adminPanelUiState.adminPanelUiProps,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserRow);
