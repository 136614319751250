import {
    UPDATE_SIGNUP_FORM,
    RESET_SIGNUP_FORM_STATE,
} from '../../types/signUpFormStateActionTypes';
import { AppActions } from '../../types/actions';
import { ISignUpForm, ISignUpFormState } from '../../../../types';

export function updateSignUpForm(formData: ISignUpForm): AppActions {
    return {
        type: UPDATE_SIGNUP_FORM,
        signUpFormState: { formData },
    };
}

export function resetSignUpForm(signUpFormState: ISignUpFormState): AppActions {
    return {
        type: RESET_SIGNUP_FORM_STATE,
        signUpFormState,
    };
}
