import {
    Button,
    FormControl,
    Grid,
    InputAdornment,
    InputBaseComponentProps,
    InputLabel,
    makeStyles,
    OutlinedInput,
} from '@material-ui/core';
import { PeopleOutline, SearchRounded } from '@material-ui/icons';
import React, { FC, FormEvent, useState } from 'react';
import { connect } from 'react-redux';
import { ISearchForm } from '../../../../types';
import theme from '../../pages/App/theme';
import './SearchInput.scss';
import { AppState } from '../../store/store';
import { mapDispatchToProps } from '../../types/dispatchActionTypes';
import { setSearchInputValues } from '../../utils/utils';

const useStyles = makeStyles({
    form: {
        width: '50%',
        '& button': {
            width: '15%',
            marginLeft: '1.5rem',
            [theme.breakpoints.down('xs')]: {
                marginLeft: '1rem',
            },
        },
        [theme.breakpoints.down('md')]: {
            width: '70%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '90%',
            padding: '2rem 2rem 2rem 8rem',
        },
    },
    searchGrid: {
        background: '#ffffff',
        boxShadow: '0 2px 4px rgba(0,0,0,.1)',
        zIndex: 10,
    },
    toolbar: {
        paddingTop: '.4rem',
        [theme.breakpoints.down(900)]: {
            paddingTop: '4rem',
        },
        [theme.breakpoints.down(600)]: {
            paddingTop: '3rem',
        },
    },
});

interface ISearchInputStateProps {
    searchForm: ISearchForm;
}

interface OwnProps {
    searchUsers?: boolean;
    searchSellers?: boolean;
}

type SearchInputProps = ISearchInputStateProps & OwnProps & IDispatchProps;

const SearchInput: FC<SearchInputProps> = (props) => {
    const classes = useStyles();
    const { searchForm, searchUsers, searchSellers } = props;
    const [query, updateQuery] = useState<string>('');
    const onChangeHandler = (event: InputBaseComponentProps) => {
        const { updateSearchFormAction } = props;
        if (searchUsers || searchSellers) {
            updateQuery(event.target.value);
        } else {
            updateSearchFormAction(setSearchInputValues(event));
        }
    };

    const submitHandler = (event: FormEvent) => {
        event.preventDefault();
        const { fetchSellersAction, fetchUsersAction } = props;
        if (searchUsers) {
            fetchUsersAction(`?queryString=${query}`);
        } else {
            fetchSellersAction({ queryString: query });
        }
        return;
    };
    return (
        <Grid container={true} className={classes.toolbar}>
            <Grid
                container={true}
                justify="center"
                className={classes.searchGrid}
            >
                <form
                    onSubmit={submitHandler}
                    className={classes.form + ' form'}
                >
                    <FormControl fullWidth={true} variant="outlined">
                        <InputLabel
                            required={false}
                            htmlFor="outlined-adornment-amount"
                        >
                            Hae Myyjiä
                        </InputLabel>
                        <OutlinedInput
                            required={true}
                            placeholder="Hae Myyjiä..."
                            label="Hae Myyjiä..."
                            id="outlined-adornment-amount"
                            name="queryString"
                            type="search"
                            value={
                                searchUsers
                                    ? query
                                    : searchSellers
                                    ? query
                                    : searchForm.queryString
                            }
                            onChange={onChangeHandler}
                            startAdornment={
                                <InputAdornment position="start">
                                    <PeopleOutline />
                                </InputAdornment>
                            }
                            labelWidth={60}
                        />
                    </FormControl>
                    <Button
                        type="submit"
                        fullWidth={true}
                        variant="outlined"
                        size="large"
                        color="primary"
                    >
                        <SearchRounded />
                    </Button>
                </form>
            </Grid>
        </Grid>
    );
};

function mapStateToProps(state: AppState): ISearchInputStateProps {
    return {
        searchForm: state.searchFormState.searchForm,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchInput);
