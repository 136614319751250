import { apiCall, setHeader } from '../../apiRequests/apiCall';
import { SET_ORDER, SET_ORDERS } from '../../types/orderActionTypes';
import { AppActions } from '../../types/actions';
import { Dispatch } from 'redux';
import { addMessage } from './messageActions';
import { resetOrderTotals, setOrderTotals } from './orderTotalsActions';
import {
    resetCheckoutFormFunction,
    resettedOrderTotals,
} from '../../utils/resetUtils';
import { UPDATE_ORDERS } from '../../types/ordersActionTypes';
import { resetCheckoutForm } from './checkoutFormActions';
import { IOrder, OrderStatus } from '../../../../types';

/** Public Method */
export function setOrder(order: IOrder): AppActions {
    return {
        type: SET_ORDER,
        order,
    };
}

export function setOrders(orders: IOrder[]): AppActions {
    return {
        type: SET_ORDERS,
        orders,
    };
}

export function updateOrders(order: IOrder): AppActions {
    return {
        type: UPDATE_ORDERS,
        order,
    };
}

/** Public method */
export const fetchOrders = (query: string): ThunkResult<void> => {
    setHeader('get', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('get', '/api/orders' + query, null)
                .then((res: any) => {
                    const { orders } = res;
                    dispatch(setOrders(orders));
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

/** Restricted access to product owners only, for Users */
export const createOrder = (newOrder: IOrder): ThunkResult<void> => {
    setHeader('post', localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('post', '/api/orders', newOrder)
                .then((res: any) => {
                    const { order, message, totals } = res;
                    dispatch(resetOrderTotals(resettedOrderTotals()));
                    dispatch(setOrderTotals(totals));
                    dispatch(
                        resetCheckoutForm({
                            checkoutForm: resetCheckoutFormFunction(),
                        }),
                    );
                    dispatch(setOrder(order));
                    dispatch(
                        addMessage({
                            text: message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

/** Public method */
export const fetchOrder = (id: string): ThunkResult<void> => {
    setHeader('get', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('get', '/api/orders/' + id, null)
                .then((res: any) => {
                    const { order, totals } = res;
                    dispatch(setOrder(order));
                    dispatch(resetOrderTotals(resettedOrderTotals()));
                    dispatch(setOrderTotals(totals));
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

/** Closed method, Update Order */
export const updateOrder = (
    id: string,
    status: OrderStatus,
): ThunkResult<void> => {
    setHeader('get', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('patch', '/api/orders/' + id + '/edit', { status })
                .then((res: any) => {
                    const { order, message, totals } = res;
                    dispatch(setOrder(order));
                    dispatch(resetOrderTotals(resettedOrderTotals()));
                    dispatch(setOrderTotals(totals));
                    dispatch(updateOrders(order));
                    dispatch(
                        addMessage({
                            text: message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};
