import { IOrder } from '../../../types';

export const SET_ORDER = 'SET_ORDER';
export const SET_ORDERS = 'SET_ORDERS';

export interface SetOrder {
    type: typeof SET_ORDER;
    order: IOrder;
}

export interface SetOrders {
    type: typeof SET_ORDERS;
    orders: IOrder[];
}
export type OrderActionTypes = SetOrder | SetOrders;
