import { apiCall, setHeader } from '../../apiRequests/apiCall';
import {
    SET_USER_PRODUCTS,
    ADD_PRODUCT,
    UPDATE_PRODUCT,
    REMOVE_PRODUCT,
} from '../../types/userProductsActionTypes';
import { AppActions } from '../../types/actions';
import { Dispatch } from 'redux';
import { addMessage } from './messageActions';
import { setOtherUserProducts } from './otherUserProductActions';
import { setUserServices } from './userServiceActions';
import { IProductForm, IProduct } from '../../../../types';

/** Public Method */
export function setUserProducts(userProducts: IProduct[]): AppActions {
    return {
        type: SET_USER_PRODUCTS,
        userProducts,
    };
}

/** Restricted access to product owners only, for Users */
export function addProduct(product: IProduct): AppActions {
    return {
        type: ADD_PRODUCT,
        product,
    };
}

export function updateProduct(product: IProduct): AppActions {
    return {
        type: UPDATE_PRODUCT,
        product,
    };
}

export function removeProduct(id: string): AppActions {
    return {
        type: REMOVE_PRODUCT,
        id,
    };
}
/** Public method */
export const fetchUserProducts = (id: string): ThunkResult<void> => {
    setHeader('get', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('get', '/api/users/' + id + '/products', null)
                .then((res: any) => {
                    const { products, otherProducts, services } = res;
                    dispatch(setUserProducts(products));
                    dispatch(setOtherUserProducts(otherProducts));
                    dispatch(setUserServices(services));
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

/** Restricted access to product owners only, for Users */
export const createProduct = (productForm: IProductForm): ThunkResult<void> => {
    setHeader('post', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('post', '/api/products', productForm)
                .then((res: any) => {
                    const { product } = res;
                    dispatch(addProduct(product));
                    dispatch(
                        addMessage({
                            text: res.message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

export const editProductsImage = (
    formData: FormData,
    productId: string,
): ThunkResult<void> => {
    setHeader('multipart/form-data', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('post', `/api/products/${productId}/image`, {
                formData,
            })
                .then((res: any) => {
                    const { product, message } = res;
                    dispatch(updateProduct(product));
                    dispatch(
                        addMessage({
                            text: message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

export const editProduct = (
    productForm: IProductForm,
    productId: string,
): ThunkResult<void> => {
    setHeader('put', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('put', '/api/products/' + productId, productForm)
                .then((res: any) => {
                    const { product, message } = res;
                    dispatch(updateProduct(product));
                    dispatch(
                        addMessage({
                            text: message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

/** Restricted access to product owners only, for Users */
export const deleteProduct = (id: string): ThunkResult<void> => {
    setHeader('delete', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('delete', '/api/products/' + id, null)
                .then((res: any) => {
                    const { message } = res;
                    dispatch(removeProduct(id));
                    dispatch(
                        addMessage({
                            text: message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};
