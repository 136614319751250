import { IOrderTotals } from '../../../../types';
import { AppActions } from '../../types/actions';
import {
    RESET_ORDER_TOTALS,
    SET_ORDER_TOTALS,
} from '../../types/orderTotalsActionTypes';

/** Public Method */
export function setOrderTotals(orderTotals: IOrderTotals): AppActions {
    orderTotals = {
        totalPriceExcludingTax: Number(orderTotals.totalPriceExcludingTax),
        totalTaxes: Number(orderTotals.totalTaxes),
        totalPrice: Number(orderTotals.totalPrice),
    };
    return {
        type: SET_ORDER_TOTALS,
        orderTotals,
    };
}

export function resetOrderTotals(orderTotals: IOrderTotals): AppActions {
    return {
        type: RESET_ORDER_TOTALS,
        orderTotals,
    };
}
