import { IGoogleMapsState } from '../../../types';

export const UPDATE_GOOGLE_MAPS_POSITION = 'UPDATE_GOOGLE_MAPS_POSITION';

export interface UpdateGoogleMapsPosition {
    type: typeof UPDATE_GOOGLE_MAPS_POSITION;
    googleMapsState: IGoogleMapsState;
}

export type GoogleMapsStateActionTypes = UpdateGoogleMapsPosition;
