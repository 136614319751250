import { IGoogleMapsProps } from '../../../../types';
import { AppActions } from '../../types/actions';
import { UPDATE_GOOGLE_MAPS_POSITION } from '../../types/googleMapsUiStateActionTypes';

export function updateGoogleMapsState(
    googleMapsProps: IGoogleMapsProps,
): AppActions {
    return {
        type: UPDATE_GOOGLE_MAPS_POSITION,
        googleMapsState: { googleMapsProps },
    };
}
