import { apiCall, setHeader } from '../../apiRequests/apiCall';
import { AppActions } from '../../types/actions';
import { Dispatch } from 'redux';
import { addMessage } from './messageActions';
import {
    SET_ADVERTISEMENTS,
    ADD_ADVERTISEMENT,
    UPDATE_ADVERTISEMENT,
    REMOVE_ADVERTISEMENT,
} from '../../types/advertisementActionTypes';
import { IAdvertisementForm, IAdvertisement } from '../../../../types';

/** Public Method */
export function setAdvertisements(
    advertisements: IAdvertisement[],
): AppActions {
    return {
        type: SET_ADVERTISEMENTS,
        advertisements,
    };
}

/** Restricted access to admins only, for Admins */
export function addAdvertisement(advertisement: IAdvertisement): AppActions {
    return {
        type: ADD_ADVERTISEMENT,
        advertisement,
    };
}

export function updateAdvertisement(advertisement: IAdvertisement): AppActions {
    return {
        type: UPDATE_ADVERTISEMENT,
        advertisement,
    };
}

export function removeAdvertisement(id: string): AppActions {
    return {
        type: REMOVE_ADVERTISEMENT,
        id,
    };
}
/** Public method */
export const fetchAdvertisements = (query: string): ThunkResult<void> => {
    setHeader('get', '');
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('get', '/api/advertisements' + query, null)
                .then((res: any) => {
                    const { advertisements } = res;
                    dispatch(setAdvertisements(advertisements));
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

/** Restricted access to admins only, for Admins */
export const createAdvertisement = (
    formData: IAdvertisementForm,
): ThunkResult<void> => {
    setHeader('post', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('post', '/api/advertisements', formData)
                .then((res: any) => {
                    const { advertisement } = res;
                    dispatch(addAdvertisement(advertisement));
                    dispatch(
                        addMessage({
                            text: res.message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

export const editAdvertisement = (
    formData: IAdvertisementForm,
    advertisementId: string,
): ThunkResult<void> => {
    setHeader('put', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall(
                'put',
                '/api/advertisements/' + advertisementId,
                formData,
            )
                .then((res: any) => {
                    const { advertisement, message } = res;
                    dispatch(updateAdvertisement(advertisement));
                    dispatch(
                        addMessage({
                            text: message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

/** Restricted access to admins only, for Admins */
export const deleteAdvertisement = (id: string): ThunkResult<void> => {
    setHeader('delete', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('delete', '/api/advertisements/' + id, {})
                .then((res: any) => {
                    const { message } = res;
                    dispatch(removeAdvertisement(id));
                    dispatch(
                        addMessage({
                            text: message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};
