import { IProduct } from '../../../../types';
import {
    ADD_OTHER_USER_PRODUCT,
    DELETE_OTHER_USER_PRODUCT,
    EDIT_OTHER_USER_PRODUCT,
    OtherUserProductActionTypes,
    SET_OTHER_USER_PRODUCTS,
} from '../../types/userOtherProductsActionTypes';

const DEFAULT_STATE: IProduct[] = [];

const otherUserProductReducer = (
    state = DEFAULT_STATE,
    action: OtherUserProductActionTypes,
) => {
    switch (action.type) {
        case SET_OTHER_USER_PRODUCTS:
            return action.otherUserProducts;
        case ADD_OTHER_USER_PRODUCT:
            return [...state, action.otherUserProduct];
        case EDIT_OTHER_USER_PRODUCT:
            return state.map((otherUserProduct: IProduct) => {
                if (otherUserProduct._id === action.otherUserProduct._id) {
                    return {
                        ...otherUserProduct,
                        ...action.otherUserProduct,
                    };
                } else {
                    return otherUserProduct;
                }
            });
        case DELETE_OTHER_USER_PRODUCT:
            return state.filter(({ _id }) => _id !== action.id);
        default:
            return state;
    }
};

export default otherUserProductReducer;
