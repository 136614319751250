import { Dispatch } from 'redux';
import { ContactStatus, IContactForm, IContact } from '../../../../types';
import { apiCall, setHeader } from '../../apiRequests/apiCall';
import { AppActions } from '../../types/actions';
import {
    SET_CONTACT,
    SET_CONTACTS,
    UPDATE_CONTACT,
} from '../../types/contactsActionTypes';
import { addMessage } from './messageActions';

/** Private Method for Admins */
export function setContacts(contacts: IContact[]): AppActions {
    return {
        type: SET_CONTACTS,
        contacts,
    };
}

export function updateContact(contact: IContact): AppActions {
    return {
        type: UPDATE_CONTACT,
        contact,
    };
}

/** Private Method */
export function setContact(contact: IContact): AppActions {
    return {
        type: SET_CONTACT,
        contact,
    };
}

/** Fetch a contact for a user */
export const fetchTicket = (id: string, token: string): ThunkResult<void> => {
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall(
                'get',
                `/api/admin/contacts/${id}/ticket?t=${token}`,
                null,
            )
                .then((res: any) => {
                    const { contact } = res;
                    dispatch(setContact(contact));
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

/** Add a reply to a contact from a user */
export const respondToContact = (
    id: string,
    message: string,
    userName: string,
    token: string,
): ThunkResult<void> => {
    setHeader('patch', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('post', '/api/admin/contacts/' + id + '/ticket', {
                message,
                name: userName,
                token,
            })
                .then((res: any) => {
                    const { contact } = res;
                    dispatch(setContact(contact));
                    dispatch(
                        addMessage({
                            text: res.message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

/** Public method for everyone */
export const createContact = (contactForm: IContactForm): ThunkResult<void> => {
    setHeader('post', '');
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('post', '/api/admin/contacts', contactForm)
                .then((res: any) => {
                    const { contact } = res;
                    dispatch(setContact(contact));
                    dispatch(
                        addMessage({
                            text: res.message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

/** Private Method for admins and users */
export const fetchContact = (id: string): ThunkResult<void> => {
    setHeader('get', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('get', '/api/admin/contacts/' + id, null)
                .then((res: any) => {
                    const { contact } = res;
                    dispatch(setContact(contact));
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

export const respondToContactFromAdmin = (
    id: string,
    message: string,
): ThunkResult<void> => {
    setHeader('patch', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('patch', '/api/admin/contacts/' + id + '/respond', {
                message,
            })
                .then((res: any) => {
                    const { contact } = res;
                    dispatch(setContact(contact));
                    dispatch(
                        addMessage({
                            text: res.message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

/** Private method for Admins */
export const fetchContacts = (query: string): ThunkResult<void> => {
    setHeader('get', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('get', '/api/admin/contacts' + query, null)
                .then((res: any) => {
                    const { contacts } = res;
                    dispatch(setContacts(contacts));
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};

export const updateContactsStatus = (
    id: string,
    status: ContactStatus,
): ThunkResult<void> => {
    setHeader('put', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('put', '/api/admin/contacts/' + id, { status })
                .then((res: any) => {
                    const { contact } = res;
                    dispatch(updateContact(contact));
                    dispatch(
                        addMessage({
                            text: res.message,
                            bgColor: 'success',
                            visible: true,
                        }),
                    );
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error
                                ? error.message
                                : 'virhe palvelimella, yritä uudelleen hetken kuluttua.',
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};
