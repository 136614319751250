import { IProductFormState } from '../../../../types';
import {
    UPDATE_PRODUCT_FORM,
    ProductFormActionTypes,
    RESET_PRODUCT_FORM,
} from '../../types/productFormActionTypes';
import { resetProductFormFunction } from '../../utils/resetUtils';

const DEFAULT_STATE: IProductFormState = {
    productForm: resetProductFormFunction(),
};

const productFormReducer = (
    state = DEFAULT_STATE,
    action: ProductFormActionTypes,
) => {
    switch (action.type) {
        case UPDATE_PRODUCT_FORM:
            return Object.assign({}, state, {
                productForm: {
                    ...state.productForm,
                    [Object.keys(action.productFormState.productForm)[0]]:
                        Object.values(action.productFormState.productForm)[0],
                },
            });
        case RESET_PRODUCT_FORM:
            return {
                ...state,
                productForm: action.productFormState.productForm,
            };
        default:
            return state;
    }
};

export default productFormReducer;
