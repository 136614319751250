import { AppActions } from '../../types/actions';
import { IDeliveryCostForm } from '../../../../types';
import {
    RESET_DELIVERYCOST_FORM,
    UPDATE_DELIVERYCOST_FORM,
} from '../../types/deliveryCostFormActionTypes';

export function updateDeliveryCostForm(
    deliveryCostForm: IDeliveryCostForm,
): AppActions {
    return {
        type: UPDATE_DELIVERYCOST_FORM,
        deliveryCostFormState: { deliveryCostForm },
    };
}

export function resetDeliveryCostForm(
    deliveryCostForm: IDeliveryCostForm,
): AppActions {
    return {
        type: RESET_DELIVERYCOST_FORM,
        deliveryCostFormState: { deliveryCostForm },
    };
}
