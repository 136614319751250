import { IDeliveryCost } from '../../../types';

export const SET_DELIVERYCOSTS = 'SET_DELIVERYCOSTS';
export const ADD_DELIVERYCOST = 'ADD_DELIVERYCOST';
export const UPDATE_DELIVERYCOST = 'UPDATE_DELIVERYCOST';
export const REMOVE_DELIVERYCOST = 'REMOVE_DELIVERYCOST';

export interface SetDeliveryCosts {
    type: typeof SET_DELIVERYCOSTS;
    deliveryCosts: IDeliveryCost[];
}

export interface AddDeliveryCost {
    type: typeof ADD_DELIVERYCOST;
    deliveryCost: IDeliveryCost;
}

export interface UpdateDeliveryCost {
    type: typeof UPDATE_DELIVERYCOST;
    deliveryCost: IDeliveryCost;
}

export interface RemoveDeliveryCost {
    type: typeof REMOVE_DELIVERYCOST;
    id: string;
}

export type DeliveryCostActionTypes =
    | SetDeliveryCosts
    | AddDeliveryCost
    | UpdateDeliveryCost
    | RemoveDeliveryCost;
