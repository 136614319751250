import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { rootReducer } from './reducers/rootReducer';
import storage from 'redux-persist/lib/storage';
import { Persistor } from 'redux-persist/es/types';

export type AppState = ReturnType<typeof rootReducer>;

const persistConfig = {
    key: 'rootReducer',
    storage,
};
const presistedReducer: any = persistReducer(persistConfig, rootReducer);
const store = createStore(
    presistedReducer,
    composeWithDevTools(applyMiddleware(thunk)),
);
const persistor: Persistor = persistStore(store);
export { persistor, store };
