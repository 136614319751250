export const ADD_TO_IDS = 'ADD_TO_IDS';
export const REMOVE_FROM_IDS = 'REMOVE_FROM_IDS';
export const UPDATE_IDS = 'UPDATE_IDS';
export const RESET_IDS = 'RESET_IDS';

export interface AddToIds {
    type: typeof ADD_TO_IDS;
    id: string;
}

export interface RemoveFromIds {
    type: typeof REMOVE_FROM_IDS;
    id: string;
}

export interface UpdateIds {
    type: typeof UPDATE_IDS;
    ids: string[];
}

export interface ResetIds {
    type: typeof RESET_IDS;
    ids: string[];
}

export type IdsActionTypes = AddToIds | RemoveFromIds | UpdateIds | ResetIds;
