import { ILocation } from '../../../../types';
import {
    LocalitiesActionTypes,
    SET_LOCALITIES,
    ADD_LOCALITY,
    UPDATE_LOCALITY,
    REMOVE_LOCALITY,
} from '../../types/localitiesActionTypes';

const DEFAULT_STATE: ILocation[] = [];

const localityReducer = (
    state = DEFAULT_STATE,
    action: LocalitiesActionTypes,
) => {
    switch (action.type) {
        case SET_LOCALITIES:
            return action.localities;
        case ADD_LOCALITY:
            return [...state, action.locality];
        case UPDATE_LOCALITY:
            return state.map((locality: ILocation) => {
                if (locality._id === action.locality._id) {
                    return {
                        ...locality,
                        ...action.locality,
                    };
                } else {
                    return locality;
                }
            });
        case REMOVE_LOCALITY:
            return state.filter(({ _id }) => _id !== action.id);
        default:
            return state;
    }
};

export default localityReducer;
