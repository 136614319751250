import React, { FC, Fragment } from 'react';
import './ManageProducts.scss';
import { AppBar, Toolbar, Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

interface ISortMenuBarStateProps {
    title: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbar: {
            minHeight: '0',
            [theme.breakpoints.down(900)]: {
                minHeight: '4rem',
            },
            [theme.breakpoints.down(600)]: {
                minHeight: '3.3rem',
            },
        },
        appbar: {
            position: 'sticky',
            top: 0,
            left: 0,
            zIndex: 1,
            backgroundColor: '#fff',
            borderBottom: '#fe7f46',
        },
    }),
);

const SortMenuBar: FC<ISortMenuBarStateProps> = ({ title }) => {
    const classes = useStyles();

    return (
        <Fragment>
            <div className={classes.toolbar} />
            <AppBar position="sticky" className={classes.appbar}>
                <Toolbar>
                    <Typography variant="h6">{title}</Typography>
                </Toolbar>
            </AppBar>
            <Toolbar />
        </Fragment>
    );
};

export default SortMenuBar;
