import { IOrder } from '../../../../types';
import {
    OrdersActionTypes,
    SET_ORDERS,
    UPDATE_ORDERS,
} from '../../types/ordersActionTypes';

const DEFAULT_STATE: IOrder[] = [];

const ordersReducer = (state = DEFAULT_STATE, action: OrdersActionTypes) => {
    switch (action.type) {
        case SET_ORDERS:
            return action.orders;
        case UPDATE_ORDERS:
            return state.map((order: IOrder) => {
                if (order._id === action.order._id) {
                    return {
                        ...order,
                        ...action.order,
                    };
                } else {
                    return order;
                }
            });
        default:
            return state;
    }
};

export default ordersReducer;
