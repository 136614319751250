import React, { FC } from 'react';
import { Box } from '@material-ui/core';

interface ITabPanelProps {
    value: number;
    index: number;
}

const TabPanel: FC<ITabPanelProps> = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
};

export default TabPanel;
