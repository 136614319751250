import { IAdminPanelPageState } from '../../../../../types';
import {
    AdminPanelPageActionTypes,
    RESET_ADMIN_PANEL_PAGE,
    UPDATE_ADMIN_PANEL_PAGE,
} from '../../../types/admin/AdminPanelPageActionTypes';

const DEFAULT_STATE: IAdminPanelPageState = {
    adminPanelPage: {
        usersCount: 0,
        newUsersCount: 0,
        ordersCount: 0,
        contactsCount: 0,
    },
};

const adminPanelPageReducer = (
    state = DEFAULT_STATE,
    action: AdminPanelPageActionTypes,
) => {
    switch (action.type) {
        case UPDATE_ADMIN_PANEL_PAGE:
            const { adminPanelPage } = action.adminPanelPageState;
            return Object.assign({}, state, {
                ...state,
                adminPanelPage,
            });
        case RESET_ADMIN_PANEL_PAGE:
            return Object.assign({}, state, {
                ...state,
                adminPanelPage,
            });

        default:
            return state;
    }
};

export default adminPanelPageReducer;
