import { IRegion } from '../../../../types';
import {
    RegionActionTypes,
    SET_REGIONS,
    ADD_REGION,
    UPDATE_REGION,
    REMOVE_REGION,
} from '../../types/regionActionTypes';

const DEFAULT_STATE: IRegion[] = [];

const regionReducer = (state = DEFAULT_STATE, action: RegionActionTypes) => {
    switch (action.type) {
        case SET_REGIONS:
            return action.regions;
        case ADD_REGION:
            return [...state, action.region];
        case UPDATE_REGION:
            return state.map((region: IRegion) => {
                if (region._id === action.region._id) {
                    return {
                        ...region,
                        ...action.region,
                    };
                } else {
                    return region;
                }
            });
        case REMOVE_REGION:
            return state.filter(({ _id }) => _id !== action.id);
        default:
            return state;
    }
};

export default regionReducer;
