import React, { Component } from 'react';
import { Grid, Box, Button } from '@material-ui/core';
import { AppState } from '../../../store/store';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../../types/dispatchActionTypes';
import './Users.scss';
import UsersTable from './UsersTable';
import SearchInput from '../../../components/SearchInput/SearchInput';
import { apiCall, setHeader } from '../../../apiRequests/apiCall';
import { IAdminPanelUiState, IUser } from '../../../../../types';
import DetailedView from './DetailedView';

interface IAdminUsersStateProps {
    sellers: IUser[];
    adminPanelUiState: IAdminPanelUiState;
}

type AdminUsersProps = IAdminUsersStateProps & IDispatchProps;

class Users extends Component<AdminUsersProps, AppState, IDispatchProps> {
    public getSellers = (query: string) => {
        const { fetchUsersAction } = this.props;
        fetchUsersAction(query);
    };
    public showMoreDetails = () => {
        const { updateAdminPanelUiStateAction, adminPanelUiState } = this.props;
        const { showMoreDetails } = adminPanelUiState.adminPanelUiProps;
        updateAdminPanelUiStateAction({ showMoreDetails: !showMoreDetails });
    };
    public downloadUsers = async () => {
        setHeader('get', window.localStorage.getItem('token'));
        return apiCall('get', '/api/admin/users/download-seller-list-csv', {
            responseType: 'blob',
        })
            .then((response: any) => {
                const defaultFilename = 'seller-list.csv';
                const data = new Blob([response]);
                if (typeof window.navigator.msSaveBlob === 'function') {
                    // If it is IE that support download blob directly.
                    window.navigator.msSaveBlob(data, defaultFilename);
                } else {
                    const blob = data;
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = defaultFilename;
                    document.body.appendChild(link);
                    link.click(); // create an <a> element and simulate the click operation.
                }
            })
            .catch((error: any) => {
                console.log(error);
                return error;
            });
    };
    public downloadUsersExcell = async () => {
        setHeader('get', window.localStorage.getItem('token'));
        return apiCall('get', '/api/admin/users/download-seller-list-excell', {
            responseType: 'blob',
        })
            .then((response: any) => {
                const defaultFilename = 'seller-list.xlsx';
                const data = new Blob([response]);
                if (typeof window.navigator.msSaveBlob === 'function') {
                    // If it is IE that support download blob directly.
                    window.navigator.msSaveBlob(data, defaultFilename);
                } else {
                    const blob = data;
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = defaultFilename;
                    document.body.appendChild(link);
                    link.click(); // create an <a> element and simulate the click operation.
                }
            })
            .catch((error: any) => {
                console.log(error);
                return error;
            });
    };
    public render() {
        const { sellers, adminPanelUiState } = this.props;
        const { showMoreDetails } = adminPanelUiState.adminPanelUiProps;
        return (
            <Grid container={true} justify="center">
                <SearchInput searchUsers={true} />
                <Grid item={true} xs={12} md={12}>
                    <Box py={2}>
                        <Grid container={true}>
                            <Grid item={true} xs={2}>
                                <Button
                                    onClick={this.showMoreDetails}
                                    variant="outlined"
                                    color="secondary"
                                    size="medium"
                                >
                                    {showMoreDetails
                                        ? 'palaa takaisin'
                                        : 'Laaja näkymä'}
                                </Button>
                            </Grid>
                            <Grid item={true} xs={2}></Grid>
                            <Grid item={true} xs={8}>
                                <Button
                                    onClick={this.downloadUsersExcell}
                                    variant="outlined"
                                    color="default"
                                    size="medium"
                                >
                                    Myyjät Excelliin
                                </Button>
                                <Button
                                    onClick={this.downloadUsers}
                                    variant="outlined"
                                    color="primary"
                                    size="medium"
                                >
                                    Myyjät CSV:nä
                                </Button>
                                <Button
                                    onClick={() => {
                                        this.getSellers(
                                            '?active=true&admin=' + true,
                                        );
                                        localStorage.setItem(
                                            'usersQuery',
                                            '?active=true',
                                        );
                                    }}
                                    variant="outlined"
                                    color="default"
                                    size="medium"
                                >
                                    {'Aktiiviset ' + sellers.length + ' myyjää'}
                                </Button>
                                <Button
                                    onClick={() => {
                                        this.getSellers(
                                            '?active=false&admin=' + true,
                                        );
                                        localStorage.setItem(
                                            'usersQuery',
                                            '?active=false',
                                        );
                                    }}
                                    variant="outlined"
                                    color="default"
                                    size="medium"
                                >
                                    Passiiviset
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid
                            container={true}
                            justify="space-between"
                            alignContent="center"
                        >
                            <Grid item={true} xs={12}>
                                {showMoreDetails ? (
                                    <DetailedView />
                                ) : (
                                    <UsersTable />
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        );
    }
}

function mapStateToProps(state: AppState): IAdminUsersStateProps {
    return {
        adminPanelUiState: state.adminPanelUiState,
        sellers: state.sellers,
    };
}

export default connect<IAdminUsersStateProps, IDispatchProps, {}, AppState>(
    mapStateToProps,
    mapDispatchToProps,
)(Users);
