import React, { ComponentType, FC } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { AppState } from '../../store/store';

interface ILoggedOutRouteProps {
    exact?: boolean;
    isAuthenticated: boolean | null;
    path: string;
    Component: ComponentType<any>;
}

const LoggedOutRoute: FC<ILoggedOutRouteProps> = ({
    isAuthenticated,
    Component,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                if (!isAuthenticated) {
                    return <Component {...props} />;
                } else {
                    return (
                        <Redirect
                            to={{
                                pathname: '/profiili',
                                state: {
                                    from: props.location,
                                },
                            }}
                        />
                    );
                }
            }}
        />
    );
};

const mapStateToProps = (state: AppState) => ({
    isAuthenticated: state.currentUser.isAuthenticated,
});

export default connect(mapStateToProps)(LoggedOutRoute);
