import { IAdminPanelPageState } from '../../../../types';

export const UPDATE_ADMIN_PANEL_PAGE = 'UPDATE_ADMIN_PANEL_PAGE';
export const RESET_ADMIN_PANEL_PAGE = 'RESET_ADMIN_PANEL_PAGE';

export interface UpdateAdminPanelPage {
    type: typeof UPDATE_ADMIN_PANEL_PAGE;
    adminPanelPageState: IAdminPanelPageState;
}

export interface ResetAdminPanelPage {
    type: typeof RESET_ADMIN_PANEL_PAGE;
    adminPanelPageState: IAdminPanelPageState;
}

export type AdminPanelPageActionTypes =
    | UpdateAdminPanelPage
    | ResetAdminPanelPage;
