import { IProduct } from '../../../../types';
import {
    SET_USER_SERVICES,
    ADD_SERVICE,
    UserServiceActionTypes,
    UPDATE_SERVICE,
    REMOVE_SERVICE,
} from '../../types/userServicesActionTypes';

const DEFAULT_STATE: IProduct[] = [];

const userServiceReducer = (
    state = DEFAULT_STATE,
    action: UserServiceActionTypes,
) => {
    switch (action.type) {
        case SET_USER_SERVICES:
            return action.userServices;
        case ADD_SERVICE:
            return [...state, action.service];
        case UPDATE_SERVICE:
            return state.map((service: IProduct) => {
                if (service._id === action.service._id) {
                    return {
                        ...service,
                        ...action.service,
                    };
                } else {
                    return service;
                }
            });
        case REMOVE_SERVICE:
            return state.filter(
                (service: IProduct) => service._id !== action.id,
            );
        default:
            return state;
    }
};

export default userServiceReducer;
