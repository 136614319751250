import React from 'react';
import { Link } from 'react-router-dom';
import {
    AppBar,
    Typography,
    Toolbar,
    IconButton,
    Theme,
    MenuItem,
    Menu,
    Badge,
} from '@material-ui/core';
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles';
import {
    AccountCircle,
    Search,
    ShoppingCart,
    Dashboard,
    SupervisorAccount,
    Map,
} from '@material-ui/icons';
import {
    MoreHoriz as MoreIcon,
    ArrowDropDown as ArrowDropDownIcon,
} from '@material-ui/icons';
import './AppNavbar.scss';
import { AppState } from '../../store/store';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../types/dispatchActionTypes';
import { setPriceTag } from '../../utils/utils';
import { OikopolutLinks } from '../../oikopolutLinks';
import {
    ICart,
    ICurrentUser,
    IOikoPolkuLink,
    IUiProps,
} from '../../../../types';

const styles = (theme: Theme) =>
    createStyles({
        appbar: {
            backgroundColor: '#007038',
        },
        root: {
            flexGrow: 1,
        },
        grow: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            display: 'block',
        },
        link: {
            display: 'none',
            marginLeft: '1rem',
            [theme.breakpoints.up(780)]: {
                display: 'block',
            },
        },
        menuLink: {
            color: '#333',
            textDecoration: 'none',
        },
        buttonLink: {
            display: 'none',
            marginLeft: '1rem',
            color: '#fff',
            textTransform: 'capitalize',
            fontSize: '1.9rem',
            [theme.breakpoints.up(780)]: {
                display: 'block',
            },
        },
        profile: {
            display: 'none',
            [theme.breakpoints.up(780)]: {
                display: 'block',
            },
        },
        profileName: {
            color: '#fff',
            paddingLeft: '0.5rem',
        },
        accountLinks: {
            display: 'flex',
            alignItems: 'center',
        },
        cart: {
            display: 'none',
            [theme.breakpoints.up(780)]: {
                display: 'block',
            },
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up(780)]: {
                display: 'none',
            },
        },
        navIcon: {
            fontSize: '2.4rem !important',
            marginRight: '0.7rem',
        },
        firewoodInfoLinksDesktop: {
            width: '36rem',
        },
        firewoodInfoLinksMobile: {
            display: 'flex',
            flexDirection: 'column',
            padding: '1.2rem 1rem 1.2rem 4rem',
            background: '#f7f7f7',
            '& a': {
                color: '#333',
                textDecoration: 'none',
                padding: '0.4rem 0.9rem',
                fontSize: '1.6rem',
                borderRadius: '.5rem',
            },
            '& a:hover': {
                background: '#eee',
            },
            '& a:not(:last-child)': {
                marginBottom: '1.6rem',
            },
        },
        firewoodInfoLinksBtnOpener: {
            width: '30rem',
        },
    });

interface AppNavbarStateProps {
    currentUser: ICurrentUser;
    uiState: IUiProps;
    cart: ICart;
}

interface AppNavBarState {
    mobileMoreAnchorEl: null | HTMLElement;
    firewoodInfoDesktopMenuEl: null | HTMLElement;
    firewoodInfoMobileLinksOpen: boolean;
}

type AppNavbarProps = IDispatchProps &
    AppNavbarStateProps &
    WithStyles<typeof styles>;

class AppNavbar extends React.Component<AppNavbarProps, AppNavBarState> {
    constructor(props: AppNavbarProps) {
        super(props);
        this.state = {
            mobileMoreAnchorEl: null,
            firewoodInfoDesktopMenuEl: null,
            firewoodInfoMobileLinksOpen: false,
        };
    }

    public componentDidMount() {
        const { fetchCartAction, logoutAction, currentUser } = this.props;
        if (Object.keys(currentUser.user).length === 0) {
            logoutAction();
        }
        fetchCartAction();
    }

    public handleMobileMenuClose = () => {
        this.setState({
            mobileMoreAnchorEl: null,
            firewoodInfoMobileLinksOpen: false,
        });
    };

    public handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ mobileMoreAnchorEl: event.currentTarget });
    };

    public handleFirewoodInfoDesktopOpen = (
        event: React.MouseEvent<HTMLElement>,
    ) => {
        this.setState({ firewoodInfoDesktopMenuEl: event.currentTarget });
    };

    public handleFirewoodInfoDesktopClose = () => {
        this.setState({ firewoodInfoDesktopMenuEl: null });
    };

    public onFirewoodInfoLinksBtnOpenerClicked = () => {
        this.setState({
            firewoodInfoMobileLinksOpen:
                !this.state.firewoodInfoMobileLinksOpen,
        });
    };

    public render() {
        const isMobileMenuOpen = Boolean(this.state.mobileMoreAnchorEl);
        const { classes, cart, currentUser } = this.props;
        const { user, isAuthenticated, isAdmin } = this.props.currentUser;
        const profileLink: string = !!isAdmin
            ? '/admin'
            : !!isAuthenticated
            ? '/profiili'
            : '/kirjaudu';

        const mobileMenuId = 'primary-search-account-menu-mobile';
        const renderMobileMenu = (
            <Menu
                anchorEl={this.state.mobileMoreAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id={mobileMenuId}
                keepMounted={true}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMobileMenuOpen}
                onClose={this.handleMobileMenuClose}
            >
                <MenuItem
                    onClick={this.handleMobileMenuClose}
                    component={Link}
                    to="/haku"
                >
                    <Map className={classes.navIcon} />
                    Hae Myyjiä
                </MenuItem>
                <MenuItem
                    onClick={this.handleMobileMenuClose}
                    component={Link}
                    to="/ilmoitukset"
                >
                    <Search className={classes.navIcon} />
                    Hae polttopuita
                </MenuItem>
                <MenuItem
                    onClick={this.onFirewoodInfoLinksBtnOpenerClicked}
                    className={classes.firewoodInfoLinksBtnOpener}
                >
                    <ArrowDropDownIcon className={classes.navIcon} />
                    Polttopuuinfo
                </MenuItem>

                {this.state.firewoodInfoMobileLinksOpen && (
                    <div className={classes.firewoodInfoLinksMobile}>
                        {OikopolutLinks.map((oikoPolutLink: IOikoPolkuLink) => {
                            return (
                                <Link
                                    key={oikoPolutLink.id}
                                    to={oikoPolutLink.href}
                                    onClick={this.handleMobileMenuClose}
                                >
                                    {oikoPolutLink.innerHTML}
                                </Link>
                            );
                        })}
                    </div>
                )}

                <MenuItem
                    onClick={this.handleMobileMenuClose}
                    component={Link}
                    to={profileLink}
                >
                    {!isAuthenticated && (
                        <React.Fragment>
                            {!!isAdmin ? (
                                <SupervisorAccount />
                            ) : (
                                <AccountCircle className={classes.navIcon} />
                            )}
                            Kirjaudu
                        </React.Fragment>
                    )}
                    {Object.keys(currentUser.user).length !== 0 &&
                        !!isAuthenticated && (
                            <React.Fragment>
                                <AccountCircle className={classes.navIcon} />
                                {user.contactInformation.firstname.substring(
                                    0,
                                    1,
                                ) +
                                    ' ' +
                                    user.contactInformation.lastname.substring(
                                        0,
                                        1,
                                    )}
                            </React.Fragment>
                        )}
                    {!!isAdmin && (
                        <React.Fragment>
                            <Dashboard />
                            Hallintapaneeli
                        </React.Fragment>
                    )}
                </MenuItem>
            </Menu>
        );

        return (
            <div className={classes.root}>
                <AppBar className={classes.appbar} position="fixed">
                    <Toolbar>
                        <Typography variant="h5" className={classes.title}>
                            <Link to="/">
                                <img
                                    src="https://res.cloudinary.com/halkoliiteri-com/image/upload/v1617117536/halkoliitericom-logo_bgiayh.png"
                                    alt="halkoliiteri.com Logo"
                                />
                            </Link>
                        </Typography>
                        <IconButton
                            className={classes.link}
                            component={Link}
                            to="/haku"
                            aria-label="Tuotteiden haku paikkakunnittain"
                            color="inherit"
                        >
                            <Map className={classes.navIcon} />
                            <Typography
                                className="margin-left-half"
                                variant="h6"
                            >
                                Hae Myyjiä
                            </Typography>
                        </IconButton>
                        <IconButton
                            className={classes.link}
                            component={Link}
                            onClick={this.handleMobileMenuClose}
                            to="/ilmoitukset"
                            aria-label="Tuotteiden haku paikkakunnittain"
                            color="inherit"
                        >
                            <Search className={classes.navIcon} />
                            <Typography
                                className="margin-left-half"
                                variant="h6"
                            >
                                Hae polttopuita
                            </Typography>
                        </IconButton>
                        <div>
                            <IconButton
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                className={classes.buttonLink}
                                onClick={this.handleFirewoodInfoDesktopOpen}
                            >
                                Polttopuuinfo <ArrowDropDownIcon />
                            </IconButton>
                            <Menu
                                anchorEl={this.state.firewoodInfoDesktopMenuEl}
                                className={classes.firewoodInfoLinksDesktop}
                                keepMounted={true}
                                open={Boolean(
                                    this.state.firewoodInfoDesktopMenuEl,
                                )}
                                onClose={this.handleFirewoodInfoDesktopClose}
                            >
                                <MenuItem
                                    onClick={
                                        this.handleFirewoodInfoDesktopClose
                                    }
                                >
                                    <Link
                                        to="/polttopuuinfo"
                                        className={classes.menuLink}
                                    >
                                        Polttopuuinfo
                                    </Link>
                                </MenuItem>
                                <MenuItem
                                    onClick={
                                        this.handleFirewoodInfoDesktopClose
                                    }
                                >
                                    <Link
                                        to="/polttopuuinfo/termit-ja-mittayksikot"
                                        className={classes.menuLink}
                                    >
                                        Termit ja mittayksiköt
                                    </Link>
                                </MenuItem>

                                <MenuItem
                                    onClick={
                                        this.handleFirewoodInfoDesktopClose
                                    }
                                >
                                    <Link
                                        to="/polttopuuinfo/polttopuun-ominaisuudet"
                                        className={classes.menuLink}
                                    >
                                        Polttopuun ominaisuudet
                                    </Link>
                                </MenuItem>

                                <MenuItem
                                    onClick={
                                        this.handleFirewoodInfoDesktopClose
                                    }
                                >
                                    <Link
                                        to="/polttopuuinfo/polttopuun-kuivaus-ja-varastointi"
                                        className={classes.menuLink}
                                    >
                                        Polttopuun kuivaus ja varastointi
                                    </Link>
                                </MenuItem>

                                <MenuItem
                                    onClick={
                                        this.handleFirewoodInfoDesktopClose
                                    }
                                >
                                    <Link
                                        to="/polttopuuinfo/tulisijan-lammitys"
                                        className={classes.menuLink}
                                    >
                                        Tulisijan lämmitys
                                    </Link>
                                </MenuItem>

                                <MenuItem
                                    onClick={
                                        this.handleFirewoodInfoDesktopClose
                                    }
                                >
                                    <Link
                                        to="/polttopuuinfo/energiavertailu"
                                        className={classes.menuLink}
                                    >
                                        Energiavertailu
                                    </Link>
                                </MenuItem>

                                <MenuItem
                                    onClick={
                                        this.handleFirewoodInfoDesktopClose
                                    }
                                >
                                    <Link
                                        to="/polttopuuinfo/energialaskuri"
                                        className={classes.menuLink}
                                    >
                                        Energialaskuri
                                    </Link>
                                </MenuItem>
                            </Menu>
                        </div>
                        <div className={classes.grow} />
                        <IconButton
                            component={Link}
                            to="/ostoskori"
                            aria-label="cart of user"
                            color="inherit"
                        >
                            <Badge
                                badgeContent={cart.totalQuantity}
                                color="error"
                            >
                                <ShoppingCart className={classes.navIcon} />
                            </Badge>
                            <Typography
                                className="margin-left-half"
                                variant="h6"
                            >
                                {setPriceTag(cart.totalPrice)}
                            </Typography>
                        </IconButton>
                        <div className={classes.accountLinks}>
                            {!isAuthenticated && (
                                <IconButton
                                    className={classes.profile}
                                    component={Link}
                                    to="/kirjaudu"
                                    aria-label="account of current user"
                                    color="inherit"
                                >
                                    {!!isAdmin ? (
                                        <SupervisorAccount />
                                    ) : (
                                        <AccountCircle
                                            className={classes.navIcon}
                                        />
                                    )}
                                    <Typography
                                        className="margin-left-half"
                                        variant="h6"
                                    >
                                        Kirjaudu
                                    </Typography>
                                </IconButton>
                            )}
                            {!!isAuthenticated && (
                                <IconButton
                                    className={classes.profile}
                                    component={Link}
                                    to="/profiili"
                                    aria-label="account of current user"
                                    color="inherit"
                                >
                                    <AccountCircle
                                        className={classes.navIcon}
                                    />
                                    <Typography
                                        variant="body1"
                                        className={classes.profileName}
                                    >
                                        {user.username}
                                    </Typography>
                                </IconButton>
                            )}
                            {!!isAdmin && (
                                <IconButton
                                    className={classes.profile}
                                    component={Link}
                                    to="/admin"
                                    aria-label="hallintapaneeli"
                                    color="inherit"
                                >
                                    <Dashboard />
                                    <Typography
                                        variant="h6"
                                        className="margin-left-1"
                                    >
                                        Hallintapaneeli
                                    </Typography>
                                </IconButton>
                            )}
                        </div>
                        <div className={classes.sectionMobile}>
                            <IconButton
                                aria-label="show more"
                                aria-controls={mobileMenuId}
                                aria-haspopup="true"
                                onClick={this.handleMobileMenuOpen}
                                color="inherit"
                            >
                                <MoreIcon />
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
                {renderMobileMenu}
            </div>
        );
    }
}

function mapStateToProps(state: AppState): AppNavbarStateProps {
    return {
        currentUser: state.currentUser,
        uiState: state.uiState.uiProps,
        cart: state.cartState,
    };
}

export default connect<AppNavbarStateProps, IDispatchProps, {}, AppState>(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(AppNavbar));
