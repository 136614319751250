import { IUser } from '../../../../types';

export const SET_NEW_USERS = 'SET_NEW_USERS';
export const ADD_NEW_USER = 'ADD_NEW_USER';
export const UPDATE_NEW_USER = 'UPDATE_NEW_USER';
export const REMOVE_NEW_USER = 'REMOVE_NEW_USER';

export interface SetNewUsers {
    type: typeof SET_NEW_USERS;
    newUsers: IUser[];
}

export interface AddNewUser {
    type: typeof ADD_NEW_USER;
    newUser: IUser;
}

export interface UpdateNewUser {
    type: typeof UPDATE_NEW_USER;
    newUser: IUser;
}

export interface RemoveNewUser {
    type: typeof REMOVE_NEW_USER;
    id: string;
}

export type NewUsersActionTypes =
    | SetNewUsers
    | AddNewUser
    | UpdateNewUser
    | RemoveNewUser;
