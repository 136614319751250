import { combineReducers } from 'redux';
import cartReducer from './cartReducer';
import productReducer from './productReducer';
import sellerReducer from './sellerReducer';
import userProductReducer from './userProductReducer';
import localityReducer from './localitiesReducer';
import signUpFormReducer from './setSignUpFormReducer';
import checkoutFormReducer from './checkoutFormReducer';
import messageReducer from './messageReducer';
import isLoadingReducer from './isLoadingReducer';
import currentUserReducer from './currentUserReducer';
import uiReducer from './uiReducer';
import sidebarReducer from './sidebarReducer';
import regionReducer from './regionReducer';
import productFormReducer from './productFormReducer';
import searchFormReducer from './searchFormReducer';
import orderReducer from './orderReducer';
import ordersReducer from './ordersReducer';
import adminPanelUiStateReducer from './admin/adminPanelUiStateReducer';
import googleMapsUiStateReducer from './googleMapsUiStateReducer';
import accountReducer from './accountReducer';
import advertisementsReducer from './advertisementsReducer';
import resetPasswordFormReducer from './resetPasswordFormReducer';
import accountSettingsFormReducer from './accountSettingsFormReducer';
import userServiceReducer from './userServiceReducer';
import localitiesByRegionReducer from './localitiesByRegionReducer';
import orderTotalsReducer from './orderTotalsReducer';
import favoritesReducer from './favoritesReducer';
import usersReducer from './admin/usersReducer';
import adminPanelPageReducer from './admin/adminPanelPageReducer';
import newUsersReducer from './admin/newUsersReducer';
import contactReducer from './contactReducer';
import contactsReducer from './contactsReducer';
import contactFormReducer from './contactFormReducer';
import otherUserProductReducer from './otherUserProductReducer';
import serviceFormReducer from './serviceFormReducer';
import localityFormReducer from './localityFormReducer';
import regionFormReducer from './regionFormReducer';
import idsReducer from './admin/idsReducer';
import userFormReducer from './admin/userFormReducer';
import deliveryCostReducer from './deliveryCostReducer';
import deliveryCostFormReducer from './deliveryCostFormReducer';

export const rootReducer = combineReducers({
    accounts: accountReducer,
    advertisements: advertisementsReducer,
    adminPanelPageState: adminPanelPageReducer,
    adminPanelUiState: adminPanelUiStateReducer,
    contact: contactReducer,
    contacts: contactsReducer,
    deliveryCosts: deliveryCostReducer,
    deliveryCostForm: deliveryCostFormReducer,
    contactForm: contactFormReducer,
    newUsers: newUsersReducer,
    users: usersReducer,
    ids: idsReducer,
    cartState: cartReducer,
    favorites: favoritesReducer,
    message: messageReducer,
    products: productReducer,
    sellers: sellerReducer,
    userProducts: userProductReducer,
    otherUserProducts: otherUserProductReducer,
    userServices: userServiceReducer,
    order: orderReducer,
    orders: ordersReducer,
    orderTotals: orderTotalsReducer,
    productFormState: productFormReducer,
    userFormState: userFormReducer,
    serviceFormState: serviceFormReducer,
    regionFormState: regionFormReducer,
    searchFormState: searchFormReducer,
    googleMapsState: googleMapsUiStateReducer,
    localities: localityReducer,
    localitiesByRegion: localitiesByRegionReducer,
    localityFormState: localityFormReducer,
    currentUser: currentUserReducer,
    resetPasswordFormState: resetPasswordFormReducer,
    accountSettingsFormState: accountSettingsFormReducer,
    isLoading: isLoadingReducer,
    signUpFormState: signUpFormReducer,
    checkoutFormState: checkoutFormReducer,
    uiState: uiReducer,
    sidebarState: sidebarReducer,
    regions: regionReducer,
});
