import { IUser } from '../../../../../types';
import {
    ADD_USER,
    REMOVE_USER,
    REMOVE_USERS,
    SET_USERS,
    UPDATE_USER,
    UsersAdminActionTypes,
} from '../../../types/admin/usersActionTypes';

const DEFAULT_STATE: IUser[] = [];

const usersReducer = (state = DEFAULT_STATE, action: UsersAdminActionTypes) => {
    switch (action.type) {
        case SET_USERS:
            return action.users;
        case ADD_USER:
            return [...state, action.user];
        case UPDATE_USER:
            return state.map((user: IUser) => {
                if (user._id === action.user._id) {
                    return {
                        ...user,
                        ...action.user,
                    };
                } else {
                    return user;
                }
            });
        case REMOVE_USER:
            return state.filter(({ _id }) => _id !== action.id);
        case REMOVE_USERS:
            return state.filter(
                ({ _id }) => action.ids.includes(_id) === false,
            );
        default:
            return state;
    }
};

export default usersReducer;
