import { IOrder } from '../../../../types';
import { OrderActionTypes, SET_ORDER } from '../../types/orderActionTypes';
import { resetOrder } from '../../utils/resetUtils';

const DEFAULT_STATE: IOrder = resetOrder();

const orderReducer = (state = DEFAULT_STATE, action: OrderActionTypes) => {
    switch (action.type) {
        case SET_ORDER:
            return action.order;
        default:
            return state;
    }
};

export default orderReducer;
