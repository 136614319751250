import { IProduct } from '../../../types';

export const SET_FAVORITES = 'SET_FAVORITES';
export const ADD_FAVORITE = 'ADD_FAVORITE';
export const REMOVE_FAVORITE = 'REMOVE_FAVORITE';

export interface SetFavorites {
    type: typeof SET_FAVORITES;
    favorites: IProduct[];
}

export interface AddFavorite {
    type: typeof ADD_FAVORITE;
    favorite: IProduct;
}

export interface RemoveFavorite {
    type: typeof REMOVE_FAVORITE;
    id: string;
}

export type FavoritesActionTypes = SetFavorites | AddFavorite | RemoveFavorite;
