import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { store } from '../store/store';
import { setLoading } from '../store/actions/loadingActions';
import { setUiPropsValues } from '../utils/utils';
import { updateUiState } from '../store/actions/uiActions';

export function setHeader(contentType: string, token: string | null) {
    axios.defaults.headers.post['Content-Type'] = contentType;
    if (token) {
        // tslint:disable-next-line
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        // tslint:disable-next-line
        delete axios.defaults.headers.common['Authorization'];
    }
}

export const apiCall = (
    method: AxiosRequestConfig['method'],
    path: AxiosRequestConfig['url'],
    data: AxiosRequestConfig['data'],
) => {
    store.dispatch(setLoading(true));
    return new Promise((resolve: (value?: unknown) => void, reject: any) => {
        const config: AxiosRequestConfig = {
            url: path,
            method,
            data,
        };
        return axios(config)
            .then((res: AxiosResponse<any>) => {
                return resolve(res.data);
            })
            .catch((error: any) => {
                if (error.response && error.response.status === 401) {
                    store.dispatch(
                        updateUiState(setUiPropsValues('responseStatus', 401)),
                    );
                }
                return reject(
                    error.response && error.response.status === 401
                        ? {
                              message:
                                  'Istuntosi on vanhentunut, kirjaudu uudelleen sisään!',
                          }
                        : error.response
                        ? error.response.data.error
                        : {
                              message: 'Virhe palvelimella!',
                          },
                );
            })
            .finally(() => {
                store.dispatch(setLoading(false));
            });
    });
};
