import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    InputBaseComponentProps,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import React, { FC, FormEvent } from 'react';
import { connect } from 'react-redux';
import { IUserForm, ILocation, IRegion, IUser } from '../../../../../types';
import { AppState } from '../../../store/store';
import { mapDispatchToProps } from '../../../types/dispatchActionTypes';
import { setInputValues } from '../../../utils/utils';

interface IOwnProps {
    userId: string;
}

interface IEditUserFormStateProps {
    users: IUser[];
    userForm: IUserForm;
    regions: IRegion[];
    localities: ILocation[];
}

type EditUserFormProps = IEditUserFormStateProps & IOwnProps & IDispatchProps;

const EditUserForm: FC<EditUserFormProps> = (props) => {
    const { userForm, users, userId, regions, localities } = props;
    const user = users.find((userInFind: IUser) => userInFind._id === userId);
    const {
        username,
        email,
        phonenumber,
        firstname,
        lastname,
        street,
        zip,
        region,
        locality,
    } = userForm;
    /** Change handler */
    const handleChange = (event: InputBaseComponentProps) => {
        const { updateUserFormAction } = props;
        updateUserFormAction(setInputValues(event));
    };

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        const { editUserAction } = props;
        editUserAction(userForm, userId);
    };
    return (
        <Grid container={true} justify="center">
            <Grid item={true} md={8}>
                <Box p={2}>
                    {user ? (
                        <form>
                            <Grid container={true} justify="center">
                                <Grid item={true} xs={12} md={6}>
                                    <Box p={1}>
                                        <TextField
                                            fullWidth={true}
                                            type="text"
                                            name="username"
                                            value={
                                                username
                                                    ? username
                                                    : user?.username
                                                    ? user.username
                                                    : username
                                            }
                                            onChange={(
                                                event: InputBaseComponentProps,
                                            ) => handleChange(event)}
                                            required={true}
                                            variant="outlined"
                                            color="primary"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item={true} xs={12} md={6}>
                                    <Box p={1}>
                                        <TextField
                                            fullWidth={true}
                                            type="email"
                                            name="email"
                                            value={
                                                email
                                                    ? email
                                                    : user?.email
                                                    ? user.email
                                                    : email
                                            }
                                            onChange={(
                                                event: InputBaseComponentProps,
                                            ) => handleChange(event)}
                                            required={true}
                                            variant="outlined"
                                            color="primary"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item={true} xs={12} md={6}>
                                    <Box p={1}>
                                        <TextField
                                            fullWidth={true}
                                            type="phonenumber"
                                            name="tel"
                                            value={
                                                phonenumber
                                                    ? phonenumber
                                                    : user?.contactInformation
                                                          .phonenumbers[0]
                                                    ? user.contactInformation
                                                          .phonenumbers[0]
                                                    : phonenumber
                                            }
                                            onChange={(
                                                event: InputBaseComponentProps,
                                            ) => handleChange(event)}
                                            required={true}
                                            variant="outlined"
                                            color="primary"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item={true} xs={12} md={12}>
                                    <Box p={1}>
                                        <Typography variant="h4">
                                            Nimi- ja osoitetiedot
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item={true} md={6} sm={12}>
                                    <Box p={1}>
                                        <TextField
                                            fullWidth={true}
                                            type="text"
                                            name="firstname"
                                            variant="outlined"
                                            color="primary"
                                            value={
                                                firstname
                                                    ? firstname
                                                    : user?.contactInformation
                                                          .firstname
                                                    ? user.contactInformation
                                                          .firstname
                                                    : firstname
                                            }
                                            onChange={(
                                                event: InputBaseComponentProps,
                                            ) => handleChange(event)}
                                            required={true}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item={true} md={6} sm={12}>
                                    <Box p={1}>
                                        <TextField
                                            fullWidth={true}
                                            type="text"
                                            name="lastname"
                                            variant="outlined"
                                            color="primary"
                                            value={
                                                lastname
                                                    ? lastname
                                                    : user?.contactInformation
                                                          .lastname
                                                    ? user.contactInformation
                                                          .lastname
                                                    : lastname
                                            }
                                            onChange={(
                                                event: InputBaseComponentProps,
                                            ) => handleChange(event)}
                                            required={true}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item={true} md={6} sm={12}>
                                    <Box p={1}>
                                        <TextField
                                            fullWidth={true}
                                            type="text"
                                            name="street"
                                            variant="outlined"
                                            color="primary"
                                            value={
                                                street
                                                    ? street
                                                    : user?.address.street
                                                    ? user.address.street
                                                    : street
                                            }
                                            onChange={(
                                                event: InputBaseComponentProps,
                                            ) => handleChange(event)}
                                            required={true}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item={true} md={6} sm={12}>
                                    <Box p={1}>
                                        <TextField
                                            fullWidth={true}
                                            type="text"
                                            name="zip"
                                            variant="outlined"
                                            color="primary"
                                            value={
                                                zip
                                                    ? zip
                                                    : user?.address.zip
                                                    ? user.address.zip
                                                    : zip
                                            }
                                            onChange={(
                                                event: InputBaseComponentProps,
                                            ) => handleChange(event)}
                                            required={true}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item={true} xs={12} md={6}>
                                    <Box p={1}>
                                        <FormControl
                                            fullWidth={true}
                                            required={true}
                                        >
                                            <InputLabel id="demo-simple-select-required-label">
                                                Maakunta
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-required-label"
                                                id="demo-simple-select-required"
                                                name="region"
                                                value={
                                                    region
                                                        ? region
                                                        : user
                                                        ? user.address.region
                                                              ._id
                                                        : region
                                                }
                                                onChange={handleChange}
                                            >
                                                <MenuItem
                                                    value={
                                                        region
                                                            ? region
                                                            : user
                                                            ? user.address
                                                                  .region._id
                                                            : region
                                                    }
                                                >
                                                    <em>
                                                        {
                                                            user.address.region
                                                                .name
                                                        }
                                                    </em>
                                                </MenuItem>
                                                {!!regions.length &&
                                                    regions.map(
                                                        (region2: IRegion) => {
                                                            return (
                                                                <MenuItem
                                                                    key={
                                                                        region2._id
                                                                    }
                                                                    value={
                                                                        region2._id
                                                                    }
                                                                >
                                                                    {
                                                                        region2.name
                                                                    }
                                                                </MenuItem>
                                                            );
                                                        },
                                                    )}
                                            </Select>
                                            <FormHelperText>
                                                Valitse toinen maakunta
                                            </FormHelperText>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item={true} xs={12} md={6}>
                                    <Box p={1}>
                                        <FormControl
                                            fullWidth={true}
                                            required={true}
                                        >
                                            <InputLabel id="demo-simple-select-required-label">
                                                Kunta
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-required-label"
                                                id="demo-simple-select-required"
                                                name="locality"
                                                value={
                                                    locality ||
                                                    user.address.locality._id
                                                }
                                                onChange={(
                                                    event: InputBaseComponentProps,
                                                ) => handleChange(event)}
                                            >
                                                <MenuItem
                                                    value={
                                                        locality ||
                                                        user.address.locality
                                                            ._id
                                                    }
                                                >
                                                    <em>
                                                        {
                                                            user.address
                                                                .locality
                                                                .location
                                                        }
                                                    </em>
                                                </MenuItem>
                                                {!!localities.length &&
                                                    localities.map(
                                                        (
                                                            locality2: ILocation,
                                                        ) => {
                                                            return (
                                                                <MenuItem
                                                                    key={
                                                                        locality2._id
                                                                    }
                                                                    value={
                                                                        locality2._id
                                                                    }
                                                                >
                                                                    {
                                                                        locality2.location
                                                                    }
                                                                </MenuItem>
                                                            );
                                                        },
                                                    )}
                                            </Select>
                                            <FormHelperText>
                                                Jos haluat muuttaa kuntaa
                                                muualle Suomeen, muuta ensin
                                                maakuntaa
                                            </FormHelperText>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                {user.business && (
                                    <>
                                        <Grid item={true} xs={12} md={12}>
                                            <Box p={1}>
                                                <Typography variant="h4">
                                                    Yritystiedot
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item={true} xs={12} md={6}>
                                            <Box p={1}>
                                                <TextField
                                                    fullWidth={true}
                                                    type="text"
                                                    name="ytunnus"
                                                    value={user.ytunnus}
                                                    onChange={(
                                                        event: InputBaseComponentProps,
                                                    ) => handleChange(event)}
                                                    variant="outlined"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item={true} xs={12} md={6}>
                                            <Box p={1}>
                                                <TextField
                                                    fullWidth={true}
                                                    type="text"
                                                    name="companyName"
                                                    value={user.companyName}
                                                    onChange={(
                                                        event: InputBaseComponentProps,
                                                    ) => handleChange(event)}
                                                    variant="outlined"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item={true} xs={12} md={6}>
                                            <Box p={1}>
                                                <TextField
                                                    name="website"
                                                    type="text"
                                                    fullWidth={true}
                                                    value={user.website}
                                                    onChange={(
                                                        event: InputBaseComponentProps,
                                                    ) => handleChange(event)}
                                                    variant="outlined"
                                                />
                                            </Box>
                                        </Grid>
                                    </>
                                )}
                                <Grid item={true} xs={12} md={12}>
                                    <Box p={1}>
                                        <Button
                                            type="submit"
                                            variant="outlined"
                                            color="primary"
                                            onClick={(event: FormEvent) =>
                                                handleSubmit(event)
                                            }
                                        >
                                            Tallenna
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                    ) : (
                        <h2>Käyttäjää ei löytynyt!</h2>
                    )}
                </Box>
            </Grid>
        </Grid>
    );
};

function mapStateToProps(state: AppState): IEditUserFormStateProps {
    return {
        users: state.users,
        userForm: state.userFormState.userForm,
        regions: state.regions,
        localities: state.localities,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUserForm);
