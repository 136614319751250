import { Dispatch } from 'redux';
import { IAdminPanelPage } from '../../../../../types';
import { apiCall, setHeader } from '../../../apiRequests/apiCall';
import { AppActions } from '../../../types/actions';
import {
    RESET_ADMIN_PANEL_PAGE,
    UPDATE_ADMIN_PANEL_PAGE,
} from '../../../types/admin/AdminPanelPageActionTypes';
import { addMessage } from '../messageActions';

export function updateAdminPanelPageState(
    adminPanelPage: IAdminPanelPage,
): AppActions {
    return {
        type: UPDATE_ADMIN_PANEL_PAGE,
        adminPanelPageState: { adminPanelPage },
    };
}

export function resetAdminPanelPageState(
    adminPanelPage: IAdminPanelPage,
): AppActions {
    return {
        type: RESET_ADMIN_PANEL_PAGE,
        adminPanelPageState: { adminPanelPage },
    };
}

export const fetchAdminPanelPageData = (): ThunkResult<void> => {
    setHeader('get', window.localStorage.getItem('token'));
    return (dispatch: Dispatch<AppActions>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('get', '/api/admin', null)
                .then((res: any) => {
                    dispatch(updateAdminPanelPageState(res));
                    resolve();
                })
                .catch((error: Error) => {
                    dispatch(
                        addMessage({
                            text: error.message,
                            bgColor: 'danger',
                            visible: true,
                        }),
                    );
                    reject();
                });
        });
    };
};
