import { AppActions } from '../../../types/actions';
import {
    ADD_TO_IDS,
    REMOVE_FROM_IDS,
    RESET_IDS,
    UPDATE_IDS,
} from '../../../types/admin/idsActionTypes';

export function addToIds(id: string): AppActions {
    return {
        type: ADD_TO_IDS,
        id,
    };
}

export function removeFromIds(id: string): AppActions {
    return {
        type: REMOVE_FROM_IDS,
        id,
    };
}

export function updateIds(ids: string[]): AppActions {
    return {
        type: UPDATE_IDS,
        ids,
    };
}

export function resetIds(ids: []): AppActions {
    return {
        type: RESET_IDS,
        ids,
    };
}
